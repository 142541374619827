import { useStrings } from "@1js/localization-components";
import {
  Avatar,
  InteractionTag,
  InteractionTagPrimary,
  Tag,
  makeStyles,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { ChevronDown16Regular } from "@fluentui/react-icons";
import * as React from "react";
import type { ProductType } from "../../../data";
import { BadgesGroupLabel, CategoriesGroupLabel } from "../../../sharedStrings";
import {
  usePromptBadge,
  type BadgeProps,
  type InteractiveBadgeProps,
  type WorkgroupBadgeProps,
} from "./PromptBadge.hook";

const useStyles = makeStyles({
  chevronDown: {
    verticalAlign: "text-bottom",
    paddingLeft: "4px",
  },
  iconOnly: {
    paddingRight: "0px",
  },
  iconOnlyInteractive: {
    paddingRight: "0px",
  },
  iconAndTextInteractiveInner: {
    fontWeight: "600",
  },
});

export const InteractivePromptBadge: React.FC<InteractiveBadgeProps> = ({
  badge,
  tagSize,
  displayMode,
  labelWrapper,
  appearance,
  withOptions,
}) => {
  const { getBadgeIconInteractive, onBadgeAction, badgeToLabel, tagRef } =
    usePromptBadge({ badge });
  const localizations = useStrings({
    BadgesGroupLabel,
    CategoriesGroupLabel,
  });

  const styles = useStyles();
  const restoreFocusTargetAttribute = useRestoreFocusTarget();

  return (
    <InteractionTag size={tagSize} appearance={appearance}>
      <InteractionTagPrimary
        {...restoreFocusTargetAttribute}
        ref={tagRef}
        aria-label={
          displayMode !== "textOnly"
            ? localizations.BadgesGroupLabel() +
              badgeToLabel(badge.id, labelWrapper)
            : ""
        }
        icon={
          displayMode !== "textOnly"
            ? getBadgeIconInteractive(badge.id as ProductType)
            : undefined
        }
        role="listitem"
        data-testid="interactive-prompt-badge"
        className={
          displayMode === "iconOnly" ? styles.iconOnlyInteractive : undefined
        }
        onClick={onBadgeAction}
      >
        <div className={styles.iconAndTextInteractiveInner}>
          {displayMode !== "iconOnly" && badgeToLabel(badge.id, labelWrapper)}
          {withOptions && (
            <ChevronDown16Regular
              className={styles.chevronDown}
              data-testid="chevron-down-icon"
            />
          )}
        </div>
      </InteractionTagPrimary>
    </InteractionTag>
  );
};

InteractivePromptBadge.displayName = "InteractiveBadge";

export const NonInteractivePromptBadge: React.FC<BadgeProps> = ({
  badge,
  tagSize,
  displayMode,
  labelWrapper,
}) => {
  const { getBadgeIconNonInteractive, badgeToLabel } = usePromptBadge({
    badge,
  });

  const localizations = useStrings({
    BadgesGroupLabel,
    CategoriesGroupLabel,
  });

  const styles = useStyles();
  return (
    <Tag
      aria-label={
        displayMode !== "textOnly"
          ? localizations.BadgesGroupLabel() +
            badgeToLabel(badge.id, labelWrapper)
          : ""
      }
      size={tagSize}
      icon={
        displayMode !== "textOnly"
          ? getBadgeIconNonInteractive(badge.id as ProductType)
          : undefined
      }
      role="listitem"
      data-testid="prompt-badge"
      className={displayMode === "iconOnly" ? styles.iconOnly : undefined}
    >
      {displayMode !== "iconOnly" && badgeToLabel(badge.id, labelWrapper)}
    </Tag>
  );
};

NonInteractivePromptBadge.displayName = "NonInteractiveBadge";

export const WorkgroupPromptBadge: React.FC<WorkgroupBadgeProps> = ({
  badge,
}) => {
  const image = badge.group.AvatarUrl
    ? { src: badge.group.AvatarUrl }
    : undefined;
  return (
    <Avatar
      name={badge.group.DisplayName}
      shape="square"
      image={image}
      color="colorful"
      size={24}
      role="listitem"
      data-testid="workgroup-prompt-badge"
    />
  );
};

WorkgroupPromptBadge.displayName = "WorkgroupBadge";
