// Copyright (C) Microsoft Corporation. All rights reserved.

import {
  generateExtensionTelemetryValue,
  type NoCustomerRelatedFilterDimension,
  type NoCustomerRelatedFilterValue,
  type Prompt,
  type PromptTier,
} from "@1js/pl-card-components";
import {
  extractJobTypes,
  isExtensionPrompt,
  isWorkgroupPrompt,
  type ExtensionPrompt,
} from "@1js/pl-types";
import type { IPublicClientApplication } from "@azure/msal-browser";
import {
  HandoffErrors,
  type HandoffErrorsType,
} from "../../hooks/handoff.types";
import type { LinkedPrompt } from "../../services/prompt-client.web";
import type { VideoTelemetry } from "../../types";
import type {
  Action,
  ApiErrorReason,
  ApiName,
  ApiName as ApiNameType,
  Context,
  FilterAction,
  FilterStep,
  HttpStatusCode,
  SettingAction,
  SettingName,
  Step,
  UINotificationAction,
  UINotificationName,
} from "./config";
import {
  ActionInputMethods,
  ActionTarget,
  FeatureActionType,
  getDataFieldName,
  readFromMetaTags,
} from "./config";
import { type Events } from "./events";
import { telemetrySender } from "./telemetrySender";
import {
  getFocusedElementRecursiveId,
  getPageLoadTime,
  getPromptOrigin,
} from "./utils";

declare global {
  interface Window {
    // Note: the following line is used to set the OCV Feedback contextual data (e.g last clicked prompt)
    setFeedbackContextData: (prompt: Prompt) => void;
  }
}

/**
 * Send an intentional Click event
 *
 * @param eventName Event Name
 * @param dataFields Optional Otel Data Fields
 * @param actionTarget Can pertain to, for example a document target destination path or a UX element that the action
 * @param featureActionType What's type of action represent for the feature?
 */
function sendClickTelemetryEvent(
  eventName: keyof typeof Events,
  dataFields?: {
    [key: string]:
      | string
      | number
      | boolean
      | Record<string, string | string[]>
      | undefined;
  },
  actionTarget = ActionTarget.Button,
  featureActionType = FeatureActionType.IsTried
) {
  telemetrySender.sendTelemetryEvent(
    eventName,
    {
      ...(dataFields ?? {}),
      ElementId: getFocusedElementRecursiveId(),
    },
    true,
    ActionInputMethods.All,
    actionTarget,
    featureActionType
  );
}

/**
 * Sends a Page Init telemetry event (before the window "load" event)
 */
export function sendPageInitEvent() {
  telemetrySender.sendTelemetryEvent("PageInit");
}

/**
 * Sends a Page Load telemetry event (after the window "load" event)
 */
export function sendPageLoadEvent() {
  telemetrySender.sendTelemetryEvent("PageLoad", {
    "Page.LoadTime": getPageLoadTime(),
  });
}

/**
 * Sends a Page Shutdown telemetry event (after the window "beforeunload" event)
 */
export function sendPageShutdownEvent() {
  telemetrySender.sendTelemetryEvent("Shutdown");
}

/**
 * Sends a telemetry event for video telemetry
 */
export function sendVideoTelemetryEvent(videoTelemetry: VideoTelemetry) {
  telemetrySender.sendTelemetryEvent(
    "VideoPlayerActions",
    videoTelemetry,
    true,
    ActionInputMethods.Mouse,
    ActionTarget.Button,
    FeatureActionType.IsTried
  );
}

/**
 * Sends a Prompt Clicked telemetry event
 * @param prompt Prompt Object
 * @param isFeatured Is featured prompt?
 */
export function sendPromptClickedEvent(prompt: Prompt, isFeatured = false) {
  // Note: the following line is used to set the OCV Feedback contextual data (e.g last clicked prompt)
  // This is unfortunately creating unnecessary coupling with the the Telemetry module and needs to be improved
  if (typeof window.setFeedbackContextData === "function") {
    window.setFeedbackContextData(prompt);
  }

  sendClickTelemetryEvent("PromptClicked", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("Featured")]: isFeatured,
    [getDataFieldName("IsFavorite")]: prompt.UserActivity?.Favorite?.IsFavorite,
    [getDataFieldName("ReferenceId")]: prompt.ReferenceId ?? undefined,
    [getDataFieldName("LogicalId")]: prompt.LogicalId,
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("Extension")]: isExtensionPrompt(prompt)
      ? generateExtensionTelemetryValue({
          Origin: prompt.Origin as string,
          ProviderPartyType: (prompt as ExtensionPrompt)
            .ProviderPartyType as string,
        })
      : undefined,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
  });
}

/**
 * Sends a Copy Prompt telemetry event
 * @param prompt Prompt Object
 * @param isFeatured Is featured prompt?
 */
export function sendCopyPromptEvent(prompt: Prompt, isFeatured = false) {
  sendClickTelemetryEvent("CopyPrompt", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("Featured")]: isFeatured,
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ReferenceId")]: prompt.ReferenceId ?? undefined,
    [getDataFieldName("LogicalId")]: prompt.LogicalId,
    [getDataFieldName("Extension")]: isExtensionPrompt(prompt)
      ? generateExtensionTelemetryValue({
          Origin: prompt.Origin as string,
          ProviderPartyType: (prompt as ExtensionPrompt)
            .ProviderPartyType as string,
        })
      : undefined,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
  });
}

/**
 * Sends a Share Prompt telemetry event
 * @param prompt Prompt Object
 * @param isFeatured Is featured prompt?
 */
export function sendSharePromptEvent(prompt: Prompt, isFeatured = false) {
  sendClickTelemetryEvent("SharePrompt", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("Featured")]: isFeatured,
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ReferenceId")]: prompt.ReferenceId ?? undefined,
    [getDataFieldName("LogicalId")]: prompt.LogicalId,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
  });
}

/**
 * Sends a Share Prompt via Email telemetry event
 * @param prompt Prompt Object
 * @param MsGraphLatency Microsoft Graph call latency
 * @param MsGraphResponseCode Microsoft Graph call response code (e.g. 200, 400)
 */
export function sendSharePromptViaEmailEvent(
  prompt: Prompt,
  MsGraphLatency: number,
  MsGraphResponseCode: number
) {
  sendClickTelemetryEvent("SharePromptViaEmail", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ReferenceId")]: prompt.ReferenceId ?? undefined,
    [getDataFieldName("LogicalId")]: prompt.LogicalId,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
    "MSGraph.Latency": MsGraphLatency,
    "MSGraph.ResponseCode": MsGraphResponseCode,
  });
}

export function sendTryInAppEventCopy(
  prompt: Prompt,
  product: string,
  error?: HandoffErrorsType
) {
  if (!!error && !HandoffErrors.has(error)) {
    error = "UnknownError";
  }

  sendClickTelemetryEvent("TryInAppCopy", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    IsUserPrompt: getPromptOrigin(prompt) === "User",
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ProductTried")]: product,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
    [getDataFieldName("Result")]: error,
  });
}

export function sendTryInAppEventError(
  prompt: Prompt,
  product: string,
  error: HandoffErrorsType
) {
  if (!HandoffErrors.has(error)) {
    error = "UnknownError";
  }

  sendClickTelemetryEvent("TryInAppError", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    IsUserPrompt: getPromptOrigin(prompt) === "User",
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ProductTried")]: product,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
    [getDataFieldName("Result")]: error,
  });
}

export function sendTryInAppEventLoading(prompt: Prompt, product: string) {
  sendClickTelemetryEvent("TryInAppLoading", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    IsUserPrompt: getPromptOrigin(prompt) === "User",
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ProductTried")]: product,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
  });
}

export function sendTryInAppEventSuccess(
  prompt: Prompt,
  product: string,
  queryId?: string
) {
  sendClickTelemetryEvent("TryInAppSuccess", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    IsUserPrompt: getPromptOrigin(prompt) === "User",
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ProductTried")]: product,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
    [getDataFieldName("QueryId")]: queryId,
  });
}

export function sendTryInAppEventCancelled(
  prompt: Prompt,
  product: string,
  error?: HandoffErrorsType
) {
  if (!!error && !HandoffErrors.has(error)) {
    error = "UnknownError";
  }
  sendClickTelemetryEvent("TryInAppCancelled", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    IsUserPrompt: getPromptOrigin(prompt) === "User",
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ProductTried")]: product,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
    [getDataFieldName("Result")]: error,
  });
}

/**
 * Sends a LoadMorePrompts telemetry event
 */
export function sendLoadMorePromptsEvent() {
  sendClickTelemetryEvent("LoadMorePrompts");
}

/**
 * Sends a "More Like This" Prompt Click telemetry event
 * @param prompt Prompt Object
 */
export function sendMoreLikeThisPromptClickEvent(prompt: Prompt) {
  sendClickTelemetryEvent("MoreLikeThisPromptClicked", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
  });
}
/**
 * Warning! Sends a PromptsFilter telemetry event
 * Warning! Do NOT send customer related data to this function!
 * @param filters Prompt filter
 */
export function sendPromptsFilterEvent(
  filters: Record<
    NoCustomerRelatedFilterDimension,
    NoCustomerRelatedFilterValue[]
  >
) {
  sendClickTelemetryEvent(
    "PromptsFilter",
    {
      "Prompt.Filters": JSON.stringify(filters),
    },
    ActionTarget.DropDown
  );
}

/**
 * Sends a PromptBookmarked telemetry event
 * @param prompt Prompt Object
 * @param isFeatured Is featured prompt?
 */
export function sendPromptBookmarkedEvent(prompt: Prompt, isFeatured = false) {
  sendClickTelemetryEvent("PromptBookmarked", {
    // Agent telemetry cannot contain the promptId property
    PromptId: !isExtensionPrompt(prompt) ? prompt.PromptId : undefined,
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("Featured")]: isFeatured,
    [getDataFieldName("IsFavorite")]: prompt.UserActivity.Favorite.IsFavorite,
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ReferenceId")]: prompt.ReferenceId ?? undefined,
    [getDataFieldName("LogicalId")]: prompt.LogicalId,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
  });
}

/**
 * Sends a PromptLiked telemetry event
 * @param prompt Prompt Object
 */
export function sendPromptLikedEvent(prompt: Prompt) {
  sendClickTelemetryEvent("PromptLiked", {
    // Liking telemetry cannot contain the promptId property
    // See: https://o365trustcompliance.visualstudio.com/DefaultCollection/Trust/_workitems/edit/917179#14119061
    [getDataFieldName("Origin")]: getPromptOrigin(prompt),
    [getDataFieldName("IsFavorite")]: prompt.UserActivity.Favorite.IsFavorite,
    [getDataFieldName("DisplayCategory")]: prompt.DisplayCategory,
    [getDataFieldName("Products")]: prompt.Products.join(","),
    [getDataFieldName("JobType")]: extractJobTypes(prompt).join(","),
    [getDataFieldName("ReferenceId")]: prompt.ReferenceId ?? undefined,
    [getDataFieldName("LogicalId")]: prompt.LogicalId,
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
  });
}

/**
 * Sends a SelectTab telemetry event
 * @param promptTier PromptTier
 */
export function sendSelectTabEvent(promptTier: PromptTier) {
  sendClickTelemetryEvent("SelectTab", {
    "Prompt.Tier": promptTier,
  });
}

/**
 * Sends a PageScroll telemetry event
 * @param scrollDetails Page scroll details object
 */
export function sendPageScrollEvent(scrollDetails: {
  viewportWidth: number;
  viewportHeight: number;
  scrollLeft: number;
  scrollTop: number;
  pageHeight: number | undefined;
}) {
  telemetrySender.sendTelemetryEvent(
    "PageScroll",
    scrollDetails,
    true,
    ActionInputMethods.Mouse,
    ActionTarget.ScrollArea
  );
}

/**
 * Sends a Click telemetry event
 * @param clickDetails Page click details object
 */
export function sendClickEvent(clickDetails: {
  clickCoordinateX: number;
  clickCoordinateY: number;
  viewportWidth: number;
  viewportHeight: number;
  scrollLeft: number;
  scrollTop: number;
  pageHeight: number | undefined;
}) {
  telemetrySender.sendTelemetryEvent(
    "Click",
    clickDetails,
    true,
    ActionInputMethods.Mouse
  );
}

/**
 * Sends a ResultsRendered event
 * @param E2ELatency e2e latency of prompts rendering process
 * @param is3SServiceEnabled is 3s enabled?
 * @param prompts prompts returned by the allPrompts endpoint
 */
export function sendResultsRenderedEvent(
  E2ELatency: number,
  is3SServiceEnabled: boolean,
  prompts: Prompt[],
  msalInstance?: IPublicClientApplication
) {
  telemetrySender.sendTelemetryEvent(
    "ResultsRendered",
    {
      E2ELatency,
      Is3SServiceEnabled: is3SServiceEnabled,
      [getDataFieldName("LogicalId")]:
        prompts.length > 0 ? prompts[0].LogicalId : undefined,
    },
    undefined,
    undefined,
    undefined,
    undefined,
    msalInstance
  );
}

/**
 * Sends a ResponseReceived event
 * @param Latency latency of the response
 * @param Status status code of the response
 * @param TraceId trace id of the response
 */
export function sendResponseReceivedEvent(
  Latency: number,
  Status: number,
  TraceId: string,
  msalInstance?: IPublicClientApplication
) {
  telemetrySender.sendTelemetryEvent(
    "ResponseReceived",
    {
      Latency,
      Status,
      TraceId,
    },
    undefined,
    undefined,
    undefined,
    undefined,
    msalInstance
  );
}

/**
 * Sends a Notification event
 * @param apiName Name of API causing the notification
 * @param apiStatus Status of API causing the notification
 * @param action Action triggered
 */
export function sendNotificationActionEvent(
  apiName: ApiName,
  apiStatus: HttpStatusCode,
  action: Action
) {
  sendClickTelemetryEvent("NotificationAction", {
    APIName: apiName,
    APIStatus: apiStatus,
    Action: action,
  });
}

/**
 * Sends a FilterAction event
 * @param name Name of the filter
 * @param step Step of filter interaction
 */
export function sendFilterActionEvent(
  context: Context,
  step: FilterStep,
  action: FilterAction,
  actionContext?: Record<string, string[]>
) {
  sendClickTelemetryEvent("FilterAction", {
    Context: context,
    Step: step,
    Action: action,
    ActionContext: actionContext || {},
  });
}

/**
 * Sends an UINotificationsActions event
 */
export function sendUINotificationsActionEvent(
  action: UINotificationAction,
  name: UINotificationName
) {
  sendClickTelemetryEvent("UINotificationsActions", {
    Action: action,
    Name: name,
  });
}

/**
 * Sends an setting related event
 */
export function sendSettingsActionEvent(
  action: SettingAction,
  name: SettingName
) {
  sendClickTelemetryEvent("SettingsAction", {
    Action: action,
    Name: name,
  });
}

/**
 * Sends an unshare prompt event
 */
export function sendUnsharePromptEvent(prompt: LinkedPrompt) {
  sendClickTelemetryEvent("UnsharePrompt", {
    PromptId: prompt.PromptId,
    Link: prompt.Link,
  });
}

export type SendApiActionEventParameter = {
  apiName: ApiNameType;
  status?: HttpStatusCode;
  isInit?: boolean;
  isFromCache?: boolean;
  errorReason?: ApiErrorReason;
  supportServiceLatency?: number;
  substrateLatency?: number;
  msGraphLatency?: number;
  successfulResultCount?: number;
  errorResultCount?: number;
  traceId: string;
  repository?: string;
};

/*
Sends an ApiAction event.
@param apiName Name of the API.
@param status HTTP status code of the response.
@param isInit Indicates whether the event is for the outgoing part of the API call.
@param isFromCache Indicates whether the response was from cache.
@param errorReason Reason for the error.
@param supportServiceLatency Latency of the support service.
@param substrateLatency Latency of the substrate.
@param msGraphLatency Latency of the Microsoft Graph.
@param successfulResultCount Number of successful results.
@param errorResultCount Number of error results.
@param traceId Trace ID of the API-call.
*/
export function sendApiActionEvent({
  apiName,
  status,
  isInit,
  isFromCache,
  errorReason,
  supportServiceLatency,
  substrateLatency,
  msGraphLatency,
  successfulResultCount,
  errorResultCount,
  traceId,
  repository,
}: SendApiActionEventParameter) {
  telemetrySender.sendTelemetryEvent("ApiAction", {
    APIName: apiName,
    APIStatus: status,
    IsInit: !!isInit,
    IsFromCache: isFromCache,
    ErrorReason: errorReason,
    SupportServiceLatency: supportServiceLatency,
    SubstrateLatency: substrateLatency,
    MsGraphLatency: msGraphLatency,
    SuccessfulResultCount: successfulResultCount,
    ErrorResultCount: errorResultCount,
    TraceId: traceId,
    Repository: repository,
  });
}

/*
 * Sends a PromptAction event
 * @param prompt Prompt Object
 * @param context The feature context of this event
 * @param step The step of the feature for the telemetry event
 * @param action The action of the feature at the Step for the telemetry event
 * @param isFeatured Is featured prompt?
 */
export function sendPromptActionEvent(
  context: Context,
  step: Step,
  action: Action,
  prompt: Prompt,
  isFeatured = false
) {
  // Note: the following line is used to set the OCV Feedback contextual data (e.g last clicked prompt)
  // This is unfortunately creating unnecessary coupling with the the Telemetry module and needs to be improved
  if (prompt && typeof window.setFeedbackContextData === "function") {
    window.setFeedbackContextData(prompt);
  }

  sendClickTelemetryEvent("PromptAction", {
    PromptId: prompt?.PromptId ?? "",
    Context: context,
    Step: step,
    Action: action,
    [getDataFieldName("Origin")]: (prompt && getPromptOrigin(prompt)) || "null",
    [getDataFieldName("Featured")]: (prompt && isFeatured) || "null",
    [getDataFieldName("IsFavorite")]:
      prompt?.UserActivity?.Favorite?.IsFavorite,
    [getDataFieldName("ReferenceId")]:
      (prompt && prompt.ReferenceId) || undefined,
    [getDataFieldName("LogicalId")]: (prompt && prompt.LogicalId) || "null",
    [getDataFieldName("DisplayCategory")]:
      (prompt && prompt.DisplayCategory) || "null",
    [getDataFieldName("Products")]:
      (prompt && prompt.Products.join(",")) || "null",
    [getDataFieldName("DeliveryOrigin")]:
      (isWorkgroupPrompt(prompt) && "Workgroup") || "null",
  });
}

/**
 *
 * See https://docs.substrate.microsoft.net/docs/Substrate-APIs-and-services/Substrate-Search-Service-%283S%29/3S-API-Reference/Events.html?uid=events#client-layout
 * @param prompts
 * @param layoutType
 */
export function sendClientLayoutEvent(
  prompts: Prompt[],
  layoutType: "Vertical" | "Horizontal",
  msalInstance?: IPublicClientApplication
) {
  telemetrySender.sendTelemetryEvent(
    "ClientLayout",
    {
      [getDataFieldName("LogicalId")]:
        prompts.length > 0 ? prompts[0].LogicalId : undefined,
      LayoutType: layoutType,
      ResultsView: JSON.stringify(
        prompts.map((prompt, i) => {
          return {
            Type: "Entity",
            EntityType: "Prompt",
            Name: null,
            ReferenceId: prompt.ReferenceId,
            Position: (i + 1).toString(),
            LayoutType: null,
            ResultsView: null,
          };
        })
      ),
    },
    undefined,
    undefined,
    undefined,
    undefined,
    msalInstance
  );
}
/**
 * Sends a sign in suggestion header click telemetry event
 */
export function sendSignInSuggestionHeaderClickEvent() {
  sendClickTelemetryEvent("NudgeSignIn");
}

/**
 * Sends a sign in prompt click telemetry event
 */
export function sendSignInPromptClickEvent() {
  sendClickTelemetryEvent("PromptNudgeSignIn");
}

/**
 * Sends a telemetry event if the user is signed in and has a Copilot license
 */
export function sendCopilotLicenseStateEvent(isUserCopilotLicensed: boolean) {
  telemetrySender.sendTelemetryEvent("UserHasCopilotLicensed", {
    isUserCopilotLicensed,
  });
}

export function sendDiagnosticEvent(
  logType: string,
  message: string,
  logCategory: string,
  msalInstance?: IPublicClientApplication
) {
  let userLicenses = readFromMetaTags("awa-userLicenses");
  if (
    userLicenses === undefined ||
    (userLicenses && userLicenses.length === 0)
  ) {
    userLicenses = "";
  }

  telemetrySender.sendTelemetryEvent(
    "DiagnosticEvent",
    {
      [getDataFieldName("LogType")]: logType,
      [getDataFieldName("Message")]: message,
      [getDataFieldName("LogCategory")]: logCategory,
      [getDataFieldName("UserLicenses")]: userLicenses,
    },
    undefined,
    undefined,
    undefined,
    undefined,
    msalInstance
  );
}
