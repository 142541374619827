import { useStrings } from "@1js/localization-components";
import type { ButtonProps } from "@fluentui/react-components";
import { ToggleButton, Tooltip } from "@fluentui/react-components";
import {
  EditArrowBackFilled,
  EditArrowBackRegular,
  EditFilled,
  EditRegular,
  SaveEditFilled,
  SaveEditRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import * as React from "react";
import type { Appearance } from "../data";
import {
  BackButtonTooltip,
  EditButtonTooltip,
  EditSaveButtonTooltip,
} from "./PromptEdit.strings";
import { useStyles } from "./PromptEdit.styles";

export type PromptEditProps = {
  appearance?: Appearance;
  onEditToggled?: () => void;
  onEditSaved?: () => void;
  isEditing?: boolean;
  tabIndex?: number;
};

const EditIcon = bundleIcon(EditFilled, EditRegular);
const SaveIcon = bundleIcon(SaveEditRegular, SaveEditFilled);
const BackIcon = bundleIcon(EditArrowBackRegular, EditArrowBackFilled);

export const PromptEdit: React.FC<PromptEditProps> = ({
  appearance,
  onEditToggled,
  onEditSaved,
  isEditing,
  tabIndex,
}) => {
  const styles = useStyles(appearance)();
  const localizations = useStrings({
    BackButtonTooltip,
    EditButtonTooltip,
    EditSaveButtonTooltip,
  });

  const buttonStyleProps: ButtonProps = {
    appearance: "transparent",
  };

  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onEditToggled && onEditToggled();
  };

  const handleSaveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onEditSaved && onEditSaved();
  };

  const handleBackClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onEditToggled && onEditToggled();
  };

  const editTooltip = localizations.EditButtonTooltip();
  const editSaveTooltip = localizations.EditSaveButtonTooltip();
  const backTooltip = localizations.BackButtonTooltip();

  return (
    <div className={styles.edit}>
      {isEditing ? (
        <>
          <Tooltip content={backTooltip} relationship="label">
            <ToggleButton
              data-testid="back-button"
              className={styles.actionButton}
              icon={<BackIcon className={styles.icon} />}
              {...buttonStyleProps}
              size="small"
              onClick={handleBackClick}
              tabIndex={tabIndex}
            />
          </Tooltip>
          <Tooltip content={editSaveTooltip} relationship="label">
            <ToggleButton
              data-testid="save-button"
              className={styles.actionButton}
              icon={<SaveIcon className={styles.icon} />}
              {...buttonStyleProps}
              size="small"
              onClick={handleSaveClick}
              tabIndex={tabIndex}
            />
          </Tooltip>
        </>
      ) : (
        <Tooltip content={editTooltip} relationship="label">
          <ToggleButton
            data-testid="edit-button"
            className={styles.actionButton}
            icon={<EditIcon className={styles.icon} />}
            {...buttonStyleProps}
            size="small"
            onClick={handleOnClick}
            tabIndex={tabIndex}
          />
        </Tooltip>
      )}
    </div>
  );
};
