import { makeStyles, mergeClasses } from "@fluentui/react-components";
import * as React from "react";

export type RowProps = React.HTMLAttributes<HTMLDivElement> & {
  noGutter?: boolean;
  isMetaOsV2?: boolean;
};

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: "-0.75rem",
    marginLeft: "-0.75rem",
  },

  rowMetaOsV2: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: "-0.75rem",
    marginLeft: "-0.75rem",
    "@media (min-width: 1920px)": {
      maxWidth: "1451px",
    },
    "@media (max-width: 1919px) and (min-width: 1024px)": {
      maxWidth: "calc(100vw - 400px)",
    },
    "@media (max-width: 1023px) and (min-width: 642px)": {
      maxWidth: "calc(100vw - 344px)",
    },
    "@media (max-width: 641px) and (min-width: 361px)": {
      maxWidth: "calc(100vw - 64px)",
    },
    "@media (max-width: 360px)": {
      maxWidth: "calc(100vw - 48px)",
    },
  },

  noGutter: {
    marginLeft: "0px",
    marginRight: "0px",
  },
});

export const Row: React.FC<RowProps> = (props) => {
  const styles = useStyles();
  const getStyles = () => {
    if (props.isMetaOsV2) {
      return mergeClasses(
        styles.rowMetaOsV2,
        props.noGutter && styles.noGutter,
        props.className
      );
    } else {
      return mergeClasses(
        styles.row,
        props.noGutter && styles.noGutter,
        props.className
      );
    }
  };

  return (
    <div {...props} className={getStyles()}>
      {props.children}
    </div>
  );
};
