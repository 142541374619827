import { useStrings } from "@1js/localization-components";
import {
  Button,
  Switch,
  Text,
  Title2,
  type SwitchOnChangeData,
} from "@fluentui/react-components";
import type { ChangeEvent } from "react";
import * as React from "react";
import { useFlightContext } from "../../hooks/useFlightContext";
import { AvailableFlights, type Flights } from "../../types";
import {
  DebugOverlayApplyFlightsButtonLabel,
  DebugOverlayFlightTitle,
  DebugOverlayFlightsDescription,
} from "./DebugPanel.web.string";

export const FlightPanel = () => {
  const { activeFlights } = useFlightContext();
  const localization = useStrings({
    DebugOverlayFlightTitle,
    DebugOverlaySaveFlightsButtonLabel: DebugOverlayApplyFlightsButtonLabel,
    DebugOverlayFlightsDescription,
  });
  const initialFlightStatus: {
    flightName: Flights;
    shouldBeActive: boolean;
  }[] = [];
  AvailableFlights.forEach((flightName) => {
    initialFlightStatus.push({
      flightName,
      shouldBeActive: activeFlights.has(flightName),
    });
  });
  const [newFlightStatus, setNewFlightStatus] =
    React.useState(initialFlightStatus);

  const onFlightChange = React.useCallback(
    (ev: ChangeEvent<HTMLInputElement>, data: SwitchOnChangeData) => {
      setNewFlightStatus((prev) => {
        const newStatus = [...prev];
        newStatus.forEach((flight) => {
          if (
            flight.flightName ===
            ev.target.attributes.getNamedItem("data-name")?.value
          ) {
            flight.shouldBeActive = data.checked;
          }
        });
        return newStatus;
      });
    },
    []
  );

  const removeParameterFromUrl = React.useCallback(
    (url: string, parameterName: string) => {
      url = url.replace(
        new RegExp("\\b" + parameterName + "=[^&;]+[&;]?", "gi"),
        ""
      );

      // Remove any leftover crud (e.g., trailing & or ;)
      url = url.replace(/[&;]$/, "");

      return url;
    },
    []
  );

  const applyFlights = React.useCallback(() => {
    // remove sf parameter from url
    const url = removeParameterFromUrl(window.location.href, "sf");

    window.location.href =
      url +
      (url.indexOf("?") !== -1 ? "&" : "?") +
      "sf=" +
      newFlightStatus
        .map((flight) =>
          flight.shouldBeActive ? flight.flightName : "-" + flight.flightName
        )
        .join(",");
  }, [newFlightStatus, removeParameterFromUrl]);

  return (
    <div>
      <Title2>{localization.DebugOverlayFlightTitle()}</Title2>
      <br />
      <Text>{localization.DebugOverlayFlightsDescription()}</Text>
      {newFlightStatus.map((flight) => (
        <Switch
          data-name={flight.flightName}
          label={flight.flightName}
          checked={flight.shouldBeActive}
          onChange={onFlightChange}
          key={flight.flightName}
        />
      ))}
      <Button onClick={applyFlights}>
        {localization.DebugOverlaySaveFlightsButtonLabel()}
      </Button>
    </div>
  );
};

FlightPanel.defaultName = "FlightPanel";
