import { Text } from "@fluentui/react-components";
import * as React from "react";
import type { BadgeLabelWrapperType } from "../../../../hooks/useProducts";
import type { ProductType } from "../../../data";
import { usePromptBadge } from "../../PromptBadges/PromptBadge/PromptBadge.hook";
import { type InteractiveBadge } from "../../PromptBadges/PromptBadges.types";
import { useStyles } from "./GoBoldPromptCardFooterTryInApp.styles";

export type GoBoldPromptCardFooterTryInAppProps = {
  badge: InteractiveBadge;
  labelWrapper: BadgeLabelWrapperType;
  showTryInAppLabel: boolean;
};

export const GoBoldPromptCardFooterTryInApp: React.FC<
  GoBoldPromptCardFooterTryInAppProps
> = ({ badge, labelWrapper, showTryInAppLabel }) => {
  const styles = useStyles();
  const { getBadgeIconInteractive, badgeToLabel } = usePromptBadge({
    badge,
  });
  return (
    <div className={styles.container}>
      <div className={styles.icon} data-testid={"try-in-app-icon"}>
        {getBadgeIconInteractive(badge.id as ProductType, "20px")}
      </div>
      {showTryInAppLabel && (
        <Text className={styles.label} data-testid={"try-in-app-label"}>
          {badgeToLabel(badge.id, labelWrapper)}
        </Text>
      )}
    </div>
  );
};
