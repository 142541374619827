/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { Group } from "@1js/pl-types";
import * as React from "react";
import { forwardRef } from "react";
import type { Appearance, Prompt } from "../data";
import { GoBoldPromptCard } from "./GoBoldPromptCard/GoBoldPromptCard";
import { LegacyPromptCard } from "./LegacyPromptCard/LegacyPromptCard";
import { RockSteadyPromptCard } from "./RockSteadyPromptCard/RockSteadyPromptCard";
export type PromptCardProps = {
  appearance: Appearance;
  className?: string;
  isPromptFocused?: boolean;
  prompt: Prompt;
  isPromptRingTagEnabled?: boolean;
  displayPosition?: number;
  isBadgesEnabled?: boolean;
  isPromptLikingEnabled?: boolean;
  isFavoriteEnabled?: boolean;
  onClicked?: (prompt: Prompt, displayPosition?: number) => void;
  onFavoriteToggled?: (prompt: Prompt, value: boolean) => void;
  isAgentPromptFavoriteEnabled?: boolean;
  isPromptSavingEnabled?: boolean;
  isPromptEditingEnabled?: boolean;
  isPromptSharingEnabled?: boolean;
  isPromptSharingViaEmailEnabled?: boolean;
  isPromptSharingToWorkgroupEnabled?: boolean;
  isPromptRemovingFromWorkgroupEnabled?: boolean;
  isPromptSharingOneClickUxEnabled?: boolean;
  onShareViaCopyLinkClicked?: (prompt: Prompt) => void;
  onShareViaEmailClicked?: (prompt: Prompt) => void | Promise<void>;
  onShareToWorkgroupClicked?: (prompts: Prompt[]) => void | Promise<void>;
  onDelete?: (prompt: Prompt) => void;
  onEditToggled?: (prompt: Prompt) => void;
  onEditSaved?: (prompt: Prompt) => void;
  onFocus?: (prompt: Prompt, displayPosition?: number) => void;
  onRemoveFromWorkgroupClicked?: (
    prompts: Prompt[],
    groupIds: string[]
  ) => void;
  "aria-posinset"?: number;
  "aria-setsize"?: number;
  selectedGroupId?: string;
  groups?: Group[];
  setShareButtonTarget?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  isShowPromptAuthor?: boolean;
  extensionIconMap?: Record<string, string>;
  handoffProduct?: string;
};

export const PromptCard = forwardRef<HTMLDivElement, PromptCardProps>(
  (props, ref) => {
    const { appearance } = props;

    switch (appearance) {
      case "web-v2":
      case "in-app-v2":
        return <GoBoldPromptCard {...props} ref={ref} />;
      case "in-app-v3":
      case "web-v3":
        return <RockSteadyPromptCard {...props} ref={ref} />;
      default:
        return <LegacyPromptCard {...props} ref={ref} />;
    }
  }
);
PromptCard.displayName = "PromptCard";
