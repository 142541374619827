import * as React from "react";
import { createSingletonState } from "singleton-state";

// We cannot strongly type the defaultValue of the cached context objects.
// We must trust callers that agree on the name to also agree on what the
// default value should be. This is reasonable, since in the cases where
// there are multiple callers, these callers are expected to be duplicate
// instances of the same code.
type ContextState = Record<string, React.Context<any>>;

const contextState = createSingletonState<ContextState>(
  "@1js/create-shared-react-context",
  {}
);

/**
 * A memoized version of React.createContext().
 *
 * @param defaultValue The default value to pass to React.createContext().
 * @param name The name that uniquely identifies the context object. This function
 * maintains a cache in module state; if the same name was specified in a previous
 * call, the function will return the cached context object.
 * @returns The context object that was returned by React.createContext() or found in the cache.
 */
export function createSharedReactContext<T>(
  defaultValue: T,
  name: string
): React.Context<T> {
  const state = contextState.get();
  return state[name] || (state[name] = React.createContext(defaultValue));
}
