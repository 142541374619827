import { Tag, Tooltip, type TagSize } from "@fluentui/react-components";
import * as React from "react";
import { useProducts } from "../../../../hooks/useProducts";
import type { Badge } from "../PromptBadges.types";

type Props = {
  badges?: Badge[];
  tagSize?: TagSize;
};

export const AdditionalBadges: React.FC<Props> = ({
  tagSize = "small",
  badges,
}) => {
  const { badgeToLabel } = useProducts();

  if (!badges || badges.length === 0) {
    return null;
  }

  const tooltipContent = badges
    .map((badge) => badgeToLabel(badge.id))
    .join(", ");

  return (
    <Tooltip content={tooltipContent} relationship="label">
      <Tag size={tagSize} role="listitem">
        +{badges.length}
      </Tag>
    </Tooltip>
  );
};
