import {
  Localization,
  useStringForPlaceholders,
  useStringProvider,
} from "@1js/localization-components";
import React from "react";
import ReactDOM from "react-dom";
import { PromptCardEmailTemplate } from "../components/Email/PromptCardEmailTemplate";
import { PromptShareEmailSubject } from "../components/Email/PromptCardEmailTemplate.strings";
import type { Prompt } from "../components/data";

export const renderToString = (
  element: string | JSX.Element | JSX.Element[]
): string => {
  if (typeof element === "string") {
    return element;
  }

  const div = document.createElement("div");
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(element, div);
  return div.innerHTML;
};

/**
 * Hook to get the email body and subject for sharing a prompt.
 */
export const useSharePromptViaEmail = () => {
  const PromptShareEmailSubjectString = useStringForPlaceholders(
    PromptShareEmailSubject
  );
  const stringProvider = useStringProvider();

  /**
   * Get the email body for sharing a prompt.
   * @param prompt The prompt to share.
   * @param sharedByDisplayName The display name of the user sharing the prompt.
   * @param promptUrl URL to the prompt in the email body.
   * @returns The email body.
   */
  const getEmailBody = (
    prompt: Prompt,
    sharedByDisplayName: string,
    promptUrl: string,
    ocidPrefix?: string
  ) => {
    const emailBody = (
      <Localization lookupString={stringProvider.lookupString}>
        <PromptCardEmailTemplate
          prompt={prompt}
          promptUrl={promptUrl}
          sharedByDisplayName={sharedByDisplayName}
          ocidPrefix={ocidPrefix}
        />
      </Localization>
    );

    return renderToString(emailBody);
  };

  /**
   * Get the email subject for sharing a prompt.
   * @param prompt The prompt to share.
   * @param sharedByDisplayName The display name of the user sharing the prompt.
   * @returns The email subject.
   */
  const getEmailSubject = (prompt: Prompt, sharedByDisplayName: string) =>
    PromptShareEmailSubjectString({
      promptAuthorDisplayName: sharedByDisplayName ?? "",
      promptTitle: prompt.Title,
    });

  return {
    getEmailBody,
    getEmailSubject,
  };
};
