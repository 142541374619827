import type { SupportedLocale } from "@1js/localization";
import type { IPublicClientApplication } from "@azure/msal-browser";

export const AvailableFlights = [
  "is3SServiceEnabled",
  "isTryInOfficeMsaEnabled",
  "isPromptSavingEnabled",
  "isPromptSharingEnabled",
  "isWorkgroupPublishingMultiSelectShareEnabled",
  "isPromptSharingViaEmailEnabled",
  "isTryInEnabled",
  "isTryInBizChatEnabled",
  "isTryInBizChatEnabledForAgents",
  "isTryInWordEnabledForAgents",
  "isTryInPowerPointEnabledForAgents",
  "isTryInCWCForBusinessChatUsersEnabled",
  "isTryInCWCForNonAIUsersEnabled",
  "isTryInOfficeEnabled",
  "isTryInWordEnabled",
  "isTryInExcelEnabled",
  "isTryInPowerPointEnabled",
  "isTryInOfficeWebLicenseEnabled",
  "isBookmarksEnabled",
  "isWorkgroupPublishingGroupStateEnabled",
  "isHeaderEnabled",
  "isUserLicensed",
  "isWorkgroupPublishingMicrosoftPromptsEnabled",
  "isDebugPanelEnabled",
  "isMsalEnabled",
  "isWorkgroupPublishingEnabled",
  "isWorkgroupTeachingPopoverEnabled",
  "isUnifiedWorkgroupPromptsViewEnabled",
  "isTryInAppendM365ChatFeatures",
  "isTryInDisableCosmosDBEnabled",
  "testFlight",
  "testFlight2",
  "isTryIn1_1FormatEnabled",
  "isDeclarativeCopilotPromptEnabled",
  "isPluginPromptEnabled",
  "isTryIn3SPromptLinkEnabled",
  "isSignInSuggestionEnabled",
  "isUseAccessUrlEnabled",
  "isGoBoldPromptCardEnabled",
  "isTeamAnnouncementEnabled",
  "isMetaOSAppActive",
  "isTryInTeamPromptsEnabled",
  "isAgentPromptSavingEnabled",
  "isAgentPromptFavoriteEnabled",
  "isUseNewHandoffLoadingWindowEnabled",
  "isLinkToAppFromChannelEnabled",
  "isPromptLikingEnabled",
  "isBizChatTryInTeamsAppEnabled",
  "isUserPromptWarningEnabled",
  "isWorkgroupNotificationEnabled",
  "isMosAppV2DesignEnabled",
  "isResponsiveDimensionsEnabled",
  "isPromptAppLinkEnabled",
] as const;

export type Flights = (typeof AvailableFlights)[number];

//This type is get to get the user from the API, for MetaOs flow
export type UserObject = {
  localId?: string;
  tenantId?: string;
  dataBoundary?: string;
  objectId: string;
  displayName: string;
  email: string;
  avatar?: string;
  type: "MSA" | "AAD";
  flights: Set<Flights | string>;
  licenses: string[];
  substrateRing: string;
};

export type SiteSetting = {
  authentication?: {
    clientId: string;
    redirectUrl: string;
    authCode: string;
  };

  licenses?: string[];
  showManageAccess?: boolean;
  activeFlights: Set<Flights | string>;
  /**
   * The current logged in user information
   */
  user?: {
    objectId: string;
    displayName: string;
    email: string;
    avatar?: string;
    type: "MSA" | "AAD";
    tenantId?: string;
    copilotLicensed?: boolean;
  };
};

export type CopilotCapabilitiesLinks = {
  displayText: string;
  url: string;
  openInNewWindow: boolean;
};

export type MsalInstance = {
  instance: IPublicClientApplication | undefined;
};

export type PromptSettings = {
  bizchatBaseUri: string;
  isBookmarksEnabled: boolean;
  is3SServiceEnabled: boolean;
  copilotProductUrls: CopilotCapabilitiesLinks[];
  gettingStartedUrls: CopilotCapabilitiesLinks[];
  isPromptSavingEnabled: boolean;
  isTryInEnabled: boolean;
  isDeclarativeCopilotPromptEnabled: boolean;
  isPluginPromptEnabled: boolean;
  licenses: string[];
};

export type CopilotPortalSettings = {
  capabilitiesSiteUrl: string;
  whatsNewUrl: string;
  businessSiteChatUrl: string;
};

export type ClientSettings = {
  promptsClientSettings: PromptSettings;
  copilotPortalSettings: CopilotPortalSettings;
  locale: string;
  flights: Set<string>;
  user?: {
    objectId: string;
    displayName: string;
    email: string;
    avatar: string;
    type: "MSA" | "AAD";
    copilotLicensed: boolean;
  };
};

export type BasePageProps = {
  isUserLicensed: boolean;
  uhfDisplayed?: boolean;
  locale: SupportedLocale;
};

export type VideoTelemetry = {
  actionType: string;
  behavior: number | undefined;
  content: string;
  isAuto: boolean;
};

export type VideoPostMessage = {
  eventName: string;
  playerId?: string;
  data?: {
    currentTime: number;
    endTime: number;
    startTime: number;
  };
};

export type ContentTags = {
  vidnm: string;
  vidid: string;
  vidpct: number;
  vidwt: number;
  viddur: number;
  vidtimeseconds: number;
  live: boolean;
  containerName: string;
  id: string;
};

export type SearchResultsData = {
  queryText: string;
  numOfResults: number;
  searchError: boolean;
};

export const GroupPromptsFilterStateId = "WorkGroupID";
export const IsTeamsTab = "IsTeamsTab";
export const TeamName = "TeamName";
export const TeamWorkGroupId = "TeamWorkGroupId";
