import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  icon: {
    marginRight: "12px",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: tokens.colorNeutralStrokeAlpha,
    flexShrink: 0,
    ...shorthands.padding("6px"),
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
