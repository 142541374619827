import { declareStringWithPlaceholders } from "@1js/localization";
export const PluginTooltip = declareStringWithPlaceholders(
  "promptLibrary.PluginTooltip",
  {
    text: ({ extensionName }) =>
      `Make sure ${extensionName} is on before submitting your prompt.`,
    placeholders: {
      extensionName: "The name of the extension installed for user",
    },
    comment:
      "Tooltip that's displayed to user when user hovers over the info button icon on a Copilot prompt card for a plugin prompt",
  }
);
