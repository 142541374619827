import { declareString } from "@1js/localization";

export const RemoveButtonTooltip = declareString(
  "promptLibrary.removeButtonTooltip",
  {
    text: "Remove from team",
    comment:
      "Tooltip that's displayed to user when user hovers over the remove button icon on a Copilot prompt card to remove it from a team.",
  }
);
