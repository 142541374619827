import { isTest } from "./environmentUtils";
import type {
  ErrorHandler,
  NestedStringMap,
  StringMap,
  StringMapLoader,
  StringProvider,
} from "./stringMap";
import {
  addStringMap,
  addStringMapLoader,
  clearStringMapLoaders,
  getCurrentLocale,
  lookUpString,
  setCurrentLocale,
  setLocalizationErrorHandler,
} from "./stringMap";
import type { SupportedLocale } from "./supportedLocales";

declare const console: { error: (...args: unknown[]) => void };

const defaultErrorHandler = (error: string | Error) => console.error(error);

let defaultStringProvider: StringProvider = {
  currentLocale: undefined,
  strings: {},
  errorHandler: defaultErrorHandler,
  loaders: [],
  stringMapInitialized: false,
  suppressEmptyStringMapErrors: isTest(),
};

export function getDefaultStringProvider(): StringProvider {
  return defaultStringProvider;
}

export function setLocalizationErrorHandlerForDefaultStringProvider(
  errorHandler: ErrorHandler
): void {
  defaultStringProvider = setLocalizationErrorHandler(
    errorHandler,
    defaultStringProvider
  );
}

export function addStringMapForDefaultStringProvider(
  locale: SupportedLocale,
  stringMap: StringMap | NestedStringMap
): void {
  defaultStringProvider = addStringMap(
    locale,
    stringMap,
    defaultStringProvider
  );
}

export function addStringMapLoaderForDefaultStringProvider(
  loader: StringMapLoader
): void {
  defaultStringProvider = addStringMapLoader(loader, defaultStringProvider);
}

export function getCurrentLocaleForDefaultStringProvider(): Promise<
  SupportedLocale | undefined
> {
  return getCurrentLocale(getDefaultStringProvider());
}

export async function setCurrentLocaleForDefaultStringProvider(
  locale: SupportedLocale
): Promise<SupportedLocale | undefined> {
  defaultStringProvider = await setCurrentLocale(locale, defaultStringProvider);

  return defaultStringProvider.currentLocale;
}

export async function clearStringMapLoadersForDefaultStringProvider(): Promise<void> {
  defaultStringProvider = await clearStringMapLoaders(
    getDefaultStringProvider()
  );
}

export function lookupStringInDefaultStringProvider(key: string): string {
  return lookUpString(key, getDefaultStringProvider());
}
