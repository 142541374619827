// Copyright (C) Microsoft Corporation. All rights reserved.

import type { IPublicClientApplication } from "@azure/msal-browser";
import type { DataField, TelemetryLogger } from "@microsoft/oteljs";
import get1DSSink from "./Sinks/1ds";
import get3SEventsSink from "./Sinks/3SEvents";

/**
 * List of all available telemetry sinks
 */
const Sinks = {
  "1DS": get1DSSink,
  "3SEvents": get3SEventsSink,
};

export interface SinkParameters {
  sink: keyof typeof Sinks;
  endpointUrl: string;
  partADataFields?: DataField[];
  msalInstance?: IPublicClientApplication;
}

export default function attachSink(
  telemetryLogger: TelemetryLogger,
  sinkParameters: SinkParameters
) {
  const sink = Sinks[sinkParameters.sink](sinkParameters);

  if (!sink) {
    console.error(`Telemetry sink ${sinkParameters.sink} is undefined`);
    return;
  }

  // If you waited between creating the telemetryLogger and attaching the sink,
  // then events may have accumulated in the queue. Flush the queue.
  telemetryLogger.flushQueue();

  telemetryLogger.addSink(sink);
}
