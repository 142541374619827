function getRandomValuesMathRandom(): Array<number> {
  const randomNumbers = new Array<number>(31);
  for (let i = 0; i < 31; i++) {
    randomNumbers[i] = Math.floor(Math.random() * 16);
  }
  return randomNumbers;
}

function getRandomValuesCrypto(): Uint8Array {
  const randomNumbers = new Uint8Array(31);
  window.crypto.getRandomValues(randomNumbers);
  return randomNumbers;
}

export default function getRandomValues(): Uint8Array | Array<number> {
  return (
    (typeof window === "object" && window.crypto && getRandomValuesCrypto()) ||
    getRandomValuesMathRandom()
  );
}
