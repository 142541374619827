export const msalConfig = {
  auth: {
    clientId: "69e1409d-f9f8-4199-98e1-654df1626537",
    authority: "https://login.microsoftonline.com/common",
    supportsNestedAppAuth: true,
  },
};

export const scopes = [
  "api://69e1409d-f9f8-4199-98e1-654df1626537/access_as_user",
];
