import type { IPublicClientApplication } from "@azure/msal-browser";
import { getNestedAppAuthToken } from "../services/nestedAuthentication";
import { GetScenarioName } from "./url";

export const buildCoreHeaders = async (
  msalContext: IPublicClientApplication | undefined,
  logicalId?: string
): Promise<Headers> => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  if (logicalId !== undefined) {
    headers.append("x-logical-id", logicalId);
  }

  if (msalContext !== undefined) {
    const accessToken = await getNestedAppAuthToken(msalContext);
    headers.append("Authorization", `Bearer ${accessToken}`);
  }

  headers.append("x-scenario-name", GetScenarioName());

  return headers;
};
