import { useStrings } from "@1js/localization-components";
import { useLogger } from "@1js/pl-logger";
import { isWorkgroupPrompt, type Group } from "@1js/pl-types";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  Spinner,
  Tooltip,
  type MenuButtonProps,
} from "@fluentui/react-components";
import {
  bundleIcon,
  LinkFilled,
  LinkRegular,
  MailFilled,
  MailRegular,
  PeopleTeamFilled,
  PeopleTeamRegular,
  ShareFilled,
  ShareRegular,
} from "@fluentui/react-icons";
import React from "react";
import type { Appearance, Prompt } from "../../data";
import {
  PromptShareCopyLinkButtonLabel,
  PromptShareCopyPromptLinkLabel,
  PromptShareCopyPromptLinkLoadingLabel,
  PromptShareEmailButtonLabel,
  PromptShareTeamButtonLabel,
  PromptShareTooltip,
  PromptSharing,
} from "./PromptShare.strings";
import { useStyles } from "./PromptShare.styles";

export type PromptShareProps = Omit<MenuButtonProps, "appearance"> & {
  appearance?: Appearance;
  prompt: Prompt;
  isLoading?: boolean;
  isPromptSharingViaEmailEnabled?: boolean;
  isPromptSharingToWorkgroupEnabled?: boolean;
  tabIndex?: number;
  isPromptSharingOneClickUxEnabled?: boolean;

  /**
   * Callback for when the user clicks the share via copy link button
   * @param prompt The prompt to share
   */
  onShareViaCopyLinkClicked?: (prompt: Prompt) => void | Promise<void>;

  /**
   * Callback for when the user clicks the share via email button.
   * Note: Not used in the current implementation.
   * @param prompt The prompt to share
   */
  onShareViaEmailClicked?: (prompt: Prompt) => void | Promise<void>;

  /**
   * Callback for when the user clicks the share with workgroup button.
   * @param prompt The prompt to share
   */
  onShareToWorkgroupClicked?: (prompts: Prompt[]) => void | Promise<void>;

  groups?: Group[];

  setShareButtonTarget?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;

  /**
   * If true, the menu popover will be displayed with an increased zIndex.
   */
  displayMenuAboveOthers?: boolean;
};

const ShareIcon = bundleIcon(ShareFilled, ShareRegular);
const LinkIcon = bundleIcon(LinkFilled, LinkRegular);
const MailIcon = bundleIcon(MailFilled, MailRegular);
const PeopleTeamIcon = bundleIcon(PeopleTeamFilled, PeopleTeamRegular);

export const PromptShare: React.FC<PromptShareProps> = ({
  appearance,
  size,
  prompt,
  isLoading,
  isPromptSharingViaEmailEnabled,
  isPromptSharingToWorkgroupEnabled,
  tabIndex,
  onShareViaCopyLinkClicked,
  onShareViaEmailClicked,
  onShareToWorkgroupClicked,
  groups,
  setShareButtonTarget,
  isPromptSharingOneClickUxEnabled,
  displayMenuAboveOthers,
  ...props
}) => {
  const localizations = useStrings({
    PromptShareTooltip,
    PromptSharing,
    PromptShareCopyLinkButtonLabel,
    PromptShareEmailButtonLabel,
    PromptShareTeamButtonLabel,
    PromptShareCopyPromptLinkLabel,
    PromptShareCopyPromptLinkLoadingLabel,
  });
  const styles = useStyles(appearance)();
  const logger = useLogger();
  const isRockSteady = appearance === "in-app-v3" || appearance === "web-v3";
  const defaultSize = isRockSteady ? "medium" : "small";

  const [isCopyingLink, setIsCopyingLink] = React.useState(false);

  const handleShareViaCopyLinkClicked = React.useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      logger?.promptCard.sendPromptCopyLinkEvent({ prompt });

      if (onShareViaCopyLinkClicked) {
        isPromptSharingOneClickUxEnabled && setIsCopyingLink(true);
        try {
          await onShareViaCopyLinkClicked(prompt);
        } catch (_) {
          // no-op
        } finally {
          setIsCopyingLink(false);
        }
      }
    },
    [
      onShareViaCopyLinkClicked,
      prompt,
      logger,
      isPromptSharingOneClickUxEnabled,
    ]
  );

  const handleShareViaEmailClicked = React.useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      logger?.promptCard.sendPromptShareViaEmailEvent({ prompt });

      if (onShareViaEmailClicked) {
        try {
          await onShareViaEmailClicked(prompt);
        } catch (_) {
          // no-op
        }
      }
    },
    [onShareViaEmailClicked, prompt, logger]
  );

  const handleShareToWorkgroupClicked = React.useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      onShareToWorkgroupClicked?.([prompt]);
    },
    [onShareToWorkgroupClicked, prompt]
  );

  const handleMenuButtonClick = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const setRef = React.useCallback(
    (value: React.SetStateAction<HTMLElement | null>) =>
      setShareButtonTarget && setShareButtonTarget(value),
    [setShareButtonTarget]
  );

  if (isCopyingLink) {
    return (
      <Spinner
        size="tiny"
        labelPosition="after"
        label={localizations.PromptShareCopyPromptLinkLoadingLabel()}
      />
    );
  }

  if (isLoading) {
    return <></>;
  }

  if (
    (!isPromptSharingViaEmailEnabled && !isPromptSharingToWorkgroupEnabled) ||
    isPromptSharingOneClickUxEnabled
  ) {
    return (
      <Tooltip
        content={
          isPromptSharingOneClickUxEnabled
            ? localizations.PromptShareCopyPromptLinkLabel()
            : localizations.PromptShareTooltip()
        }
        relationship="label"
      >
        <Button
          icon={
            isPromptSharingOneClickUxEnabled ? (
              <LinkIcon className={styles.icon} />
            ) : (
              <ShareIcon className={styles.icon} />
            )
          }
          size={size ?? "small"}
          appearance="transparent"
          onClick={handleShareViaCopyLinkClicked}
          className={mergeClasses(styles.shareButton, props.className)}
          data-testid="share"
          tabIndex={tabIndex}
        />
      </Tooltip>
    );
  }

  const isMemberOfAnyGroup = groups?.some((group) => group.IsMember) || false;
  const shouldRenderShareToWorkgroupButton =
    isPromptSharingToWorkgroupEnabled &&
    isMemberOfAnyGroup &&
    !isWorkgroupPrompt(prompt);

  return (
    <Menu inline={!isRockSteady}>
      <MenuTrigger>
        <Tooltip
          content={localizations.PromptShareTooltip()}
          relationship="label"
        >
          <MenuButton
            icon={
              <ShareIcon data-testid="share-icon" className={styles.icon} />
            }
            size={size ?? defaultSize}
            appearance={isRockSteady ? "subtle" : "transparent"}
            onClick={handleMenuButtonClick}
            className={mergeClasses(styles.shareButton, props.className)}
            data-testid="share-menu-button"
            ref={setRef}
            tabIndex={tabIndex ?? 0}
          />
        </Tooltip>
      </MenuTrigger>

      <MenuPopover
        className={
          (displayMenuAboveOthers && styles.displayAboveOthers) || undefined
        }
      >
        <MenuList>
          <MenuItem
            icon={<LinkIcon className={styles.icon} />}
            aria-label={localizations.PromptShareCopyLinkButtonLabel()}
            data-testid="share"
            onClick={handleShareViaCopyLinkClicked}
          >
            {localizations.PromptShareCopyLinkButtonLabel()}
          </MenuItem>
          {isPromptSharingViaEmailEnabled && (
            <MenuItem
              icon={<MailIcon className={styles.icon} />}
              aria-label={localizations.PromptShareEmailButtonLabel()}
              data-testid="share-via-email"
              onClick={handleShareViaEmailClicked}
            >
              {localizations.PromptShareEmailButtonLabel()}
            </MenuItem>
          )}
          {shouldRenderShareToWorkgroupButton && (
            <MenuItem
              icon={<PeopleTeamIcon className={styles.icon} />}
              aria-label={localizations.PromptShareTeamButtonLabel()}
              onClick={handleShareToWorkgroupClicked}
            >
              {localizations.PromptShareTeamButtonLabel()}
            </MenuItem>
          )}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
