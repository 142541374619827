import type { TagAppearance, TagSize } from "@fluentui/react-components";
import { WhiteboardRegular } from "@fluentui/react-icons";
import React from "react";
import {
  useProducts,
  type BadgeLabelWrapperType,
} from "../../../../hooks/useProducts";
import {
  AzureBlack,
  AzureColor,
  CopilotFilled,
  CopilotRegular,
  LoopColor,
  LoopRegular,
  OfficeExcelMono,
  OfficeExcelRegular,
  OfficeFormsColor,
  OfficeFormsRegular,
  OfficeOneDriveMono,
  OfficeOneDriveRegular,
  OfficeOutlookMono,
  OfficeOutlookRegular,
  OfficePlannerColor,
  OfficePlannerRegular,
  OfficePowerPointMono,
  OfficePowerPointRegular,
  OfficeSharepointMono,
  OfficeSharepointRegular,
  OfficeWordMono,
  OfficeWordRegular,
  OneNoteColor,
  OneNoteRegular,
  StreamColor,
  StreamRegular,
  TeamsMono,
  TeamsRegular,
  VivaEngageColor,
  VivaEngageRegular,
  VivaGoalsColor,
  VivaGoalsRegular,
  VivaTopicsColor,
  VivaTopicsRegular,
  WhiteboardColor,
} from "../../../Icons";
import type { ProductType } from "../../../data";
import type { DisplayMode } from "../PromptBadges";
import type {
  Badge,
  InteractiveBadge,
  WorkgroupBadge,
} from "../PromptBadges.types";

export type BadgeProps = {
  badge: Badge;
  tagSize: TagSize;
  displayMode: DisplayMode;
  labelWrapper?: BadgeLabelWrapperType;
};

export type InteractiveBadgeProps = BadgeProps & {
  badge: InteractiveBadge;
  appearance: TagAppearance;
  withOptions?: boolean;
};

export type WorkgroupBadgeProps = Omit<
  BadgeProps,
  "displayMode" | "labelWrapper"
> & {
  badge: WorkgroupBadge;
  appearance: TagAppearance;
};

type PromptBadgeHookPropsType = {
  badge: Badge | InteractiveBadge;
};

type PromptBadgeHookResultType = {
  getBadgeIconNonInteractive: (badgeId: ProductType | string) => JSX.Element;
  getBadgeIconInteractive: (
    badgeId: ProductType | string,
    size?: string
  ) => JSX.Element;
  onBadgeAction: () => void;
  badgeToLabel: (
    badgeId: ProductType | string,
    labelWrapper?: BadgeLabelWrapperType
  ) => string;
  tagRef: React.MutableRefObject<null>;
};

export const usePromptBadge = (
  props: PromptBadgeHookPropsType
): PromptBadgeHookResultType => {
  const { badge } = props;
  const { badgeToLabel } = useProducts();

  const getBadgeIconNonInteractive = React.useCallback(
    (badgeId: ProductType | string): JSX.Element => {
      switch (badgeId) {
        case "Word":
          return <OfficeWordMono />;
        case "PowerPoint":
          return <OfficePowerPointMono />;
        case "Outlook":
          return <OfficeOutlookMono />;
        case "Teams":
          return <TeamsMono />;
        case "CWC":
        case "CWCWithBizChatLabel":
        case "BizChat":
          return <CopilotRegular />;
        case "Copilot":
          return <CopilotRegular />;
        case "OneNote":
          return <OneNoteRegular />;
        case "Loop":
          return <LoopRegular />;
        case "Whiteboard":
          return <WhiteboardRegular />;
        case "SharePoint":
          return <OfficeSharepointMono />;
        case "Excel":
          return <OfficeExcelMono />;
        case "OneDrive":
          return <OfficeOneDriveMono />;
        case "Topics":
          return <VivaTopicsRegular />;
        case "Forms":
          return <OfficeFormsRegular />;
        case "Planner":
          return <OfficePlannerRegular />;
        case "Stream":
          return <StreamRegular />;
        case "VivaEngage":
          return <VivaEngageRegular />;
        case "VivaGoals":
          return <VivaGoalsRegular />;
        case "Azure":
        case "AzureWeb":
          return <AzureBlack />;
        default:
          return <></>;
      }
    },
    []
  );

  const getBadgeIconInteractive = React.useCallback(
    (badgeId: ProductType | string, size?: string): JSX.Element => {
      switch (badgeId) {
        case "Word":
          return <OfficeWordRegular size={size} />;
        case "PowerPoint":
          return <OfficePowerPointRegular size={size} />;
        case "Outlook":
          return <OfficeOutlookRegular size={size} />;
        case "Teams":
          return <TeamsRegular size={size} />;
        case "CWC":
        case "CWCWithBizChatLabel":
        case "BizChat":
          return <CopilotFilled size={size} />;
        case "Copilot":
          return <CopilotFilled size={size} />;
        case "OneNote":
          return <OneNoteColor size={size} />;
        case "Loop":
          return <LoopColor size={size} />;
        case "Whiteboard":
          return <WhiteboardColor size={size} />;
        case "SharePoint":
          return <OfficeSharepointRegular size={size} />;
        case "Excel":
          return <OfficeExcelRegular size={size} />;
        case "OneDrive":
          return <OfficeOneDriveRegular size={size} />;
        case "Topics":
          return <VivaTopicsColor size={size} />;
        case "Forms":
          return <OfficeFormsColor size={size} />;
        case "Planner":
          return <OfficePlannerColor size={size} />;
        case "Stream":
          return <StreamColor size={size} />;
        case "VivaEngage":
          return <VivaEngageColor size={size} />;
        case "VivaGoals":
          return <VivaGoalsColor size={size} />;
        case "Azure":
        case "AzureWeb":
          return <AzureColor size={size} />;
        default:
          return <></>;
      }
    },
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isInteractive = (badge: any): badge is InteractiveBadge => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return badge.onAction !== undefined;
  };

  const tagRef = React.useRef(null);
  const onBadgeAction = React.useCallback(() => {
    if (isInteractive(badge)) {
      badge.onAction?.(tagRef);
    }
  }, [badge]);

  return {
    getBadgeIconNonInteractive,
    getBadgeIconInteractive,
    onBadgeAction,
    badgeToLabel,
    tagRef,
  };
};
