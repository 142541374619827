import {
  AllowedJobTypeList,
  isExtensionPrompt,
  type ExtensionPrompt,
  type Metadata,
  type Prompt,
} from "@1js/pl-types";
import { JobTypeWeights, type JobType } from "../data";
import {
  CreatedByKey,
  DisplayCategoryKey,
  ExtensionKey,
  JobTypeKey,
  LikedKey,
  ProductNameKey,
  WorkgroupKey,
} from "../PromptLibrary/PromptLibrary.keys";
import { PromptTabs } from "../PromptTab/PromptTabs";
import type { Filter, FilterName, FilterOption } from "./types";

type ExtensionPartyType = {
  Origin: string;
  ProviderPartyType: string;
};
/**
 * Group filter options by group key.
 */
export const groupFilters = (filter: Filter) => {
  return filter.options.reduce(
    (
      acc: {
        [group: string]: {
          value: string;
          group?: string;
        }[];
      },
      curr: {
        value: string;
        group?: string;
      }
    ) => {
      const group = curr.group ?? "NA";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(curr);
      return acc;
    },
    {}
  );
};

const convertMetadataToJobTypeFilters = (metadatas: Metadata[]) => {
  const uniqueJobTypeFilterTracker = new Set<string>();
  let metadataFilters: FilterOption[] = [];
  metadatas.forEach((item: Metadata) => {
    if (AllowedJobTypeList.has(item.Name)) {
      const key = `${item.Name}_${item.Value}`;
      if (!uniqueJobTypeFilterTracker.has(key)) {
        uniqueJobTypeFilterTracker.add(key);
        metadataFilters.push({
          value: item.Value,
          group: item.Name,
        });
      }
    }
  });

  metadataFilters = metadataFilters.sort((a, b) => {
    const aWeight = JobTypeWeights[a.group as JobType] || 0;
    const bWeight = JobTypeWeights[b.group as JobType] || 0;
    return aWeight - bWeight;
  });

  return metadataFilters;
};

/**
 * Populates missing fields for extension prompts. The reason for postprocessing is that 3s sends extension prompts without these fields
 * @param prompts list of prompts
 * @returns prompts with extension prompts having their missing fields populated
 */
export const populateMissingFieldsForPrompts = (
  prompts: Prompt[]
): Prompt[] => {
  return prompts.map((prompt) => {
    // TODO: Daniel, this defaulting logic is breaking user expectation for user-authored prompts. As a temp fix I am only scoping your logic to agent prompts
    // please work with PM to see what's the right defaulting logic that can be applied for all non-msft prompts
    if (isExtensionPrompt(prompt)) {
      if (!prompt.DisplayCategory) {
        prompt = {
          ...prompt,
          DisplayCategory: "Ask",
        };
      }
      if (!prompt.Products) {
        prompt = {
          ...prompt,
          Products: ["BizChat"],
        };
      }
    }
    return prompt;
  });
};

export const buildFilters = (
  prompts: Prompt[],
  excludes?: FilterName[]
): Filter[] => {
  if (!prompts || prompts.length === 0) {
    return [];
  }
  prompts = populateMissingFieldsForPrompts(prompts);
  const displayCategories: string[] = [];
  let productNames: string[] = [];
  let metadatas: Metadata[] = [];

  let extensions: Record<string, ExtensionPartyType> = {};

  prompts.forEach((p) => {
    if (p.Origin !== "User" && p.DisplayCategory) {
      displayCategories.push(p.DisplayCategory.toString());
    }
    if (p.Products) {
      productNames = [...productNames, ...p.Products];
    }

    if (p.Metadata) {
      metadatas = [...metadatas, ...p.Metadata];
    }

    if (p.Origin && p.ProviderName && isExtensionPrompt(p)) {
      extensions[p.ProviderName] = {
        Origin: p.Origin,
        ProviderPartyType: (p as ExtensionPrompt).ProviderPartyType as string,
      };
    }
  });

  extensions = postProcessExtensionFilters(extensions, prompts);

  const filterOptions = [
    {
      key: ProductNameKey,
      options: [...new Set(productNames)].map((x) => {
        return { value: x };
      }),
      type: "dropdown",
      isLogValuesInTelemetry: true,
    },
    {
      key: LikedKey,
      options: [{ value: "Liked" }, { value: "NotLiked" }],
      isSingleSelect: true,
      hidden: true,
      type: "dropdown",
      isLogValuesInTelemetry: true,
    },
    {
      key: DisplayCategoryKey,
      options: [...new Set(displayCategories)].map((x) => {
        return { value: x };
      }),
      type: "dropdown",
      isLogValuesInTelemetry: true,
    },
    {
      key: JobTypeKey,
      options: convertMetadataToJobTypeFilters(metadatas),
      type: "dropdown",
      isLogValuesInTelemetry: true,
    },
    {
      key: ExtensionKey,
      options: [...new Set(Object.keys(extensions))]
        .map((x) => {
          return { value: x };
        })
        .sort((a, b) => (a.value > b.value ? 1 : -1)),
      type: "dropdown",
      isLogValuesInTelemetry: false,
      telemetryValueOverrides: createTelemetryValueMapForExtensions(extensions),
    },
  ] as Filter[];

  if (excludes && excludes.length > 0) {
    return filterOptions.filter((filter) => !excludes.includes(filter.key));
  }

  return filterOptions;
};

/**
 * Filters out extensions if they are present in all prompts
 * @param extensions Record of extension name and associated extension and party type
 * @param prompts list of prompts
 * @returns Filtered record of extension name and associated extension and party type
 */
const postProcessExtensionFilters = (
  extensions: Record<string, ExtensionPartyType>,
  prompts: Prompt[]
): Record<string, ExtensionPartyType> => {
  const filteredExtensions: Record<string, ExtensionPartyType> = {};

  Object.entries(extensions).forEach(([extensionName, extensionPartyType]) => {
    if (!prompts.every((prompt) => prompt.ProviderName === extensionName)) {
      filteredExtensions[extensionName] = extensionPartyType;
    }
  });

  return filteredExtensions;
};

/**
 * Generates lookup map matching provider name to generated string that will be logged in telemetry for extensions since extension names are considered customer data
 * @param extensions Record of extension name and associated extension and party type
 * @returns Lookup table with provider name as key and generic values for telemetry as values (e.g. Data_ExtensionPlugIn_1p, Data_ExtensionPlugIn_3p, Data_ExtensionDC_1p, Data_ExtensionDC_3p)
 */
const createTelemetryValueMapForExtensions = (
  extensions: Record<string, ExtensionPartyType>
): Record<string, string> => {
  return Object.entries(extensions).reduce(
    (result: Record<string, string>, [key, value]) => {
      result[key] = generateExtensionTelemetryValue(value);
      return result;
    },
    {}
  );
};

/**
 * Generates string from origin and party type that will be logged in telemetry for extensions inplace of extension name since it is considered customer data
 * @param extensionString extension name
 * @param partyString party type
 * @returns string for telemetry (e.g. Data_ExtensionPlugIn_1p, Data_ExtensionPlugIn_3p, Data_ExtensionDC_1p, Data_ExtensionDC_3p)
 */
export const generateExtensionTelemetryValue = (
  extensionPartyType: ExtensionPartyType
): string => {
  const extensionType = getExtensionType(extensionPartyType.Origin as string);
  const partyType = getPartyType(
    extensionPartyType.ProviderPartyType as string
  );
  return `Data_Extension${extensionType}_${partyType}`;
};

/**
 * Generates string from origin
 * @param originString
 * @returns string for extension type (e.g. PlugIn, DC, User)
 */
const getExtensionType = (originString: string): string => {
  switch (originString) {
    case "Plugin":
      return "PlugIn";
    case "GPT":
      return "DC";
    case "User":
      return "User";
    default:
      return "Unknown";
  }
};

/**
 * Generates string from party type
 * @param partyString
 * @returns string for party type (e.g. 1p, 3p)
 */
const getPartyType = (partyString: string): string => {
  switch (partyString) {
    case "FirstParty":
      return "1p";
    case "ThirdParty":
      return "3p";
    default:
      return "Unknown";
  }
};

/**
 * Checks if rightFacets contains leftFacets
 * @param leftFacets
 * @param rightFacets
 * @returns true if rightFacets contains leftFacets, false otherwise
 */
export const facetsContain = (
  leftFacets: Record<string, string[]>,
  rightFacets: Record<string, string[]>
): boolean => {
  return Object.entries(leftFacets).every(([leftKey, leftValues]) => {
    const rightValues = rightFacets[leftKey];
    if (!rightValues) {
      return false;
    }

    return leftValues.every((v) => rightValues.includes(v));
  });
};

export const isFilterEnabled = (
  filterName: FilterName,
  selectedTab: PromptTabs,
  isAgentPromptFilteringEnabled?: boolean,
  isMetaOsV2?: boolean
): boolean => {
  const AllPromptsTabFilters = [
    DisplayCategoryKey,
    ProductNameKey,
    JobTypeKey,
    ExtensionKey,
  ];
  const SearchTabFilters = [
    DisplayCategoryKey,
    ProductNameKey,
    JobTypeKey,
    ExtensionKey,
    LikedKey,
  ];
  const SavedPromptsTabFilters = [
    DisplayCategoryKey,
    ProductNameKey,
    JobTypeKey,
    LikedKey,
    CreatedByKey,
    isAgentPromptFilteringEnabled ? ExtensionKey : "",
  ];
  const WorkgroupPromptsTabFilters = [WorkgroupKey, CreatedByKey];
  const FromTeamsPromptsTabFilters = [
    DisplayCategoryKey,
    ProductNameKey,
    JobTypeKey,
    WorkgroupKey,
    CreatedByKey,
  ];
  const AgentPromptsTabFilters = [ExtensionKey];
  const LikedPromptsTabFilters = [
    DisplayCategoryKey,
    ProductNameKey,
    JobTypeKey,
  ];

  switch (selectedTab) {
    case PromptTabs.AllPrompts:
      return AllPromptsTabFilters.includes(filterName);
    case PromptTabs.SearchPrompts:
      return SearchTabFilters.includes(filterName);
    case PromptTabs.SavedPrompts:
      return SavedPromptsTabFilters.includes(filterName);
    case PromptTabs.GroupPrompts:
      return isMetaOsV2
        ? FromTeamsPromptsTabFilters.includes(filterName)
        : WorkgroupPromptsTabFilters.includes(filterName);
    case PromptTabs.AgentPrompts:
      return AgentPromptsTabFilters.includes(filterName);
    case PromptTabs.LikedPrompts:
      return LikedPromptsTabFilters.includes(filterName);
  }

  return false;
};
