import { isDefined } from "./isDefined";

/**
 * Filters out null and undefined values from an array.
 * @param array The array to filter.
 */
export function filterOutNull<T>(
  array: ReadonlyArray<T | undefined | null>
): T[] {
  // @ts-ignore
  return array.filter(isDefined);
}
