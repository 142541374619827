/**
 * Returns the names of the enumerable properties and methods of an object.
 */
export function keys<T>(obj: T): Array<keyof T> {
  // IE behaves differently from other browsers and throws a TypeError if you
  // pass a primitive value to Object.keys(). Normalize the behavior across browsers.
  if (typeof obj === "string") {
    // Convert "abc" => ["a", "b", "c"] before passing it to Object.keys
    return Object.keys(obj.split("")) as Array<keyof T>;
  } else if (typeof obj === "number" || typeof obj === "boolean") {
    return [];
  } else {
    // Note that this may still throw if you pass null or undefined.
    return Object.keys(obj as {}) as Array<keyof T>;
  }
}
