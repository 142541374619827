// Copyright (C) Microsoft Corporation. All rights reserved.

import type { IPublicClientApplication } from "@azure/msal-browser";
import { TelemetryLogger, onNotification } from "@microsoft/oteljs";
import { OneDSEndpoint } from "@microsoft/oteljs-1ds";
import { sendPageLoadEvent, sendPageShutdownEvent } from ".";
import { ThreeSEventsEndpoint } from "./Sinks/3SEvents";
import attachSink from "./attachSink";
import { captureClickEvents, captureScrollEvents } from "./autoCapture";
import {
  ARIA_INGESTION_KEY,
  DataBoundary,
  getDataBoundary,
  isGcc,
  isProduction,
  isStaging,
} from "./config";
import { EVENT_NAMESPACE } from "./events";

let telemetryLogger: TelemetryLogger | undefined;
let publicClientApplication: IPublicClientApplication | undefined;

/**
 * Resets the Telemetry Logger instance to undefined. Mainly used for testing purposes
 * @internal
 */
export function resetTelemetryLogger() {
  telemetryLogger = undefined;
}

/**
 * Gets or creates a new Telemetry Logger instance
 */
export default function getOrCreateTelemetryLogger(
  msalInstance?: IPublicClientApplication
): TelemetryLogger {
  const isProductionValue = isProduction();
  const isStagingValue = isStaging();
  const isGccValue = isGcc();
  const shouldAttachSink = isProductionValue || isStagingValue || isGccValue;
  publicClientApplication = msalInstance;

  if (telemetryLogger) {
    //We need to reattach the 3S Sync since MSAL public application instance may change
    if (shouldAttachSink && publicClientApplication !== undefined) {
      telemetryLogger.telemetrySinks = [];
      // Attach the 3S Events API
      attachSink(telemetryLogger, {
        sink: "1DS",
        endpointUrl: determine1DsEndpoint(),
      });

      // Attach the 3S Events API
      attachSink(telemetryLogger, {
        sink: "3SEvents",
        // Note: The 3S Events API automatically routes the EUDB data
        endpointUrl: ThreeSEventsEndpoint,
        msalInstance: msalInstance,
      });
    }
    return telemetryLogger;
  }

  telemetryLogger = new TelemetryLogger(
    undefined, // Parent logger
    undefined, // Persistent data fields
    {
      // Enables queueing events before a sink is attached; otherwise, events are dropped
      enableQueue: true,
    }
  );

  telemetryLogger.setTenantToken(EVENT_NAMESPACE, ARIA_INGESTION_KEY);

  window.addEventListener("beforeunload", (_ev) => {
    sendPageShutdownEvent();
  });

  window.addEventListener("load", (_ev) => {
    sendPageLoadEvent();
  });

  if (shouldAttachSink) {
    // Attach the 1DS telemetry sink
    attachSink(telemetryLogger, {
      sink: "1DS",
      endpointUrl: determine1DsEndpoint(),
    });

    // Attach the 3S Events API
    attachSink(telemetryLogger, {
      sink: "3SEvents",
      // Note: The 3S Events API automatically routes the EUDB data
      endpointUrl: ThreeSEventsEndpoint,
      msalInstance: msalInstance,
    });

    // Note: getTelemetryLogger is a singleton and the following auto capture
    // event handlers are only added once to the page's event listener.

    // auto capture onscroll events
    captureScrollEvents();
    // auto capture onclick events
    captureClickEvents();
  }

  if (shouldLogConsoleEvents()) {
    onNotification().addListener((event) => {
      console.log(event.message(), event.category, event.level);
    });
  }

  return telemetryLogger;
}

export function determine1DsEndpoint(): OneDSEndpoint {
  return isGcc()
    ? OneDSEndpoint.USGOV_DOJ
    : getDataBoundary() === DataBoundary.EU
      ? OneDSEndpoint.EUDB
      : OneDSEndpoint.PUBLIC;
}

export function shouldLogConsoleEvents(): boolean {
  const isProductionValue = isProduction();
  const isGccValue = isGcc();
  return !isProductionValue && !isGccValue;
}
