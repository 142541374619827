/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLogger } from "@1js/pl-logger";
import { Card, mergeClasses } from "@fluentui/react-components";
import DOMPurify from "dompurify";
import * as React from "react";
import { forwardRef, useState } from "react";
import type { Badge } from "../PromptBadges/PromptBadges.types";
import { PromptCardBody } from "../PromptBody/PromptCardBody";
import type { PromptCardProps } from "../PromptCard";
import { useInteractiveStyles, useStyles } from "../PromptCard.styles";
import { RockSteadyPromptCardFooter } from "./RockSteadyPromptCardFooter";

const handleArrowKeyNavigation = (
  event: React.KeyboardEvent<HTMLDivElement>
) => {
  const currentCard = event.currentTarget;
  const parentElement = currentCard.parentElement;
  let newTarget;

  if (!parentElement) return;

  switch (event.key) {
    case "ArrowUp":
      newTarget = parentElement.previousElementSibling?.firstElementChild;
      break;
    case "ArrowDown":
      newTarget = parentElement.nextElementSibling?.firstElementChild;
      break;
    default:
      return;
  }

  if (newTarget) {
    event.preventDefault();
    (newTarget as HTMLElement).focus();
    (newTarget as HTMLElement).tabIndex = 0;
    (currentCard as HTMLElement).tabIndex = -1;
  }
};

export const RockSteadyPromptCard = forwardRef<HTMLDivElement, PromptCardProps>(
  (props, ref) => {
    const {
      appearance,
      prompt,
      isPromptFocused: promptFocused,
      displayPosition,
      className,
      isBadgesEnabled,
      isFavoriteEnabled,
      onClicked,
      onFavoriteToggled,
      isPromptSavingEnabled,
      isPromptEditingEnabled,
      isPromptSharingEnabled,
      isPromptSharingViaEmailEnabled,
      isPromptSharingToWorkgroupEnabled,
      isPromptRingTagEnabled,
      isPromptRemovingFromWorkgroupEnabled,
      isPromptSharingOneClickUxEnabled,
      isPromptLikingEnabled,
      onDelete,
      onEditToggled,
      onEditSaved,
      onFocus,
      onShareViaCopyLinkClicked,
      onShareViaEmailClicked,
      onShareToWorkgroupClicked,
      onRemoveFromWorkgroupClicked,
      selectedGroupId,
      groups,
      setShareButtonTarget,
      isShowPromptAuthor,
      extensionIconMap,
    } = props;

    const logger = useLogger();
    const styles = useStyles(appearance);
    const interactiveStyles = useInteractiveStyles(appearance);

    const handleClick = () => {
      !prompt.IsEditing && onClicked && onClicked(prompt, displayPosition);
      onClicked && logger?.promptCard.sendPromptCardClickEvent({ prompt });
    };

    const [isCardFocused, setIsCardFocused] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      handleArrowKeyNavigation(event);
      const target = event.target as HTMLElement;
      const isCard = target.getAttribute("data-type") === "card";
      if (isCard && (event.key === "Enter" || event.key === " ")) {
        event.preventDefault();
        if (onClicked) {
          onClicked(prompt, displayPosition);
        }
        logger?.promptCard.sendPromptCardClickEvent({ prompt });
      }
    };

    const badges: Badge[] = prompt.Products.map((product) => {
      return {
        id: product,
        onAction: undefined,
      };
    });

    const sanitizedDisplayText = DOMPurify.sanitize(
      `${prompt.Title}. ${prompt.DisplayText}.`,
      {
        IN_PLACE: true,
        ALLOWED_TAGS: [],
      }
    );

    React.useEffect(() => {
      if (!promptFocused) {
        setIsCardFocused(false);
      }
    }, [promptFocused]);

    const handleCardFocus = () => {
      setIsCardFocused(true);
      if (onFocus) {
        onFocus(prompt, displayPosition);
      }
    };

    return (
      <Card
        data-type="card"
        className={mergeClasses(
          styles.card,
          !!onClicked && interactiveStyles.card,
          className
        )}
        size="large"
        tabIndex={displayPosition === 0 ? 0 : -1}
        onKeyDown={handleKeyDown}
        ref={ref}
        data-testid="prompt-card"
        onFocus={handleCardFocus}
        role="listitem"
        aria-label={sanitizedDisplayText}
        aria-posinset={props["aria-posinset"]}
        aria-setsize={props["aria-setsize"]}
      >
        {/* we are attaching handleClick to this div instead of <Card> itself because as soon as we add handleClick to <Card>, tab navigation within the card breaks. User loses the ability to navigate to bookmark action button using tab. */}
        <div
          className={mergeClasses(
            styles.cardBody,
            !!onClicked && interactiveStyles.cardBody
          )}
          tabIndex={-1}
          onClick={handleClick}
        >
          <PromptCardBody prompt={prompt} appearance={appearance} />
          <RockSteadyPromptCardFooter
            appearance={appearance}
            products={badges}
            prompt={prompt}
            isBookmarking={prompt.IsBookmarking}
            isBadgeEnabled={isBadgesEnabled}
            isCardFocused={isCardFocused}
            isFavoriteEnabled={isFavoriteEnabled}
            isFavorited={prompt.UserActivity?.Favorite?.IsFavorite}
            onFavorited={() => {
              logger?.promptCard.sendPromptCardFavoriteEvent(
                { prompt },
                !prompt.UserActivity.Favorite.IsFavorite
              );
              onFavoriteToggled &&
                onFavoriteToggled(
                  prompt,
                  !prompt.UserActivity.Favorite.IsFavorite
                );
            }}
            isPromptSavingEnabled={isPromptSavingEnabled}
            isPromptEditingEnabled={isPromptEditingEnabled}
            isPromptSharingEnabled={isPromptSharingEnabled}
            isPromptSharingViaEmailEnabled={isPromptSharingViaEmailEnabled}
            isPromptSharingToWorkgroupEnabled={
              isPromptSharingToWorkgroupEnabled
            }
            isPromptLikingEnabled={isPromptLikingEnabled}
            isPromptSharingOneClickUxEnabled={isPromptSharingOneClickUxEnabled}
            onShareViaCopyLinkClicked={onShareViaCopyLinkClicked}
            onShareViaEmailClicked={onShareViaEmailClicked}
            onShareToWorkgroupClicked={onShareToWorkgroupClicked}
            isDeleting={prompt.IsDeleting}
            isRemoving={prompt.RemovingState === "long"}
            onDelete={() => {
              onDelete && onDelete(prompt);
            }}
            onEditToggled={() => {
              onEditToggled && onEditToggled(prompt);
            }}
            onEditSaved={() => {
              onEditSaved && onEditSaved(prompt);
            }}
            isEditing={prompt.IsEditing}
            isPromptRingTagEnabled={isPromptRingTagEnabled}
            isPromptRemovingFromWorkgroupEnabled={
              isPromptRemovingFromWorkgroupEnabled
            }
            onRemoveFromWorkgroupClicked={onRemoveFromWorkgroupClicked}
            selectedGroupId={selectedGroupId}
            groups={groups}
            setShareButtonTarget={setShareButtonTarget}
            isShowPromptAuthor={isShowPromptAuthor}
            extensionIconMap={extensionIconMap}
          />
        </div>
      </Card>
    );
  }
);
RockSteadyPromptCard.displayName = "RockSteadyPromptCard";
