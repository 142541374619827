import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { DebugPanel } from "../components/DebugPanel/DebugPanel.web";
import { MsalWrapper } from "../components/MsalWrapper/MsalWrapper.web";
import { PageHeader } from "../components/PageHeader/PageHeader.web";
import type { PageHeaderProps } from "../components/PageHeader/PageHeader.web.data";
import { ButtonRefProvider } from "../contexts/ButtonRefContext.web";
import { useFlightContext } from "../hooks/useFlightContext";
import type { SiteSetting } from "../types";
import useTelemetry from "../utils/Telemetry/useTelemetry";
import { PropertyContext } from "./PropertyContext.web";

type LayoutProps = {
  children: React.ReactNode;
  header: PageHeaderProps;
  setting: SiteSetting;
  browserRouterBasename?: string;
};

export const Layout = ({
  children,
  header,
  setting,
  browserRouterBasename,
}: LayoutProps) => {
  const initialSiteSettings: SiteSetting = {
    ...setting,
  };
  const [siteSetting, setSiteSetting] = React.useState(initialSiteSettings);
  const telemetry = useTelemetry();
  React.useEffect(() => {
    if (setting.user) {
      telemetry.sendCopilotLicenseStateEvent(!!setting.user.copilotLicensed);
    }
  }, [telemetry, setting.user]);

  return (
    <ButtonRefProvider>
      <PropertyContext.Provider value={[siteSetting, setSiteSetting]}>
        {browserRouterBasename ? (
          <BrowserRouter basename={browserRouterBasename}>
            <LayoutWithContext header={header} setting={setting}>
              {children}
            </LayoutWithContext>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <LayoutWithContext header={header} setting={setting}>
              {children}
            </LayoutWithContext>
          </BrowserRouter>
        )}
      </PropertyContext.Provider>
    </ButtonRefProvider>
  );
};

Layout.defaultName = "Layout";

const LayoutWithContext = ({ children, header }: LayoutProps) => {
  const { isFlightActive } = useFlightContext();
  const telemetry = useTelemetry();
  React.useEffect(() => {
    telemetry.sendPageInitEvent();
  }, [telemetry]);
  return (
    <MsalWrapper>
      {isFlightActive("isHeaderEnabled") && <PageHeader {...header} />}
      {children}
      <DebugPanel />
    </MsalWrapper>
  );
};
