import type { Group } from "@1js/pl-types";
import {
  CardFooter,
  makeStyles,
  shorthands,
  Tag,
  Text,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import type { Appearance, Prompt } from "../../data";
import { PromptAuthor } from "../PromptAuthor";
import { PromptBadges } from "../PromptBadges/PromptBadges";
import { type Badge } from "../PromptBadges/PromptBadges.types";
import { PromptCardActionButtons } from "../PromptCardActionButtons";

type LegacyPromptCardFooterProps = {
  products: Badge[];
  prompt: Prompt;
  appearance?: Appearance;
  isBadgeEnabled?: boolean;
  isFavoriteEnabled?: boolean;
  isBookmarking?: boolean;
  isCardFocused?: boolean;
  isFavorited?: boolean;
  onFavorited?: () => void;
  isDeleting?: boolean;
  isRemoving?: boolean;
  isPromptSavingEnabled?: boolean;
  isPromptEditingEnabled?: boolean;
  isPromptSharingEnabled?: boolean;
  isPromptSharingViaEmailEnabled?: boolean;
  isPromptSharingToWorkgroupEnabled?: boolean;
  isPromptRemovingFromWorkgroupEnabled?: boolean;
  isPromptSharingOneClickUxEnabled?: boolean;
  isPromptLikingEnabled?: boolean;
  onShareViaCopyLinkClicked?: (prompt: Prompt) => void;
  onShareViaEmailClicked?: (prompt: Prompt) => void | Promise<void>;
  onShareToWorkgroupClicked?: (prompts: Prompt[]) => void | Promise<void>;
  onDelete?: () => void;
  onEditToggled?: () => void;
  onEditSaved?: () => void;
  onRemoveFromWorkgroupClicked?: (
    prompts: Prompt[],
    groupIds: string[]
  ) => void;
  isEditing?: boolean;
  isPromptRingTagEnabled?: boolean;
  selectedGroupId?: string;
  groups?: Group[];
  setShareButtonTarget?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  isShowPromptAuthor?: boolean;
  extensionIconMap?: Record<string, string>;
};

const useStyles = makeStyles({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    alignSelf: "stretch",
  },
  statusBar: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  promptRingText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  promptRingTag: {
    backgroundColor: tokens.colorBrandBackground2,
  },
});

export const LegacyPromptCardFooter: React.FC<LegacyPromptCardFooterProps> = ({
  products,
  appearance,
  prompt,
  isFavorited,
  isFavoriteEnabled,
  isBookmarking,
  isBadgeEnabled,
  isCardFocused,
  onFavorited,
  isDeleting,
  isRemoving,
  isPromptSavingEnabled,
  onDelete: onDelete,
  isPromptEditingEnabled,
  onEditToggled,
  onEditSaved,
  onRemoveFromWorkgroupClicked,
  isEditing,
  isPromptSharingEnabled,
  isPromptSharingViaEmailEnabled,
  isPromptSharingToWorkgroupEnabled,
  isPromptRemovingFromWorkgroupEnabled,
  isPromptSharingOneClickUxEnabled,
  isPromptLikingEnabled,
  onShareViaCopyLinkClicked,
  onShareViaEmailClicked,
  onShareToWorkgroupClicked,
  isPromptRingTagEnabled,
  selectedGroupId,
  groups,
  setShareButtonTarget,
  isShowPromptAuthor,
  extensionIconMap,
}) => {
  const styles = useStyles();
  const tabIndex = isCardFocused ? 0 : -1;

  return (
    <CardFooter className={styles.footer}>
      <div className={styles.statusBar}>
        {((isPromptSavingEnabled &&
          (selectedGroupId === "Default" || !selectedGroupId)) ||
          isShowPromptAuthor) && (
          <PromptAuthor
            prompt={prompt}
            tabIndex={tabIndex}
            extensionIconMap={extensionIconMap}
          />
        )}
        {isBadgeEnabled && (
          <>
            <PromptBadges
              badges={products}
              // only display one product icon if the prompt author is displayed
              maxNumber={
                isPromptSavingEnabled || isDeleting || isRemoving ? 1 : 4
              }
              displayMode="iconOnly"
            />
          </>
        )}
        {isPromptRingTagEnabled && prompt.PromptLabel && (
          <Tag className={styles.promptRingTag} size="small">
            <Text className={styles.promptRingText}>{prompt.PromptLabel}</Text>
          </Tag>
        )}
      </div>
      <PromptCardActionButtons
        prompt={prompt}
        appearance={appearance}
        isFavoriteEnabled={isFavoriteEnabled}
        isBookmarking={isBookmarking}
        isFavorited={isFavorited}
        onFavorited={onFavorited}
        isDeleting={isDeleting}
        isRemoving={isRemoving}
        isPromptSavingEnabled={isPromptSavingEnabled}
        isPromptEditingEnabled={isPromptEditingEnabled}
        isPromptSharingEnabled={isPromptSharingEnabled}
        isPromptSharingViaEmailEnabled={isPromptSharingViaEmailEnabled}
        isPromptSharingToWorkgroupEnabled={isPromptSharingToWorkgroupEnabled}
        isPromptRemovingFromWorkgroupEnabled={
          isPromptRemovingFromWorkgroupEnabled
        }
        isPromptSharingOneClickUxEnabled={isPromptSharingOneClickUxEnabled}
        isPromptLikingEnabled={isPromptLikingEnabled}
        onShareViaCopyLinkClicked={onShareViaCopyLinkClicked}
        onShareViaEmailClicked={onShareViaEmailClicked}
        onShareToWorkgroupClicked={onShareToWorkgroupClicked}
        onDelete={onDelete}
        onEditToggled={onEditToggled}
        onEditSaved={onEditSaved}
        onRemoveFromWorkgroupClicked={onRemoveFromWorkgroupClicked}
        isEditing={isEditing}
        selectedGroupId={selectedGroupId}
        groups={groups}
        setShareButtonTarget={setShareButtonTarget}
        tabIndex={tabIndex}
      />
    </CardFooter>
  );
};
