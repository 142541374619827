import {
  Column,
  Grid,
  PromptCard,
  Row,
  type Prompt,
} from "@1js/pl-card-components";
import { makeStyles, shorthands } from "@fluentui/react-components";
import * as React from "react";
import { NotFoundSection } from "../../components/NotFound/NotFoundSection.web";
import { useFlightContext } from "../../hooks/useFlightContext";
import { useSharePrompt } from "../../hooks/useSharePrompt";
import useTelemetry from "../../utils/Telemetry/useTelemetry";

const useStyles = makeStyles({
  notFound: {
    paddingTop: "32px",
    "@media (max-width: 540px)": {
      paddingTop: "24px",
    },
  },

  row: {
    rowGap: "16px",
  },

  col: {
    "@media (max-width: 1084px)": {
      ...shorthands.flex(0, 0, "50%"),
      maxWidth: "50%",
    },

    "@media (max-width: 953px)": {
      ...shorthands.flex(0, 0, "100%"),
      maxWidth: "100%",
    },
  },
});

export type NotFoundPageProps = {
  prompts: Prompt[];
};

const { host } = window.location;

const handleClick = () => {
  window.location.assign(`https://${host}/prompts/all`);
};

export const NotFoundPage: React.FC<NotFoundPageProps> = ({ prompts }) => {
  const telemetry = useTelemetry();
  const styles = useStyles();
  const sharePrompt = useSharePrompt();
  const handleCardClick = React.useCallback(
    (prompt: Prompt) => {
      telemetry.sendPromptClickedEvent(prompt, true);
      window.location.assign(sharePrompt.buildPromptDetailPageUrl(prompt));
    },
    [telemetry, sharePrompt]
  );

  const { isFlightActive } = useFlightContext();
  const appearance = isFlightActive("isGoBoldPromptCardEnabled")
    ? "web-v2"
    : "web";

  return (
    <Grid
      data-testid="not-found-page"
      goBold={isFlightActive("isGoBoldPromptCardEnabled")}
    >
      <div
        className={
          isFlightActive("isHeaderEnabled") ? styles.notFound : undefined
        }
      >
        <NotFoundSection onHandleButtonClick={handleClick} />
      </div>
      <Row className={styles.row}>
        {prompts.map((prompt) => {
          return (
            <Column key={prompt.Id} size={3} className={styles.col}>
              <PromptCard
                appearance={appearance}
                prompt={prompt}
                isFavoriteEnabled={false}
                onClicked={handleCardClick}
                isBadgesEnabled={true}
              />
            </Column>
          );
        })}
      </Row>
    </Grid>
  );
};
