import { declareString } from "@1js/localization";

export const SuccessStopShareToastMessage = declareString(
  "promptLibrary.successStopShareToastMessage",
  {
    text: "You stopped sharing the prompt",
    comment: `Toast title that shows in a toast notification when the user clicks on the button to stop sharing a prompt with other users. 'prompts' is the text input provided to AI model like GPT.`,
  }
);
export const FailureStopShareToastMessage = declareString(
  "promptLibrary.failureStopShareToastMessage",
  {
    text: "Couldn't stop sharing the prompt. Try again later.",
    comment: `Toast title that shows in a toast notification when the user clicks on the button and there is a failure to stop sharing a prompt with other users. 'prompts' is the text input provided to AI model like GPT.`,
  }
);
export const ManageAccessDialogTitle = declareString(
  "promptLibrary.manageAccessDialogTitle",
  {
    text: "Manage Access",
    comment: `Dialog title that shows when the user clicks on the link to manage access to the prompt. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ManageAccessDialogDescription = declareString(
  "promptLibrary.manageAccessDialogDescription",
  {
    text: "Manage shared prompts",
    comment: `Dialog description that shows when the user clicks on the link to manage access to the prompt. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ManageAccessDialogInstructions = declareString(
  "promptLibrary.manageAccessDialogInstructions",
  {
    text: "Select the link icon to stop sharing a prompt you created and issued to Copilot.",
    comment: `Dialog instructions that instruct a user to click on the link icon to manage access to the prompt they are sharing. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ManageAccessDialogListHeader = declareString(
  "promptLibrary.manageAccessDialogListHeader",
  {
    text: "Your shared prompts",
    comment: `Dialog header that shows when the user opens a dialog to manage access to a list of prompts they are sharing. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ConfirmationDialogTitle = declareString(
  "promptLibrary.confirmationDialogTitle",
  {
    text: "Stop sharing the prompt?",
    comment: `Confirmation text that shows when the user clicks on the button to stop sharing a prompt with other users. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ConfirmationDialogBody = declareString(
  "promptLibrary.confirmationDialogBody",
  {
    text: "People you previously shared this prompt with will no longer have access to it.",
    comment: `Confirmation text that shows when the user clicks on the button to stop sharing a prompt with other users. 'prompts' is the text input provided to AI model like GPT.`,
  }
);
export const ConfirmationDialogStopSharing = declareString(
  "promptLibrary.confirmationDialogStopSharing",
  {
    text: "Stop sharing",
    comment: `Confirmation text that shows when the user clicks on the button to stop sharing a prompt with other users. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ConfirmationDialogClose = declareString(
  "promptLibrary.confirmationDialogClose",
  {
    text: "Close",
    comment: `Confirmation text that shows when the user clicks on the button to stop sharing a prompt with other users. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ConfirmationDialogDeleting = declareString(
  "promptLibrary.confirmationDialogDeleting",
  {
    text: "Deleting...",
    comment: `Confirmation label that when the user clicks on the button to stop sharing a prompt with other users. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ManageAccessNoSharedPrompts = declareString(
  "promptLibrary.manageAccessNoSharedPrompts",
  {
    text: "Once you create and share a prompt with others, you can manage access here.",
    comment: `Text that shows when the user opens a dialog to manage access to a list of prompts they are sharing. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ManageAccessLogin = declareString(
  "promptLibrary.manageAccessLogin",
  {
    text: "Please sign in to manage shared prompts.",
    comment: `Text that shows when the user is not logged in and opens a dialog to manage access to a list of prompts they are sharing. 'prompts' is the text input provided to AI model like GPT.`,
  }
);

export const ManageAccessTooltip = declareString(
  "promptLibrary.manageAccessTooltip",
  {
    text: "Manage access to shared prompts",
    comment: `Tooltip text that shows when the user hovers over the link to manage access to the prompt. 'prompts' is the text input provided to AI model like GPT.`,
  }
);
