// Copyright (C) Microsoft Corporation. All rights reserved.

import type { Prompt, PromptOrigin } from "@1js/pl-types";
import type { IFeedbackTelemetry } from "@ms/inapp-feedback-loader";
import UAParser from "ua-parser-js";
import { isMetaOsHelper } from "../url";
import { readFromMetaTags } from "./config";

/**
 * The label of elements with this attribute are allowed to be logged
 */
export const ALLOWED_ELEMENTS_LABEL_DATA_ATTR = "data-telemetry-labels";

/**
 * The full URL (e.g. href) of elements with this attribute are allowed to be logged
 */
export const ALLOWED_ELEMENTS_URL_DATA_ATTR = "data-telemetry-urls";

export const ALLOWED_ELEMENTS_PARENT_ID_DATA_ATTR = "data-telemetry-parent-id";

/**
 * Debounce function
 * @param fn - The function that will be triggered
 * @params wait - The debounce time interval
 */
export function debounce(fn: (...args: any) => void, wait: number) {
  let timeout: number;
  return function (...args: any) {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => fn(args), wait);
  };
}

/**
 * Use window dimensions if available before reaching into DOM
 */
export function getViewportDimensions(): {
  viewportWidth: number;
  viewportHeight: number;
} {
  const viewport = { viewportWidth: 0, viewportHeight: 0 };
  const win = window;
  const doc = window.document;
  if (win && doc && win.screen) {
    const body: HTMLElement = doc.body || ({} as HTMLElement);
    const docElem: HTMLElement = doc.documentElement || ({} as HTMLElement);
    viewport.viewportHeight =
      win.innerHeight || body.clientHeight || docElem.clientHeight;
    viewport.viewportWidth =
      win.innerWidth || body.clientWidth || docElem.clientWidth;
  }

  return viewport;
}

/**
 *  Get scroll offset
 */
export function getScrollOffset(): { scrollLeft: number; scrollTop: number } {
  let scrollOffset = { scrollLeft: 0, scrollTop: 0 };
  const doc = window.document;
  if (doc) {
    scrollOffset = {
      scrollLeft: doc.body.scrollLeft || doc.documentElement.scrollLeft,
      scrollTop: doc.body.scrollTop || doc.documentElement.scrollTop,
    };
  }

  return scrollOffset;
}

/**
 * Returns the page height (scroll height)
 * @returns Page scroll height
 */
export function getPageHeight(): number | undefined {
  if (document && document.body) {
    return document.body.scrollHeight;
  }
}

/**
 * Returns an object containing the details of a clickable element (e.g element label)
 * @param element Element object
 * @returns clickable element details
 */
export function getClickableElementDetails(element: HTMLElement):
  | {
      elementLabel: string;
      elementUrl: string;
      elementType: string;
      elementParentId: string;
      elementId: string;
    }
  | undefined {
  if (!element) return;

  const res = {
    elementUrl: "",
    elementLabel: "",
    elementType: element.tagName.toLowerCase(),
    elementParentId: "",
    elementId: getElementRecursiveId(element),
  };

  const allowedToLogLabels = element.closest(
    `[${ALLOWED_ELEMENTS_LABEL_DATA_ATTR}]`
  );
  const allowedToLogFullUrls = element.closest(
    `[${ALLOWED_ELEMENTS_URL_DATA_ATTR}]`
  );

  // set this attribute to indicate the parent id of a tag, this is used to distinguish same links in different locations, e.g. same links in header and body.
  const allowedToLogParentId = element.closest(
    `[${ALLOWED_ELEMENTS_PARENT_ID_DATA_ATTR}]`
  );

  // We cannot log any element label because it may contain PII.
  // Only log those elements who have been explicitly denoted.
  if (allowedToLogLabels) {
    res.elementLabel = element.getAttribute("aria-label") || element.innerText;
  }

  if (res.elementType === "a") {
    // Convert relative URL to full URL
    let href = element.getAttribute("href") || "";
    if (href && !href.startsWith("http") && !href.startsWith("//")) {
      const baseUrl = window.location.origin;
      href = new URL(href, baseUrl).href;
    }
    res.elementUrl = href;
  }

  // We cannot log the full URL of any element because it may contain PII/CC.
  // Only log those elements who have been explicitly denoted.
  if (!allowedToLogFullUrls) {
    res.elementUrl = sanitizeUrl(res.elementUrl) || "";
  }

  if (allowedToLogParentId) {
    res.elementParentId =
      allowedToLogParentId.getAttribute("data-telemetry-parent-id") || "";
  }

  return res;
}

/**
 * Returns the current page performance metrics
 * @returns Page performance metrics
 */
export function getPageLoadTime(): number | undefined {
  const performance = window.performance;

  if (typeof performance.getEntriesByType === "function") {
    const navigationPerformance = window.performance.getEntriesByType(
      "navigation"
    )?.[0] as PerformanceNavigationTiming;

    if (navigationPerformance) {
      return (
        navigationPerformance.domContentLoadedEventEnd -
        navigationPerformance.startTime
      );
    }
  }

  if (performance.timing) {
    // try the deprecated timing API if getEntriesByType is not available
    return (
      performance.timing.domContentLoadedEventEnd -
      performance.timing.navigationStart
    );
  }

  return undefined;
}

/**
 * Returns the user's ECS flight IDs
 * @returns ECS flights
 */
export function getEcsConfigIds(): string {
  return readFromMetaTags("awa-expid") || "";
}

/**
 * Returns the user's country code
 * @returns User's country code
 */
export function getUserCountryCode(): string {
  return readFromMetaTags("awa-userCountryCode") || "";
}

/**
 * Returns the user's tenant ID from the meta tags, undefined if not found
 * @returns User's tenant ID
 */
export function getUserTenantId() {
  return readFromMetaTags("awa-tenantid");
}

/**
 * Accepts a URL and removes the querystring / hash from the URL
 * @param url URL to sanitize
 * @param appendPathname Whether the URL path should be appended to the result
 * @returns sanitized URL
 */
export function sanitizeUrl(
  url: string,
  appendPathname = false
): string | undefined {
  if (isMetaOsHelper()) {
    return url;
  }

  try {
    const parsedUrl = new URL(url);
    let result = parsedUrl.origin;
    if (appendPathname) {
      result += parsedUrl.pathname;
    }
    return result;
  } catch (_) {
    return undefined;
  }
}

/**
 * Cached page referrer
 */
let pageReferrer: string | undefined = undefined;

/**
 * Returns the referrer for this page by inspecting the passed querystring
 * if the user is coming from the AAD login page, or by returning the document.referrer field
 * @param referrerQueryStringName QueryString name for the Referrer.
 * @returns either the referrer passed in querystring, or document.referrer
 */
export function getPageReferrer(referrerQueryStringName = "Referer"): string {
  if (!pageReferrer) {
    const location = new URL(document.location.href);
    let queryStringReferrer = undefined;

    if (
      /login\.(microsoft|live)/gi.test(document.referrer) &&
      location.searchParams.has(referrerQueryStringName)
    ) {
      // read and then delete the 'Referer' querystring
      queryStringReferrer = location.searchParams.get(referrerQueryStringName);
      location.searchParams.delete(referrerQueryStringName);
      history.replaceState({}, "", location.href);
    }

    pageReferrer = queryStringReferrer || document.referrer;
  }

  return pageReferrer;
}

/**
 * Given a page URL, extracts the OCID parameter and returns it
 * See: https://microsoft.sharepoint.com/teams/MarTechPortal/SitePages/website-tools/adobe-analytics/guided-learning/tracking-codes.aspx
 * @param url Page Url
 * @param ocidQueryStringKey The OCID key in querystring
 */
export function extractOCID(
  url: string,
  ocidQueryStringKey = "ocid"
): string | undefined {
  try {
    const parsedUrl = new URL(url.toLowerCase());
    return parsedUrl.searchParams.get(ocidQueryStringKey) || "";
  } catch (_) {
    return undefined;
  }
}

/**
 * Returns the prompt origin (e.g. "Microsoft", "Global", or "User")
 * @param prompt Prompt object
 * @returns Prompt origin
 */
export const getPromptOrigin = (prompt: Prompt): PromptOrigin => {
  if (prompt.Origin) {
    return prompt.Origin;
  }

  return "Microsoft";
};

export function getFeedbackTelemetry(): IFeedbackTelemetry {
  const { os, browser, device } = new UAParser().getResult();
  return {
    browser: browser.name,
    browserVersion: browser.version,
    deviceType: device.type,
    platform: `${os.name} ${os.version}`,
    sourcePageName: document.title,
    sourcePageURI: `${location.protocol}//${location.host}${location.pathname}`, // construct URI without querystrings to avoid PII leaks
  };
}

/**
 * Returns the recursive ID of an element
 * Example:
 *   div#parent > div#child > div#grandchild
 *   The recursive ID of grandchild: "parent.child.grandchild"
 * @param element Element to get the recursive ID for
 * @param recursiveId Recursive ID
 * @param maxRecursionDepth Maximum recursion depth
 * @param idSeparator Separator for the recursive ID
 * @returns Recursive ID of the given element
 */
export const getElementRecursiveId = (
  element: Element | null,
  recursiveId = "",
  maxRecursionDepth = 10,
  idSeparator = "."
): string => {
  if (maxRecursionDepth <= 0) return recursiveId;
  if (!element) return recursiveId;

  if (element.id && element.id.length > 0) {
    if (recursiveId.length > 0) {
      recursiveId = `${element.id}${idSeparator}${recursiveId}`;
    } else {
      recursiveId = element.id;
    }
  }

  return getElementRecursiveId(
    element.parentElement,
    recursiveId,
    maxRecursionDepth - 1
  );
};

/**
 * Returns the recursive ID of the current active element in the document (i.e. the element that has focus)
 * @returns Recursive ID of the active element
 */
export const getFocusedElementRecursiveId = (): string => {
  return getElementRecursiveId(document.activeElement);
};
