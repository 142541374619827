import { useStrings } from "@1js/localization-components";
import type { ButtonProps } from "@fluentui/react-components";
import { Spinner, ToggleButton, Tooltip } from "@fluentui/react-components";
import type { FluentIconsProps } from "@fluentui/react-icons";
import {
  BookmarkFilled,
  BookmarkRegular,
  bundleIcon,
  HeartFilled,
  HeartRegular,
} from "@fluentui/react-icons";
import * as React from "react";
import type { Appearance } from "../../data";
import {
  BookmarkButtonTooltip,
  BookmarkRemovingLabel,
  BookmarkSavingLabel,
  LikeButtonTooltip,
  UnBookmarkButtonTooltip,
  UnLikeButtonTooltip,
} from "./PromptFavorite.strings";
import { useStyles } from "./PromptFavorite.styles";

export type PromptFavoriteProps = {
  appearance?: Appearance;
  isLoading?: boolean;
  isFavorited?: boolean;
  onFavorited?: () => void;
  tabIndex?: number;
  isPromptLikingEnabled?: boolean;
};

const BookmarkIcon = bundleIcon(BookmarkFilled, BookmarkRegular);
const HeartIcon = bundleIcon(HeartFilled, HeartRegular);

export const PromptFavorite: React.FC<PromptFavoriteProps> = ({
  appearance,
  isLoading,
  isFavorited,
  onFavorited,
  tabIndex,
  isPromptLikingEnabled,
}) => {
  const styles = useStyles(appearance)();
  const localizations = useStrings({
    BookmarkButtonTooltip,
    BookmarkSavingLabel,
    UnBookmarkButtonTooltip,
    BookmarkRemovingLabel,
    LikeButtonTooltip,
    UnLikeButtonTooltip,
  });

  const buttonStyleProps: ButtonProps = {
    appearance: "transparent",
  };

  const iconStyleProps: FluentIconsProps = {
    filled: isFavorited,
  };

  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onFavorited && onFavorited();
  };

  const bookmarkTooltip = isFavorited
    ? localizations.UnBookmarkButtonTooltip()
    : localizations.BookmarkButtonTooltip();

  const likeTooltip = isFavorited
    ? localizations.UnLikeButtonTooltip()
    : localizations.LikeButtonTooltip();
  return (
    <div className={styles.bookMarks}>
      <Tooltip
        content={isPromptLikingEnabled ? likeTooltip : bookmarkTooltip}
        relationship="label"
      >
        <ToggleButton
          data-testid="bookmark-button"
          className={
            isLoading
              ? styles.spinner
              : isFavorited
                ? styles.bookmark
                : styles.unbookmark
          }
          {...buttonStyleProps}
          size="small"
          checked={isFavorited}
          onClick={handleOnClick}
          aria-labelledby={isLoading ? "saving-spinner" : ""}
          tabIndex={tabIndex ?? 0}
          aria-label={isPromptLikingEnabled ? likeTooltip : bookmarkTooltip}
        >
          {isLoading ? (
            <Spinner
              id="saving-spinner"
              size="tiny"
              labelPosition="after"
              label={
                <span className={styles.iconText}>
                  {isFavorited
                    ? localizations.BookmarkRemovingLabel()
                    : localizations.BookmarkSavingLabel()}
                </span>
              }
            />
          ) : isPromptLikingEnabled ? (
            <HeartIcon {...iconStyleProps} className={styles.icon} />
          ) : (
            <BookmarkIcon {...iconStyleProps} className={styles.icon} />
          )}
        </ToggleButton>
      </Tooltip>
    </div>
  );
};
