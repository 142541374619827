import { declareString } from "@1js/localization";

export const EditButtonTooltip = declareString(
  "promptLibrary.editButtonTooltip",
  {
    text: "Edit prompt",
    comment:
      "Tooltip that's displayed to user when user hovers over the edit button icon on a Copilot prompt card to edit it",
  }
);

export const EditSaveButtonTooltip = declareString(
  "promptLibrary.editSaveButtonTooltip",
  {
    text: "Save edit",
    comment:
      "Tooltip that's displayed to user when user hovers over the save edit button icon on a Copilot prompt card to save it",
  }
);

export const BackButtonTooltip = declareString(
  "promptLibrary.backButtonTooltip",
  {
    text: "Back",
    comment:
      "Tooltip that's displayed to user when user hovers over the back button icon on a Copilot prompt card to stop editing it",
  }
);
