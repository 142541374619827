export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function safeToLowerCase(
  s: string | null | undefined
): string | null | undefined {
  if (!isNullOrEmpty(s)) {
    return s!.toLowerCase();
  }

  return s;
}

export function isNullOrEmpty(s: string | null | undefined): boolean {
  return s === undefined || s === null || s.length === 0;
}

export function areEqualStringsIgnoreCasing(
  a: string | null | undefined,
  b: string | null | undefined
): boolean {
  return safeToLowerCase(a) === safeToLowerCase(b);
}

export function upperFirst(s: string): string {
  if (s.length) {
    return s[0].toUpperCase() + s.slice(1);
  }

  return s;
}

export function lowerFirst(s: string): string {
  if (s.length) {
    return s[0].toLowerCase() + s.slice(1);
  }

  return s;
}

export function startsWith(s: string, prefix: string): boolean {
  return s.slice(0, prefix.length) === prefix;
}

export function endsWith(s: string, suffix: string): boolean {
  return s.slice(0 - suffix.length) === suffix;
}

export type ConcatStringLiterals<
  T extends readonly string[],
  S extends string,
> = string[] extends T
  ? never
  : T extends readonly [infer F, ...infer R]
    ? F extends string
      ? R extends readonly string[]
        ? `${F}${R extends [] ? "" : S}${ConcatStringLiterals<R, S>}`
        : never
      : never
    : "";

export function safeJoinStrings<T extends readonly string[], S extends string>(
  strings: string[] extends T ? never : T,
  separator: S
) {
  return strings.join(separator) as ConcatStringLiterals<T, S>;
}
