import {
  Input,
  Textarea,
  makeStyles,
  shorthands,
  tokens,
  type InputOnChangeData,
  type TextareaOnChangeData,
} from "@fluentui/react-components";

import type { ChangeEvent } from "react";
import * as React from "react";
import type { Appearance, Prompt } from "../data";
import { PromptIcon } from "./PromptIcon/PromptIcon";

type PromptCardEditContentProps = {
  appearance?: Appearance;
  prompt: Prompt;
};

const useInAppStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    ...shorthands.gap("12px"),
  },

  preview: {
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },

  title: {
    display: "flex",
    alignItems: "flex-start",
    alignSelf: "stretch",
    ...shorthands.gap("8px"),
  },

  titleText: {
    color: tokens.colorNeutralForeground2,
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "137.5%",
    ...shorthands.overflow("hidden"),
  },
});

const useWebStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    ...shorthands.gap("12px"),
  },

  preview: {
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },

  title: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    ...shorthands.gap("4px"),
  },

  titleText: {
    color: tokens.colorNeutralForeground2,
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "1.5rem",
    ...shorthands.overflow("hidden"),
    "@media(max-width: 540px)": {
      fontSize: "1rem",
    },
  },
});

const useWebGoBoldStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    alignSelf: "stretch",
    ...shorthands.gap("12px"),
  },

  preview: {
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
    padding: "0px 2px",
  },

  title: {
    // GoBold prompt cards should not display the prompt title
    display: "none",
  },

  titleText: {},
});

const useM365Styles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    ...shorthands.gap("8px"),
  },

  preview: {
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },

  title: {
    display: "flex",
    alignItems: "flex-start",
    alignSelf: "stretch",
    ...shorthands.gap("6px"),
  },

  titleText: {
    color: tokens.colorNeutralForeground2,
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "1.25rem",
    ...shorthands.overflow("hidden"),
  },
});

const textAreaStyle = {
  overflow: "hidden",
};

const maxBodyLineByAppearance = (appearance?: Appearance) => {
  switch (appearance) {
    case "in-app":
      return 3;
    case "web":
    case "web-v2":
    case "in-app-v2":
      return 4;
    case "m365":
      return 4;
    default:
      return 3;
  }
};

const minBodyLineByAppearance = (appearance?: Appearance) => {
  switch (appearance) {
    case "in-app":
      return 2;
    case "web":
    case "web-v2":
    case "in-app-v2":
      return 3;
    case "m365":
      return 2;
    default:
      return 2;
  }
};

export const PromptCardEditBody: React.FC<PromptCardEditContentProps> =
  React.memo(({ prompt, appearance }) => {
    const inAppStyles = useInAppStyles();
    const webStyles = useWebStyles();
    const webGoBoldStyles = useWebGoBoldStyles();
    const m365Styles = useM365Styles();
    const useAppearanceStyles = (appearance?: Appearance) => {
      switch (appearance) {
        case "in-app":
          return inAppStyles;
        case "web":
          return webStyles;
        case "web-v2":
        case "in-app-v2":
          return webGoBoldStyles;
        case "m365":
          return m365Styles;
        default:
          return inAppStyles;
      }
    };
    const styles = useAppearanceStyles(appearance);
    const maxBodyLine = maxBodyLineByAppearance(appearance);
    const minBodyLine = minBodyLineByAppearance(appearance);
    const cardTitleRef = React.useRef<HTMLDivElement>(null);
    const cardBodyRef = React.useRef<HTMLDivElement>(null);

    const [titleFieldValue, setTitleFieldValue] = React.useState(prompt.Title);
    const [promptFieldValue, setPromptFieldValue] = React.useState(
      prompt.CommandText
    );

    const onChangeTitleFieldValue = React.useCallback(
      (_event: ChangeEvent<HTMLInputElement>, newValue?: InputOnChangeData) => {
        setTitleFieldValue(newValue?.value || prompt.Title);
      },
      [prompt.Title]
    );

    const onChangePromptFieldValue = React.useCallback(
      (
        _event: ChangeEvent<HTMLTextAreaElement>,
        newValue?: TextareaOnChangeData
      ) => {
        setPromptFieldValue(newValue?.value || prompt.CommandText);
      },
      [prompt.CommandText]
    );

    React.useEffect(() => {
      const titleOffSetHeight = cardTitleRef.current?.offsetHeight ?? 0;
      const numberOfLines = cardTitleRef.current
        ? Math.round(
            titleOffSetHeight /
              parseFloat(getComputedStyle(cardTitleRef.current).lineHeight)
          )
        : 0;

      if (numberOfLines > 1) {
        if (cardBodyRef.current) {
          cardBodyRef.current.style.webkitLineClamp = `${minBodyLine}`;
        }
      } else {
        if (cardBodyRef.current) {
          cardBodyRef.current.style.webkitLineClamp = `${maxBodyLine}`;
        }
      }
    }, [cardTitleRef, maxBodyLine, minBodyLine]);

    return (
      <div className={styles.content}>
        <div className={styles.title}>
          <PromptIcon
            prompt={prompt}
            appearance={appearance}
            useColorfulIcon={
              appearance === "in-app-v3" || appearance === "web-v3"
            }
          />
          <Input value={titleFieldValue} onChange={onChangeTitleFieldValue} />
        </div>
        <div
          data-testid="prompt-description"
          className={styles.preview}
          ref={cardBodyRef}
        >
          <Textarea
            id="editPromptTextArea"
            resize="none"
            value={promptFieldValue}
            onChange={onChangePromptFieldValue}
            textarea={{ style: textAreaStyle }}
            size="large"
          />
        </div>
      </div>
    );
  });

PromptCardEditBody.displayName = "PromptCardEditBody";
