import { useIconState } from "@fluentui/react-icons";
import * as React from "react";

// reference: https://dev.azure.com/microsoftdesign/Design%20System/_git/fluent-product-icons?path=%2Fassets

// This is essentially the copy of the createFluentIcon function from the fluentui/react-icons package but with support to
// pass in the actual REactSVGElement[] instead of the paths as strings. This is useful when we want to customize the SVG path such as
// flip rule and clip rule used by PowerPoint icon.
export interface IconProps extends React.SVGAttributes<SVGSVGElement> {
  size?: string;
}
export const createFluentIconExtended = (
  displayName: string,
  width: string,
  paths: React.ReactSVGElement[],
  viewBox?: string
) => {
  const viewBoxWidth = width === "1em" ? "20" : width.replace("px", "");

  const Icon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
    const size = props.size;
    const state = {
      ...useIconState(props),
      ref,
      width: size ?? width,
      height: size ?? width,
      viewBox: viewBox ?? `0 0 ${viewBoxWidth} ${viewBoxWidth}`,
      xmlns: "https://www.w3.org/2000/svg",
    };
    return React.createElement("svg", state, paths);
  });
  Icon.displayName = displayName;
  return Icon;
};
