import { Text, makeStyles, tokens } from "@fluentui/react-components";
import * as React from "react";
import type { ErrorTitleProps } from "./Error.web.data";

const useStyles = makeStyles({
  main: {
    color: tokens.colorNeutralForeground1,
    textAlign: "center",
    fontSize: "76px",
    maxWidth: "853px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "100%", // equivalent to 96px
    letterSpacing: "-2.88px",
    "@media (max-width: 540px)": {
      fontSize: "32px",
      letterSpacing: "-0.96px",
      maxWidth: "296px",
    },
  },
});

export const ErrorTitle: React.FC<ErrorTitleProps> = ({ titleText }) => {
  const styles = useStyles();

  return (
    <div style={{ textAlign: "center" }}>
      <Text className={styles.main}>{titleText}</Text>
    </div>
  );
};
