import { declareString } from "@1js/localization";

export const DebugOverlayToggleButtonLabel = declareString(
  "debugPanel.DebugOverlayToggleButtonLabel",
  {
    text: "Toggle debug overlay",
    comment: `Button label to toggle an overlay that displays debug information.`,
  }
);

export const DebugOverlayHeader = declareString(
  "debugPanel.DebugOverlayHeader",
  {
    text: "Debug settings",
    comment: `Header of overlay that displays debug information.`,
  }
);

export const DebugOverlayAuthenticationHeader = declareString(
  "debugPanel.DebugOverlayAuthenticationHeader",
  {
    text: "Authentication",
    comment: `Header of Authentication section in debug overlay.`,
  }
);

export const DebugOverlaySignedInUsernameServerLabel = declareString(
  "debugPanel.DebugOverlaySignedInUsernameServerLabel",
  {
    text: "User signed in on server:",
    comment: `Label for the username of the user signed in on the server.`,
  }
);

export const DebugOverlaySignedInUsernameClientLabel = declareString(
  "debugPanel.DebugOverlaySignedInUsernameClientLabel",
  {
    text: "User signed in on client:",
    comment: `Label for the username of the user signed in on the client.`,
  }
);

export const DebugOverlaySignInStatusLabel = declareString(
  "debugPanel.DebugOverlaySignInStatusLabel",
  {
    text: "Sign in status:",
    comment: `Label for state of ongoing sign in processes`,
  }
);

export const DebugOverlaySignInRedirectUrlLabel = declareString(
  "debugPanel.DebugOverlaySignInRedirectUrlLabel",
  {
    text: "Redirect Url:",
    comment: `Label for the Redirect Url used by authentication.`,
  }
);

export const DebugOverlayRetrieveTokenButtonLabel = declareString(
  "debugPanel.DebugOverlayRetrieveTokenButtonLabel",
  {
    text: "Retrieve token",
    comment: `Label for button to retrieve authentication token.`,
  }
);

export const DebugOverlayTokenLabel = declareString(
  "debugPanel.DebugOverlayRetrieveTokenLabel",
  {
    text: "Token:",
    comment: `Label for retrieved token.`,
  }
);

export const DebugOverlayScopesLabel = declareString(
  "debugPanel.DebugOverlayScopesLabel",
  {
    text: "Scopes to retrieve (comma-separated):",
    comment: `Label for scopes to be retrieved.`,
  }
);

export const DebugOverlayFlightTitle = declareString(
  "debugPanel.DebugOverlayFlightTitle",
  {
    text: "Flights",
    comment: "Title for flight section in debug overlay.",
  }
);

export const DebugOverlayFlightsDescription = declareString(
  "debugPanel.DebugOverlayFlightsDescription",
  {
    text: "Toggle the flights you want to enable or disable, then click the save button to reload the page.",
    comment: "Description for the flights section",
  }
);

export const DebugOverlayApplyFlightsButtonLabel = declareString(
  "debugPanel.DebugOverlayApplyFlightsButtonLabel",
  {
    text: "Apply flights and reload",
    comment:
      "Button label to apply flights and reload page after toggling whether these flights are on or off.",
  }
);
