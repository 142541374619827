import type { Event3sType, IChatLogger } from "@1js/scc-types-logger";
import { createContext } from "react";

export interface LoggerContextProps {
  /**
   * 3S logical id from the host
   */
  logicalId: string;
  /**
   * 3S scenario name from the host
   */
  scenarioName: string;
  /**
   * The logger instance to use for logging
   */
  logger: IChatLogger;

  /**
   * Optional callback to append metadata to the event
   * @param eventType The type of event (e.g. Event3sType.SearchEntityClicked)
   */
  metaData?: (
    eventType: Event3sType
  ) => Record<string, string | boolean | number> | undefined;
}

export const LoggerContext = createContext<LoggerContextProps | undefined>(
  undefined
);
