import { declareString } from "@1js/localization";

export const SavedFilterLabel = declareString(
  "promptLibrary.savedPromptsFilter",
  {
    text: "Filter",
    comment:
      "Placeholder label used on the saved Copilot prompts filter component",
  }
);

export const WorkgroupFilterLabel = declareString(
  "promptLibrary.groupPromptsFilter",
  {
    text: "Select or enter a team name",
    comment:
      "Placeholder label used on the Team Copilot prompts filter component",
  }
);

export const JobTypeFilterLabel = declareString(
  "promptLibrary.jobTypeFilterLabel",
  {
    text: "Job type",
    comment:
      "The label 'Job type' is used as the default display text for a dropdown menu that allows users to select Copilot prompts based on job-related categories such as Human Resources, Marketing and Financial Services.",
  }
);

export const ExtensionFilterLabel = declareString(
  "promptLibrary.extensionFilterLabel",
  {
    text: "Copilot agent",
    comment:
      "The label is used as the default display text for a dropdown menu that allows users to select Copilot prompts based on installed plugins or installed declarative copilots",
  }
);

export const AppFilterLabel = declareString("promptLibrary.appFilterLabel", {
  text: "App",
  comment:
    "Text that appears as a placeholder text for a dropdown that allows user to filter Copilot prompts by Microsoft product type such as Word, Teams, Excel.",
});

export const DisplayFilterLabel = declareString(
  "promptLibrary.displayFilterLabelV2",
  {
    text: "Task",
    comment:
      "Text that appears as a placeholder text for a dropdown that allows user to filter Copilot prompts by the type of activity the prompts are intended for such as 'Ask', 'Edit', Summarize'.",
  }
);

export const MobileFilterButtonLabel = declareString(
  "promptLibrary.mobileFilterButtonLabel",
  {
    text: "Filters",
    comment: "Text that appears on filter button for mobile. This is an noun",
  }
);

export const MobileFilterLabel = declareString(
  "promptLibrary.mobileFilterLabel",
  {
    text: "Filter by",
    comment:
      "Title that appears on modal dialog in the mobile view when filtering is selected",
  }
);

export const MobileClearFilterLabel = declareString(
  "promptLibrary.mobileClearFilterLabel",
  {
    text: "Clear all",
    comment:
      "Button text that appears during mobile view for clearing all filters",
  }
);

export const MobileApplyFilterLabel = declareString(
  "promptLibrary.mobileApplyFilterLabel",
  {
    text: "Apply",
    comment:
      "Button text that appears during mobile view for applying filters. This is an action (verb)",
  }
);

export const MenuFilterPlaceholderValueLabel = declareString(
  "promptLibrary.menuFilterPlaceholderValueLabel",
  {
    text: "Any",
    comment:
      "Default placeholder value for the menu filters when no value is selected",
  }
);

export const CreatedByFilterLabel = declareString(
  "promptLibrary.createdByFilterLabel",
  {
    text: "Created by",
    comment:
      "Placeholder label used on the Created by Copilot prompts filter component",
  }
);

export const TeamFilterLabel = declareString("promptLibrary.teamFilterLabel", {
  text: "Team",
  comment:
    "Placeholder label used on the Team Copilot prompts filter component",
});

export const AgentFilterLabel = declareString(
  "promptLibrary.agentFilterLabel",
  {
    text: " Agent",
    comment:
      "Placeholder label used on the Agent Copilot prompts filter component",
  }
);
