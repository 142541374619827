import {
  declareString,
  declareStringWithPlaceholders,
} from "@1js/localization";

export const PromptShareEmailBodyIntro = declareStringWithPlaceholders(
  "promptLibrary.promptShareEmailBodyIntro",
  {
    text: ({ productName }) =>
      `Hi! This prompt got me great results from ${productName}!`,
    comment:
      "The introduction text for the email body when sharing a Copilot prompt. {productName} is the name of the Microsoft product.",
    placeholders: {
      productName: "The name of the Microsoft product",
    },
  }
);

export const PromptShareEmailBodyCopilotFor = declareStringWithPlaceholders(
  "promptLibrary.promptShareEmailBodyCopilotFor",
  {
    text: ({ productName }) => `Copilot for ${productName}`,
    comment:
      "The 'Copilot for' prefix in the introduction text for the email body when sharing a Copilot prompt. {productName} is the name of the Microsoft product.",
    placeholders: {
      productName: "The name of the Microsoft product",
    },
  }
);

export const PromptShareEmailBodyGiveItATry = declareString(
  "promptLibrary.promptShareEmailBodyGiveItATry",
  {
    text: "Give it a try or make it your own.",
    comment:
      "The closing text for the email body when sharing a Copilot prompt.",
  }
);

export const PromptShareEmailBodyCheckItOut = declareString(
  "promptLibrary.promptShareEmailBodyCheckItOut",
  {
    text: "Check it out and give it a try.",
    comment:
      "The closing text for the email body when sharing a Copilot prompt.",
  }
);

export const PromptShareEmailSharedBySubtitle = declareString(
  "promptLibrary.promptShareEmailSharedBySubtitle",
  {
    text: "shared this prompt with you",
    comment: "The subtitle for the email body when sharing a Copilot prompt.",
  }
);

export const PromptShareEmailTryWithCopilotLabel = declareString(
  "promptLibrary.promptShareEmailTryWithCopilotLabel",
  {
    text: "Try prompt in Copilot",
    comment:
      "The label for the 'Try prompt in Copilot' link in the email body.",
  }
);

export const PromptShareEmailViewInCopilotPromptGalleryLabel = declareString(
  "promptLibrary.promptShareEmailViewInCopilotPromptGalleryLabel",
  {
    text: "View in Copilot Prompt Gallery",
    comment:
      "The label for the 'View in Copilot Prompt Gallery' link in the email body.",
  }
);

export const PromptShareEmailMicrosoftCopilot = declareString(
  "promptLibrary.promptShareEmailMicrosoftCopilot",
  {
    text: "Microsoft Copilot",
    comment:
      "The name of the Microsoft Copilot product in the footer of the email body.",
  }
);

export const PromptShareEmailSubject = declareStringWithPlaceholders(
  "promptLibrary.promptShareEmailSubject",
  {
    text: ({ promptAuthorDisplayName, promptTitle }) =>
      `${promptAuthorDisplayName} shared prompt “${promptTitle}” with you!`,
    comment:
      "The subject of the email when sharing a Copilot prompt. {authorDisplayName} is the author name of the Copilot prompt and {promptTitle} is the title of the Copilot prompt.",
    placeholders: {
      promptAuthorDisplayName: "The author name of the Copilot prompt",
      promptTitle: "The title of the Copilot prompt",
    },
  }
);
