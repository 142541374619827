import {
  isExtensionPrompt,
  isUserPrompt,
  type Prompt,
  type PromptCategory,
} from "@1js/pl-types";
import { Tooltip } from "@fluentui/react-components";
import {
  ArrowSyncCheckmarkRegular,
  ArrowTrendingLinesRegular,
  BuildingMultipleRegular,
  CalendarClockRegular,
  CalendarEditRegular,
  CatchUpRegular,
  ChatBubblesQuestionRegular,
  ChatHelpRegular,
  CodeTextRegular,
  CollectionsRegular,
  DesignIdeasRegular,
  DocumentRegular,
  DraftsRegular,
  EditRegular,
  HatGraduationRegular,
  LightbulbRegular,
  Microscope20Regular,
  NewsRegular,
  PersonNoteRegular,
  PersonRegular,
  SearchRegular,
  SignatureRegular,
  SquareArrowForwardRegular,
  TextBulletListSquareRegular,
  WrenchScrewdriverRegular,
} from "@fluentui/react-icons";
import React from "react";
import type { Appearance } from "../../data";
import {
  AgentPromptsIcon,
  AnalyzePromptsIcon,
  AskPromptsIcon,
  BookmarkPromptsIcon,
  CatchUpPromptsIcon,
  CodePromptsIcon,
  CreatePromptsIcon,
  DesignPromptsIcon,
  EditPromptsIcon,
  ExecutePromptsIcon,
  FindPromptsIcon,
  LearnPromptsIcon,
  ManagePromptsIcon,
  PreparePromptsIcon,
  TenantPromptsIcon,
  UnderstandPromptsIcon,
} from "../../Icons";
import { usePromptFilterLocalizations } from "../../PromptFilters/usePromptFilterStrings";
import { usePromptBadge } from "../PromptBadges/PromptBadge/PromptBadge.hook";
import { useStyles } from "./PromptIcon.styles";

export type PromptIconProps = {
  prompt: Prompt;
  appearance?: Appearance;
  useColorfulIcon?: boolean;
};

const displayCategoryToIconColorful = (
  displayCategory: PromptCategory,
  styles: Record<"icon", string>
) => {
  switch (displayCategory) {
    case "Analyze":
      return <AnalyzePromptsIcon className={styles.icon} />;
    case "Ask":
      return <AskPromptsIcon className={styles.icon} />;
    case "Brainstorm":
    case "Create":
    case "Suggestion":
      return <CreatePromptsIcon className={styles.icon} />;
    case "Catch up":
    case "Summarize":
      return <CatchUpPromptsIcon className={styles.icon} />;
    case "Code":
      return <CodePromptsIcon className={styles.icon} />;
    case "Created by Me":
    case "UserAuthored":
      return <BookmarkPromptsIcon className={styles.icon} />;
    case "Design":
      return <DesignPromptsIcon className={styles.icon} />;
    case "Edit":
      return <EditPromptsIcon className={styles.icon} />;
    case "Execute":
      return <ExecutePromptsIcon className={styles.icon} />;
    case "Find":
      return <FindPromptsIcon className={styles.icon} />;
    case "Learn":
      return <LearnPromptsIcon className={styles.icon} />;
    case "Manage":
      return <ManagePromptsIcon className={styles.icon} />;
    case "Tenant":
      return <TenantPromptsIcon className={styles.icon} />;
    case "Prepare":
      return <PreparePromptsIcon className={styles.icon} />;
    case "Understand":
      return <UnderstandPromptsIcon className={styles.icon} />;
    default:
      return null;
  }
};

/**
 * Converts a display category to an icon. If the colorful icon is not available, the Regular icon will be used.
 *
 * @param displayCategory the display category of the prompt
 * @param styles the styles to be applied to the icon
 * @param useColorfulIcon true if the colorful icon should be used.
 * @returns the icon to be displayed for the given display category. If the display category is not found, an empty component is returned.
 */
export const displayCategoryToIcon = (
  displayCategory: PromptCategory,
  styles: Record<"icon", string>,
  useColorfulIcon?: boolean
) => {
  if (useColorfulIcon) {
    const icon = displayCategoryToIconColorful(displayCategory, styles);
    if (icon) {
      return icon;
    }
  }

  switch (displayCategory) {
    case "Create":
    case "Suggestion":
    case "Brainstorm":
      return <DraftsRegular className={styles.icon} />;
    case "Email":
      return <CatchUpRegular className={styles.icon} />;
    case "Edit":
      return <EditRegular className={styles.icon} />;
    case "Code":
      return <CodeTextRegular className={styles.icon} />;
    case "Analyze":
      return <Microscope20Regular className={styles.icon} />;
    case "Assist":
      return <ChatHelpRegular className={styles.icon} />;
    case "Automate":
      //todo: replace with automate icon
      return null;
    case "Browse":
      return <DocumentRegular className={styles.icon} />;
    case "Collect":
      return <CollectionsRegular className={styles.icon} />;
    case "Execute":
      return <SquareArrowForwardRegular className={styles.icon} />;
    case "Find":
      return <SearchRegular className={styles.icon} />;
    case "News":
      return <NewsRegular className={styles.icon} />;
    case "People":
      return <PersonRegular className={styles.icon} />;
    case "Prepare":
    case "Catch up":
    case "Summarize":
      return <TextBulletListSquareRegular className={styles.icon} />;
    case "Ask":
      return <ChatBubblesQuestionRegular className={styles.icon} />;
    case "Understand":
      return <LightbulbRegular className={styles.icon} />;
    case "UserAuthored":
    case "Created by Me":
      return <SignatureRegular className={styles.icon} />;
    case "Customize":
      return <PersonNoteRegular className={styles.icon} />;
    case "Design":
      return <DesignIdeasRegular className={styles.icon} />;
    case "Optimize":
      return <ArrowTrendingLinesRegular className={styles.icon} />;
    case "Operate":
      return <ArrowSyncCheckmarkRegular className={styles.icon} />;
    case "Troubleshoot":
      return <WrenchScrewdriverRegular className={styles.icon} />;
    case "Learn":
      return <HatGraduationRegular className={styles.icon} />;
    case "Schedule":
      return <CalendarClockRegular className={styles.icon} />;
    case "Manage":
      return <CalendarEditRegular className={styles.icon} />;
    case "Tenant":
      return <BuildingMultipleRegular className={styles.icon} />;
    default:
      return null;
  }
};

export const PromptIcon: React.FC<PromptIconProps> = ({
  prompt,
  appearance,
  useColorfulIcon = false,
}) => {
  const styles = useStyles(appearance)();
  const { facetValueToLabel } = usePromptFilterLocalizations();
  const { getBadgeIconInteractive } = usePromptBadge({
    badge: {
      id: "",
    },
  });
  if (useColorfulIcon && isExtensionPrompt(prompt)) {
    return <AgentPromptsIcon className={styles.icon} />;
  }

  if (
    appearance === "web-v3" &&
    useColorfulIcon &&
    isUserPrompt(prompt) &&
    prompt.Products[0]
  ) {
    return getBadgeIconInteractive(prompt.Products[0], "24px");
  }

  const displayCategoryToUse = isUserPrompt(prompt)
    ? "Created by Me"
    : prompt.DisplayCategory;
  if (!displayCategoryToUse) {
    return null;
  }
  return (
    <Tooltip
      data-testid="prompt-icon"
      content={facetValueToLabel(displayCategoryToUse)}
      relationship="label"
    >
      {displayCategoryToIcon(displayCategoryToUse, styles, useColorfulIcon)}
    </Tooltip>
  );
};
