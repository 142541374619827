import { usePromptFilterLocalizations } from "@1js/pl-card-components";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Link,
  MenuItem,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import type { HeaderDropDownMobileProps } from "./PageHeader.web.data";

const useStyles = makeStyles({
  dropdown: {
    display: "flex",
    ...shorthands.padding("0px 12px"),
    flexDirection: "column",
    alignItems: "flex-start",
    ...shorthands.gap("2px"),
    alignSelf: "stretch",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      color: tokens.colorNeutralForeground2,
      textAlign: "center",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      paddingLeft: "16px",
    },
  },
  panel: {
    height: "32px",
    alignItems: "center",
    alignSelf: "stretch",
    backgroundColor: "#FFF",
    ...shorthands.borderRadius("8px"),
  },
  link: {
    "& span": {
      color: tokens.colorNeutralForeground2,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
    },
    "&:hover": {
      ...shorthands.textDecoration("none"),
      "&:focus, &:active": {
        ...shorthands.textDecoration("none"),
      },
    },
  },
  menuItem: {
    paddingLeft: "16px",
  },
});

export const HeaderDropDownMobile: React.FC<HeaderDropDownMobileProps> = ({
  displayText,
  linkDictionary,
}) => {
  const styles = useStyles();
  const { facetValueToLabel } = usePromptFilterLocalizations();

  return (
    <div data-telemetry-labels="true" data-telemetry-urls="true">
      <Accordion collapsible>
        <AccordionItem className={styles.dropdown} value={"1"}>
          <AccordionHeader
            className={styles.header}
            expandIconPosition="end"
            inline={true}
          >
            {displayText}
          </AccordionHeader>
          {linkDictionary.map((item) => (
            <AccordionPanel className={styles.panel} key={item.displayText}>
              <Link className={styles.link} href={item.url} target="_self">
                <MenuItem className={styles.menuItem}>
                  {facetValueToLabel(item.displayText)}
                </MenuItem>
              </Link>
            </AccordionPanel>
          ))}
        </AccordionItem>
      </Accordion>
    </div>
  );
};
