import {
  createNestablePublicClientApplication,
  InteractionRequiredAuthError,
  type AccountInfo,
  type IPublicClientApplication,
  type SilentRequest,
} from "@azure/msal-browser";
import { app, nestedAppAuth } from "@microsoft/teams-js";
import { msalConfig, scopes } from "../utils/msal/authConfig";
import { sendDiagnosticEvent } from "../utils/Telemetry";
import { LogCategory, LogType } from "../utils/Telemetry/events";

type nestedAuthenticationResponse = {
  pca: IPublicClientApplication | undefined;
  isNaaNotRecommended: boolean;
};

const nestedAuthentication = (() => {
  // https://learn.microsoft.com/en-us/office/dev/add-ins/develop/enable-nested-app-authentication-in-your-add-in
  async function initializePublicClient(): Promise<
    nestedAuthenticationResponse | undefined
  > {
    sendDiagnosticEvent(
      LogType.Informational,
      "Starting PublicClient initialization",
      LogCategory.NAA
    );

    try {
      const isRecommended = await nestedAppAuth.isNAAChannelRecommended();

      if (!isRecommended) {
        sendDiagnosticEvent(
          LogType.Warning,
          `Failed to create Public Client, NAA not recommended.`,
          LogCategory.NAA
        );
        throw new Error(`Failed to create Public Client, NAA not recommended.`);
      }

      try {
        const result = await createNestablePublicClientApplication(msalConfig);

        const response: nestedAuthenticationResponse = {
          pca: result,
          isNaaNotRecommended: false,
        };

        return response;
      } catch (error) {
        throw new Error(`Failed to create Public Client: ${error}`);
      }
    } catch (error) {
      const response: nestedAuthenticationResponse = {
        pca: undefined,
        isNaaNotRecommended: false,
      };

      sendDiagnosticEvent(LogType.Error, `${error}`, LogCategory.NAA);

      return response;
    }
  }

  const getNestedAppAuthToken = async (
    nestablePca: IPublicClientApplication
  ) => {
    try {
      const activeAccount = await getActiveAccount(nestablePca);

      const tokenRequest: SilentRequest = {
        scopes,
        account: activeAccount || undefined,
      };

      try {
        const result = await nestablePca.acquireTokenSilent(tokenRequest);
        return result.accessToken;
      } catch (silentError) {
        //Acquire token silent failure, and send an interactive request
        sendDiagnosticEvent(
          LogType.Warning,
          `Silent token acquisition failed: ${silentError}. Attempting with popup.`,
          LogCategory.NAA
        );

        if (silentError instanceof InteractionRequiredAuthError) {
          tokenRequest.claims = silentError.claims;
        }

        const result = await nestablePca.acquireTokenPopup(tokenRequest);
        return result.accessToken;
      }
    } catch (error) {
      sendDiagnosticEvent(
        LogType.Error,
        `Silent token acquisition failed: ${error}`,
        LogCategory.NAA
      );
      throw new Error("Token acquisition failed");
    }
  };

  async function getActiveAccount(
    nestablePca: IPublicClientApplication
  ): Promise<AccountInfo | null> {
    try {
      console.log("Getting active account");
      const activeAccount = nestablePca.getActiveAccount();
      if (activeAccount) {
        return activeAccount;
      }
    } catch (error) {
      sendDiagnosticEvent(
        LogType.Error,
        `Failed to get active account from PCA: ${error}`,
        LogCategory.NAA
      );
    }

    try {
      const context = await app.getContext();
      const user = context.user;

      if (user) {
        const accountFilter = {
          tenantId: user.tenant?.id,
          homeAccountId: user.id,
          loginHint: user.loginHint,
        };

        try {
          const accountWithFilter = nestablePca.getAccount(accountFilter);
          if (accountWithFilter) {
            nestablePca.setActiveAccount(accountWithFilter);
            return accountWithFilter;
          }
        } catch (error) {
          sendDiagnosticEvent(
            LogType.Error,
            `Failed to get account with filter: ${error}`,
            LogCategory.NAA
          );
        }
      }
    } catch (error) {
      sendDiagnosticEvent(
        LogType.Error,
        `Failed to get context or user: ${error}`,
        LogCategory.NAA
      );
    }
    return null;
  }

  return {
    initializePublicClient,
    getNestedAppAuthToken,
  };
})();

export const { initializePublicClient, getNestedAppAuthToken } =
  nestedAuthentication;
