import { declareString } from "@1js/localization";

export const PromptShareTooltip = declareString(
  "promptLibrary.promptShareTooltip",
  {
    text: "Share prompt",
    comment:
      "Tooltip that is displayed to user when user hovers over the prompt share button icon on a Copilot prompt card to share it",
  }
);

export const PromptSharing = declareString("promptLibrary.promptSharing", {
  text: "Sharing...",
  comment:
    "Text displayed on the prompt card when a Copilot prompt is being shared by the user",
});

export const PromptShareCopyLinkButtonLabel = declareString(
  "promptLibrary.promptShareCopyLinkButtonLabel",
  {
    text: "Copy link",
    comment:
      "Label for the button that copies the prompt link on a Copilot prompt card to share it",
  }
);

export const PromptShareEmailButtonLabel = declareString(
  "promptLibrary.promptShareEmailButtonLabel",
  {
    text: "Email",
    comment:
      "Label for the button that opens email client to share the prompt link on a Copilot prompt card",
  }
);

export const PromptShareSendEmailButtonLabel = declareString(
  "promptLibrary.promptShareSendEmailButtonLabel",
  {
    text: "Send email",
    comment:
      "Label for the button that opens email client to share the prompt link on a Copilot prompt card",
  }
);

export const PromptShareTeamButtonLabel = declareString(
  "promptLibrary.promptShareTeamButtonLabel",
  {
    text: "Share to team",
    comment:
      "Label for the button that opens a modal dialog to share the prompt with a team.",
  }
);

export const PromptShareCopyPromptLinkLabel = declareString(
  "promptLibrary.promptShareCopyPromptLinkLabel",
  {
    text: "Copy prompt link",
    comment:
      "Label for the button to copy the link to the user's copilot prompt.",
  }
);

export const PromptShareCopyPromptLinkLoadingLabel = declareString(
  "promptLibrary.promptShareCopyPromptLinkLoadingLabel",
  {
    text: "Copying...",
    comment:
      "Label for the loading spinner when a link to the user's copilot prompt is being copied.",
  }
);
