import { makeStyles, tokens } from "@fluentui/react-components";

export const useStyles = makeStyles({
  visibleTooltipIcon: {
    color: tokens.colorNeutralForeground2BrandSelected,
  },
  tooltipIcon: {
    marginLeft: "4px",
    height: "14px",
    width: "14px",
  },
});
