import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import * as React from "react";
import { useFlightContext } from "../../hooks/useFlightContext";
import { useIsMediumScreen } from "../../hooks/useIsSmallScreen";
import { MetaOSAppContext } from "../../pages/metaos/MetaOSAppContext";
import { PropertyContext } from "../../pages/PropertyContext.web";
import { isMetaOsHelper } from "../../utils/url";
import { isEligibile } from "../../utils/utils.web";
import { ManageSharedPromptsButton } from "../ManageSharedPrompts/ManageSharedPromptsButton";
import { HeaderNav } from "./HeaderNav";
import { HeaderNavMobile } from "./HeaderNavMobile.web";
import type { PageHeaderProps } from "./PageHeader.web.data";

const useStyles = makeStyles({
  main: {
    width: "100%",
    position: "sticky",
    top: "0px",
    zIndex: "1",
  },
  bar: {
    display: "flex",
    alignItems: "center",
    flexShrink: "0",
    justifyContent: "space-between",
    backgroundColor: tokens.colorNeutralBackground2,
    boxShadow: tokens.shadow4,
    height: "48px",
    ...shorthands.borderRadius("0px"),

    "@media (max-width: 540px)": {
      boxShadow: tokens.shadow2,
    },
  },
  metaOsBar: {
    display: "flex",
    alignItems: "center",
    flexShrink: "0",
    justifyContent: "space-between",
    backgroundColor: tokens.colorNeutralBackground2,
    height: "48px",
    ...shorthands.borderRadius("0px"),

    "@media (max-width: 540px)": {
      boxShadow: tokens.shadow2,
    },
  },
  metaOsBarv2: {
    display: "flex",
    alignItems: "center",
    flexShrink: "0",
    justifyContent: "space-between",
    backgroundColor: "white",
    height: "48px",
    ...shorthands.borderRadius("0px"),

    "@media (max-width: 540px)": {
      boxShadow: tokens.shadow2,
    },
  },
  button: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    padding: "8px 16px",
    gap: "2px",
    marginLeft: "auto",
    "@media (max-width: 800px)": {
      display: "flex",
      width: "40px",
      height: "48px",
      padding: "8px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
    },
  },
  buttonv2: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    padding: "8px 16px",
    gap: "2px",
    marginLeft: "auto",
    "@media (max-width: 800px)": {
      display: "flex",
      width: "40px",
      height: "48px",
      padding: "8px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
    },
    background: "white",
  },
});

export const PageHeader: React.FC<PageHeaderProps> = ({
  capabilitiesLink,
  whatsNewLink,
  copilotProductLinks,
  gettingStartedLinks,
}) => {
  const styles = useStyles();
  const isMobile = useIsMediumScreen();
  const { isFlightActive } = useFlightContext();
  const metaOSAppContext = React.useContext(MetaOSAppContext);
  const HeaderNavComponent = isMobile ? HeaderNavMobile : HeaderNav;
  const [siteSetting] = React.useContext(PropertyContext);
  const showSecondaryHeader =
    !metaOSAppContext.isMosApp ||
    (metaOSAppContext.isMosApp &&
      localStorage.getItem("IsTeamsTab") !== "true" &&
      isEligibile(siteSetting.licenses, isFlightActive("isMetaOSAppActive"))) ||
    (metaOSAppContext.isMosApp &&
      localStorage.getItem("IsTeamsTab") === "true" &&
      isFlightActive("isWorkgroupPublishingEnabled") &&
      isEligibile(siteSetting.licenses, isFlightActive("isMetaOSAppActive")));
  const showManageAccess =
    siteSetting.showManageAccess &&
    isFlightActive("isPromptSharingEnabled") &&
    isFlightActive("isPromptSavingEnabled");

  const isMetaOSV2 = isFlightActive("isMosAppV2DesignEnabled");
  const getHeaderClass = () => {
    if (isMetaOsHelper()) {
      if (isMetaOSV2 && metaOSAppContext.isMetaOsV2) {
        return styles.metaOsBarv2;
      } else {
        return styles.metaOsBar;
      }
    } else {
      return styles.bar;
    }
  };
  return (
    <div className={styles.main}>
      {!metaOSAppContext.isMetaOsV2 && showSecondaryHeader && (
        <div
          className={getHeaderClass()}
          data-telemetry-labels="true"
          data-telemetry-urls="true"
        >
          {!metaOSAppContext.isMosApp && (
            <HeaderNavComponent
              capabilitiesLink={capabilitiesLink}
              copilotProductLinks={copilotProductLinks}
              gettingStartedLinks={gettingStartedLinks}
              whatsNewLink={whatsNewLink}
            />
          )}
          {showManageAccess && (
            <div className={styles.button}>
              <ManageSharedPromptsButton />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
