import { declareStringWithPlaceholders } from "@1js/localization";

export const PromptAuthorLabel = declareStringWithPlaceholders(
  "promptLibrary.authorLabel",
  {
    text: ({ authorName }) => `Author: ${authorName}`,
    comment:
      "The prefix of aria-label used for a tag component that displays the author of a Copilot prompt. The overall label looks like this 'Owner: Robert Su'. {authorName} is the name of the author of the prompt.",
    placeholders: {
      authorName: "Robert",
    },
  }
);
