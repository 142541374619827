import type { HydratedEntity, HydratedEntityType } from "@1js/pl-types";
import { Image } from "@fluentui/react-components";
import {
  CalendarRegular,
  GlobeRegular,
  PersonCircleRegular,
  type FluentIconsProps,
} from "@fluentui/react-icons";
import * as React from "react";

const FABRIC_CDN_URL =
  "https://res-1.cdn.office.net/files/fabric-cdn-prod_20221209.001/assets/item-types";

export const getIconUrl = (icon: string, size: number) => {
  return `${FABRIC_CDN_URL}/${size}/${icon}.svg`;
};

const FileEntityIcon: React.FC<EntityIconProps & FluentIconsProps> = ({
  entity,
  iconSize,
  className,
}) => {
  const fileName = entity.Entity?.FileName?.toLowerCase().trim();
  const fileExtension = fileName?.split(".").pop();
  const supportedFileExtensions = [
    "docx",
    "xlsx",
    "pdf",
    "pptx",
    "one",
    "spo",
    "loop",
  ];

  if (!fileExtension || supportedFileExtensions.indexOf(fileExtension) === -1) {
    return (
      <Image
        src={getIconUrl("txt", iconSize)}
        height={iconSize}
        width={iconSize}
        className={className}
      />
    );
  }

  return (
    <Image
      src={getIconUrl(fileExtension, iconSize)}
      height={iconSize}
      width={iconSize}
      className={className}
    />
  );
};

export type EntityIconProps = {
  entity: HydratedEntity;
  iconSize: number;
};

export const EntityIcon: React.FC<EntityIconProps> = ({ entity, iconSize }) => {
  const entityType = entity.Type;

  switch (entityType) {
    case "File":
      return <FileEntityIcon entity={entity} iconSize={iconSize} />;
    case "Event":
    case "Meeting" as HydratedEntityType:
      return <CalendarRegular fontSize={iconSize} />;
    case "Person" as HydratedEntityType:
    case "People":
      return <PersonCircleRegular fontSize={iconSize} />;
  }

  return <GlobeRegular fontSize={iconSize} />;
};
