import { generateGuid } from "@1js/guid";
import type {
  ProductType,
  Prompt,
  ScenarioType,
} from "@1js/pl-card-components";
import {
  isExtensionPrompt,
  isUserCreatedExtensionPrompt,
  isWorkgroupPrompt,
  type ExtensionPrompt,
  type Group,
  type RepositoryType,
  type UserExtensionPrompt,
  type WorkgroupMicrosoftPrompt,
  type WorkgroupUserPrompt,
} from "@1js/pl-types";
import type { IPublicClientApplication } from "@azure/msal-browser";
import type { SiteSetting } from "../types";
import { buildCoreHeaders } from "../utils/header";
import {
  sendResponseReceivedEvent,
  type SendApiActionEventParameter,
} from "../utils/Telemetry";
import type { HttpStatusCode } from "../utils/Telemetry/config";
import { extractHydratedEntities, logApiError } from "./client-utils";
import type { ApiError } from "./fetch";
import { authAwareFetch, retry } from "./fetch";
/**
 * Response header cue used to tell if the response is from 3S Suggestions API or not.
 */
export const Is3SResponseHeader = "x-datasource-is3s";

/**
 * Response header cue used to tell if the current user has proper Copilot license.
 */
export const IsUserLicensed = "x-licensed";

/**
 * Logical ID header key used to identify 3s API calls.
 * See: https://docs.substrate.microsoft.net/docs/Substrate-Intelligence/MSAI-Data-Platform/Contracts/SearchInstrumentationOverview/ContractOverview.html
 */
export const LogicalIdHeaderKey = "x-logical-id";

export type PromptReference = {
  PromptId: string;
};

export type PostPromptsPublishResponse = {
  Prompts: PromptReference[];
};

export type DeletePromptsPublishResponse = {
  Prompts: PromptReference[];
};

export type GetPromptsPublishResponse = {
  PromptList: (WorkgroupUserPrompt | WorkgroupMicrosoftPrompt)[];
  PromptListCount: number;
};

export type GetPromptsWorkgroupsResponse = {
  Workgroups: Group[];
};

// TODO: moved to pl-types
export type LinkedPrompt = {
  PromptId: string;
  Title: string;
  CommandText: string;
  DisplayText: string;
  Link?: string;
  LinkCreatedDatetimeInUtc: Date;
};

export type GetLinkedPromptsApiResponse = {
  PromptList: LinkedPrompt[];
  PromptListCount: number;
};

export type DeleteLinkedPromptsApiResponse = {
  IsPartialSuccess: boolean;
  PromptIds: string[];
};

/**
 * Response model for PostSendSharePromptEmailNotification API.
 * Maps the recipient AAD Object Id
 * to the ODSP message id, or null in case of error.
 */
export type PostSendSharePromptEmailNotificationResponse = Map<
  string,
  string | null
>;

/**
 * Json transit version of PostSendSharePromptEmailNotificationResponse.
 */
export type PostSendSharePromptEmailNotificationJsonResponse = [
  string,
  string | null,
][];

/**
 * Response model for GetGroupMemberCountResponse API.
 * Maps the groupId to member count
 */
export type GetGroupMemberCountResponse = Map<string, number>;

/**
 * Json transit version of GetGroupMemberCountResponse.
 */
export type GetGroupMemberCountJsonResponse = { [key: string]: number };

export type PostSendSharePromptEmailNotificationRequestModel = {
  /**
   * The recipients when sending to individuals, i.e., non-groups.
   */
  Individuals?: {
    /**
     * AAD object ids (Guids) of the recipients.
     */
    Recipients: string[];

    /**
     * TryIn url to be rendered as a Try in Copilot-button.
     * Ensure customer can revoke access, i.e., use 3S Prompt Link Share Prompt links,
     * NOT TryIn links from 3S Prompt Link Handoff API.
     */
    TryInUrl?: string;

    /**
     * The CopilotGalleryUrl to be rendered as a View in Copilot Gallery-button.
     * Ensure customer can revoke access.
     */
    CopilotGalleryUrl?: string;
  };

  /**
   * The recipients when sending to groups, e.g., Teams teams.
   */
  Workgroups?: {
    /**
     * The AAD Object Id of this group.
     */
    GroupId: string;

    /**
     * TryIn url to be rendered as a Try in Copilot-button.
     * Ensure customer can revoke access, i.e., use 3S Prompt Link Share Prompt links,
     * NOT TryIn links from 3S Prompt Link Handoff API.
     */
    TryInUrl?: string;

    /**
     * The CopilotGalleryUrl to be rendered as a View in Copilot Gallery-button.
     * Ensure customer can revoke access.
     */
    CopilotGalleryUrl?: string;
  }[];

  /**
   * The plain text message to be sent to the recipients.
   */
  Message?: string;

  /**
   * The Product name identifying the Copilot target, e.g., BizChat, Word, Excel, PowerPoint.
   */
  Product?: ProductType;

  /**
   * The primary color to be used as button background, specified as Hex Color.
   */
  PrimaryColor: string;

  /**
   * The primary text color to be used as button text color, specified as Hex Color.
   */
  PrimaryTextColor: string;

  /**
   * The primary border color to be used as button border color, specified as Hex Color.
   */
  PrimaryBorderColor: string;

  /**
   * The secondary color to be used as button background, specified as Hex Color.
   */
  SecondaryColor: string;

  /**
   * The secondary text color to be used as button text color, specified as Hex Color.
   */
  SecondaryTextColor: string;

  /**
   * The secondary border color to be used as button border color, specified as Hex Color.
   */
  SecondaryBorderColor: string;

  /**
   * 3s Prompt Link to fetch prompt details from Substrate.
   */
  PromptLink: string;
};

export const searchAsync = async (
  host: string,
  locale: string,
  path: string
): Promise<string> => {
  const logicalId = generateGuid();
  const serviceUrl = locale
    ? `https://${host}/${locale}/${path}`
    : `https://${host}/${path}`;

  const response = await retry(() =>
    authAwareFetch(serviceUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        [LogicalIdHeaderKey]: logicalId,
      },
    })
  );

  return response.json();
};

/**
 * Calls SupportService endpoint to get group prompts.
 *
 * @param host service host
 * @param locale requested locale
 * @returns Returns prompts, enriched with group information.
 */
export const fetchGroupPrompts = async (
  host: string,
  locale: string,
  groupId: string,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  groups?: Group[],
  msalContext?: IPublicClientApplication
): Promise<GetPromptsPublishResponse> => {
  const logicalId = generateGuid();

  if (!groups) {
    onSendApiAction({
      apiName: "GetPublish",
      isInit: true,
      traceId: logicalId,
      errorReason: "GroupsNotSpecified",
    });
    return { PromptList: [], PromptListCount: 0 };
  }

  const group = groups.find((g) => g.Id === groupId);
  if (!group) {
    onSendApiAction({
      apiName: "GetPublish",
      isInit: true,
      traceId: logicalId,
      errorReason: "NoGroupWithSpecifiedID",
    });
    return { PromptList: [], PromptListCount: 0 };
  }

  const startTimer = Date.now();

  try {
    onSendApiAction({
      apiName: "GetPublish",
      isInit: true,
      traceId: logicalId,
    });

    const serviceUrl = `https://${host}/${locale}/api/promptsclient/prompts/publish?workgroupId=${groupId}`;
    const avatarUrl = `https://${host}/api/graph/users/{userId}/photos/48x48/$value`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );

    const parsedResponse = (await response.json()) as GetPromptsPublishResponse;
    parsedResponse.PromptList = parsedResponse.PromptList.map(
      (prompt: WorkgroupMicrosoftPrompt | WorkgroupUserPrompt) => {
        return {
          ...prompt,
          LogicalId: logicalId,
          Groups: [group],
          Author:
            prompt.Origin === "User" && prompt.Publisher
              ? {
                  DisplayName: prompt.Publisher.Name,
                  Email: prompt.Publisher.Address,
                  Avatar: prompt.Publisher.Address
                    ? avatarUrl.replace("{userId}", prompt.Publisher.Address)
                    : undefined,
                }
              : undefined,
        };
      }
    );

    onSendApiAction({
      apiName: "GetPublish",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: parsedResponse.PromptList.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });

    return parsedResponse;
  } catch (error) {
    logApiError(
      "GetPublish",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );
    throw error;
  }
};

/**
 * Calls 3s suggestions endpoint to get prompts for the given query.
 *
 * @param host service host
 * @param locale requested locale
 * @param is3SServiceEnabled optional is3SServiceEnabled flag
 * @returns Returns a tuple of [Prompt[], boolean] where the boolean indicates if the response is from 3S or not.
 */
export const fetchAllPrompts = async (
  host: string,
  locale: string,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  is3SServiceEnabled?: boolean,
  msalContext?: IPublicClientApplication
): Promise<[Prompt[], boolean]> => {
  const allPromptRoute = "allPrompts";
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetAllPrompts",
    isInit: true,
    traceId: logicalId,
  });

  const startTimer = Date.now();

  try {
    let serviceUrl = `https://${host}/${locale}/api/promptsclient/${allPromptRoute}`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    if (is3SServiceEnabled === false) {
      // 3s endpoint is always enabled, only attach the ?is3SServiceEnabled querystring if the value is false
      serviceUrl += "?is3SServiceEnabled=false";
    }

    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );

    sendResponseReceivedEvent(
      parseInt(response.headers.get("x-datasource-latency") ?? "0", 10),
      parseInt(response.headers.get("x-datasource-statuscode") ?? "0", 10),
      response.headers.get("x-datasource-traceid") ?? "",
      msalContext
    );

    let prompts = (await response.json()) as Prompt[];
    prompts = prompts.map((prompt) => {
      return {
        ...prompt,
        LogicalId: logicalId,
        Repository: getRepository(response),
      };
    });

    onSendApiAction({
      apiName: "GetAllPrompts",
      status: response.status as HttpStatusCode,
      isInit: false,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: prompts.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
      repository: prompts.length > 0 ? prompts[0].Repository : undefined,
    });

    return [
      prompts,
      response.headers.get(Is3SResponseHeader)?.toLowerCase() === "true",
    ];
  } catch (error) {
    logApiError(
      "GetAllPrompts",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Calls 3s suggestions endpoint to get prompts for the given query.
 *
 * @param host service host
 * @param locale requested locale
 * @param queryText query text
 * @param msalContext the instance of MSAL.js.  Will be undefined if not MoS app
 * @returns Returns a list of prompts
 */
export const fetchPromptSearchResults = async (
  host: string,
  locale: string,
  queryText: string,
  //onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<Prompt[]> => {
  const searchResultsRoute = "searchPrompts";
  const logicalId = generateGuid();
  // TODO: Uncomment and fix telemetry when privacy approved
  // onSendApiAction({
  //   apiName: "GetAllPrompts",
  //   isInit: true,
  //   traceId: logicalId,
  // });

  //const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/${locale}/api/promptsclient/${searchResultsRoute}`;
    const headers = await buildCoreHeaders(msalContext, logicalId);

    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );
    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "POST",
        headers,
        body: JSON.stringify({
          QueryText: queryText,
        }),
      })
    );

    // sendResponseReceivedEvent(
    //   parseInt(response.headers.get("x-datasource-latency") ?? "0", 10),
    //   parseInt(response.headers.get("x-datasource-statuscode") ?? "0", 10),
    //   response.headers.get("x-datasource-traceid") ?? "",
    //   msalContext
    // );

    let prompts = (await response.json()) as Prompt[];
    prompts = prompts.map((prompt) => {
      return {
        ...prompt,
        LogicalId: logicalId,
        Repository: getRepository(response),
      };
    });

    // onSendApiAction({
    //   apiName: "GetAllPrompts",
    //   status: response.status as HttpStatusCode,
    //   isInit: false,
    //   isFromCache: false,
    //   substrateLatency: parseInt(
    //     response.headers.get("x-datasource-latency") ?? "0",
    //     10
    //   ),
    //   supportServiceLatency: Date.now() - startTimer,
    //   successfulResultCount: prompts.length,
    //   traceId: response.headers.get("x-datasource-traceid") || logicalId,
    // });

    return prompts;
  } catch (error) {
    console.log("fetchPromptSearchResults error", error);
    // logApiError(
    //   "GetAllPrompts",
    //   error as Error,
    //   logicalId,
    //   Date.now() - startTimer,
    //   onSendApiAction
    // );

    throw error;
  }
};

/**
 * Calls SupportService endpoint to get all group prompts.
 * @param host service host
 * @param locale requested locale
 * @param onSendApiAction callback to send telemetry events
 * @param msalContext the instance of MSAL.js.  Will be undefined if not MoS app
 * @returns Returns all group prompts user has access to.
 */
export const fetchAllGroupPrompts = async (
  host: string,
  locale: string,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<(WorkgroupMicrosoftPrompt | WorkgroupUserPrompt)[]> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetPublish",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/${locale}/api/promptsclient/prompts/publish`;
    const avatarUrl = `https://${host}/api/graph/users/{userId}/photos/48x48/$value`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );

    const parsedResponse = (await response.json()) as GetPromptsPublishResponse;
    parsedResponse.PromptList = parsedResponse.PromptList.map(
      (prompt: WorkgroupMicrosoftPrompt | WorkgroupUserPrompt) => {
        return {
          ...prompt,
          LogicalId: logicalId,
          Groups: prompt.Workgroup
            ? [{ ...prompt.Workgroup, IsMember: true, HasPrompts: true }]
            : [],
          Author:
            prompt.Origin === "User" && prompt.Publisher
              ? {
                  DisplayName: prompt.Publisher.Name,
                  Email: prompt.Publisher.Address,
                  Avatar: prompt.Publisher.Address
                    ? avatarUrl.replace("{userId}", prompt.Publisher.Address)
                    : undefined,
                }
              : undefined,
        };
      }
    );

    onSendApiAction({
      apiName: "GetPublish",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: parsedResponse.PromptList.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });

    return parsedResponse.PromptList;
  } catch (error) {
    logApiError(
      "GetPublish",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Calls 3s suggestions endpoint to get prompts for installed Gpts.
 *
 * @param host service host
 * @param locale requested locale
 * @param providerId providerId of the Gpt
 * @param is3SServiceEnabled optional is3SServiceEnabled flag
 * @param msalContext the instance of MSAL.js.  Will be undefined if not MoS app
 * @returns Returns a list of gpt (or declarative copilot prompts).
 */
export const fetchGptPrompts = async (
  host: string,
  locale: string,
  providerId: string,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<Prompt[]> => {
  const gptPromptRoute = "gptPrompts";
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetGptPrompts",
    isInit: true,
    traceId: logicalId,
  });

  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/${locale}/api/promptsclient/${gptPromptRoute}`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );

    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "POST",
        headers,
        body: JSON.stringify({
          ProviderId: providerId,
        }),
      })
    );

    sendResponseReceivedEvent(
      parseInt(response.headers.get("x-datasource-latency") ?? "0", 10),
      parseInt(response.headers.get("x-datasource-statuscode") ?? "0", 10),
      response.headers.get("x-datasource-traceid") ?? "",
      msalContext
    );

    let prompts = (await response.json()) as Prompt[];
    prompts = prompts.map((prompt) => {
      return {
        ...prompt,
        LogicalId: logicalId,
        Repository: "Substrate",
      };
    });

    onSendApiAction({
      apiName: "GetGptPrompts",
      status: response.status as HttpStatusCode,
      isInit: false,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: prompts.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });

    return prompts;
  } catch (error) {
    logApiError(
      "GetGptPrompts",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Calls MS Graph via SupportService to get groups for the given user.
 *
 * @param host service host
 * @param locale requested locale
 * @returns Returns a Group-array
 */
export const fetchGroups = async (
  host: string,
  locale: string,
  isMember: boolean,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<GetPromptsWorkgroupsResponse> => {
  const logicalId = generateGuid();

  onSendApiAction({
    apiName: isMember ? "GetWorkgroupsMember" : "GetWorkgroups",
    isInit: true,
    traceId: logicalId,
  });

  const startTimer = Date.now();
  try {
    const serviceUrl = `https://${host}/${locale}/api/promptsclient/prompts/workgroups?workgroupType=${isMember ? "member" : "hasPrompts"}`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );

    const groups = (await response.json()) as GetPromptsWorkgroupsResponse;
    groups.Workgroups.forEach((group) => {
      group.IsMember = true;
    });

    onSendApiAction({
      apiName: isMember ? "GetWorkgroupsMember" : "GetWorkgroups",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      msGraphLatency: parseInt(
        response.headers.get("x-datasource-msgraph-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: groups.Workgroups.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });

    return groups;
  } catch (error) {
    logApiError(
      isMember ? "GetWorkgroupsMember" : "GetWorkgroups",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Fetches saved prompts from the server.
 * @param host service host
 * @param user current logged in user
 * @returns saved prompts
 */
export const fetchSavedPrompts = async (
  host: string,
  user: NonNullable<SiteSetting["user"]>,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<Prompt[]> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetSaved",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/api/promptsclient/saved`;
    const avatarUrl = `https://${host}/api/graph/users/${user.objectId}/photos/48x48/$value`;
    const headers = await buildCoreHeaders(msalContext, logicalId);

    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );

    sendResponseReceivedEvent(
      parseInt(response.headers.get("x-datasource-latency") ?? "0", 10),
      parseInt(response.headers.get("x-datasource-statuscode") ?? "0", 10),
      response.headers.get("x-datasource-traceid") ?? "",
      msalContext
    );

    const payload = (await response.json()) as {
      PromptList: Prompt[];
      PromptListCount: number;
    };
    let prompts = payload["PromptList"] as Prompt[];
    prompts = prompts.map((prompt) => {
      return {
        ...prompt,
        Origin: "User",
        DisplayCategory: "Created by Me",
        Author: {
          DisplayName: user.displayName,
          Email: user.email,
          Avatar: avatarUrl,
        },
        LogicalId: logicalId,
        Repository: getRepository(response),
      };
    });

    onSendApiAction({
      apiName: "GetSaved",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: prompts.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });

    return prompts;
  } catch (error) {
    logApiError(
      "GetSaved",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Deletes a saved prompt from the server.
 * @param host service host
 * @param promptId prompt id to delete
 */
export const deleteSavedPrompt = async (
  host: string,
  promptId: string,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<void> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "DeleteSaved",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();
  try {
    const serviceUrl = `https://${host}/api/promptsclient/saved`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );

    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "DELETE",
        headers,
        body: JSON.stringify({
          promptId,
        }),
      })
    );

    onSendApiAction({
      apiName: "DeleteSaved",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
  } catch (error) {
    logApiError(
      "DeleteSaved",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Deletes a shared prompt from the workgroup.
 * @param host service host
 * @param promptId prompt id to delete from groupId
 * @param groupId group id to delete prompt from
 */
export const deleteSharedPromptFromWorkgroup = async (
  host: string,
  prompts: Prompt[],
  groupIds: string[],
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<DeletePromptsPublishResponse> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "DeletePublish",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/api/promptsclient/prompts/publish`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );

    const response: Response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "DELETE",
        headers,
        body: JSON.stringify({
          Prompts: prompts.map((prompt) => ({ PromptId: prompt.PromptId })),
          Workgroups: groupIds.map((groupId) => ({ Id: groupId })),
        }),
      })
    );

    const result: DeletePromptsPublishResponse = await response.json();

    onSendApiAction({
      apiName: "DeletePublish",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      successfulResultCount: result.Prompts.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
    return result;
  } catch (error) {
    logApiError(
      "DeletePublish",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Shares a prompt with a workgroup.
 * @param host service host
 * @param prompts prompts to share
 * @param groupIds group ids to share with
 */
export const sharePromptToWorkgroup = async (
  host: string,
  prompts: Prompt[],
  groupIds: string[],
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<PostPromptsPublishResponse> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "PostPublish",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/api/promptsclient/prompts/publish`;

    const headers = await buildCoreHeaders(msalContext, logicalId);
    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );

    const response: Response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "POST",
        headers,
        body: JSON.stringify({
          Prompts: prompts.map((prompt) => ({
            Id: prompt.Id,
            Origin: prompt.Origin,
          })),
          Workgroups: groupIds.map((groupId) => ({ Id: groupId })),
        }),
      })
    );

    const result: PostPromptsPublishResponse = await response.json();

    onSendApiAction({
      apiName: "PostPublish",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      successfulResultCount: result.Prompts.length,
      errorResultCount: prompts.length - result.Prompts.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
    return result;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if ((error as ApiError)?.response?.status !== 409) {
      logApiError(
        "PostPublish",
        error as Error,
        logicalId,
        Date.now() - startTimer,
        onSendApiAction
      );
    }

    throw error;
  }
};

export const fetchBookmarkedPrompts = async (
  host: string,
  locale: string,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<Prompt[]> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetBookmarkedPrompts",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/${locale}/api/promptsclient/bookmarkedPrompts`;
    const headers = await buildCoreHeaders(msalContext, logicalId);

    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );
    let prompts: Prompt[] = await response.json();
    onSendApiAction({
      apiName: "GetBookmarkedPrompts",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      successfulResultCount: prompts.length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
    prompts = prompts.map((prompt: Prompt) => {
      return {
        ...prompt,
        Repository: getRepository(response),
      };
    });
    return prompts;
  } catch (error) {
    logApiError(
      "GetBookmarkedPrompts",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

export const addFavoriteAsync = async (
  host: string,
  prompt: Prompt,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<void> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetFavoritesAdd",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    let serviceUrl = `https://${host}/api/promptsclient/favorites/add?promptId=${prompt.PromptId}`;

    if (isWorkgroupPrompt(prompt)) {
      serviceUrl += `&sharedType=WorkgroupPublish`;
    }

    const headers = await buildCoreHeaders(msalContext, logicalId);
    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );
    onSendApiAction({
      apiName: "GetFavoritesAdd",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
  } catch (error) {
    logApiError(
      "GetFavoritesAdd",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );
    throw error;
  }
};

export const removeFavoriteAsync = async (
  host: string,
  prompt: Prompt,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<void> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetFavoritesRemove",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    let serviceUrl = `https://${host}/api/promptsclient/favorites/remove?promptId=${prompt.PromptId}`;

    if (isWorkgroupPrompt(prompt)) {
      serviceUrl += `&sharedType=WorkgroupPublish`;
    }

    const headers = await buildCoreHeaders(msalContext, logicalId);
    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );
    onSendApiAction({
      apiName: "GetFavoritesRemove",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
  } catch (error) {
    logApiError(
      "GetFavoritesRemove",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Fetches linked prompts from the server.
 * @param host service host
 * @param user current logged in user
 * @returns saved prompts
 */
export const fetchLinkedPrompts = async (
  host: string,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<LinkedPrompt[]> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetLinkedPrompts",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/api/promptsclient/link`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );

    sendResponseReceivedEvent(
      parseInt(response.headers.get("x-datasource-latency") ?? "0", 10),
      parseInt(response.headers.get("x-datasource-statuscode") ?? "0", 10),
      response.headers.get("x-datasource-traceid") ?? "",
      msalContext
    );

    const payload = (await response.json()) as GetLinkedPromptsApiResponse;
    onSendApiAction({
      apiName: "GetLinkedPrompts",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: payload.PromptListCount,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });

    return payload.PromptList;
  } catch (error) {
    logApiError(
      "GetLinkedPrompts",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

export const removeLinkedPromptAsync = async (
  host: string,
  promptIds: string[],
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<void> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "DeleteLinkedPrompts",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/api/promptsclient/link`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );

    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "DELETE",
        headers,
        body: JSON.stringify({
          PromptIds: promptIds,
        }),
      })
    );

    const payload = (await response.json()) as DeleteLinkedPromptsApiResponse;
    if (payload.IsPartialSuccess) {
      logApiError(
        "DeleteLinkedPrompts",
        new Error("IsPartialSuccess"),
        logicalId,
        Date.now() - startTimer,
        onSendApiAction
      );
    } else {
      onSendApiAction({
        apiName: "DeleteLinkedPrompts",
        status: response.status as HttpStatusCode,
        isFromCache: false,
        substrateLatency: parseInt(
          response.headers.get("x-datasource-latency") ?? "0",
          10
        ),
        successfulResultCount: payload.PromptIds?.length,
        supportServiceLatency: Date.now() - startTimer,
        traceId: response.headers.get("x-datasource-traceid") || logicalId,
      });
    }
  } catch (error) {
    logApiError(
      "DeleteLinkedPrompts",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

const getRepository = (response: Response): RepositoryType => {
  return response.headers.get(Is3SResponseHeader)?.toLowerCase() === "true"
    ? "Substrate"
    : "CosmosDB";
};

/**
 * Create a server-side prompt link.
 * Spec: https://microsoftapc-my.sharepoint.com/:w:/g/personal/apagrawal_microsoft_com/EfZ7GpL9AChBmnM_5By2EeoB6dAm65hkD_kf38bAPkkDBw?e=VrJfgC
 * @param host service host
 * @param prompt prompt to generate a link for
 */
export const createPromptLink = async (
  host: string,
  prompt: Prompt,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication,
  scenario?: ScenarioType,
  isTryInEnabledForAgents?: boolean
): Promise<{
  PromptEncodedLink: string;
}> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "CreatePromptLink",
    isInit: true,
    traceId: logicalId,
  });
  const serviceUrl = new URL(`https://${host}/api/promptsclient/link`);

  if (scenario) {
    serviceUrl.searchParams.append("scenario", scenario);
  }

  const headers = await buildCoreHeaders(msalContext, logicalId);
  headers.append(
    "RequestVerificationToken",
    document
      ?.querySelector('input[name="__RequestVerificationToken"]')
      ?.getAttribute("value") ?? ""
  );

  let response: Response;
  const request = buildPromptLinkRequestObject(
    prompt,
    scenario,
    isTryInEnabledForAgents
  );
  const startTimer = Date.now();

  try {
    response = await retry(() =>
      authAwareFetch(serviceUrl.toString(), {
        method: "POST",
        headers,
        body: JSON.stringify(request),
      })
    );

    onSendApiAction({
      apiName: "CreatePromptLink",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      successfulResultCount: 1,
      supportServiceLatency: Date.now() - startTimer,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
  } catch (error) {
    logApiError(
      "CreatePromptLink",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }

  return response.json();
};

/**
 * Creates a request body object for POST /api/promptsclient/link requests
 * @param host service host
 * @param promptId prompt id to fetch the link for
 */
function buildPromptLinkRequestObject(
  prompt: Prompt,
  scenario?: ScenarioType,
  isTryInBizChatEnabledForAgents?: boolean
) {
  const localeDelimiter = "_";
  const isFavoritedOrSaved =
    prompt.UserActivity?.Favorite?.IsFavorite || prompt.Origin === "User";
  const promptOrigin = prompt.Origin ?? "Global";
  let promptId = promptOrigin === "User" ? prompt.PromptId : prompt.Id;
  // If the prompt is a workgroup prompt, we need to use the source prompt id (or deprecated parent prompt id)
  promptId =
    (prompt as WorkgroupMicrosoftPrompt | WorkgroupUserPrompt).SourcePromptId ??
    (prompt as WorkgroupMicrosoftPrompt | WorkgroupUserPrompt).ParentPromptId ??
    promptId;
  let promptLocale: string | undefined = undefined;
  if (promptId.includes(localeDelimiter)) {
    const [id, locale] = promptId.split(localeDelimiter);
    promptLocale = locale;
    promptId = id;
  }

  let gptName = undefined;
  let gptId = undefined;
  if (isTryInBizChatEnabledForAgents && isExtensionPrompt(prompt)) {
    const isUserCreatedPrompt = isUserCreatedExtensionPrompt(prompt);
    gptName = isUserCreatedPrompt
      ? (prompt as UserExtensionPrompt).GptName
      : prompt.ProviderName;
    gptId = isUserCreatedPrompt
      ? (prompt as UserExtensionPrompt).GptId
      : (prompt as ExtensionPrompt).ProviderId;

    if (!isUserCreatedPrompt) {
      if (prompt.Id?.includes(localeDelimiter)) {
        const promptIdSections = prompt.Id.split(localeDelimiter);

        // locale will always be the last element
        promptLocale = promptIdSections.pop();

        // join the rest of the sections back together
        promptId = promptIdSections.join(localeDelimiter);
      } else {
        promptLocale = undefined;
        promptId = prompt.Id;
      }
    }
  }

  const request = {
    Prompt: {
      PromptId: promptId,
      Locale: promptLocale,
      Origin: promptOrigin,
      Title: prompt.Title,
      Products: !isFavoritedOrSaved ? prompt.Products : undefined,
      CommandText: !isFavoritedOrSaved ? prompt.CommandText : undefined,
      HydratedEntities:
        prompt.HydratedEntities ?? extractHydratedEntities(prompt.CommandText),
      GptName: gptName,
      GptId: gptId,
    },
  };

  //update the request body based on the scenario
  switch (scenario) {
    //tryInApp scenario requires command text and products to be present and not null in the request body.
    case "tryInApp":
      request.Prompt.CommandText = prompt.CommandText;
      request.Prompt.Products = prompt.Products;
      break;
    default:
      break;
  }

  return request;
}

/**
 * Sends an email notification for shared prompts.
 * Do not await the response, but use then/catch, as the API takes several seconds.
 * @param host service host
 * @param model request model
 * @param onSendApiAction callback to send telemetry
 * @msalContext the instance of MSAL.js.  Will be undefined if not MoS app
 */
export const sendSharedPromptEmailNotification = async (
  host: string,
  model: PostSendSharePromptEmailNotificationRequestModel,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<PostSendSharePromptEmailNotificationResponse> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "SendSharePromptEmailNotification",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/api/promptsclient/prompts/sendsharedpromptemailnotification`;

    const headers = await buildCoreHeaders(msalContext, logicalId);
    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );

    const response: Response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "POST",
        headers,
        body: JSON.stringify(model),
      })
    );

    const resultInternal: PostSendSharePromptEmailNotificationJsonResponse =
      await response.json();

    const result: PostSendSharePromptEmailNotificationResponse = new Map(
      resultInternal
    );

    onSendApiAction({
      apiName: "SendSharePromptEmailNotification",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: Array.from(result.values()).filter(
        (messageId: string | null) => messageId !== null
      ).length,
      errorResultCount: Array.from(result.values()).filter(
        (messageId: string | null) => messageId === null
      ).length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
    return result;
  } catch (error) {
    logApiError(
      "SendSharePromptEmailNotification",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Fetches User's eligibility.
 * @param host service host
 * @returns user's eligibility
 */
export const getUserEligibility = async (
  host: string,
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<boolean> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetUserEligibilityForCWC",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/api/promptsclient/eligibility/cwc`;
    const headers = await buildCoreHeaders(msalContext, logicalId);
    const response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );

    sendResponseReceivedEvent(
      parseInt(response.headers.get("x-datasource-latency") ?? "0", 10),
      parseInt(response.headers.get("x-datasource-statuscode") ?? "0", 10),
      response.headers.get("x-datasource-traceid") ?? "",
      msalContext
    );

    const payload = (await response.json()) as boolean;
    onSendApiAction({
      apiName: "GetUserEligibilityForCWC",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      substrateLatency: parseInt(
        response.headers.get("x-datasource-latency") ?? "0",
        10
      ),
      supportServiceLatency: Date.now() - startTimer,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });

    return payload;
  } catch (error) {
    logApiError(
      "GetUserEligibilityForCWC",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};

/**
 * Gets group member count.
 * @param host service host
 * @param model request model
 * @param onSendApiAction callback to send telemetry
 * @msalContext the instance of MSAL.js.  Will be undefined if not MoS app
 */
export const getGroupMemberCount = async (
  host: string,
  groupIds: string[],
  onSendApiAction: (params: SendApiActionEventParameter) => void,
  msalContext?: IPublicClientApplication
): Promise<GetGroupMemberCountResponse> => {
  const logicalId = generateGuid();
  onSendApiAction({
    apiName: "GetGroupMemberCount",
    isInit: true,
    traceId: logicalId,
  });
  const startTimer = Date.now();

  try {
    const serviceUrl = `https://${host}/api/graph/prompts/getgroupmembercount?groupIds=${groupIds.join(",")}`;

    const headers = await buildCoreHeaders(msalContext, logicalId);
    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );

    const response: Response = await retry(() =>
      authAwareFetch(serviceUrl, {
        method: "GET",
        headers,
      })
    );

    const resultInternal: GetGroupMemberCountJsonResponse =
      await response.json();

    const result: GetGroupMemberCountResponse = new Map<string, number>(
      Object.entries(resultInternal)
    );

    onSendApiAction({
      apiName: "GetGroupMemberCount",
      status: response.status as HttpStatusCode,
      isFromCache: false,
      supportServiceLatency: Date.now() - startTimer,
      successfulResultCount: Array.from(result.values()).length,
      errorResultCount: groupIds.length - Array.from(result.values()).length,
      traceId: response.headers.get("x-datasource-traceid") || logicalId,
    });
    return result;
  } catch (error) {
    logApiError(
      "GetGroupMemberCount",
      error as Error,
      logicalId,
      Date.now() - startTimer,
      onSendApiAction
    );

    throw error;
  }
};
