import { Text } from "@fluentui/react-components";
import * as React from "react";
import type { Appearance, Prompt } from "../../data";
import { PromptCommand } from "../PromptCommand/PromptCommand";
import { PromptIcon } from "../PromptIcon/PromptIcon";
import { generateTitleFromPrompt } from "../utils";
import { useStyles } from "./PromptCardBody.styles";

export type PromptCardContentProps = {
  appearance?: Appearance;
  prompt: Prompt;
};

const maxBodyLineByAppearance = (appearance?: Appearance) => {
  switch (appearance) {
    case "web":
    case "web-v2":
    case "in-app-v2":
    case "single-col":
    case "win-copilot":
    case "m365":
      return 4;
    default:
      return 3;
  }
};

const minBodyLineByAppearance = (appearance?: Appearance) => {
  switch (appearance) {
    case "web":
    case "web-v2":
    case "in-app-v2":
      return 3;
    default:
      return 2;
  }
};

export const PromptCardBody: React.FC<PromptCardContentProps> = React.memo(
  ({ prompt, appearance }) => {
    const styles = useStyles(appearance)();
    const cardTitleRef = React.useRef<HTMLDivElement>(null);
    const cardBodyRef = React.useRef<HTMLDivElement>(null);
    const title = prompt.Title || generateTitleFromPrompt(prompt);
    const isGoBold = appearance === "web-v2" || appearance === "in-app-v2";
    const isRockSteady = appearance === "in-app-v3" || appearance === "web-v3";

    React.useEffect(() => {
      if (isGoBold || isRockSteady) {
        // Since there is no title displayed in Go Bold prompt cards, we don't need to handle multi-line title
        // In RockSteady, the title is always one-line, so we don't need to handle it either
        return;
      }
      const maxBodyLine = maxBodyLineByAppearance(appearance);
      const minBodyLine = minBodyLineByAppearance(appearance);
      const titleOffSetHeight = cardTitleRef.current?.offsetHeight ?? 0;
      const numberOfLines = cardTitleRef.current
        ? Math.round(
            titleOffSetHeight /
              parseFloat(getComputedStyle(cardTitleRef.current).lineHeight)
          )
        : 0;

      if (numberOfLines > 1) {
        if (cardBodyRef.current) {
          cardBodyRef.current.style.webkitLineClamp = `${minBodyLine}`;
        }
      } else {
        if (cardBodyRef.current) {
          cardBodyRef.current.style.webkitLineClamp = `${maxBodyLine}`;
        }
      }
    }, [appearance, cardTitleRef, isGoBold, isRockSteady]);

    return (
      <div className={styles.root}>
        {!isGoBold && (
          <div className={styles.title}>
            <div className={styles.iconWrapper}>
              <PromptIcon
                prompt={prompt}
                appearance={appearance}
                useColorfulIcon={isRockSteady}
              />
            </div>
            <Text
              data-testid="prompt-title"
              ref={cardTitleRef}
              className={styles.titleText}
            >
              {title}
            </Text>
          </div>
        )}
        <div
          data-testid="prompt-description"
          className={styles.preview}
          ref={cardBodyRef}
        >
          <PromptCommand
            command={prompt.DisplayText}
            entities={prompt.HydratedEntities}
            appearance={appearance}
          />
        </div>
      </div>
    );
  }
);

PromptCardBody.displayName = "PromptCardBody";
