import {
  Button,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import type { ErrorButtonProps } from "./Error.web.data";

const useStyles = makeStyles({
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.padding("5px", "12px"),
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    "@media (max-width: 540px)": {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  filledBtn: {
    ...shorthands.borderRadius("8px"),
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    "&:hover": {
      backgroundColor: tokens.colorBrandBackgroundHover,
      color: tokens.colorNeutralForegroundOnBrand,
      "&:active": {
        backgroundColor: tokens.colorBrandBackgroundPressed,
        color: tokens.colorNeutralForegroundOnBrand,
      },
    },
  },
  transparentBtn: {
    color: tokens.colorBrandForeground2,
    "&:hover": {
      color: tokens.colorBrandForeground2Hover,
      "&:active": {
        color: tokens.colorBrandForeground2Pressed,
      },
    },
  },
});

export const ErrorButton: React.FC<ErrorButtonProps> = ({
  buttonText,
  onHandleButtonClick,
  isTransparent,
}) => {
  const styles = useStyles();
  const buttonAppearance = isTransparent ? "transparent" : "secondary";
  const buttonStyles = mergeClasses(
    styles.btn,
    isTransparent ? styles.transparentBtn : styles.filledBtn
  );

  return (
    <div>
      <Button
        data-testid="error-button"
        className={buttonStyles}
        onClick={onHandleButtonClick}
        appearance={buttonAppearance}
      >
        {buttonText}
      </Button>
    </div>
  );
};
