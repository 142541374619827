import { useStrings } from "@1js/localization-components";
import type { ProductType } from "../components/data";
import {
  AzureLabel,
  BadgesGroupLabel,
  BusinessChatLabel,
  CategoriesGroupLabel,
  CopilotChatLabel,
  CopilotForMicrosoft365Label,
  ExcelLabel,
  FormsLabel,
  LoopLabel,
  OneDriveLabel,
  OneNoteLabel,
  OutlookLabel,
  PlannerLabel,
  PowerPointLabel,
  SharePointLabel,
  StreamLabel,
  TeamsLabel,
  VivaEngageLabel,
  VivaGoalsLabel,
  VivaTopicsLabel,
  WhiteboardLabel,
  WordLabel,
} from "../components/sharedStrings";

export type BadgeLabelWrapperType = ({
  productName,
}: {
  productName: string;
}) => string;

export const useProducts = () => {
  const localizations = useStrings({
    OneDriveLabel,
    OneNoteLabel,
    OutlookLabel,
    ExcelLabel,
    WordLabel,
    PowerPointLabel,
    CopilotForMicrosoft365Label,
    BadgesGroupLabel,
    CategoriesGroupLabel,
    TeamsLabel,
    LoopLabel,
    WhiteboardLabel,
    SharePointLabel,
    VivaTopicsLabel,
    FormsLabel,
    PlannerLabel,
    StreamLabel,
    VivaEngageLabel,
    VivaGoalsLabel,
    AzureLabel,
    BusinessChatLabel,
    CopilotChatLabel,
  });

  const badgeToLabel = (
    badge: ProductType | string,
    labelWrapper?: BadgeLabelWrapperType
  ): string => {
    if (!labelWrapper) {
      return badgeToLabelText(badge);
    }

    return labelWrapper({ productName: badgeToLabelText(badge) });
  };

  const badgeToLabelText = (badge: ProductType | string): string => {
    switch (badge) {
      case "Word":
        return localizations.WordLabel();
      case "PowerPoint":
        return localizations.PowerPointLabel();
      case "Outlook":
        return localizations.OutlookLabel();
      case "Teams":
        return localizations.TeamsLabel();
      case "CWC":
      case "BizChat":
      case "CWCWithBizChatLabel":
        return localizations.CopilotChatLabel();
      case "Copilot":
        return localizations.CopilotForMicrosoft365Label();
      case "OneDrive":
        return localizations.OneDriveLabel();
      case "OneNote":
        return localizations.OneNoteLabel();
      case "Excel":
        return localizations.ExcelLabel();
      case "Loop":
        return localizations.LoopLabel();
      case "Whiteboard":
        return localizations.WhiteboardLabel();
      case "SharePoint":
        return localizations.SharePointLabel();
      case "Topics":
        return localizations.VivaTopicsLabel();
      case "Forms":
        return localizations.FormsLabel();
      case "Planner":
        return localizations.PlannerLabel();
      case "Stream":
        return localizations.StreamLabel();
      case "VivaEngage":
        return localizations.VivaEngageLabel();
      case "VivaGoals":
        return localizations.VivaGoalsLabel();
      case "Azure":
      case "AzureWeb":
        return localizations.AzureLabel();
      default:
        return badge;
    }
  };

  return {
    badgeToLabel,
  };
};
