type PartialRequire<T, K extends keyof T> = Omit<Partial<T>, K> & Pick<T, K>;
export type RepositoryType = "Substrate" | "CosmosDB";

export const PromptCategories = [
  "Create",
  "Suggestion",
  "Brainstorm",
  "Email",
  "Analyze",
  "Assist",
  "Automate",
  "Browse",
  "Collect",
  "Execute",
  "Find",
  "News",
  "People",
  "Prepare",
  "Summarize",
  "Ask",
  "Catch up",
  "Understand",
  "Edit",
  "UserAuthored",
  "Created by Me",
  "Customize",
  "Design",
  "Operate",
  "Optimize",
  "Troubleshoot",
  "Learn",
  "Schedule",
  "Manage",
  "Code",
  "Communications",
  "Tenant", // tenant prompts don't have a display category, so add 'Tenant' as a fake category in order to keep prompt icon rendering consistent
] as const;

export type PromptCategory = (typeof PromptCategories)[number];

export type UserActivity = {
  Favorite: {
    IsFavorite: boolean;
    ActivityDateTimeInUtc?: string;
  };
  Execution?: {
    ActivityDateTimeInUtc?: string;
  };
};

export type Status = "idle" | "loading" | "failed" | "fulfilled";

export type HydratedEntityType =
  | "People"
  | "File"
  | "Event"
  | "Message"
  | "External"
  | "Site"
  | "Chat";

export type HydratedEntity = {
  Type: HydratedEntityType;
  Entity: {
    Id: string;
    ReferenceId: string;
    FileName?: string;
    AccessUrl?: string;
    SpoId?: string;
    OriginalId?: string;
    Subject?: string;
    SkypeTeamsMeetingUrl?: string;
    DisplayName?: string;
    EmailAddresses?: string[];
    Start?: string;
    End?: string;
    OrganizerName?: string;
    OrganizerAddress?: string;
    EventId?: { Id: string };
    Attendees?: Array<{
      EmailAddress: {
        Name: string;
        Address: string;
      };
    }>;
    Title?: string;
    FileExtension?: string;
    ParentLink?: string;
    LastModifiedTime?: string;
    SecondaryFileExtension?: string;
    SPWebUrl?: string;
    AuthorOwsUser?: string;
    SiteTitle?: string;
    IconUrl?: string;
    ItemId?: string;
    Url?: string;
    Text?: string;
    Name?: string;
  };
};

export type PromptRingType = "FC" | "MSIT" | "SDF";

export type Group = {
  Id: string;
  Mail?: string;
  MailNickName?: string;
  Description?: string;
  DisplayName: string;
  Theme?: string;
  Visibility?: string;
  CreatedDateTime?: string;
  RenewedDateTime?: string;
  IsMember?: boolean;
  HasPrompts?: boolean;
  AvatarUrl?: string;
};

export type Metadata = {
  Name: string;
  Value: string;
};

export type Extension = {
  Origin: PromptOrigin;
  ProviderName: string;
};

type UserPromptOrigin = "User";

// Microsoft is replaced by Global in the new prompt origin. Keeping this for backward compatibility.
type MicrosoftPromptOrigin = "Microsoft" | "Global";

type ExtensionPromptOrigin = "Plugin" | "GPT" | "CustomEngineCopilot";

type TenantPromptOrigin = "Tenant";

export type PromptOrigin =
  | UserPromptOrigin
  | MicrosoftPromptOrigin
  | ExtensionPromptOrigin
  | TenantPromptOrigin;

export type BasePrompt = {
  PromptId: string;
  Id: string;
  CommandText: string;
  DisplayText: string;
  Title: string;
  Products: string[];
  UserActivity: UserActivity;
  DisplayCategory: PromptCategory;
  DisplayDescription?: string | null;
  Slug?: string | null;
  /**
   * Optional list of hydrated entities that are in the prompt.
   */
  HydratedEntities?: HydratedEntity[];
  /**
   * Reference ID generated by the 3s suggestions to identify each prompt.
   * This can be empty if the prompt is not from 3s suggestions (e.g. Cosmos DB)
   * or null if the prompt is a user authored one.
   */
  ReferenceId?: string | null;
  /**
   * Logical ID generated by the client to identify the /suggestion call to 3S.
   */
  LogicalId?: string;
  /**
   * State indicating the 'bookmark' action is currently in process.
   */
  IsBookmarking?: boolean;

  /**
   * State indicating the 'save' action is currently in process for prompt sharing.
   */
  IsSaving?: boolean;

  /**
   * State indicating the 'delete' action is currently in process.
   */
  IsDeleting?: boolean;

  /**
   * State indicating the 'remove' action is currently in process.
   * Long depicts long wait time leading to progress indicator.
   */
  RemovingState?: "false" | "long" | "true";

  /**
   * State indicated the 'edit' action is currently in progress.
   */
  IsEditing?: boolean;

  /**
   * tag indicating what group of users if exposed to the prompt - for experimental purposes
   */
  PromptLabel?: PromptRingType;

  /**
   * Groups this prompt is shared with.
   */
  Groups?: Group[];

  /** tag indicating where the Prompt was sourced from */
  Repository?: RepositoryType;

  /**
   * Optional property bag.
   */
  Metadata?: Metadata[];

  /**
   * Optional provider name (i.e. Microsoft, plugin name, GPT name )
   */
  ProviderName?: string;

  /**
   * Optional flag indicating if the prompt is a recommended prompt (3SR).
   */
  IsRecommended?: boolean;
};

export type DraftPrompt = PartialRequire<BasePrompt, "CommandText">;

export type MicrosoftPrompt = BasePrompt & {
  Text: string;
  Origin?: MicrosoftPromptOrigin;
};

export type ExtensionPrompt = BasePrompt & {
  Text: string;
  Origin?: ExtensionPromptOrigin;
  ProviderId?: string;
  ProviderPartyType?: string;
  DeveloperName?: string;
};

export type BaseUserPrompt = BasePrompt & {
  CreatedDateTimeInUtc?: string;
  LastUpdatedDateTimeInUtc?: string;
  Author?: {
    DisplayName: string;
    Avatar?: string;
    Email?: string;
  };
  Origin?: UserPromptOrigin;
};

export type TenantPrompt = BasePrompt &
  WorkgroupPromptFields & {
    Origin: TenantPromptOrigin;
  };

// reference: https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/469129/Prompt-Store-Schema-Document
type ProviderDetails = {
  PromptId: string;
  ProviderName: string;
  AuthorType: string;
  AuthorId?: string;
  AuthorDisplayName?: string;
  Metadata?: Record<string, string>;
};

type SharedDetails = {
  Type: string;
  ShareTeam: string;
  SharedBy?: string;
  SharedCanvas?: string;
};

type Annotation = {
  EntityId: string;
  Type: string;
  Index: number;
  DisplayText: string;
  Data?: string;
};

type SkillResult = {
  WhetherUsedInFinalResponse?: "Yes" | "No";
};

type Skill = {
  Type?: "Web" | "Work" | "fileSearch" | "EmailSearch";
  Results: SkillResult[];
};

type ExecutionDetails = {
  ExecutedTime: string;
  Scenario?: string;
  Turn: number;
  IsApology?: "Yes" | "No";
  Annotations?: Annotation[];
  SkillsExecuted?: Skill[];
  Product: string;
};

export type UnifiedPrompt = {
  PromptStoreId: string;
  Locale: string;
  DisplayText: string;
  CommandText: string;
  Title?: string;
  Description?: string;
  Origin: PromptOrigin;
  Products?: string[];
  CreatedDateTime: string;
  RelationshipType?: string[];
  LastSavedDateTime?: string;
  Annotations?: Annotation[];
  ProviderDetails?: ProviderDetails;
  UserActivity?: UserActivity;
  SharedDetails?: SharedDetails;
  TotalExecutionCount?: number;
  ExecutionDetails?: ExecutionDetails[];
  Features?: string[];
  Metadata?: Record<string, string>;
};

export type UserExtensionPrompt = BaseUserPrompt & {
  Text: string;
  ProviderId?: string;
  ProviderPartyType?: string;
  DeveloperName?: string;
  GptId?: string;
  GptName?: string;
};

type WorkgroupPromptFields = {
  Publisher: {
    Name: string;
    Address?: string;
  };
  ParentPromptId?: string; // deprecated. Use SourcePromptId instead.
  SourcePromptId?: string;
  Workgroup?: Group;
};

export type WorkgroupUserPrompt = BaseUserPrompt & WorkgroupPromptFields;

export type WorkgroupMicrosoftPrompt = MicrosoftPrompt & WorkgroupPromptFields;

export type UserPrompt =
  | BaseUserPrompt
  | UserExtensionPrompt
  | WorkgroupUserPrompt;

export type Prompt =
  | UserPrompt
  | MicrosoftPrompt
  | ExtensionPrompt
  | WorkgroupMicrosoftPrompt
  | TenantPrompt;

export const AllowedJobTypeList = new Set([
  "Department",
  "Industry",
  "Education",
]);

export const AllowedExtensionList = new Set([
  "Plugin",
  "GPT",
  "CustomEngineCopilot",
]);

export type InstalledExtension = {
  type: "Plugin" | "GPT";
  providerId: string;
  iconUrl: string;
  displayName: string;
};

export const WXPProducts = new Set(["Word", "Excel", "PowerPoint"]);

export type DeliveryOrigin = "Workgroup";
