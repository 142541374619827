import {
  useStringForPlaceholders,
  useStrings,
} from "@1js/localization-components";
import {
  isExtensionPrompt,
  isTenantPrompt,
  isUserCreatedExtensionPrompt,
  isUserPrompt,
  isWorkgroupPrompt,
  type UserExtensionPrompt,
  type WorkgroupUserPrompt,
} from "@1js/pl-types";
import { Avatar, Tag, Tooltip } from "@fluentui/react-components";
import { BuildingRegular } from "@fluentui/react-icons";
import React from "react";
import type { Appearance, ExtensionPrompt, Prompt, UserPrompt } from "../data";
import { ExtensionFilterLabel } from "../PromptFilters/PromptFilter.strings";
import { ExtensionIcon } from "./ExtensionIcon";
import { PromptAuthorLabel } from "./PromptAuthor.strings";
import { useStyles } from "./PromptAuthor.styles";

export type PromptAuthorProps = {
  prompt: Prompt;
  expanded?: boolean;
  size?: "extra-small" | "small" | "medium";
  tabIndex?: number;
  extensionIconMap?: Record<string, string>;
  appearance?: Appearance;
  useAgentAsAuthor?: boolean; // For use with userCreatedExtensionPrompts when we need to override the author with the agent name (E.g. Works In tag)
};

const MicrosoftLogo: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <rect fill="#F25022" x="2" y="2" width="9.5" height="9.5" />
      <rect fill="#80BA01" x="12.5" y="2" width="9.5" height="9.5" />
      <rect fill="#FFB902" x="12.5" y="12.5" width="9.5" height="9.5" />
      <rect fill="#02A4EF" x="2" y="12.5" width="9.5" height="9.5" />
    </svg>
  );
};

export const PromptAuthor: React.FC<PromptAuthorProps> = React.memo(
  ({
    prompt,
    size,
    expanded,
    tabIndex,
    extensionIconMap,
    appearance,
    useAgentAsAuthor,
  }) => {
    const styles = useStyles(appearance)();
    const localizations = useStrings({
      ExtensionFilterLabel,
    });
    const authorLabel = useStringForPlaceholders(PromptAuthorLabel);
    const providerName = prompt.ProviderName
      ? prompt.ProviderName
      : localizations.ExtensionFilterLabel();
    const isExtension = isExtensionPrompt(prompt);
    const isUserExtensionPrompt = isUserCreatedExtensionPrompt(prompt);
    let displayName = isExtension ? providerName : "Microsoft";
    const isGoBold = appearance === "web-v2" || appearance === "in-app-v2";
    const isRockSteady = appearance === "in-app-v3" || appearance === "web-v3";
    let avatar = (
      <div
        data-testid="prompt-author-avatar"
        className={styles.microsoftAvatarContainer}
      >
        {isExtension ? (
          <ExtensionIcon
            prompt={prompt as ExtensionPrompt}
            extensionIconMap={extensionIconMap}
            className={styles.avatar}
          />
        ) : (
          <MicrosoftLogo className={styles.avatar} />
        )}
      </div>
    );
    if (isUserPrompt(prompt) || isWorkgroupPrompt(prompt)) {
      let author = (prompt as UserPrompt).Author;
      if (!author && isWorkgroupPrompt(prompt)) {
        const publisher = (prompt as WorkgroupUserPrompt).Publisher;
        if (publisher) {
          author = {
            DisplayName: publisher.Name,
            Email: publisher.Address,
          };
        }
      }
      if (!author) {
        // there is no way to render the author if the prompt is a user prompt and the author is not available
        return null;
      } else {
        if (isUserExtensionPrompt && useAgentAsAuthor) {
          displayName = (prompt as UserExtensionPrompt).GptName ?? "";
          avatar = (
            <div
              data-testid="prompt-author-avatar"
              className={styles.microsoftAvatarContainer}
            >
              <ExtensionIcon
                prompt={prompt as ExtensionPrompt}
                extensionIconMap={extensionIconMap}
                className={styles.avatar}
              />
            </div>
          );
        } else {
          displayName = author.DisplayName;
          avatar = (
            <Avatar
              image={{ src: author.Avatar }}
              name={displayName}
              shape={isRockSteady ? "circular" : "square"}
              size={isGoBold || isRockSteady ? 16 : 20}
              className={styles.avatar}
              aria-hidden={true}
              data-testid="prompt-author-avatar"
            />
          );
        }
      }
    }

    if (isTenantPrompt(prompt)) {
      displayName = prompt.ProviderName ?? "";
      avatar = <BuildingRegular className={styles.avatar} />;
    }

    if (expanded) {
      return (
        <Tag
          tabIndex={tabIndex ?? 0}
          size={size ?? "small"}
          media={avatar}
          data-testid="prompt-author-tag"
        >
          <span aria-hidden={true}>{displayName}</span>
          <span className={styles.srOnly}>
            {authorLabel({ authorName: displayName })}
          </span>
        </Tag>
      );
    }

    return (
      <Tooltip content={displayName} relationship="label">
        <Tag
          tabIndex={tabIndex ?? 0}
          data-testid="prompt-author"
          size={size ?? "small"}
          media={avatar}
          aria-label={authorLabel({ authorName: displayName })}
          role="img"
          className={styles.iconOnly}
          primaryText={null}
          shape={isRockSteady ? "circular" : "rounded"}
        >
          <span className={styles.srOnly}>
            {authorLabel({ authorName: displayName })}
          </span>
        </Tag>
      </Tooltip>
    );
  }
);

PromptAuthor.displayName = "PromptAuthor";
