interface Default {
  [s: string]: string | undefined;
}

const CHARMAP: Default = {
  a: "ȧ",
  A: "Ȧ",
  b: "ƀ",
  B: "Ɓ",
  c: "ƈ",
  C: "Ƈ",
  d: "ḓ",
  D: "Ḓ",
  e: "ḗ",
  E: "Ḗ",
  f: "ƒ",
  F: "Ƒ",
  g: "ɠ",
  G: "Ɠ",
  h: "ħ",
  H: "Ħ",
  i: "ī",
  I: "Ī",
  j: "ĵ",
  J: "Ĵ",
  k: "ķ",
  K: "Ķ",
  l: "ŀ",
  L: "Ŀ",
  m: "ḿ",
  M: "Ḿ",
  n: "ƞ",
  N: "Ƞ",
  o: "ǿ",
  O: "Ǿ",
  p: "ƥ",
  P: "Ƥ",
  q: "ɋ",
  Q: "Ɋ",
  r: "ř",
  R: "Ř",
  s: "ş",
  S: "Ş",
  t: "ŧ",
  T: "Ŧ",
  v: "ṽ",
  V: "Ṽ",
  u: "ŭ",
  U: "Ŭ",
  w: "ẇ",
  W: "Ẇ",
  x: "ẋ",
  X: "Ẋ",
  y: "ẏ",
  Y: "Ẏ",
  z: "ẑ",
  Z: "Ẑ",
};

const elongate = (s: string): boolean => "aeiou".indexOf(s.toLowerCase()) >= 0;

export const pseudoLocalize = (s: string): string =>
  s.split("").reduce((acc, cur) => {
    const mapped = CHARMAP[cur] || cur;
    const elongated = mapped + (elongate(cur) ? mapped : "");
    return acc + elongated;
  }, "");
