import type {
  Placeholders,
  StringFunction,
  StringFunctionWithPlaceholders,
} from "@1js/localization";
import { isDevLocalization } from "@1js/localization";
import type { StringProviderContext } from "../StringProviderContext";

export function getString(
  stringProvider: StringProviderContext,
  stringFunction: StringFunction
): string {
  // In development mode we want to show the english string that is defined in `declareString`
  if (isDevLocalization()) {
    return stringFunction();
  }

  return stringProvider.lookupString(stringFunction.key)();
}

export function getStringWithPlaceholders<K extends string>(
  stringProvider: StringProviderContext,
  stringFunction: StringFunctionWithPlaceholders<K>,
  placeholders: Placeholders<K>
): string {
  // In development mode we want to show the english string that is defined in `declareStringWithPlaceholders`
  if (isDevLocalization()) {
    return stringFunction(placeholders);
  }

  return stringProvider.lookupString(stringFunction.key)(placeholders);
}
