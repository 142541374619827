import { declareString } from "@1js/localization";

export const NotFoundTitle = declareString("promptLibrary.notFoundTitle", {
  text: "Prompt not found",
  comment: `When we try to load the page and the page is not found, we render this title at top of the error page.`,
});

export const NotFoundText = declareString("promptLibrary.notFoundText", {
  text: "It looks like the prompt isn't available anymore. Try using another prompt.",
  comment: `When we try to load the page and the page is not found, we render this text under the title in the error page.`,
});

export const ExploreButton = declareString("promptLibrary.exploreBtn", {
  text: "Explore",
  comment: `When encountering an error page, we render this button . When the user clicks on the button, they are redirected to another url.`,
});

export const PromptGalleryButton = declareString(
  "promptLibrary.PromptGalleryBtn",
  {
    text: "Copilot Prompt Gallery",
    comment:
      "When we try to load the page and the page is not found, we render this button. We render this text next to text found in ExploreButton. When the user clicks on the button, they are redirected to Copilot Prompt Gallery {Locked}",
  }
);
