import {
  makeStyles,
  shorthands,
  tokens,
  type GriffelStyle,
} from "@fluentui/react-components";
import type { Appearance } from "../../data";

type Styles = {
  root: GriffelStyle;
  preview: GriffelStyle;
  title: GriffelStyle;
  titleText: GriffelStyle;
  iconWrapper: GriffelStyle;
};

const copilotLabStyles: Styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    ...shorthands.gap("12px"),
  },

  preview: {
    fontFamily:
      "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },

  title: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    ...shorthands.gap("4px"),
  },

  titleText: {
    color: tokens.colorNeutralForeground2,
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "1.5rem",
    ...shorthands.overflow("hidden"),
    "@media(max-width: 540px)": {
      fontSize: "1rem",
    },
  },

  iconWrapper: {},
};

const copilotLabGoBoldStyles: Styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    alignSelf: "stretch",
    ...shorthands.gap("12px"),
  },

  preview: {
    fontFamily:
      "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    "@media (max-width: 540px)": {
      WebkitLineClamp: "3",
    },
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
    padding: "0px 2px",
  },

  title: {
    // GoBold prompt cards should not display the prompt title
    display: "none",
  },

  titleText: {
    display: "none",
  },

  iconWrapper: {},
};

const inAppStyles: Styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    ...shorthands.gap("12px"),
  },

  preview: {
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },

  title: {
    display: "flex",
    alignItems: "flex-start",
    alignSelf: "stretch",
    ...shorthands.gap("8px"),
  },

  titleText: {
    color: tokens.colorNeutralForeground2,
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "137.5%",
    ...shorthands.overflow("hidden"),
  },

  iconWrapper: {},
};

const m365Styles: Styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    ...shorthands.gap("8px"),
  },

  preview: {
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },

  title: {
    display: "flex",
    alignItems: "flex-start",
    alignSelf: "stretch",
    ...shorthands.gap("6px"),
  },

  titleText: {
    color: tokens.colorNeutralForeground1,
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "1.25rem",
    ...shorthands.overflow("hidden"),
  },

  iconWrapper: {},
};

const singleColumnStyles: Styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    ...shorthands.gap("8px"),
  },

  preview: {
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },

  title: {
    display: "flex",
    alignItems: "flex-start",
    alignSelf: "stretch",
    ...shorthands.gap("6px"),
  },

  titleText: {
    color: tokens.colorNeutralForeground1,
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
    fontStyle: "normal",
    lineHeight: "20px",
    ...shorthands.overflow("hidden"),
  },

  iconWrapper: {},
};

const rockSteadyStyles: Styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 0,
    alignSelf: "stretch",
    gap: tokens.spacingHorizontalL,
  },

  preview: {
    whiteSpace: "initial",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },

  title: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    height: "36px",
    gap: tokens.spacingHorizontalMNudge,
  },

  titleText: {
    color: tokens.colorNeutralForeground1,
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
  },

  iconWrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: tokens.borderRadiusLarge,
    backgroundColor: tokens.colorNeutralBackground1,
    paddingTop: tokens.spacingVerticalSNudge,
    paddingRight: tokens.spacingHorizontalSNudge,
    paddingBottom: tokens.spacingVerticalSNudge,
    paddingLeft: tokens.spacingHorizontalSNudge,
    background: tokens.colorNeutralBackground2,
  },
};

const useInAppStyles = makeStyles(inAppStyles);
const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const useM365Styles = makeStyles(m365Styles);
const useSingleColumnStyle = makeStyles(singleColumnStyles);
const useRockSteadyStyles = makeStyles(rockSteadyStyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "in-app":
      return useInAppStyles;
    case "web":
      return useCopilotLabStyles;
    case "web-v2":
    case "in-app-v2":
      return useCopilotLabGoBoldStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    case "m365":
    case "bing-web":
    case "win-copilot":
      return useM365Styles;
    case "bing-mobile":
    case "underside":
    case "single-col":
      return useSingleColumnStyle;
    default:
      return useInAppStyles;
  }
};
