import { createContext } from "react";
import type { SiteSetting } from "../types";

export const defaultSiteSetting: SiteSetting = {
  activeFlights: new Set(),
  showManageAccess: false,
  user: undefined,
  licenses: [],
};

export const PropertyContext = createContext<
  [SiteSetting, React.Dispatch<React.SetStateAction<SiteSetting>>]
>([defaultSiteSetting, () => undefined]);
