import {
  useStringForPlaceholders,
  useStrings,
} from "@1js/localization-components";
import {
  isExtensionPrompt,
  isMicrosoftPrompt,
  isTenantPrompt,
  isUserPrompt,
  isWorkgroupPrompt,
  type Group,
  type UserExtensionPrompt,
  type WorkgroupUserPrompt,
} from "@1js/pl-types";
import {
  CardFooter,
  makeStyles,
  Badge as RingTag,
  shorthands,
  Text,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import type { Appearance, Prompt } from "../../data";
import { PromptAuthor } from "../PromptAuthor";
import { type Badge } from "../PromptBadges/PromptBadges.types";
import { PromptCardActionButtons } from "../PromptCardActionButtons";
import {
  AgentName,
  CreatedByYou,
  SharedWithYou,
  SuggestedByMicrosoft,
} from "./RockSteadyPromptCardFooter.strings";

type RockSteadyPromptCardFooterProps = {
  products: Badge[];
  prompt: Prompt;
  appearance?: Appearance;
  isBadgeEnabled?: boolean;
  isFavoriteEnabled?: boolean;
  isBookmarking?: boolean;
  isCardFocused?: boolean;
  isFavorited?: boolean;
  onFavorited?: () => void;
  isDeleting?: boolean;
  isRemoving?: boolean;
  isPromptSavingEnabled?: boolean;
  isPromptEditingEnabled?: boolean;
  isPromptSharingEnabled?: boolean;
  isPromptSharingViaEmailEnabled?: boolean;
  isPromptSharingToWorkgroupEnabled?: boolean;
  isPromptRemovingFromWorkgroupEnabled?: boolean;
  isPromptSharingOneClickUxEnabled?: boolean;
  isPromptLikingEnabled?: boolean;
  onShareViaCopyLinkClicked?: (prompt: Prompt) => void;
  onShareViaEmailClicked?: (prompt: Prompt) => void | Promise<void>;
  onShareToWorkgroupClicked?: (prompts: Prompt[]) => void | Promise<void>;
  onDelete?: () => void;
  onEditToggled?: () => void;
  onEditSaved?: () => void;
  onRemoveFromWorkgroupClicked?: (
    prompts: Prompt[],
    groupIds: string[]
  ) => void;
  isEditing?: boolean;
  isPromptRingTagEnabled?: boolean;
  selectedGroupId?: string;
  groups?: Group[];
  setShareButtonTarget?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  isShowPromptAuthor?: boolean;
  extensionIconMap?: Record<string, string>;
};

const useStyles = makeStyles({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    height: "32px",
  },
  statusBar: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    height: "20px",
    ...shorthands.gap("4px"),
  },
  statusText: {
    color: tokens.colorNeutralForeground3,
    fontSize: tokens.fontSizeBase200,
    lineHeight: tokens.lineHeightBase300,
    fontWeight: tokens.fontWeightRegular,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  promptRingText: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground3,
    lineHeight: tokens.lineHeightBase100,
  },
  promptRing: {
    borderRadius: tokens.borderRadiusMedium,
  },
});

export const RockSteadyPromptCardFooter: React.FC<
  RockSteadyPromptCardFooterProps
> = ({
  appearance,
  prompt,
  isFavorited,
  isFavoriteEnabled,
  isBookmarking,
  isCardFocused,
  onFavorited,
  isDeleting,
  isRemoving,
  isPromptSavingEnabled,
  onDelete: onDelete,
  isPromptEditingEnabled,
  onEditToggled,
  onEditSaved,
  onRemoveFromWorkgroupClicked,
  isEditing,
  isPromptSharingEnabled,
  isPromptSharingViaEmailEnabled,
  isPromptSharingToWorkgroupEnabled,
  isPromptRemovingFromWorkgroupEnabled,
  isPromptSharingOneClickUxEnabled,
  isPromptLikingEnabled,
  onShareViaCopyLinkClicked,
  onShareViaEmailClicked,
  onShareToWorkgroupClicked,
  selectedGroupId,
  groups,
  setShareButtonTarget,
  isShowPromptAuthor,
  extensionIconMap,
  isPromptRingTagEnabled,
}) => {
  const styles = useStyles();
  const tabIndex = isCardFocused ? 0 : -1;
  const localizations = useStrings({
    CreatedByYou,
    SuggestedByMicrosoft,
  });
  const sharedWithYouLabel = useStringForPlaceholders(SharedWithYou);
  const extensionPromptLabel = useStringForPlaceholders(AgentName);
  let statusText = "";
  if (isWorkgroupPrompt(prompt)) {
    const publisher = (prompt as WorkgroupUserPrompt).Publisher;
    if (publisher && publisher.Name) {
      statusText = sharedWithYouLabel({
        publisherName: publisher.Name,
      });
    }
  } else if (isUserPrompt(prompt)) {
    statusText = localizations.CreatedByYou();
  } else if (isExtensionPrompt(prompt)) {
    const agentName =
      prompt.ProviderName ?? (prompt as UserExtensionPrompt).GptName ?? "";
    if (agentName) {
      statusText = extensionPromptLabel({
        agentName,
      });
    }
  } else if (isMicrosoftPrompt(prompt)) {
    statusText = localizations.SuggestedByMicrosoft();
  } else if (isTenantPrompt(prompt)) {
    statusText = prompt.ProviderName ?? "";
  }

  return (
    <CardFooter className={styles.footer}>
      <div className={styles.statusBar}>
        {(isPromptSavingEnabled || isShowPromptAuthor) && (
          <PromptAuthor
            prompt={prompt}
            tabIndex={tabIndex}
            extensionIconMap={extensionIconMap}
            appearance={appearance}
          />
        )}
        {isPromptRingTagEnabled && prompt.PromptLabel ? (
          <RingTag
            data-testid="prompt-label-tag"
            color="informative"
            size="small"
            appearance="outline"
            className={styles.promptRing}
          >
            <Text className={styles.promptRingText}>{prompt.PromptLabel}</Text>
          </RingTag>
        ) : (
          <Text
            data-testid="status-bar-text"
            className={styles.statusText}
            title={statusText}
          >
            {statusText}
          </Text>
        )}
      </div>
      <PromptCardActionButtons
        prompt={prompt}
        appearance={appearance}
        isFavoriteEnabled={isFavoriteEnabled}
        isBookmarking={isBookmarking}
        isFavorited={isFavorited}
        onFavorited={onFavorited}
        isDeleting={isDeleting}
        isRemoving={isRemoving}
        isPromptSavingEnabled={isPromptSavingEnabled}
        isPromptEditingEnabled={isPromptEditingEnabled}
        isPromptSharingEnabled={isPromptSharingEnabled}
        isPromptSharingViaEmailEnabled={isPromptSharingViaEmailEnabled}
        isPromptSharingToWorkgroupEnabled={isPromptSharingToWorkgroupEnabled}
        isPromptRemovingFromWorkgroupEnabled={
          isPromptRemovingFromWorkgroupEnabled
        }
        isPromptSharingOneClickUxEnabled={isPromptSharingOneClickUxEnabled}
        isPromptLikingEnabled={isPromptLikingEnabled}
        onShareViaCopyLinkClicked={onShareViaCopyLinkClicked}
        onShareViaEmailClicked={onShareViaEmailClicked}
        onShareToWorkgroupClicked={onShareToWorkgroupClicked}
        onDelete={onDelete}
        onEditToggled={onEditToggled}
        onEditSaved={onEditSaved}
        onRemoveFromWorkgroupClicked={onRemoveFromWorkgroupClicked}
        isEditing={isEditing}
        selectedGroupId={selectedGroupId}
        groups={groups}
        setShareButtonTarget={setShareButtonTarget}
        tabIndex={tabIndex}
      />
    </CardFooter>
  );
};
