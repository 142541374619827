import type { Prompt } from "../data";
const MAX_TITLE_LENGTH = 35;

//TODO: This should be tackled via css rather than hardcoded: https://office.visualstudio.com/MAX/_workitems/edit/9305494
export const generateTitleFromPrompt = (prompt: Prompt): string => {
  if (!prompt) return "";
  const maxTitleLength = MAX_TITLE_LENGTH;

  if (prompt.DisplayText.length <= maxTitleLength) return prompt.DisplayText;

  // Use -3 because we need to leave room for the ellipsis
  const title = prompt.DisplayText.substring(0, MAX_TITLE_LENGTH - 3);
  const lastSpaceIndex = title.lastIndexOf(" ");

  if (lastSpaceIndex === -1) {
    // No spaces found; return the title as is, but trimmed to the max length
    return `${title}...`;
  }
  return `${title.substring(0, lastSpaceIndex)}...`;
};
