import { declareString } from "@1js/localization";

export const DiscoverLink = declareString("promptLibrary.discover", {
  text: "Discover",
  comment: `Text that appears as a tab within secondary header under shell suite. Clicking on this will redirect user to Copilot Capabilities page.`,
});

export const ExploreProductsLink = declareString(
  "promptLibrary.exploreProducts",
  {
    text: "Explore in products",
    comment: `Text that appears as a tab within secondary header under shell suite. Clicking on this will open a drop-down menu which opens a list of links representing product-specific Copilot help links.`,
  }
);

export const WhatsNew = declareString("promptLibrary.whatsNew", {
  text: "What's new",
  comment: `Text that appears as a tab within secondary header under shell suite. Clicking on this will redirect user to Updates for Copilot page.`,
});

export const GettingStarted = declareString("promptLibrary.gettingStarted", {
  text: "Getting started",
  comment: `Text that appears as a tab within secondary header under shell suite. Clicking on this will open a drop-down menu which opens a list of links representing different uses of Copilot.`,
});
