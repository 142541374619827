/* eslint-disable @rnx-kit/no-const-enum */
import type { IPublicClientApplication } from "@azure/msal-browser";
import { FeedbackPolicyValue } from "@ms/inapp-feedback-loader";
import { buildCoreHeaders } from "../utils/header";

export type TenantType = "gcc" | "public";

export const OcpsLocalStorageKey = "CopilotLab_OcpsUserPolicies";
export const CheckInIntervalHeaderName = "CheckInInterval";

export interface OcpsResponse {
  policiesHash: string;
  value: {
    id: string;
    policiesPayload: {
      key: string;
      type: string;
      settingId: string;
      platform: string;
      enabled: string;
      value: string;
    }[];
  }[];
}

export interface OcpsSettings {
  ocpsResponse: OcpsResponse;
  expiryTimestamp: number;
}

export const enum OcpsSettingID {
  /**
   * OCPS setting id for SendFeedback
   */
  SEND_FEEDBACK_SETTING_ID = "office16;L_SendFeedback",

  /**
   * OCPS setting id for EmailCollection
   */
  EMAILCOLLECTION_SETTING_ID = "office16;L_EmailCollection",

  /**
   * OCPS setting id for SendSurvey
   */
  SEND_SURVEY_SETTING_ID = "office16;L_SendSurvey",

  /**
   * OCPS setting id for Screenshot
   */
  SCREENSHOT_SETTING_ID = "office16;L_Screenshot",

  /**
   * OCPS setting id for LogCollection
   */
  LOG_COLLECTION_SETTING_ID = "office16;L_LogCollection",

  /**
   * OCPS setting id for ConnectedExperiences
   */
  CONNECTED_EXPERIENCES_SETTING_ID = "office16;L_ConnectedOfficeExperiences",
}

export async function fetchUserOcpsSettings(
  policiesHash?: string,
  instance?: IPublicClientApplication
): Promise<OcpsResponse | undefined> {
  let cachedOcpsSettings: OcpsSettings | undefined = undefined;

  try {
    cachedOcpsSettings = JSON.parse(
      localStorage.getItem(OcpsLocalStorageKey) as string
    );
  } catch (_) {
    // ignore the exception if the key cannot be deserialized
  }

  if (cachedOcpsSettings && cachedOcpsSettings.expiryTimestamp > Date.now()) {
    return cachedOcpsSettings.ocpsResponse;
  }

  const headers = await buildCoreHeaders(instance);
  const response = await fetch(
    `/api/privacy/getocpssettings?policiesHash=${policiesHash ?? ""}`,
    {
      method: "GET",
      headers,
    }
  );

  const responseJson = await response.json();
  const checkInInterval = response.headers.get(CheckInIntervalHeaderName);

  const ocpsSettings: OcpsSettings = {
    ocpsResponse: responseJson,
    expiryTimestamp: new Date(
      Date.now() + parseInt(checkInInterval as string, 10) * 60 * 1000
    ).getTime(),
  };

  localStorage.setItem(OcpsLocalStorageKey, JSON.stringify(ocpsSettings));

  if (response.status === 200) {
    return responseJson;
  }
}

function handleTenantSpecificLogic(
  settingValue: FeedbackPolicyValue,
  tenantType: TenantType
): FeedbackPolicyValue {
  if (
    settingValue === FeedbackPolicyValue.NotConfigured &&
    tenantType === "gcc"
  ) {
    // For GCC we need to treat unconfigured the same as disabled.
    return FeedbackPolicyValue.Disabled;
  }
  return settingValue;
}

export async function getUserOcpsSetting(
  ocpsResponse: OcpsResponse,
  ocpsSettingID: OcpsSettingID,
  tenantType: TenantType
) {
  const settingValue = await getUserOcpsSettingInner(
    ocpsResponse,
    ocpsSettingID
  );
  return handleTenantSpecificLogic(settingValue, tenantType);
}

async function getUserOcpsSettingInner(
  ocpsResponse: OcpsResponse,
  ocpsSettingID: OcpsSettingID
): Promise<FeedbackPolicyValue> {
  if (!ocpsResponse?.value.length) {
    return FeedbackPolicyValue.NotConfigured;
  }

  for (const policy of ocpsResponse.value) {
    for (const setting of policy.policiesPayload) {
      if (setting.settingId === ocpsSettingID)
        return getFeedbackPolicyValue(setting.value);
    }
  }

  return FeedbackPolicyValue.NotConfigured;
}

function getFeedbackPolicyValue(policyValue: string): FeedbackPolicyValue {
  switch (policyValue?.toString()) {
    case "0":
      return FeedbackPolicyValue.Disabled;
    case "1":
      return FeedbackPolicyValue.Enabled;
    case "2":
      return FeedbackPolicyValue.Disabled;
    default:
      return FeedbackPolicyValue.NotConfigured;
  }
}
