import { declareString } from "@1js/localization";

export const AllPromptsHeading = declareString(
  "promptLibrary.allPromptsHeading",
  {
    text: "Prompts to try",
    comment:
      "Title that appears above all the prompts that the user can interact with. 'prompts' is the text input provided to AI model like GPT",
  }
);
