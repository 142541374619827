import { keys, memoizeFunction } from "@1js/functional";
import { isDev, isTest } from "./environmentUtils";
import type { SupportedLocale } from "./supportedLocales";

/**
 * A map from string resource keys to localized strings (for some specific locale).
 */
export type StringMap = { readonly [key in string]: string | undefined };

/**
 * A string map where values may be nested objects.
 */
export type NestedStringMap = {
  readonly [key in string]: string | undefined | NestedStringMap;
};

/**
 * An async function to load a string map for a given locale.
 */
export type StringMapLoader = (
  locale: SupportedLocale
) => Promise<StringMap | NestedStringMap>;

export type ErrorHandler = (
  error: string | Error,
  context?: StringErrorContext
) => void;

export type StringErrorContext = {
  stringsLoaded: boolean;
  locale?: SupportedLocale;
};

export type StringProvider = {
  strings: StringMap;
  errorHandler: ErrorHandler;
  currentLocale: SupportedLocale | undefined;
  loaders: StringMapLoader[];
  stringMapInitialized?: boolean;
  suppressEmptyStringMapErrors?: boolean;
};

export function setLocalizationErrorHandler(
  errorHandler: ErrorHandler,
  stringProvider: StringProvider
): StringProvider {
  return {
    ...stringProvider,
    errorHandler,
  };
}

export function handleLocalizationError(
  error: string | Error,
  stringProvider: StringProvider
): void {
  stringProvider.errorHandler(error);
}

const makeStringMapLoader = memoizeFunction(
  (locale: SupportedLocale, stringMap: StringMap | NestedStringMap) => {
    const flatStringMap = flattenStringMap(stringMap);

    return (localeToLoad: SupportedLocale) =>
      Promise.resolve(locale === localeToLoad ? flatStringMap : {});
  }
);

/**
 * Adds a string map for a given locale.
 */
export function addStringMap(
  locale: SupportedLocale,
  stringMap: StringMap | NestedStringMap,
  stringProvider: StringProvider
): StringProvider {
  // If the locale matches the current locale, we add the strings synchronously.
  if (
    stringProvider.currentLocale === locale ||
    stringProvider.currentLocale === undefined
  ) {
    const flatStringMap = flattenStringMap(stringMap);

    return {
      ...stringProvider,
      currentLocale:
        stringProvider.currentLocale === undefined
          ? locale
          : stringProvider.currentLocale,
      strings: { ...stringProvider.strings, ...flatStringMap },
      stringMapInitialized: true,
    };
  }

  // Add an async loader to load the strings if we later switch to that locale.
  return addStringMapLoader(
    makeStringMapLoader(locale, stringMap),
    stringProvider
  );
}

/**
 * Adds a loader that knows how to asynchronously load string maps for various
 * locales.
 *
 * @param loader Callback function to asynchronously load a string map for a
 *               given locale.
 */
export function addStringMapLoader(
  loader: StringMapLoader,
  stringProvider: StringProvider
): StringProvider {
  if (stringProvider.loaders.indexOf(loader) === -1) {
    return {
      ...stringProvider,
      loaders: stringProvider.loaders.concat(loader),
    };
  }

  return stringProvider;
}

/**
 * Gets the current locale, or undefined if no locale has been set yet. This is
 * an async operation, since an async locale switch might be in progress.
 */
export function getCurrentLocale(
  stringProvider: StringProvider
): Promise<SupportedLocale | undefined> {
  if (!nextLocalePromise) {
    return Promise.resolve(stringProvider.currentLocale);
  } else {
    return nextLocalePromise.then((provider) => provider.currentLocale);
  }
}

let nextLocalePromise: Promise<StringProvider> | undefined;

/**
 * Sets the current locale, and invokes all registered string map loaders to
 * load strings for the new locale.
 *
 * @param locale The locale to load.
 */
export function setCurrentLocale(
  locale: SupportedLocale,
  stringProvider: StringProvider
): Promise<StringProvider> {
  const promise = loadStringMapForLocale(locale, stringProvider).then(
    (stringMap) => {
      return {
        ...stringProvider,
        strings: stringMap,
        currentLocale: locale,
        stringMapInitialized: true,
      };
    }
  );

  if (nextLocalePromise) {
    return (nextLocalePromise = nextLocalePromise.then(() => promise));
  } else {
    return (nextLocalePromise = promise);
  }
}

async function loadStringMapForLocale(
  locale: SupportedLocale,
  stringProvider: StringProvider
): Promise<StringMap> {
  const tryLoader = async (loader: StringMapLoader) => {
    try {
      return await loader(locale);
    } catch (error: any) {
      const message = error.message || "unknown error";
      stringProvider.errorHandler(`Async string map loader failed: ${message}`);
      return {};
    }
  };

  const promises = stringProvider.loaders.map(tryLoader);
  const maps = await Promise.all(promises);
  return maps
    .map(flattenStringMap)
    .reduce((a: StringMap, b: StringMap): StringMap => ({ ...a, ...b }), {});
}

/**
 * Clears all string maps and string map loaders that have been added,
 * and resets the current locale to undefined.
 */
export async function clearStringMapLoaders(
  stringProvider: StringProvider
): Promise<StringProvider> {
  if (nextLocalePromise) {
    await nextLocalePromise;
  }

  nextLocalePromise = undefined;

  return {
    ...stringProvider,
    loaders: [],
    strings: {},
    currentLocale: undefined,
  };
}

export function flattenStringMap(nestedStringMap: NestedStringMap): StringMap {
  const result: { [key: string]: string | undefined } = {};

  function traverse(keyPrefix: string, strings: NestedStringMap): void {
    for (const key of keys(strings)) {
      if (typeof key === "string") {
        const value = strings[key];
        if (typeof value === "string") {
          result[keyPrefix + key] = value;
        } else if (typeof value === "object") {
          traverse(keyPrefix + key + ".", value);
        }
      }
    }
  }

  if (nestedStringMap) {
    traverse("", nestedStringMap);
  }

  return result;
}

/**
 * Returns true iff a string with the given key exists in the currently loaded
 * string maps.
 */
export function stringExists(
  key: string,
  stringProvider: StringProvider
): boolean {
  return typeof stringProvider.strings[key] === "string";
}

/**
 *
 * @param obj: Object
 * @returns boolean
 * Checks if an object is empty
 */
function isEmptyObject(obj: Object): boolean {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
}

/**
 * Looks for a string with the given key in the currently loaded string maps. If
 * there is no such string, the key is returned as a fallback value in Dev, else emptyString.
 */
export function lookUpString(
  key: string,
  stringProvider: StringProvider
): string {
  const value = stringProvider.strings[key];

  if (!value || typeof value !== "string") {
    const fallbackString = isDev() || isTest() ? key : "";

    if (isEmptyObject(stringProvider.strings)) {
      !stringProvider.suppressEmptyStringMapErrors &&
        stringProvider.errorHandler(
          `The current string map is empty. The string is probably resolved too early, at import time. Key: ${key}`
        );
    } else {
      stringProvider.errorHandler(`Key ${key} does not exist in string map`, {
        stringsLoaded: !!stringProvider.stringMapInitialized,
      });
    }

    return fallbackString;
  }

  return value;
}
