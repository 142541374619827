import {
  declareString,
  declareStringWithPlaceholders,
} from "@1js/localization";
export const CreatedByYou = declareString(
  "promptLibrary.promptCardFooter.CreatedByYou",
  {
    text: "You created this",
    comment:
      "Message displayed at the bottom of a Copilot prompt card to indicate that the user is the author of the prompt.",
  }
);

export const SuggestedByMicrosoft = declareString(
  "promptLibrary.promptCardFooter.SuggestedByMicrosoft",
  {
    text: "Microsoft suggested this",
    comment:
      "Message displayed at the bottom of a Copilot prompt card to indicate that Microsoft is the author of the prompt.",
  }
);

export const SharedWithYou = declareStringWithPlaceholders(
  "promptLibrary.promptCardFooter.SharedWithYou",
  {
    text: ({ publisherName }) => `${publisherName} shared this with you`,
    comment:
      "Message displayed at the bottom of a Copilot prompt card to indicate who shared the prompt with the user. The overall message can look like this 'Robert shared this with you'.",
    placeholders: {
      publisherName: "the name of the person who shared the prompt with you",
    },
  }
);

export const AgentName = declareStringWithPlaceholders(
  "promptLibrary.promptCardFooter.AgentName",
  {
    text: ({ agentName }) => `${agentName} Agent`,
    comment:
      "Message displayed at the bottom of a Copilot prompt card to indicate which Copilot agent the prompt is designed for. The overall message can look like this 'Idea Coach Agent'.",
    placeholders: {
      agentName:
        "the name of the Copilot agent for which the prompt is designed",
    },
  }
);
