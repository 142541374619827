/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { type Prompt } from "@1js/pl-card-components";
import type { IPublicClientApplication } from "@azure/msal-browser";
import {
  FeedbackHostPlatformType,
  FeedbackUiType,
  openFeedback as inAppOpenFeedback,
  type IFeedbackInitOptions,
} from "@ms/inapp-feedback-loader";
import { getFeedbackTelemetry } from "../utils/Telemetry/utils";
import {
  getAgeGroup,
  getAuthenticationType,
  getComplianceChecks,
} from "./compliance";

declare global {
  interface Window {
    setFeedbackContextData: (contextData: Prompt) => void;

    initializeFeedback: (
      centroHostname: string,
      language: string
    ) => Promise<void>;

    openFeedback: (
      isProduction: boolean,
      appId: number,
      userEmail: string,
      authType: string,
      userId: string,
      tenantId: string,
      ageGroup: string
    ) => Promise<void>;
  }
}

/**
 * Additional contextual data attached to OCV feedback items
 * This variable holders either the last clicked Prompt on the homepage, or
 * the prompt details if the user is on the prompt details page.
 */
let feedbackContextData: Prompt | undefined = undefined;

/**
 * Set optional context data for OCV feedback items
 * @param contextData Last clicked prompt, or prompt details if the user is on prompt detail
 */
window.setFeedbackContextData = function (contextData: Prompt) {
  if (!contextData) return;
  feedbackContextData = contextData;
};

/**
 * Open OCV feedback modal
 */
export async function openFeedback(
  isProduction: boolean,
  appId: number,
  userEmail: string,
  authType: string,
  userId: string,
  tenantId: string,
  ageGroup: string,
  theme?: string,
  msalInstance?: IPublicClientApplication
) {
  const authenticationType = getAuthenticationType(authType);
  const feedbackOptions: IFeedbackInitOptions = {
    appId,
    authenticationType,
    userId,
    tenantId,
    isProduction,
    feedbackConfig: {
      diagnosticsConfig: {
        isDiagnosticsIncluded: true,
        isShareContextDataEnabled: feedbackContextData !== undefined,
        isHostManagedContextDataEnabled: feedbackContextData !== undefined,
        getContextData: () => {
          if (!feedbackContextData)
            return new Promise((resolve) => resolve([]));

          const promptDetailsFile = new File(
            [
              JSON.stringify({
                CommandText: feedbackContextData.CommandText,
                PromptId: feedbackContextData.PromptId,
              }),
            ],
            "promptDetails.txt",
            {
              type: "text/plain",
            }
          );
          return new Promise((resolve) => resolve([promptDetailsFile]));
        },
      },
      isEmailCollectionEnabled: true,
      isFeedbackForumEnabled: false,
      isFileUploadEnabled: true,
      // TODO: Enable screenshot and screen recording when iframe within MetaOs allows for display-capture
      isScreenshotEnabled: false,
      isMyFeedbackEnabled: true,
      myFeedbackUrl: "https://feedbackportal.microsoft.com/feedback/mycontent/",
      isScreenRecordingEnabled: false,
      isThankYouPageDisabled: false,
      displayAdditionalInformationEnabled: false,
      hostPlatform: FeedbackHostPlatformType.Web,
      email: userEmail,
      isDisplayed: true,
      feedbackUiType: FeedbackUiType.SidePane,
      complianceChecks: await getComplianceChecks(
        authenticationType,
        msalInstance
      ),
    },
    ageGroup: getAgeGroup(parseInt(ageGroup, 10)),
    telemetry: getFeedbackTelemetry(),
    themeOptions: {
      baseTheme: theme,
    },
  };

  inAppOpenFeedback(feedbackOptions);
}
