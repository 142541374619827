import type { UserExtensionPrompt } from "@1js/pl-types";
import { createFluentIcon } from "@fluentui/react-icons";
import React from "react";
import type { ExtensionPrompt } from "../data";

export type ExtensionIconProps = {
  prompt: ExtensionPrompt | UserExtensionPrompt;
  extensionIconMap: Record<string, string> | undefined;
  className: string;
};

const ExtensionIconDefault = createFluentIcon("ExtensionIconDefault", "20", [
  "M 4.5 17 A 1.5 1.5 0 0 1 3 15.65 V 4.5 c 0 -0.78 0.6 -1.42 1.36 -1.5 H 9 c 0.78 0 1.42 0.6 1.5 1.36 v 0.4 l 2.19 -2.27 a 1.5 1.5 0 0 1 2 -0.14 l 0.12 0.1 l 2.76 2.72 c 0.55 0.55 0.6 1.42 0.11 2.02 l -0.1 0.11 l -2.31 2.2 h 0.23 c 0.78 0 1.42 0.6 1.5 1.36 v 4.64 c 0 0.78 -0.6 1.42 -1.36 1.5 H 4.5 Z m 5 -6.5 H 4 v 5 c 0 0.22 0.14 0.4 0.33 0.47 l 0.08 0.02 l 0.09 0.01 h 5 v -5.5 Z m 6 0 h -5 V 16 h 5 a 0.5 0.5 0 0 0 0.5 -0.4 V 11 a 0.5 0.5 0 0 0 -0.41 -0.5 h -0.09 Z m -5 -2.79 V 9.5 h 1.79 L 10.5 7.71 Z M 9 4.01 H 4.5 a 0.5 0.5 0 0 0 -0.5 0.4 v 5.1 h 5.5 v -5 a 0.5 0.5 0 0 0 -0.33 -0.48 l -0.08 -0.02 H 9 Z m 5.12 -0.84 a 0.5 0.5 0 0 0 -0.64 -0.05 l -0.07 0.06 l -2.62 2.71 a 0.5 0.5 0 0 0 -0.05 0.63 l 0.06 0.07 l 2.61 2.62 c 0.17 0.17 0.43 0.2 0.62 0.07 l 0.08 -0.06 l 2.76 -2.63 a 0.5 0.5 0 0 0 0.05 -0.64 l -0.05 -0.06 l -2.75 -2.72 Z",
]);
export const ExtensionIcon: React.FC<{
  prompt: ExtensionPrompt | UserExtensionPrompt;
  extensionIconMap: Record<string, string> | undefined;
  className: string;
}> = ({ prompt, extensionIconMap, className }) => {
  const gptId =
    (prompt as ExtensionPrompt).ProviderId ??
    (prompt as UserExtensionPrompt).GptId;
  const extensionIcon = extensionIconMap && gptId && extensionIconMap[gptId];

  return extensionIcon ? (
    <img
      src={extensionIcon}
      data-testid="extension-icon"
      className={className}
      alt={prompt.ProviderName ?? (prompt as UserExtensionPrompt).GptName}
    />
  ) : (
    <ExtensionIconDefault
      data-testid="extension-icon-default"
      className={className}
    />
  );
};
