import { Converter } from "showdown";

const HIDDEN_INPUT_ID = "MidgardClipboardUtilsHiddenInput";

export const isHiddenInput = (element: Element): boolean =>
  element.id === HIDDEN_INPUT_ID;

export const copyTextToClipboard = (
  textToCopy: string,
  ownerDocument?: Document,
  clipboardAreaContainer?: HTMLElement
): Promise<boolean> => {
  const documentForClipboard = ownerDocument || document;

  // Browser support for navigator.clipboard is limited
  if (navigator.clipboard) {
    return navigator.clipboard
      .writeText(textToCopy)
      .then(() => true)
      .catch(() =>
        copyTextToClipboardFallback(
          textToCopy,
          documentForClipboard,
          clipboardAreaContainer
        )
      );
  }

  return copyTextToClipboardFallback(
    textToCopy,
    documentForClipboard,
    clipboardAreaContainer
  );
};

export const copyHtmlAndTextToClipboard = async (
  textToCopy: string | Promise<string>,
  htmlToCopy: string | Promise<string>,
  ownerDocument?: Document,
  clipboardAreaContainer?: HTMLElement
): Promise<boolean> => {
  const documentForClipboard = ownerDocument || document;
  // We need to pass the promises directly to ClipboardItem to support Safari
  const textPromise = Promise.resolve(textToCopy);
  const htmlPromise = Promise.resolve(htmlToCopy);
  if (navigator.clipboard) {
    try {
      // ClipboardItem and writeText is not supported in FireFox
      if (typeof ClipboardItem !== "undefined") {
        const data = [
          new ClipboardItem({
            ["text/plain"]: textPromise.then(
              (text) => new Blob([text], { type: "text/plain" })
            ),
            ["text/html"]: htmlPromise.then(
              (html) => new Blob([html], { type: "text/html" })
            ),
          }),
        ];
        await navigator.clipboard.write(data);
      } else {
        await navigator.clipboard.writeText(await textPromise);
      }
      return true;
    } catch {
      return copyTextToClipboardFallback(
        await textPromise,
        documentForClipboard,
        clipboardAreaContainer
      );
    }
  }

  return copyTextToClipboardFallback(
    await textPromise,
    documentForClipboard,
    clipboardAreaContainer
  );
};

export const copyMarkdownTextToClipboard = async (
  textToCopy: string | Promise<string>,
  ownerDocument?: Document,
  clipboardAreaContainer?: HTMLElement
): Promise<boolean> => {
  const documentForClipboard = ownerDocument || document;
  const converter = new Converter({
    tables: true,
    disableForced4SpacesIndentedSublists: true,
  });
  // We need to pass the promises directly to ClipboardItem to support Safari
  const textPromise = Promise.resolve(textToCopy);
  const htmlPromise = new Promise<string>((resolve) =>
    textPromise.then((text) => resolve(converter.makeHtml(text)))
  );
  if (navigator.clipboard) {
    try {
      // ClipboardItem and writeText is not supported in FireFox
      if (typeof ClipboardItem !== "undefined") {
        const data = [
          new ClipboardItem({
            ["text/plain"]: textPromise.then(
              (text) => new Blob([text], { type: "text/plain" })
            ),
            ["text/html"]: htmlPromise.then(
              (html) => new Blob([html], { type: "text/html" })
            ),
          }),
        ];
        await navigator.clipboard.write(data);
      } else {
        await navigator.clipboard.writeText(await textPromise);
      }
      return true;
    } catch {
      return copyTextToClipboardFallback(
        await textPromise,
        documentForClipboard,
        clipboardAreaContainer
      );
    }
  }

  return copyTextToClipboardFallback(
    await textPromise,
    documentForClipboard,
    clipboardAreaContainer
  );
};

// Fall-back method when navigator clipboard is not supported or there was an error
// execCommand is now obsolete https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
const copyTextToClipboardFallback = (
  textToCopy: string,
  ownerDocument: Document,
  clipboardAreaContainer?: HTMLElement
): Promise<boolean> => {
  /* Remember the initial state. */
  const initiallyFocusedElement = ownerDocument.activeElement as HTMLElement;

  /* Create a non-visible textarea element. Need to be textarea instead of input to support
   * multiline text (e.g. for Address).
   * */
  const textarea = ownerDocument.createElement("textarea");
  textarea.tabIndex = -1;
  textarea.readOnly = true;
  textarea.style.opacity = "0";
  textarea.style.position = "fixed";
  textarea.style.right = "-9999px";
  textarea.style.bottom = "-9999px";
  textarea.id = HIDDEN_INPUT_ID;

  const container = ownerDocument.createElement("div");

  /*
   * OWA (and possibly other workloads) capture this event in the bubble phase,
   * disabling text selection. Preventing propagation of this event enables us to
   * select text.
   */
  container.onselectstart = (event) => event.stopPropagation();

  /* Add the input element, focus and select it. */
  container.appendChild(textarea);

  const textAreaParent = clipboardAreaContainer || ownerDocument.body;
  textAreaParent.appendChild(container);
  textarea.value = textToCopy;
  textarea.focus();
  textarea.select();

  /*
   * Try to execute the copy command. This can fail either because the browser does not support
   * copying to the clipboard, or because it is configured to disallow it. Exceptions are thus
   * expected here and we just return false if that happens, letting the caller deal with it.
   */
  let success: boolean;
  try {
    success = ownerDocument.execCommand("copy");
  } catch (error) {
    success = false;
  }

  /* Clean up and restore the initial state. */
  if (initiallyFocusedElement && initiallyFocusedElement.focus) {
    initiallyFocusedElement.focus();
  }

  textAreaParent.removeChild(container);

  return Promise.resolve(success);
};
