import { useStrings } from "@1js/localization-components";
import type { ButtonProps } from "@fluentui/react-components";
import { Spinner, ToggleButton, Tooltip } from "@fluentui/react-components";
import {
  BookmarkFilled,
  bundleIcon,
  DeleteFilled,
  DeleteRegular,
} from "@fluentui/react-icons";
import * as React from "react";
import type { Appearance } from "../../data";
import { DeletingLabel, RemoveTooltip } from "./PromptDelete.strings";
import { useStyles } from "./PromptDelete.styles";

export type PromptDeleteProps = {
  appearance?: Appearance;
  isDeleting?: boolean;
  onDelete?: () => void;
  tabIndex?: number;
  isPromptLikingEnabled?: boolean;
};

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const PromptDelete: React.FC<PromptDeleteProps> = ({
  appearance,
  isDeleting,
  onDelete,
  tabIndex,
  isPromptLikingEnabled,
}) => {
  const styles = useStyles(appearance)();
  const localizations = useStrings({
    RemoveTooltip,
    DeletingLabel,
  });

  const buttonStyleProps: ButtonProps = {
    appearance: "transparent",
  };

  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <div className={styles.deletes}>
      <Tooltip content={localizations.RemoveTooltip()} relationship="label">
        <ToggleButton
          data-testid="delete-button"
          className={isDeleting ? styles.spinner : styles.delete}
          {...buttonStyleProps}
          size="small"
          onClick={handleOnClick}
          aria-busy={isDeleting}
          checked={!isPromptLikingEnabled}
          tabIndex={tabIndex}
        >
          {isDeleting ? (
            <Spinner
              size="tiny"
              labelPosition="after"
              label={localizations.DeletingLabel()}
            />
          ) : (
            <span className={styles.span}>
              {isPromptLikingEnabled ? (
                <DeleteIcon className={styles.icon} />
              ) : (
                <BookmarkFilled className={styles.icon} />
              )}
            </span>
          )}
        </ToggleButton>
      </Tooltip>
    </div>
  );
};
