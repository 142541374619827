import {
  makeStyles,
  shorthands,
  tokens,
  type GriffelStyle,
} from "@fluentui/react-components";
import { iconFilledClassName } from "@fluentui/react-icons";
import type { Appearance } from "../../data";

type Styles = {
  removes: GriffelStyle;
  spinner: GriffelStyle;
  remove: GriffelStyle;
  icon: GriffelStyle;
};

const defaultStyles: Styles = {
  removes: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "right",
    height: "24px",
    ...shorthands.gap("4px"),
  },

  spinner: {
    ...shorthands.border("0px"),
    ...shorthands.padding("0px"),
  },

  remove: {
    ...shorthands.border("0px"),
    ...shorthands.padding("0px"),
    minWidth: "24px",
    maxWidth: "24px",
  },

  icon: {
    width: "20px",
    height: "20px",
    fontSize: "20px",
    [`&.${iconFilledClassName}`]: {
      color: tokens.colorBrandForeground1,
    },
  },
};

const copilotLabStyles: Styles = {
  ...defaultStyles,
};

const copilotLabGoBoldStyles: Styles = {
  ...defaultStyles,

  removes: {
    ...defaultStyles.removes,
    height: "20px",
  },

  remove: {
    ...defaultStyles.remove,
    minWidth: "20px",
  },

  icon: {
    ...defaultStyles.icon,
    width: "16px",
    height: "16px",
    fontSize: "16px",
  },
};

const rockSteadyStyles: Styles = {
  ...defaultStyles,
  removes: {
    ...defaultStyles.removes,
    height: "32px",
  },

  remove: {
    ...defaultStyles.remove,
    minWidth: "32px",
  },

  icon: {
    ...defaultStyles.icon,
    fontSize: "20px",
  },
};

const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const useRockSteadyStyles = makeStyles(rockSteadyStyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "web-v2":
    case "in-app-v2":
      return useCopilotLabGoBoldStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    default:
      return useCopilotLabStyles;
  }
};
