const LOCAL_STORAGE_USER_SESSION_KEY = "CopilotLab_UserSession";
export type UserSession = {
  sessionId: ReturnType<typeof generateSessionId>;
  expiryTimestamp: number;
};

export const getOrCreateUserSession = (
  sessionExpiryInDays = 30
): UserSession => {
  const existingUserSession = localStorage.getItem(
    LOCAL_STORAGE_USER_SESSION_KEY
  );

  if (existingUserSession) {
    const session = decodeSession(existingUserSession);

    if (session && session.expiryTimestamp > Date.now()) {
      return session;
    }
  }

  const newSessionId = generateSessionId();
  const newSession: UserSession = {
    sessionId: newSessionId,
    expiryTimestamp: Date.now() + 1000 * 60 * 60 * 24 * sessionExpiryInDays,
  };

  localStorage.setItem(
    LOCAL_STORAGE_USER_SESSION_KEY,
    encodeSession(newSession)
  );
  return newSession;
};

export const generateSessionId = () => window.crypto.randomUUID();

export const encodeSession = (session: UserSession): string =>
  JSON.stringify(session);

export const decodeSession = (
  encodedSession: string
): UserSession | undefined => {
  try {
    return JSON.parse(encodedSession);
  } catch (_) {
    return undefined;
  }
};
