// Copyright (C) Microsoft Corporation. All rights reserved.

import { STKAction } from "@commonschema/oteljs-schema-pu/dist/MsProductUsageSTKAction";
import {
  makeBooleanDataField,
  makeInt64DataField,
  makeStringDataField,
  type DataField,
} from "@microsoft/oteljs";
import { ActionInputMethods, ActionTarget, FeatureActionType } from "./config";
import type { Events } from "./events";

/**
 * Given a dictionary, create an array of Otel DataFields
 * @param dictionary Input dictionary
 * @returns DataField array
 */
export function dictionaryToDataFields<
  V = string | boolean | number | Record<string, string | string[]>,
>(dictionary: { [key: string]: V }): DataField[] {
  if (!dictionary) return [];

  const dataField = (key: string, val: V): DataField | undefined => {
    if (!key) return;

    key = key.charAt(0).toUpperCase() + key.slice(1);

    if (typeof val === "boolean") {
      return makeBooleanDataField(key, val);
    }
    if (typeof val === "string") {
      return makeStringDataField(key, val);
    }
    if (typeof val === "number") {
      // the "makeInt64DataField" method expects an int, so make sure we
      // always round the number before passing it to makeInt64DataField.
      return makeInt64DataField(key, Math.round(val));
    }

    return makeStringDataField(key, JSON.stringify(val));
  };

  return Object.entries(dictionary)
    .map(([k, v]) => dataField(k, v))
    .filter((x): x is DataField => x !== undefined);
}

/**
 * Required fields for GDPR requirements, these are known as Part B fields which is owned by the IDEAs team and
 * required for GDPR export and delete processing.
 * @param name Event name
 * @param isIntentional Indicates weather this action is an intentional action, e.g., MoveMessage that is automated
 * is not an intentional action
 * @param inputMethod NGP Export attributes. Examples are mouse, keyboard, track pad, controller, touch, etc
 * @param target Can pertain to, for example a document target destination path or a UX element that the action has occurred on
 * @param _featureActionType What's type of action represent for the feature?
 * @returns DataFields[]
 */
export function getPartBDataFields(
  name: keyof typeof Events,
  isIntentional = false,
  inputMethod?: ActionInputMethods,
  target?: ActionTarget,
  featureActionType?: FeatureActionType
): DataField[] {
  const actionData: STKAction.Contract = {
    name,
    featureName: name,
    isIntentional,
    isExportable: isIntentional, // we set the "isExportable" flag to true if user triggered the action
    inputMethod: inputMethod || ActionInputMethods.None,
    target: target || ActionTarget.None,
    featureActionType: featureActionType || FeatureActionType.IsOffered,
  };

  return STKAction.getFields(actionData);
}
