import { declareString } from "@1js/localization";

export const DeleteButtonTooltip = declareString(
  "promptLibrary.deleteButtonTooltip",
  {
    text: "Remove from saved",
    comment:
      "Tooltip that's displayed to user when user hovers over the delete button icon on a Copilot prompt card to delete it",
  }
);

export const RemoveTooltip = declareString("promptLibrary.removeTooltip", {
  text: "Remove",
  comment:
    "Tooltip that's displayed to user when user hovers over the delete button icon on a Copilot prompt card to delete it",
});

export const DeletingLabel = declareString("promptLibrary.deletingLabel", {
  text: "Deleting...",
  comment:
    "Label that's displayed next to spinner when user is deleting a Copilot prompt",
});
