import { useStrings } from "@1js/localization-components";
import { Button, Tooltip } from "@fluentui/react-components";
import { SettingsRegular } from "@fluentui/react-icons";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { ButtonRefContext } from "../../contexts/ButtonRefContext.web";
import { ManageSharedPromptsButtonRefId } from "../../contexts/utils/constants";
import {
  FailureStopShareToastMessage,
  ManageAccessDialogTitle,
  ManageAccessTooltip,
  SuccessStopShareToastMessage,
} from "./ManageSharedPrompts.strings";

export const ManageSharedPromptsButtonTestId = "manage-shared-prompts-button";

export const ManageSharedPromptsButton: React.FC = () => {
  const strings = useStrings({
    SuccessStopShareToastMessage,
    FailureStopShareToastMessage,
    ManageAccessDialogTitle,
    ManageAccessTooltip,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const { registerButtonReference } = React.useContext(ButtonRefContext);
  const refValue = registerButtonReference(ManageSharedPromptsButtonRefId);

  const openDialog = () => {
    searchParams.set("manageAccess", "open");
    setSearchParams(searchParams);
  };

  return (
    <Tooltip content={strings.ManageAccessTooltip()} relationship="label">
      <Button
        title={strings.ManageAccessTooltip()}
        aria-label={strings.ManageAccessTooltip()}
        data-testid={ManageSharedPromptsButtonTestId}
        appearance="transparent"
        icon={<SettingsRegular />}
        onClick={openDialog}
        ref={refValue}
      />
    </Tooltip>
  );
};
