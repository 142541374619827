import { useStrings } from "@1js/localization-components";
import { makeStyles, shorthands } from "@fluentui/react-components";
import * as React from "react";
import { HeaderDropDown } from "./HeaderDropDown.web";
import { HeaderLink } from "./HeaderLink";
import {
  DiscoverLink,
  ExploreProductsLink,
  GettingStarted,
  WhatsNew,
} from "./HeaderNav.web.string";

import { AllPromptsHeading } from "../../common/common.web.string";

import { appendOcidQueryParam } from "../../utils/ocid";
import type { HeaderNavBaseProps } from "./PageHeader.web.data";

const useStyles = makeStyles({
  main: {
    display: "inline-flex",
    alignItems: "flex-end",
    ...shorthands.borderRadius("var(--None, 0px)"),
  },
});

const isOnMainPage = window.location.pathname.endsWith("/prompts/all");
const mainPageUrl = `${window.location.origin}/prompts/all`;

export const HeaderNav: React.FC<HeaderNavBaseProps> = ({
  copilotProductLinks,
  gettingStartedLinks,
  whatsNewLink,
}) => {
  const styles = useStyles();
  const localizations = useStrings({
    DiscoverLink,
    ExploreProductsLink,
    AllPromptsHeading,
    WhatsNew,
    GettingStarted,
  });
  return (
    <div
      className={styles.main}
      data-testid="header-navbar"
      role="tablist"
      aria-owns="tab2 tab3 tab4 tab5"
    >
      <HeaderDropDown
        value="tab2"
        displayText={localizations.ExploreProductsLink()}
        linkDictionary={copilotProductLinks}
        telemetryParentId="HeaderNav"
      />
      <HeaderLink
        value="tab3"
        displayText={localizations.AllPromptsHeading()}
        url={appendOcidQueryParam(mainPageUrl, "CopilotLab_HM_PromptsToTry")}
        openInNewWindow={false}
        activeTab={isOnMainPage}
        testid="copilot-lab-tab"
      />
      <HeaderDropDown
        value="tab4"
        displayText={localizations.GettingStarted()}
        linkDictionary={gettingStartedLinks}
      />
      <HeaderLink
        value="tab5"
        displayText={localizations.WhatsNew()}
        url={whatsNewLink}
        openInNewWindow={true}
        testid="whats-new-tab"
      />
    </div>
  );
};
