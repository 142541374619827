import { useStrings } from "@1js/localization-components";
import type { PromptCategory } from "@1js/pl-types";
import type { ProductType } from "../data";
import {
  AdminLabel,
  AllLabel,
  AnalyzeCategoryLabel,
  AskCategoryLabel,
  AssistCategoryLabel,
  AutomateCategoryLabel,
  AzureLabel,
  BadgesGroupLabel,
  BrowseCategoryLabel,
  BusinessChatLabel,
  BusinessLeaderLabel,
  CatchUpCategoryLabel,
  CodeCategoryLabel,
  CollectCategoryLabel,
  CommunicationsLabel,
  ConsumerGoodsLabel,
  CopilotChatLabel,
  CopilotForMicrosoft365Label,
  CreateCategoryLabel,
  CreatedByAllLabel,
  CreatedByMeLabel,
  CreatedByMicrosoftLabel,
  CustomerServiceLabel,
  CustomizeCategoryLabel,
  DepartmentLabel,
  DesignCategoryLabel,
  EditCategoryLabel,
  EducationLabel,
  EnergyLabel,
  ExcelLabel,
  ExecuteCategoryLabel,
  FacultyLabel,
  FilterBusinessChatLabel,
  FilterCopilotChatWebLabel,
  FilterCopilotChatWorkLabel,
  FinanceLabel,
  FinancialServicesLabel,
  FindCatagoryLabel,
  FormsLabel,
  HomeUserLabel,
  HumanResourcesLabel,
  IndustryLabel,
  InformationTechnologyLabel,
  LearnCategoryLabel,
  LegalLabel,
  M365AdminCenterLabel,
  ManageCategoryLabel,
  ManufacturingLabel,
  MarketingLabel,
  MeLabel,
  MicrosoftLabel,
  MobilityLabel,
  NewsCategoryLabel,
  NonprofitLabel,
  OneDriveLabel,
  OneNoteLabel,
  OperateCategoryLabel,
  OptimizeCategoryLabel,
  OutlookLabel,
  PeopleCategoryLabel,
  PlannerLabel,
  PowerPointLabel,
  PrepareCategoryLabel,
  RetailLabel,
  SalesLabel,
  ScheduleCategoryLabel,
  SharePointLabel,
  StreamLabel,
  Student18Label,
  SummarizeCategoryLabel,
  SustainabilityLabel,
  TeamsLabel,
  TroubleshootCategoryLabel,
  UnderstandCategoryLabel,
  VideoTutorialLabel,
  VivaEngageLabel,
  VivaGoalsLabel,
  WindowsLabel,
  WordLabel,
} from "../sharedStrings";
import {
  AgentFilterLabel,
  AppFilterLabel,
  CreatedByFilterLabel,
  DisplayFilterLabel,
  ExtensionFilterLabel,
  JobTypeFilterLabel,
  SavedFilterLabel,
  TeamFilterLabel,
  WorkgroupFilterLabel,
} from "./PromptFilter.strings";
import type { Filter } from "./types";

export const usePromptFilterLocalizations = () => {
  const localizations = useStrings({
    AppFilterLabel,
    DisplayFilterLabel,
    OneDriveLabel,
    OneNoteLabel,
    OutlookLabel,
    ExcelLabel,
    WordLabel,
    PowerPointLabel,
    CopilotForMicrosoft365Label,
    BadgesGroupLabel,
    TeamsLabel,
    CodeCategoryLabel,
    CatchUpCategoryLabel,
    CreateCategoryLabel,
    AskCategoryLabel,
    EditCategoryLabel,
    LearnCategoryLabel,
    UnderstandCategoryLabel,
    SummarizeCategoryLabel,
    AnalyzeCategoryLabel,
    AssistCategoryLabel,
    AutomateCategoryLabel,
    BrowseCategoryLabel,
    CollectCategoryLabel,
    ExecuteCategoryLabel,
    FindCatagoryLabel,
    NewsCategoryLabel,
    PeopleCategoryLabel,
    PrepareCategoryLabel,
    CustomizeCategoryLabel,
    HomeUserLabel,
    BusinessLeaderLabel,
    AdminLabel,
    SharePointLabel,
    WindowsLabel,
    FormsLabel,
    PlannerLabel,
    M365AdminCenterLabel,
    StreamLabel,
    VivaEngageLabel,
    VivaGoalsLabel,
    JobTypeFilterLabel,
    ExtensionFilterLabel,
    DesignCategoryLabel,
    OptimizeCategoryLabel,
    TroubleshootCategoryLabel,
    OperateCategoryLabel,
    DepartmentLabel,
    IndustryLabel,
    HumanResourcesLabel,
    MarketingLabel,
    FinancialServicesLabel,
    RetailLabel,
    ManufacturingLabel,
    EducationLabel,
    FacultyLabel,
    Student18Label,
    SavedFilterLabel,
    MeLabel,
    AllLabel,
    MicrosoftLabel,
    CreatedByAllLabel,
    CreatedByMeLabel,
    CreatedByMicrosoftLabel,
    ScheduleCategoryLabel,
    ManageCategoryLabel,
    AzureLabel,
    VideoTutorialLabel,
    WorkgroupFilterLabel,
    AgentFilterLabel,
    CreatedByFilterLabel,
    TeamFilterLabel,
    FinanceLabel,
    SalesLabel,
    SustainabilityLabel,
    EnergyLabel,
    CustomerServiceLabel,
    NonprofitLabel,
    BusinessChatLabel,
    CopilotChatLabel,
    LegalLabel,
    MobilityLabel,
    ConsumerGoodsLabel,
    InformationTechnologyLabel,
    FilterBusinessChatLabel,
    FilterCopilotChatWorkLabel,
    FilterCopilotChatWebLabel,
    CommunicationsLabel,
  });

  const facetKeyToLabel = (facetKey: string, isMetaOsV2?: boolean): string => {
    switch (facetKey.toLowerCase()) {
      case "displaycategory":
        return localizations.DisplayFilterLabel();
      case "products/name":
        return localizations.AppFilterLabel();
      case "jobtype":
        return localizations.JobTypeFilterLabel();
      case "createdby":
        return isMetaOsV2
          ? localizations.CreatedByFilterLabel()
          : localizations.SavedFilterLabel();
      case "extension":
        return isMetaOsV2
          ? localizations.AgentFilterLabel()
          : localizations.ExtensionFilterLabel();
      case "workgroup":
        return isMetaOsV2
          ? localizations.TeamFilterLabel()
          : localizations.WorkgroupFilterLabel();
      default:
        return facetKey;
    }
  };

  const facetValueToLabel = (
    facetValue: ProductType | PromptCategory | string
  ): string => {
    switch (facetValue) {
      case "Word":
        return localizations.WordLabel();
      case "PowerPoint":
        return localizations.PowerPointLabel();
      case "Outlook":
        return localizations.OutlookLabel();
      case "Teams":
        return localizations.TeamsLabel();
      case "BizChat":
      case "CWCWithBizChatLabel":
        return localizations.CopilotChatLabel();
      case "NewBizChatFilter":
        return localizations.FilterCopilotChatWorkLabel();
      case "Copilot":
        return localizations.CopilotForMicrosoft365Label();
      case "OneDrive":
        return localizations.OneDriveLabel();
      case "OneNote":
        return localizations.OneNoteLabel();
      case "Excel":
        return localizations.ExcelLabel();
      case "Windows":
        return localizations.WindowsLabel();
      case "SharePoint":
        return localizations.SharePointLabel();
      case "Forms":
        return localizations.FormsLabel();
      case "M365AdminCenter":
        return localizations.M365AdminCenterLabel();
      case "Learn":
        return localizations.LearnCategoryLabel();
      case "Code":
        return localizations.CodeCategoryLabel();
      case "Catch up":
        return localizations.CatchUpCategoryLabel();
      case "Create":
        return localizations.CreateCategoryLabel();
      case "Ask":
        return localizations.AskCategoryLabel();
      case "Analyze":
        return localizations.AnalyzeCategoryLabel();
      case "Assist":
        return localizations.AssistCategoryLabel();
      case "Automate":
        return localizations.AutomateCategoryLabel();
      case "Browse":
        return localizations.BrowseCategoryLabel();
      case "Collect":
        return localizations.CollectCategoryLabel();
      case "Execute":
        return localizations.ExecuteCategoryLabel();
      case "Find":
        return localizations.FindCatagoryLabel();
      case "News":
        return localizations.NewsCategoryLabel();
      case "People":
        return localizations.PeopleCategoryLabel();
      case "Prepare":
        return localizations.PrepareCategoryLabel();
      case "Edit":
        return localizations.EditCategoryLabel();
      case "Understand":
        return localizations.UnderstandCategoryLabel();
      case "Summarize":
        return localizations.SummarizeCategoryLabel();
      case "Customize":
        return localizations.CustomizeCategoryLabel();
      case "HomeUser":
        return localizations.HomeUserLabel();
      case "BusinessLeader":
        return localizations.BusinessLeaderLabel();
      case "Admin":
        return localizations.AdminLabel();
      case "VideoTutorial":
        return localizations.VideoTutorialLabel();
      case "Planner":
        return localizations.PlannerLabel();
      case "Stream":
        return localizations.StreamLabel();
      case "VivaEngage":
        return localizations.VivaEngageLabel();
      case "VivaGoals":
        return localizations.VivaGoalsLabel();
      case "Design":
        return localizations.DesignCategoryLabel();
      case "Optimize":
        return localizations.OptimizeCategoryLabel();
      case "Troubleshoot":
        return localizations.TroubleshootCategoryLabel();
      case "Operate":
        return localizations.OperateCategoryLabel();
      case "Human Resources":
        return localizations.HumanResourcesLabel();
      case "Marketing":
        return localizations.MarketingLabel();
      case "Financial Services":
        return localizations.FinancialServicesLabel();
      case "Retail":
        return localizations.RetailLabel();
      case "Manufacturing":
        return localizations.ManufacturingLabel();
      case "CreatedByAll":
        return localizations.AllLabel();
      case "CreatedByMe":
        return localizations.MeLabel();
      case "Created by Me":
        return localizations.CreatedByMeLabel();
      case "CreatedByMicrosoft":
        return localizations.MicrosoftLabel();
      case "Schedule":
        return localizations.ScheduleCategoryLabel();
      case "Manage":
        return localizations.ManageCategoryLabel();
      case "AzureWeb":
      case "Azure":
        return localizations.AzureLabel();
      case "CWC":
        return localizations.FilterCopilotChatWebLabel();
      case "Faculty":
        return localizations.FacultyLabel();
      case "Student (18+)":
        return localizations.Student18Label();
      case "Finance":
        return localizations.FinanceLabel();
      case "Sales":
        return localizations.SalesLabel();
      case "Energy":
        return localizations.EnergyLabel();
      case "Sustainability":
        return localizations.SustainabilityLabel();
      case "Nonprofit":
        return localizations.NonprofitLabel();
      case "Customer Service":
        return localizations.CustomerServiceLabel();
      case "Legal":
        return localizations.LegalLabel();
      case "Information Technology":
        return localizations.InformationTechnologyLabel();
      case "Mobility":
        return localizations.MobilityLabel();
      case "Consumer Goods":
        return localizations.ConsumerGoodsLabel();
      case "Communications":
        return localizations.CommunicationsLabel();
      default:
        return facetValue;
    }
  };

  const selectedValueToLabel = (
    value: ProductType | PromptCategory | string
  ): string => {
    switch (value) {
      case "CreatedByAll":
        return localizations.CreatedByAllLabel();
      case "CreatedByMe":
        return localizations.CreatedByMeLabel();
      case "CreatedByMicrosoft":
        return localizations.CreatedByMicrosoftLabel();
      default:
        return facetValueToLabel(value);
    }
  };

  const groupValueToLabel = (groupValue: string): string => {
    switch (groupValue) {
      case "Department":
        return localizations.DepartmentLabel();
      case "Industry":
        return localizations.IndustryLabel();
      case "Education":
        return localizations.EducationLabel();
      default:
        return groupValue;
    }
  };

  const convertValuesToString = (filter: Filter, values?: string[]) => {
    if (!values || values.length == 0) {
      return "";
    }
    return values
      .map((value: string) => {
        const filterOption = filter.options.find(
          (option) => option.value === value
        );
        if (filterOption?.displayLabel) {
          return filterOption.displayLabel;
        }

        return selectedValueToLabel(value);
      })
      .join(", ");
  };

  return {
    facetKeyToLabel,
    facetValueToLabel,
    groupValueToLabel,
    selectedValueToLabel,
    convertValuesToString,
  };
};
