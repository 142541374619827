import { useEffect, useState } from "react";

function useMediaQuery(maxWidth: number) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = `(max-width: ${maxWidth}px)`;
    const mediaQueryList = window.matchMedia(mediaQuery);

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    setMatches(mediaQueryList.matches);
    mediaQueryList.addEventListener("change", handleMediaQueryChange);

    return () =>
      mediaQueryList.removeEventListener("change", handleMediaQueryChange);
  }, [maxWidth]);

  return matches;
}

export function useIsSmallScreen() {
  return useMediaQuery(540);
}

export function useIsMediumScreen() {
  return useMediaQuery(800);
}
