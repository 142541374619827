// Copyright (C) Microsoft Corporation. All rights reserved.

import type { IPublicClientApplication } from "@azure/msal-browser";
import type { DataField, TelemetryLogger } from "@microsoft/oteljs";
import type {
  ActionInputMethods,
  ActionTarget,
  FeatureActionType,
} from "./config";
import { dictionaryToDataFields, getPartBDataFields } from "./dataField";
import { getEventName, type Events } from "./events";
import getOrCreateTelemetryLogger from "./getTelemetryLogger";
import { getEcsConfigIds } from "./utils";

/**
 * Send Telemetry Event
 * @param eventName Event Name
 * @param dataFields Optional Otel Data Fields
 * @param isIntentional Indicates weather this action is an intentional action, e.g., PageInit that is automated
 * is not an intentional action.
 * @param actionInputMethod NGP Export attributes. Examples are mouse, keyboard, track pad, controller, touch, etc.
 * @param actionTarget Can pertain to, for example a document target destination path or a UX element that the action
 * @param featureActionType What's type of action represent for the feature?
 */
function sendTelemetryEvent(
  eventName: keyof typeof Events,
  dataFields?: {
    [key: string]:
      | string
      | boolean
      | number
      | Record<string, string | string[]>
      | undefined;
  },
  isIntentional?: boolean,
  actionInputMethod?: ActionInputMethods,
  actionTarget?: ActionTarget,
  featureActionType?: FeatureActionType,
  msalInstance?: IPublicClientApplication
) {
  const telemetryLogger: TelemetryLogger =
    getOrCreateTelemetryLogger(msalInstance);

  dataFields = dataFields || {};

  // Add ECS flight details
  dataFields["EcsConfigIds"] = getEcsConfigIds();

  const eventDataFields: DataField[] = [
    ...dictionaryToDataFields(dataFields),
    ...getPartBDataFields(
      eventName,
      isIntentional,
      actionInputMethod,
      actionTarget,
      featureActionType
    ),
  ];

  telemetryLogger.sendTelemetryEvent({
    eventName: getEventName(eventName),
    dataFields: eventDataFields,
    eventFlags: {
      // TODO: We should use the the following const enums from the @microsoft/oteljs package instead of manually setting the values
      // There is a TypeScript error (TS2748) that prevents us from doing so
      //
      //samplingPolicy: SamplingPolicy.NotSet,
      //costPriority: CostPriority.NotSet,
      //persistencePriority: PersistencePriority.NotSet,
      //diagnosticLevel: DiagnosticLevel.RequiredServiceData,
      //dataCategories: DataCategories.ProductServiceUsage,
      samplingPolicy: 0,
      costPriority: 0,
      persistencePriority: 0,
      diagnosticLevel: 110,
      dataCategories: 2,
    },
  });
}

export type TelemetrySenderType = {
  sendTelemetryEvent: (
    eventName: keyof typeof Events,
    dataFields?: {
      [key: string]:
        | string
        | boolean
        | number
        | Record<string, string | string[]>
        | undefined;
    },
    isIntentional?: boolean,
    actionInputMethod?: ActionInputMethods,
    actionTarget?: ActionTarget,
    featureActionType?: FeatureActionType,
    msalInstance?: IPublicClientApplication
  ) => void;
};

export const telemetrySender: TelemetrySenderType = {
  sendTelemetryEvent,
};
