import { useStrings } from "@1js/localization-components";
import type { Prompt } from "@1js/pl-types";
import type { ButtonProps } from "@fluentui/react-components";
import {
  Button,
  Spinner,
  Tooltip,
  mergeClasses,
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, bundleIcon } from "@fluentui/react-icons";
import * as React from "react";
import type { Appearance } from "../../data";
import { BookmarkRemovingLabel } from "./../PromptFavorite/PromptFavorite.strings";
import { RemoveButtonTooltip } from "./PromptRemove.strings";
import { useStyles } from "./PromptRemove.styles";

export type PromptRemoveProps = {
  appearance?: Appearance;
  onRemove?: (prompts: Prompt[], groupIds: string[]) => void;
  prompt: Prompt;
  groupId?: string;
  isRemoving: boolean;
  tabIndex?: number;
  className?: string;
};

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
export const PromptRemove: React.FC<PromptRemoveProps> = ({
  appearance,
  onRemove,
  prompt,
  groupId,
  isRemoving,
  tabIndex,
  className,
}) => {
  const styles = useStyles(appearance)();
  const localizations = useStrings({
    RemoveButtonTooltip,
    BookmarkRemovingLabel,
  });

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onRemove && groupId && onRemove([prompt], [groupId]);
    },
    [groupId, onRemove, prompt]
  );

  const buttonStyleProps: ButtonProps = {
    appearance: "transparent",
  };

  const isMemberOfGroup = React.useMemo(() => {
    return (
      prompt.Groups?.some(
        (group) => group.IsMember && group.Id === groupId && !!groupId
      ) || false
    );
  }, [prompt.Groups, groupId]);

  return isMemberOfGroup ? (
    <Tooltip content={localizations.RemoveButtonTooltip()} relationship="label">
      <Button
        className={mergeClasses(
          isRemoving ? styles.spinner : styles.remove,
          className
        )}
        {...buttonStyleProps}
        size="small"
        onClick={handleOnClick}
        data-testid="remove-button"
        aria-busy={isRemoving}
        tabIndex={tabIndex}
      >
        {isRemoving ? (
          <Spinner
            size="tiny"
            labelPosition="after"
            label={localizations.BookmarkRemovingLabel()}
            data-testid="remove-spinner"
          />
        ) : (
          <DeleteIcon className={styles.icon} />
        )}
      </Button>
    </Tooltip>
  ) : (
    <></>
  );
};
