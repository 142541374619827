import { isMetaOsHelper } from "../utils/url";

export class ApiError extends Error {
  response?: Response;

  constructor(response?: Response) {
    super(response?.statusText);
    this.response = response;
    this.name = this.constructor.name;
  }
}

/**
 * Redirects the user to the sign-in page
 */
export function redirectToSignIn(): void {
  const signInUrl = `/authentication/signin?ru=${encodeURIComponent(window.location.pathname)}`;
  window.location.href = signInUrl;
}

const appendOrUpdateQueryParameter = (
  targetUrl: string,
  param: string
): string => {
  const currentUrl = new URL(window.location.href);
  const currentValue = currentUrl.searchParams.get(param) || "";
  if (!currentValue) {
    return targetUrl;
  }

  const targetUrlObj = new URL(targetUrl);
  const targetUrlParams = targetUrlObj.searchParams;
  if (targetUrlParams.has(param)) {
    const existingValue = targetUrlParams.get(param);
    if (existingValue) {
      targetUrlParams.set(param, `${existingValue},${currentValue}`);
    }
  } else {
    targetUrlParams.append(param, currentValue);
  }
  targetUrlObj.search = targetUrlParams.toString();
  return targetUrlObj.toString();
};

/**
 * Sends a fetch request and handles authentication redirects
 * @param input
 * @param init
 * @returns
 */
export async function authAwareFetch(
  input: RequestInfo | URL,
  init?: RequestInit,
  isMetaOs = false
): Promise<Response> {
  const updatedUrl = appendOrUpdateQueryParameter(input.toString(), "sf");
  const response = await fetch(updatedUrl, {
    ...init,
    redirect: "manual",
  });

  isMetaOs = isMetaOsHelper();

  // Handle auth redirect responses (301/302)
  // This case can occur when the user is not logged in and tries to access an API that requires authentication
  if (response.redirected || response.type === "opaqueredirect") {
    // Do not redirect to sign in page for CWC eligibility check. CWC prompts are shown to users who are not signed in.
    if (!isMetaOs && !updatedUrl.includes("/promptsclient/eligibility/cwc")) {
      redirectToSignIn();
    }
    return response;
  }

  if (response.status === 401) {
    if (!isMetaOs) {
      redirectToSignIn();
    }
    return response;
  }

  // default case for non-200 responses
  if (!response.ok) {
    const error = new ApiError(response);
    throw error;
  }

  return response;
}

/**
 * Retries the given callback function given number of times
 * @param callback Callback function
 * @param retryCount Number of retries (default is 3 retries)
 * @returns The response from the callback function
 */
export async function retry<T>(
  callback: () => Promise<T>,
  retryCount = 3
): Promise<T> {
  try {
    return await callback();
  } catch (e) {
    if (retryCount <= 0) {
      throw e;
    }
    return await retry(callback, retryCount - 1);
  }
}
