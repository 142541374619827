/**
 * Returns the date in ISO format with milliseconds
 * @param date The date to convert
 */
export function getIsoTimeStringWithMs(date: Date): string {
  const timeZone = -date.getTimezoneOffset();
  const dif = timeZone >= 0 ? "+" : "-";
  return (
    date.getFullYear() +
    "-" +
    getPaddedString(date.getMonth() + 1) +
    "-" +
    getPaddedString(date.getDate()) +
    "T" +
    getPaddedString(date.getHours()) +
    ":" +
    getPaddedString(date.getMinutes()) +
    ":" +
    getPaddedString(date.getSeconds()) +
    "." +
    getPaddedString(date.getMilliseconds()) +
    dif +
    getPaddedString(timeZone / 60) +
    ":" +
    getPaddedString(timeZone % 60)
  );
}

function getPaddedString(num: number): string {
  const value = Math.floor(Math.abs(num));
  return (value < 10 ? "0" : "") + value;
}
