import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import React from "react";
import { useFlightContext } from "../../hooks/useFlightContext";
import { PropertyContext } from "../../pages/PropertyContext.web";

export type MsalWrapperProps = {
  children: React.ReactNode;
};

export const MsalWrapper = ({ children }: MsalWrapperProps) => {
  const [siteSetting] = React.useContext(PropertyContext);
  const { authentication } = siteSetting;
  const { isFlightActive } = useFlightContext();
  const msalConfig = {
    auth: {
      clientId: authentication?.clientId || "",
      redirectUri: authentication?.redirectUrl || "",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          case LogLevel.Error:
            console.error(message);
            return;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          case LogLevel.Info:
            console.info(message);
            return;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          case LogLevel.Verbose:
            console.debug(message);
            return;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  };
  const pca = new PublicClientApplication(msalConfig);

  return isFlightActive("isMsalEnabled") ? (
    <MsalProvider instance={pca}>{children}</MsalProvider>
  ) : (
    <>{children}</>
  );
};
MsalWrapper.defaultName = "MsalWrapper";
