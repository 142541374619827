import type { IPublicClientApplication } from "@azure/msal-browser";
import { createContext } from "react";

export type MetaOSAppContextProps = {
  /**
   * The appearance of the prompt card. Supported types are 'web', 'in-app', and 'm365'. If not provided, 'in-app' will be used.
   */
  isMosApp: boolean;
  pca: IPublicClientApplication | undefined;
  naaNotRecommended: boolean;
  userIsAuthenticated: boolean;
  categories: string[];
  products: string[];
  isMetaOsV2: boolean;
  updateContext: (props: MetaOSAppContextProps) => void;
};

export const MetaOSAppContext = createContext<MetaOSAppContextProps>({
  isMosApp: false,
  pca: undefined,
  naaNotRecommended: false,
  userIsAuthenticated: false,
  categories: [],
  products: [],
  isMetaOsV2: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateContext: () => {},
});
