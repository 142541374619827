import { useStrings } from "@1js/localization-components";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  ContentSettingsRegular,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import React from "react";
import { useFlightContext } from "../../hooks/useFlightContext";
import { AuthenticationPanel } from "./AuthenticationPanel.web";
import {
  DebugOverlayHeader,
  DebugOverlayToggleButtonLabel,
} from "./DebugPanel.web.string";
import { FlightPanel } from "./FlightPanel.web";

const useStyles = makeStyles({
  debugPane: {
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.border("2px", "solid", tokens.colorBrandBackground),
    ...shorthands.padding("10px"),
    position: "fixed",
    right: 0,
    top: "200px",
    width: "60px",
    zIndex: 1000,
  },
  authenticationPanel: {
    marginBottom: "20px",
  },
});

export const DebugPanel = () => {
  const styles = useStyles();
  const { isFlightActive } = useFlightContext();
  const [isDebugDrawerOpen, setIsDebugDrawerOpen] = React.useState(false);
  const toggleDebugDrawer = React.useCallback(() => {
    setIsDebugDrawerOpen((prev) => !prev);
  }, []);
  const localization = useStrings({
    DebugOverlayToggleButtonLabel,
    DebugOverlayHeader,
  });

  return isFlightActive("isDebugPanelEnabled") ? (
    <>
      <div className={styles.debugPane}>
        <Tooltip
          content={localization.DebugOverlayToggleButtonLabel()}
          relationship={"label"}
        >
          <Button
            onClick={toggleDebugDrawer}
            icon={<ContentSettingsRegular />}
            data-testid="debug-toggle-button"
          />
        </Tooltip>
      </div>
      {isDebugDrawerOpen && (
        <Drawer
          type="overlay"
          separator
          open={isDebugDrawerOpen}
          onOpenChange={(_, { open }) => setIsDebugDrawerOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => toggleDebugDrawer()}
                  data-testid="debug-overlay-close-button"
                />
              }
            >
              {localization.DebugOverlayHeader()}
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody>
            <div className={styles.authenticationPanel}>
              <AuthenticationPanel />
            </div>
            <FlightPanel />
          </DrawerBody>
        </Drawer>
      )}
    </>
  ) : null;
};
