// Copyright (C) Microsoft Corporation. All rights reserved.

/** Telemetry events namespace */
export const EVENT_NAMESPACE = "Office.Copilot.Lab";

/** Telemetry events prefix */
export const EVENTS_PREFIX = `${EVENT_NAMESPACE}.OTelJS`;

/** Enum of event names */
export enum Events {
  /** Page init event (before window load) */
  PageInit,
  /** Page load event (after window load) */
  PageLoad,
  /** Event emitted on before window unload */
  Shutdown,
  /** Load More Prompts */
  LoadMorePrompts,
  /** Prompt Filter changed */
  PromptsFilter,
  /** Prompt bookmarked */
  PromptBookmarked,
  /** Prompt clicked */
  PromptClicked,
  /** Prompt copied */
  CopyPrompt,
  /** Prompt shared */
  SharePrompt,
  /** Prompt shared via email */
  SharePromptViaEmail,
  /** "More Like This" prompt click */
  MoreLikeThisPromptClicked,
  /** Select Tab event */
  SelectTab,
  /** Page scroll event */
  PageScroll,
  /** Click event */
  Click,
  /** ResultsRendered event */
  ResultsRendered,
  /** GroupResultsRendered event */
  GroupResultsRendered,
  /** ResponseReceived event */
  ResponseReceived,
  /**
   * ClientLayout event
   * See: https://docs.substrate.microsoft.net/docs/Substrate-APIs-and-services/Substrate-Search-Service-%283S%29/3S-API-Reference/Events.html?uid=events
   */
  ClientLayout,
  /** VideoPlayerActions event
   *  Handles all of the different actions for video player
   *
   */
  VideoPlayerActions,
  /** Try In App -button clicked */
  TryInAppLoading,
  /** Try In App successfully created handoff */
  TryInAppSuccess,
  /** Try In App was cancelled by user */
  TryInAppCancelled,
  /** Try in App prompt was copied */
  TryInAppCopy,
  /** Try in App error occurred */
  TryInAppError,
  /** An API was called or its result received */
  ApiAction,
  /** An prompt-related action was triggered */
  PromptAction,
  /** A notification was triggered or interacted with */
  NotificationAction,
  /** A filter was triggered or interacted with */
  FilterAction,
  /** Events related to UI notifications usually in a form of a dialog */
  UINotificationsActions,
  /** User clicks on a button to launch UI that allows them to unshare their linked prompts */
  UnsharePrompt,
  /** Any actions users can do inside a setting UI */
  SettingsAction,
  /** User clicking the sign in button in the sign in suggestion additional header*/
  NudgeSignIn,
  /** User clicking the sign in button inside a prompt*/
  PromptNudgeSignIn,
  /** Does the User Have a Copilot License */
  UserHasCopilotLicensed,
  DiagnosticEvent,
  /** User clicking on the like/unlike button */
  PromptLiked,
}

export enum LogType {
  Informational = "Informational",
  Error = "Error",
  Warning = "Warning",
  BrowseBy = "BrowseBy",
}

//Used to categorize the Log record
export enum LogCategory {
  NAA = "NAA",
  FEEDBACK = "FEEDBACK",
  COMINGSOON = "COMINGSOON",
  NAVIGATETOAPP = "NAVIGATETOAPP",
  NOTIFICATION = "NOTIFICATION",
  APP = "APP",
  BROWSEBY = "BROWSEBY",
  PROMPTS = "PROMPTS",
}

/**
 * Returns a fully qualified event name given a specific event name
 * @param event Event name
 * @returns Fully qualified event name
 */
export const getEventName = (event: keyof typeof Events) =>
  `${EVENTS_PREFIX}.${event}`;
