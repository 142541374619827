declare const process: { env?: { NODE_ENV?: string } } | undefined;
declare const __DEV__: boolean | undefined;
declare const __DISABLE_PSEUDO_LOC__: boolean | undefined;
declare const __SKIP_LOC__: boolean | undefined;

// As we can't (easily) set __DISABLE_PSEUDO_LOC__ via loader magic in Metro, as
// with Webpack, we add an extra variable to support older RN apps
let __RUNTIME_DISABLE_PSEUDO_LOC__ = false;
// Also, it is not easy to disable dev mode. Hence adding extra parameter to disable this
let __RUNTIME_DISABLE_DEV__ = false;

/**
 * Test if we're in dev (including tests).
 */
export function isDev(): boolean {
  return typeof __DEV__ !== "undefined" && __DEV__ && !__RUNTIME_DISABLE_DEV__;
}

export function skipLocalization(): boolean {
  return typeof __SKIP_LOC__ !== "undefined" && __SKIP_LOC__;
}

export function isDevLocalization(): boolean {
  return isDev() || skipLocalization();
}

export function isTest(): boolean {
  return Boolean(
    typeof process !== "undefined" &&
      process.env &&
      process.env.NODE_ENV === "test"
  );
}
export function togglePseudoLocalization(): void {
  __RUNTIME_DISABLE_PSEUDO_LOC__ = !__RUNTIME_DISABLE_PSEUDO_LOC__;
}

export function disablePseudoLocalization(): void {
  __RUNTIME_DISABLE_PSEUDO_LOC__ = true;
}

export function enablePseudoLocalization(): void {
  __RUNTIME_DISABLE_PSEUDO_LOC__ = false;
}

export function enableDevModeForLocalization(): void {
  __RUNTIME_DISABLE_DEV__ = false;
}

export function disableDevModeForLocalization(): void {
  __RUNTIME_DISABLE_DEV__ = true;
}

/**
 * Test that we actually want pseudo localization. I.e. we're in dev and we
 * haven't disabled it.
 */
export function doPseudoLocalization(): boolean {
  // Hard exit if we're not in dev mode
  if (typeof __DEV__ === "undefined" || !__DEV__ || __RUNTIME_DISABLE_DEV__) {
    return false;
  }

  return !(
    (typeof __DISABLE_PSEUDO_LOC__ !== "undefined" && __DISABLE_PSEUDO_LOC__) ||
    __RUNTIME_DISABLE_PSEUDO_LOC__
  );
}
