import {
  makeStyles,
  shorthands,
  type GriffelStyle,
} from "@fluentui/react-components";
import type { Appearance } from "../../data";
import {
  highContrastHighlightStyles,
  highContrastHoverStyles,
} from "../sharedStyles";

type Styles = {
  bookMarks: GriffelStyle;
  spinner: GriffelStyle;
  bookmark: GriffelStyle;
  unbookmark: GriffelStyle;
  icon: GriffelStyle;
  iconText: GriffelStyle;
};

const defaultStyles: Styles = {
  bookMarks: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "24px",
    ...shorthands.gap("4px"),
    ...highContrastHoverStyles,
  },

  spinner: {
    ...shorthands.border("0px"),
    ...shorthands.padding("0px"),
    ...highContrastHighlightStyles,
    ...highContrastHoverStyles,
  },

  bookmark: {
    ...shorthands.border("0px"),
    ...shorthands.padding("0px"),
    minWidth: "24px",
    maxWidth: "24px",
    height: "24px",
    ...highContrastHighlightStyles,
    ...highContrastHoverStyles,
  },

  unbookmark: {
    ...shorthands.border("0px"),
    ...shorthands.padding("0px"),
    minWidth: "24px",
    maxWidth: "24px",
    height: "24px",
    ...highContrastHoverStyles,
  },

  icon: {
    fontSize: "20px",
    ...highContrastHighlightStyles,
  },
  iconText: {
    "@media screen and (-ms-high-contrast: active), (forced-colors: active)": {
      "&:active": {
        "-ms-high-contrast-adjust": "none",
        "forced-color-adjust": "none",
        backgroundColor: "highlight",
        color: "highlighttext",
      },

      "&:hover": {
        "-ms-high-contrast-adjust": "none",
        "forced-color-adjust": "none",
        backgroundColor: "highlight",
        color: "highlighttext",
      },
    },
  },
};

const copilotLabStyles: Styles = {
  ...defaultStyles,
};

const copilotLabGoBoldStyles: Styles = {
  ...defaultStyles,

  bookMarks: {
    ...defaultStyles.bookMarks,
    height: "20px",
  },

  bookmark: {
    ...defaultStyles.bookmark,
    height: "20px",
    minWidth: "20px",
    maxWidth: "20px",
  },

  unbookmark: {
    ...defaultStyles.unbookmark,
    height: "20px",
    minWidth: "20px",
    maxWidth: "20px",
  },

  icon: {
    ...defaultStyles.icon,
    fontSize: "16px",
  },
};

const inAppStyles: Styles = {
  ...defaultStyles,
};

const m365Styles: Styles = {
  ...defaultStyles,
};

const singleColumnStyles: Styles = {
  ...defaultStyles,

  bookmark: {
    minWidth: "24px",
    maxWidth: "24px",
    ...shorthands.border("0px"),
    ...shorthands.padding("8px", "0px"),
  },

  icon: {
    fontSize: "16px",
  },
};

const rockSteadySyles: Styles = {
  ...defaultStyles,

  bookMarks: {
    ...defaultStyles.bookMarks,
    height: "32px",
  },

  bookmark: {
    ...defaultStyles.bookmark,
    height: "32px",
    minWidth: "32px",
    maxWidth: "32px",
  },

  unbookmark: {
    ...defaultStyles.unbookmark,
    height: "32px",
    minWidth: "32px",
    maxWidth: "32px",
  },
};

const useInAppStyles = makeStyles(inAppStyles);
const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const useM365Styles = makeStyles(m365Styles);
const useSingleColumnStyle = makeStyles(singleColumnStyles);
const useRockSteadyStyles = makeStyles(rockSteadySyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "in-app":
      return useInAppStyles;
    case "web":
      return useCopilotLabStyles;
    case "web-v2":
    case "in-app-v2":
      return useCopilotLabGoBoldStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    case "m365":
    case "bing-web":
    case "win-copilot":
      return useM365Styles;
    case "bing-mobile":
    case "underside":
    case "single-col":
      return useSingleColumnStyle;
    default:
      return useInAppStyles;
  }
};
