import { safeConsole as console } from "@1js/safe-console";
import { isEqual } from "lodash-es";

declare const __WHY_DID_YOU_RECOMPUTE__: boolean | undefined;

const shouldUseWhyDidYouRecompute =
  (typeof __WHY_DID_YOU_RECOMPUTE__ !== "undefined" &&
    __WHY_DID_YOU_RECOMPUTE__) ||
  (typeof globalThis !== "undefined" &&
    (globalThis as any)["__WHY_DID_YOU_RECOMPUTE__"]);

/*
 * This code WAS copied from fabric-react.
 * !! It has since been enhanced with better typing and why-did-you-recompute functionality !!
 * Importing it from fabric-react drags the full merge-style
 * library and we don't want that for native. So we copied the code of memoizeFunction here until
 * fabric-react fixes this.
 */

/* This type signature works better than the one used in
   @fluentui/react/lib/Utilities, since it allows typescript to infer the
   return type of the memoized function. */
export type MemoizeFunction = {
  <T extends (...args: any[]) => any>(func: T, maxCacheSize?: number): T;
};

declare class WeakMap {
  public get(key: any): any;
  public set(key: any, value: any): void;
  public has(key: any): boolean;
}

const _resetCounter = 0;
const _emptyObject = { empty: true };
const _dictionary: any = {};
const _weakMap = typeof WeakMap === "undefined" ? null : WeakMap;

interface IMemoizeNode {
  map: WeakMap | null;
  value?: any;
}

/**
 * Memoizes a function; when you pass in the same parameters multiple times, it returns a cached result.
 * Be careful when passing in objects, you need to pass in the same INSTANCE for caching to work. Otherwise
 * it will grow the cache unnecessarily. Also avoid using default values that evaluate functions; passing in
 * undefined for a value and relying on a default function will execute it the first time, but will not
 * re-evaluate subsequent times which may have been unexpected.
 *
 * By default, the cache will reset after 100 permutations, to avoid abuse cases where the function is
 * unintendedly called with unique objects. Without a reset, the cache could grow infinitely, so we safeguard
 * by resetting. To override this behavior, pass a value of 0 to the maxCacheSize parameter.
 *
 * @public
 * @param cb - The function to memoize.
 * @param maxCacheSize - Max results to cache. If the cache exceeds this value, it will reset on the next call.
 * @returns A memoized version of the function.
 */
export const memoizeFunction: MemoizeFunction = <
  T extends (...args: any[]) => RET_TYPE,
  RET_TYPE,
>(
  cb: T,
  maxCacheSize = 100
): T => {
  // Avoid breaking scenarios which don't have weak map.
  if (!_weakMap) {
    return cb;
  }

  let rootNode: IMemoizeNode = _createNode();
  let cacheSize = 0;
  let localResetCounter = _resetCounter;

  // Only set when symbol is present, to prevent non-weak references to arguments
  const seenArgumentsAtNode: Map<IMemoizeNode, any[]> | undefined =
    shouldUseWhyDidYouRecompute ? new Map() : undefined;

  const definitionLocation = shouldUseWhyDidYouRecompute
    ? new Error("Memoized function defined").stack
    : "";

  return function memoizedFunction(...args: any[]): RET_TYPE {
    let currentNode: IMemoizeNode = rootNode;

    if (
      localResetCounter !== _resetCounter ||
      (maxCacheSize > 0 && cacheSize > maxCacheSize)
    ) {
      rootNode = _createNode();
      cacheSize = 0;
      localResetCounter = _resetCounter;
      seenArgumentsAtNode?.clear();
    }

    currentNode = rootNode;

    // Traverse the tree until we find the match.
    for (let i = 0; i < args.length; i++) {
      const arg = _normalizeArg(args[i]);

      if (!currentNode.map?.has(arg)) {
        currentNode.map?.set(arg, _createNode());

        // Why-did-you-recompute: Check if we have seen an equivalent argument before with a different reference
        if (shouldUseWhyDidYouRecompute && seenArgumentsAtNode) {
          const seenArguments = seenArgumentsAtNode.get(currentNode) ?? [];
          const duplicatedArguments = seenArguments.filter(
            (seenArg) => seenArg !== arg && isEqual(seenArg, arg)
          );
          const duplicatedArgument = duplicatedArguments[0];
          if (duplicatedArgument) {
            console.warn(
              "Why-did-you-recompute: Identical (deep-comparison) past arguments to memoizeFunction found with differing instance equality: "
            );
            const location = new Error("Indentical argument passed").stack;
            const highlightDuplicationRecursive = (nodeA: any, nodeB: any) => {
              console.table(nodeA);
              Object.keys(nodeA).forEach((prop) => {
                const childA = nodeA[prop];
                const childB = nodeB[prop];
                if (childA !== childB && isEqual(childA, childB)) {
                  console.warn(`Duplication present in child prop '${prop}'`);
                  highlightDuplicationRecursive(childA, childB);
                }
              });
            };
            highlightDuplicationRecursive(duplicatedArgument, arg);
            console.warn(location);
            console.warn(definitionLocation);
          }

          seenArgumentsAtNode.set(currentNode, [...seenArguments, arg]);
        }
      }

      currentNode = currentNode.map?.get(arg);
    }

    if (!currentNode.hasOwnProperty("value")) {
      currentNode.value = cb(...args);
      cacheSize++;
    }

    return currentNode.value;
  } as any;
};

function _normalizeArg(val: null | undefined): { empty: boolean } | any;
function _normalizeArg(val: object): any;
function _normalizeArg(val: any): any {
  if (!val) {
    return _emptyObject;
  } else if (typeof val === "object" || typeof val === "function") {
    return val;
  } else if (!_dictionary[val]) {
    _dictionary[val] = { val };
  }

  return _dictionary[val];
}

function _createNode(): IMemoizeNode {
  return {
    map: _weakMap ? new _weakMap() : null,
  };
}
