import * as React from "react";
import { createFluentIconExtended } from "./utils";

export const AgentPromptsIcon = createFluentIconExtended(
  "AllPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M13.9175 3L10.3321 5.46119L7.3606 20.9129C7.56621 20.9693 7.7809 20.9993 7.99993 21L8.00812 21H15.9999L16.0003 20.9975C16.7633 20.9618 17.4593 20.5404 17.8435 19.8746L18.5101 18.7196L18.4817 18.7536L18.4899 18.7311L21.719 13.1227C22.1191 12.4277 22.1191 11.5723 21.719 10.8773L17.8956 4.23675C17.6754 3.85417 17.3613 3.54498 16.9919 3.33144L16.9667 3.31707C16.673 3.15184 16.3454 3.04659 16.0028 3.01229L15.9999 3H13.9175Z",
      fill: "url(#paint0_radial_769_23819)",
    }),
    React.createElement("path", {
      d: "M13.9175 3L10.3321 5.46119L7.3606 20.9129C7.56621 20.9693 7.7809 20.9993 7.99993 21L8.00812 21H15.9999L16.0003 20.9975C16.7633 20.9618 17.4593 20.5404 17.8435 19.8746L18.5101 18.7196L18.4817 18.7536L18.4899 18.7311L21.719 13.1227C22.1191 12.4277 22.1191 11.5723 21.719 10.8773L17.8956 4.23675C17.6754 3.85417 17.3613 3.54498 16.9919 3.33144L16.9667 3.31707C16.673 3.15184 16.3454 3.04659 16.0028 3.01229L15.9999 3H13.9175Z",
      fill: "url(#paint1_radial_769_23819)",
      ["fill-opacity"]: "0.5",
    }),
    React.createElement("path", {
      d: "M13.7759 4.67398L9.9532 19.4323C9.75563 20.329 8.95619 21 8.00003 21C7.96159 21 7.9234 20.9989 7.88549 20.9968C7.49153 20.977 7.11434 20.863 6.78256 20.6711L6.75844 20.657C6.40995 20.4501 6.11276 20.1568 5.90022 19.7969C5.88605 19.7729 5.87226 19.7487 5.85885 19.7241L2.24422 13.0972C1.86907 12.4095 1.87868 11.5761 2.26958 10.8972L6.16736 4.12733C6.24571 3.99125 6.33707 3.86536 6.43937 3.75085H6.42709C6.82614 3.3042 7.39153 3.03091 7.99949 3.00247L8.00003 3H15.75C14.7565 3 13.9316 3.72448 13.7759 4.67398Z",
      fill: "url(#paint2_radial_769_23819)",
    }),
    React.createElement("path", {
      d: "M13.7759 4.67398L9.9532 19.4323C9.75563 20.329 8.95619 21 8.00003 21C7.96159 21 7.9234 20.9989 7.88549 20.9968C7.49153 20.977 7.11434 20.863 6.78256 20.6711L6.75844 20.657C6.40995 20.4501 6.11276 20.1568 5.90022 19.7969C5.88605 19.7729 5.87226 19.7487 5.85885 19.7241L2.24422 13.0972C1.86907 12.4095 1.87868 11.5761 2.26958 10.8972L6.16736 4.12733C6.24571 3.99125 6.33707 3.86536 6.43937 3.75085H6.42709C6.82614 3.3042 7.39153 3.03091 7.99949 3.00247L8.00003 3H15.75C14.7565 3 13.9316 3.72448 13.7759 4.67398Z",
      fill: "url(#paint3_linear_769_23819)",
      ["fill-opacity"]: "0.4",
    }),
    React.createElement("path", {
      d: "M15.834 3.00173C15.8062 3.00058 15.7782 3 15.75 3H15.8344L15.834 3.00173Z",
      fill: "url(#paint4_radial_769_23819)",
    }),
    React.createElement("path", {
      d: "M15.834 3.00173C15.8062 3.00058 15.7782 3 15.75 3H15.8344L15.834 3.00173Z",
      fill: "url(#paint5_linear_769_23819)",
      ["fill-opacity"]: "0.4",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "radialGradient",
        {
          id: "paint0_radial_769_23819",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(13.8929 25.8857) rotate(-87.747) scale(26.2489 22.3637)",
        },
        React.createElement("stop", {
          stopColor: "#FFC470",
        }),
        React.createElement("stop", {
          offset: "0.251092",
          stopColor: "#FF835C",
        }),
        React.createElement("stop", {
          offset: "0.584326",
          stopColor: "#F24A9D",
        }),
        React.createElement("stop", {
          offset: "0.871234",
          stopColor: "#B339F0",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#C354FF",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint1_radial_769_23819",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(20.3486 19.2) rotate(-159.917) scale(25.461 21.8263)",
        },
        React.createElement("stop", {
          offset: 0.708817,
          stopColor: "#FFB357",
          stopOpacity: 0,
        }),
        React.createElement("stop", {
          offset: 0.941624,
          stopColor: "#FFB357",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint2_radial_769_23819",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(20.3486 19.2) rotate(-159.917) scale(25.461 21.8263)",
        },
        React.createElement("stop", {
          offset: "0.221888",
          stopColor: "#4E46E2",
        }),
        React.createElement("stop", {
          offset: "0.577558",
          stopColor: "#625DF6",
        }),
        React.createElement("stop", {
          offset: "0.954926",
          stopColor: "#E37DFF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_769_23819",
          x1: "6.19603",
          y1: "10.4571",
          x2: "12.2081",
          y2: "11.8543",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#7563F7",
          stopOpacity: 0,
        }),
        React.createElement("stop", {
          offset: "0.986042",
          stopColor: "#4916AE",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint4_radial_769_23819",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(20.3486 19.2) rotate(-159.917) scale(25.461 21.8263)",
        },
        React.createElement("stop", {
          offset: "0.221888",
          stopColor: "#4E46E2",
        }),
        React.createElement("stop", {
          offset: "0.577558",
          stopColor: "#625DF6",
        }),
        React.createElement("stop", {
          offset: "0.954926",
          stopColor: "#E37DFF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint5_linear_769_23819",
          x1: "6.19603",
          y1: "10.4571",
          x2: "12.2081",
          y2: "11.8543",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#7563F7",
          stopOpacity: 0,
        }),
        React.createElement("stop", {
          offset: "0.986042",
          stopColor: "#4916AE",
        })
      )
    ),
  ]
);

export const AllPromptsIcon = createFluentIconExtended(
  "AllPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M17.6245 12.7449C17.2378 12.5216 16.7613 12.5216 16.3745 12.7449L13.627 14.3312C13.2402 14.5545 13.002 14.9671 13.002 15.4137V18.5864C13.002 19.0329 13.2402 19.4456 13.627 19.6689L16.3745 21.2552C16.7613 21.4785 17.2378 21.4785 17.6245 21.2552L20.3721 19.6689C20.7589 19.4456 20.9971 19.0329 20.9971 18.5864V15.4137C20.9971 14.9671 20.7589 14.5545 20.3721 14.3312L17.6245 12.7449Z",
      fill: "url(#paint0_linear_3412_142238)",
    }),
    React.createElement("path", {
      d: "M15.25 3C14.0074 3 13 4.00736 13 5.25V8.75C13 9.99264 14.0074 11 15.25 11H18.75C19.9926 11 21 9.99264 21 8.75V5.25C21 4.00736 19.9926 3 18.75 3H15.25Z",
      fill: "url(#paint1_linear_3412_142238)",
    }),
    React.createElement("path", {
      d: "M7 13C4.79086 13 3 14.7909 3 17C3 19.2091 4.79086 21 7 21C9.20914 21 11 19.2091 11 17C11 14.7909 9.20914 13 7 13Z",
      fill: "url(#paint2_linear_3412_142238)",
    }),
    React.createElement("path", {
      d: "M5.55031 3.89843C6.15281 2.70052 7.84719 2.70052 8.44969 3.89843L10.8216 8.61429C11.371 9.70668 10.5851 11 9.37189 11L4.62811 11C3.41489 11 2.629 9.70668 3.17842 8.61429L5.55031 3.89843Z",
      fill: "url(#paint3_linear_3412_142238)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_3412_142238",
          x1: "8.33809",
          y1: "8.89185",
          x2: "19.2117",
          y2: "20.111",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#52D17C",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#22918B",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_3412_142238",
          x1: "9.66667",
          y1: "-1",
          x2: "19.5",
          y2: "11.75",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#FFCD0F",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#FE8401",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_3412_142238",
          x1: "3",
          y1: "13",
          x2: "11",
          y2: "21",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#0FAFFF",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#2764E7",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_3412_142238",
          x1: "3.28571",
          y1: "4.5",
          x2: "8.80668",
          y2: "10.2361",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#F24A9D",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#D7257D",
        })
      )
    ),
  ]
);

export const AnalyzePromptsIcon = createFluentIconExtended(
  "AnalyzePromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M3.7998 6.25C3.7998 4.45507 5.25488 3 7.0498 3H18.5498C20.3447 3 21.7998 4.45507 21.7998 6.25V17.75C21.7998 19.5449 20.3447 21 18.5498 21H7.0498C5.25488 21 3.7998 19.5449 3.7998 17.75V6.25Z",
      fill: "url(#paint0_linear_3412_142264)",
    }),
    React.createElement("path", {
      d: "M6.7998 8.25C6.7998 7.2835 7.58331 6.5 8.5498 6.5H17.0498C18.0163 6.5 18.7998 7.2835 18.7998 8.25V9.75C18.7998 10.7165 18.0163 11.5 17.0498 11.5H8.5498C7.58331 11.5 6.7998 10.7165 6.7998 9.75V8.25ZM7.5498 13C7.13559 13 6.7998 13.3358 6.7998 13.75C6.7998 14.1642 7.13559 14.5 7.5498 14.5H12.0498C12.464 14.5 12.7998 14.1642 12.7998 13.75C12.7998 13.3358 12.464 13 12.0498 13H7.5498ZM6.7998 16.75C6.7998 16.3358 7.13559 16 7.5498 16H12.0498C12.464 16 12.7998 16.3358 12.7998 16.75C12.7998 17.1642 12.464 17.5 12.0498 17.5H7.5498C7.13559 17.5 6.7998 17.1642 6.7998 16.75ZM16.0498 13C15.0833 13 14.2998 13.7835 14.2998 14.75V15.75C14.2998 16.7165 15.0833 17.5 16.0498 17.5H17.0498C18.0163 17.5 18.7998 16.7165 18.7998 15.75V14.75C18.7998 13.7835 18.0163 13 17.0498 13H16.0498Z",
      fill: "url(#paint1_linear_3412_142264)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_3412_142264",
          x1: "10.2284",
          y1: "3",
          x2: "17.1247",
          y2: "20.0296",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#B3E0FF",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#8CD0FF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_3412_142264",
          x1: "6.7998",
          y1: "6.5",
          x2: "20.9351",
          y2: "11.7409",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#0094F0",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#2764E7",
        })
      )
    ),
  ]
);

export const AskPromptsIcon = createFluentIconExtended(
  "AskPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M12.2002 2C17.7232 2 22.2002 6.478 22.2002 12C22.2002 17.522 17.7232 22 12.2002 22C6.6772 22 2.2002 17.522 2.2002 12C2.2002 6.478 6.6772 2 12.2002 2Z",
      fill: "url(#paint0_linear_3412_142274)",
    }),
    React.createElement("path", {
      d: "M12.2002 15.5C11.6479 15.5 11.2002 15.9477 11.2002 16.5C11.2002 17.0523 11.6479 17.5 12.2002 17.5C12.7525 17.5 13.2002 17.0523 13.2002 16.5C13.2002 15.9477 12.7525 15.5 12.2002 15.5ZM12.2002 6.75C10.6814 6.75 9.4502 7.98122 9.4502 9.5C9.4502 9.91421 9.78598 10.25 10.2002 10.25C10.5799 10.25 10.8937 9.96785 10.9433 9.60177L10.9502 9.5C10.9502 8.80964 11.5098 8.25 12.2002 8.25C12.8906 8.25 13.4502 8.80964 13.4502 9.5C13.4502 10.0388 13.3152 10.3053 12.8053 10.8322L12.6699 10.9697C11.7918 11.8478 11.4502 12.4171 11.4502 13.5C11.4502 13.9142 11.786 14.25 12.2002 14.25C12.6144 14.25 12.9502 13.9142 12.9502 13.5C12.9502 12.9612 13.0852 12.6947 13.5951 12.1678L13.7305 12.0303C14.6086 11.1522 14.9502 10.5829 14.9502 9.5C14.9502 7.98122 13.719 6.75 12.2002 6.75Z",
      fill: "url(#paint1_linear_3412_142274)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_3412_142274",
          x1: "2.2002",
          y1: "2",
          x2: "22.2002",
          y2: "22",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#0FAFFF",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#2764E7",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_3412_142274",
          x1: "9.93129",
          y1: "6.93875",
          x2: "13.2076",
          y2: "17.7443",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#FDFDFD",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#CCEAFF",
        })
      )
    ),
  ]
);

export const BookmarkPromptsIcon = createFluentIconExtended(
  "BookmarkPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M6.19045 21.8538C5.69432 22.2107 5.00244 21.8562 5.00244 21.2449V6.24902C5.00244 4.4541 6.45752 2.99902 8.25244 2.99902H15.7508C17.5458 2.99902 19.0008 4.4541 19.0008 6.24902V21.2449C19.0008 21.8562 18.309 22.2107 17.8128 21.8538L12.0016 17.6728L6.19045 21.8538Z",
      fill: "url(#paint0_linear_415056_2021)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_415056_2021",
          x1: "5.00244",
          y1: "4.6104",
          x2: "15.3172",
          y2: "17.4905",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#F97DBD",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#D7257D",
        })
      )
    ),
  ]
);

export const CatchUpPromptsIcon = createFluentIconExtended(
  "CatchUpPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M11.5996 6C11.5996 5.44772 12.0473 5 12.5996 5H21.5996C22.1519 5 22.5996 5.44772 22.5996 6C22.5996 6.55228 22.1519 7 21.5996 7H12.5996C12.0473 7 11.5996 6.55228 11.5996 6Z",
      fill: "url(#paint0_linear_3412_142284)",
    }),
    React.createElement("path", {
      d: "M11.5996 9C11.5996 8.44772 12.0473 8 12.5996 8H18.5996C19.1519 8 19.5996 8.44772 19.5996 9C19.5996 9.55228 19.1519 10 18.5996 10H12.5996C12.0473 10 11.5996 9.55228 11.5996 9Z",
      fill: "url(#paint1_linear_3412_142284)",
    }),
    React.createElement("path", {
      d: "M11.5996 15C11.5996 14.4477 12.0473 14 12.5996 14H21.5996C22.1519 14 22.5996 14.4477 22.5996 15C22.5996 15.5523 22.1519 16 21.5996 16H12.5996C12.0473 16 11.5996 15.5523 11.5996 15Z",
      fill: "url(#paint2_linear_3412_142284)",
    }),
    React.createElement("path", {
      d: "M11.5996 18C11.5996 17.4477 12.0473 17 12.5996 17H18.5996C19.1519 17 19.5996 17.4477 19.5996 18C19.5996 18.5523 19.1519 19 18.5996 19H12.5996C12.0473 19 11.5996 18.5523 11.5996 18Z",
      fill: "url(#paint3_linear_3412_142284)",
    }),
    React.createElement("path", {
      d: "M4.84961 4C3.60697 4 2.59961 5.00736 2.59961 6.25V8.75C2.59961 9.99264 3.60697 11 4.84961 11H7.34961C8.59225 11 9.59961 9.99264 9.59961 8.75V6.25C9.59961 5.00736 8.59225 4 7.34961 4H4.84961ZM4.84961 13C3.60697 13 2.59961 14.0074 2.59961 15.25V17.75C2.59961 18.9926 3.60697 20 4.84961 20H7.34961C8.59225 20 9.59961 18.9926 9.59961 17.75V15.25C9.59961 14.0074 8.59225 13 7.34961 13H4.84961Z",
      fill: "url(#paint4_linear_3412_142284)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_3412_142284",
          x1: "9.94961",
          y1: "3",
          x2: "21.4996",
          y2: "19",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#36DFF1",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#0094F0",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_3412_142284",
          x1: "9.94961",
          y1: "3",
          x2: "21.4996",
          y2: "19",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#36DFF1",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#0094F0",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_3412_142284",
          x1: "9.94961",
          y1: "3",
          x2: "21.4996",
          y2: "19",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#36DFF1",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#0094F0",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_3412_142284",
          x1: "9.94961",
          y1: "3",
          x2: "21.4996",
          y2: "19",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#36DFF1",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#0094F0",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_3412_142284",
          x1: "4.26424",
          y1: "6.12697",
          x2: "7.8317",
          y2: "19.1474",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.125321",
          "stop-color": "#9C6CFE",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#7A41DC",
        })
      )
    ),
  ]
);

export const CodePromptsIcon = createFluentIconExtended(
  "CodePromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M3 6.25C3 4.45508 4.45507 3 6.25 3H17.75C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45508 21 3 19.5449 3 17.75V6.25Z",
      fill: "url(#paint0_linear_3412_141770)",
    }),
    React.createElement("path", {
      d: "M10.5303 9.28033L7.81066 12L10.5303 14.7197C10.8232 15.0126 10.8232 15.4874 10.5303 15.7803C10.2374 16.0732 9.76256 16.0732 9.46967 15.7803L6.21967 12.5303C5.92678 12.2374 5.92678 11.7626 6.21967 11.4697L9.46967 8.21967C9.76256 7.92678 10.2374 7.92678 10.5303 8.21967C10.8232 8.51256 10.8232 8.98744 10.5303 9.28033ZM14.5303 8.21967L17.7803 11.4697C18.0732 11.7626 18.0732 12.2374 17.7803 12.5303L14.5303 15.7803C14.2374 16.0732 13.7626 16.0732 13.4697 15.7803C13.1768 15.4874 13.1768 15.0126 13.4697 14.7197L16.1893 12L13.4697 9.28033C13.1768 8.98744 13.1768 8.51256 13.4697 8.21967C13.7626 7.92678 14.2374 7.92678 14.5303 8.21967Z",
      fill: "url(#paint1_linear_3412_141770)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_3412_141770",
          x1: "7.38879",
          y1: "3",
          x2: "17.2602",
          y2: "21",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.0281195",
          "stop-color": "#E67EEA",
        }),
        React.createElement("stop", {
          offset: "0.43755",
          "stop-color": "#AD64D7",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#794DC5",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_3412_141770",
          x1: "8.84518",
          y1: "8.30774",
          x2: "13.5444",
          y2: "20.4717",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#FDFDFD",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#F9DCFA",
        })
      )
    ),
  ]
);

export const CreatePromptsIcon = createFluentIconExtended(
  "CreatePromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M19.0002 2.75C19.0002 2.33579 18.6645 2 18.2502 2H5.75024C5.33603 2 5.00024 2.33579 5.00024 2.75V12.5H19.0002V2.75Z",
      fill: "url(#paint0_linear_390_21273)",
    }),
    React.createElement("path", {
      d: "M5.00024 12V14.2521C5.00024 15.4947 6.0076 16.5021 7.25024 16.5021H9.99991V20C9.99991 21.1046 10.8953 22 11.9999 22C13.1045 22 13.9999 21.1046 13.9999 20V16.5021H16.7502C17.9929 16.5021 19.0002 15.4947 19.0002 14.2521V12H5.00024Z",
      fill: "url(#paint1_linear_390_21273)",
    }),
    React.createElement("path", {
      d: "M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12V12.5H5V12Z",
      fill: "url(#paint2_linear_390_21273)",
    }),
    React.createElement("path", {
      d: "M12.5 5.25154V2H14V5.25154C14 5.66576 13.6642 6.00154 13.25 6.00154C12.8358 6.00154 12.5 5.66576 12.5 5.25154Z",
      fill: "url(#paint3_linear_390_21273)",
    }),
    React.createElement("path", {
      d: "M15 6.25112V2H16.5V6.25112C16.5 6.66534 16.1642 7.00112 15.75 7.00112C15.3358 7.00112 15 6.66534 15 6.25112Z",
      fill: "url(#paint4_linear_390_21273)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_390_21273",
          x1: "9.20024",
          y1: "-1.28085",
          x2: "15.4488",
          y2: "16.0505",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.0853737",
          "stop-color": "#FFCD0F",
        }),
        React.createElement("stop", {
          offset: "0.990707",
          "stop-color": "#E67505",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_390_21273",
          x1: "5.00024",
          y1: "8.47089",
          x2: "5.85765",
          y2: "24.5893",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.125321",
          "stop-color": "#AC80FF",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#5750E2",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_390_21273",
          x1: "8.32925",
          y1: "11.1994",
          x2: "8.44277",
          y2: "12.8912",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.125321",
          "stop-color": "#9C6CFE",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#5750E2",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_390_21273",
          x1: "12.5",
          y1: "0.610912",
          x2: "16.5483",
          y2: "5.92879",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#FF921F",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#EB4824",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_390_21273",
          x1: "12.5",
          y1: "0.610912",
          x2: "16.5483",
          y2: "5.92879",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#FF921F",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#EB4824",
        })
      )
    ),
  ]
);

export const DesignPromptsIcon = createFluentIconExtended(
  "DesignPromptsIcon",
  "24px",
  [
    React.createElement("circle", {
      cx: "10.5",
      cy: "9.5",
      r: "6.5",
      fill: "url(#paint0_radial_409271_717)",
    }),
    React.createElement("circle", {
      cx: "10.5",
      cy: "9.5",
      r: "6.5",
      fill: "url(#paint1_radial_409271_717)",
      fillOpacity: "0.6",
    }),
    React.createElement("circle", {
      cx: "10.5",
      cy: "9.5",
      r: "6.5",
      fill: "url(#paint2_radial_409271_717)",
      fillOpacity: "0.6",
    }),
    React.createElement("rect", {
      x: "10",
      y: "9",
      width: "12",
      height: "12",
      rx: "3",
      fill: "url(#paint3_radial_409271_717)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.5 16C14.0899 16 17 13.0899 17 9.5C17 9.33176 16.9936 9.16502 16.9811 9H13C11.3431 9 10 10.3431 10 12V15.9811C10.165 15.9936 10.3318 16 10.5 16Z",
      fill: "url(#paint4_linear_409271_717)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.5 16C14.0899 16 17 13.0899 17 9.5C17 9.33176 16.9936 9.16502 16.9811 9H13C11.3431 9 10 10.3431 10 12V15.9811C10.165 15.9936 10.3318 16 10.5 16Z",
      fill: "url(#paint5_linear_409271_717)",
      fillOpacity: "0.2",
    }),
    React.createElement("path", {
      d: "M7.5 13.9483C7.5 19.2172 6.03906 21.9688 4.75 21.9688C3.63281 21.9688 2 19.6855 2 13.9483C2 9.05011 3.23122 8.26953 4.75 8.26953C6.10352 8.26953 7.5 8.67934 7.5 13.9483Z",
      fill: "url(#paint6_linear_409271_717)",
    }),
    React.createElement("path", {
      d: "M7.5 13.9483C7.5 19.2172 6.03906 21.9688 4.75 21.9688C3.63281 21.9688 2 19.6855 2 13.9483C2 9.05011 3.23122 8.26953 4.75 8.26953C6.10352 8.26953 7.5 8.67934 7.5 13.9483Z",
      fill: "url(#paint7_radial_409271_717)",
      fillOpacity: "0.5",
    }),
    React.createElement("path", {
      d: "M7.5 13.9483C7.5 19.2172 6.03906 21.9688 4.75 21.9688C3.63281 21.9688 2 19.6855 2 13.9483C2 9.05011 3.23122 8.26953 4.75 8.26953C6.10352 8.26953 7.5 8.67934 7.5 13.9483Z",
      fill: "url(#paint8_linear_409271_717)",
    }),
    React.createElement("path", {
      d: "M7.5 13.9483C7.5 19.2172 6.03906 21.9688 4.75 21.9688C3.63281 21.9688 2 19.6855 2 13.9483C2 9.05011 3.23122 8.26953 4.75 8.26953C6.10352 8.26953 7.5 8.67934 7.5 13.9483Z",
      fill: "url(#paint9_radial_409271_717)",
      fillOpacity: "0.7",
    }),
    React.createElement("path", {
      d: "M2.06311 12.2056C2.33244 8.85641 3.43316 8.26953 4.75008 8.26953C5.93603 8.26953 7.15497 8.58415 7.4386 12.2064C6.67706 12.6893 5.75068 12.9725 4.75147 12.9725C3.75172 12.9725 2.82488 12.689 2.06311 12.2056Z",
      fill: "white",
      fillOpacity: "0.15",
    }),
    React.createElement("path", {
      d: "M5.57489 2.07403C5.83474 2.19892 6 2.4617 6 2.75001C6 3.49518 6.32769 4.08141 6.73156 4.68175C7.0641 5.17579 7.5 5.8234 7.5 6.75001C7.5 7.22276 7.38071 7.66766 7.17058 8.05623C6.70564 8.91598 5.79602 9.5 4.75 9.5C3.70764 9.5 2.80073 8.92007 2.33432 8.06525C2.12115 7.67455 2 7.22642 2 6.75C2 6.38181 2.00034 5.74888 2.38341 4.93168C2.75829 4.13192 3.47066 3.21301 4.78148 2.16436C5.00661 1.98425 5.31504 1.94914 5.57489 2.07403Z",
      fill: "url(#paint10_radial_409271_717)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "radialGradient",
        {
          id: "paint0_radial_409271_717",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(5.20743 19.1796) rotate(-78.3815) scale(17.5863 17.5863)",
        },
        React.createElement("stop", {
          offset: "0.221888",
          stopColor: "#4E46E2",
        }),
        React.createElement("stop", {
          offset: "0.577558",
          stopColor: "#625DF6",
        }),
        React.createElement("stop", {
          offset: "0.954926",
          stopColor: "#E37DFF",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint1_radial_409271_717",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(2.29212 12.9136) rotate(-17.2815) scale(7.09946 9.29311)",
        },
        React.createElement("stop", {
          offset: "0.566147",
          stopColor: "#251FBA",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#5E51E4",
          stopOpacity: "0",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint2_radial_409271_717",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(1.80624 8.09222) rotate(8.25944) scale(6.53056 6.67552)",
        },
        React.createElement("stop", {
          offset: "0.566147",
          stopColor: "#251FBA",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#5E51E4",
          stopOpacity: "0",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint3_radial_409271_717",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(11.5328 9.85709) rotate(42.4623) scale(14.1885 19.2719)",
        },
        React.createElement("stop", {
          stopColor: "#C354FF",
        }),
        React.createElement("stop", {
          offset: "0.157798",
          stopColor: "#B339F0",
        }),
        React.createElement("stop", {
          offset: "0.429343",
          stopColor: "#F24A9D",
        }),
        React.createElement("stop", {
          offset: "0.748908",
          stopColor: "#FF835C",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#FFC470",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_409271_717",
          x1: "16.3125",
          y1: "15.8973",
          x2: "11.0804",
          y2: "8.20815",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.194812",
          stopColor: "#6D37CD",
        }),
        React.createElement("stop", {
          offset: "0.764931",
          stopColor: "#EA71EF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint5_linear_409271_717",
          x1: "17.4196",
          y1: "17.7054",
          x2: "9.07613",
          y2: "6.04381",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.194812",
          stopColor: "#7631FF",
        }),
        React.createElement("stop", {
          offset: "0.885651",
          stopColor: "#E63080",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint6_linear_409271_717",
          x1: "0.710938",
          y1: "14.0849",
          x2: "6.87199",
          y2: "20.6102",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#0FAFFF",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#2764E7",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint7_radial_409271_717",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(4.52084 7.41333) rotate(81.2237) scale(13.6117 33.7531)",
        },
        React.createElement("stop", {
          offset: "0.5",
          stopColor: "#DD3CE2",
          stopOpacity: "0",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#DD3CE2",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint8_linear_409271_717",
          x1: "9.5625",
          y1: "14.5445",
          x2: "-0.0624995",
          y2: "14.5445",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.307407",
          stopColor: "#0D91E1",
        }),
        React.createElement("stop", {
          offset: "0.760677",
          stopColor: "#52B471",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint9_radial_409271_717",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(3.00353 8.07664) rotate(-68.5039) scale(6.92266 13.36)",
        },
        React.createElement("stop", {
          offset: "0.168605",
          stopColor: "#02888D",
        }),
        React.createElement("stop", {
          offset: "0.259941",
          stopColor: "#02888D",
          stopOpacity: "0",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint10_radial_409271_717",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(5.91705 4.45048) rotate(108.105) scale(5.75565 6.22878)",
        },
        React.createElement("stop", {
          stopColor: "#FF9532",
        }),
        React.createElement("stop", {
          offset: "0.251092",
          stopColor: "#FF835C",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#F24A9D",
        })
      )
    ),
  ]
);

export const EditPromptsIcon = createFluentIconExtended(
  "EditPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M3.5874 15.3532L14.6459 4.29346L19.7066 9.35412L8.64828 20.4136L4.70363 19.2976L3.5874 15.3532Z",
      fill: "url(#paint0_linear_378174_8834)",
    }),
    React.createElement("path", {
      d: "M3.94103 14.9995C3.5347 15.4059 3.2491 15.917 3.116 16.4761L2.02041 21.0775C1.96009 21.3309 2.03552 21.5974 2.21968 21.7816C2.40385 21.9657 2.67037 22.0412 2.92373 21.9808L7.52498 20.8853C8.08418 20.7522 8.59546 20.4664 9.00191 20.0599C9.00191 20.0599 7.17792 19.6504 5.76436 18.2368C4.34949 16.822 3.94103 14.9995 3.94103 14.9995Z",
      fill: "url(#paint1_linear_378174_8834)",
    }),
    React.createElement("path", {
      d: "M20.9518 3.0481C19.5543 1.65058 17.2884 1.65064 15.891 3.04825L13.5852 5.35413L18.6459 10.4148L20.9519 8.10861C22.3493 6.71112 22.3492 4.4455 20.9518 3.0481Z",
      fill: "url(#paint2_linear_378174_8834)",
    }),
    React.createElement("path", {
      d: "M12.7798 6.16065L14.9007 4.03857C14.9007 4.03857 15.3096 5.86171 16.7239 7.27601C18.138 8.69014 19.9613 9.09923 19.9613 9.09923L17.8407 11.2211C17.8407 11.2211 16.0167 10.8115 14.6031 9.39797C13.1882 7.98308 12.7798 6.16065 12.7798 6.16065Z",
      fill: "url(#paint3_linear_378174_8834)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_378174_8834",
          x1: "13.1371",
          y1: "8.46776",
          x2: "15.4437",
          y2: "14.7087",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#FFA43D",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#FB5937",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_378174_8834",
          x1: "0.996654",
          y1: "16.9503",
          x2: "6.04366",
          y2: "21.967",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.255",
          stopColor: "#FFD394",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#FF921F",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_378174_8834",
          x1: "20.2398",
          y1: "3.7506",
          x2: "17.1375",
          y2: "6.66781",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#F97DBD",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#DD3CE2",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_378174_8834",
          x1: "16.4946",
          y1: "8.95435",
          x2: "11.4203",
          y2: "6.73569",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#FF921F",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#FFE994",
        })
      )
    ),
  ]
);

export const ExecutePromptsIcon = createFluentIconExtended(
  "ExecutePromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M12.7441 2.29289C12.3536 1.90237 11.7204 1.90237 11.3299 2.29289C10.9394 2.68342 10.9394 3.31658 11.3299 3.70711L12.0188 4.39602C7.17424 4.40584 3.25 8.33614 3.25 13.183C3.25 18.0359 7.18407 21.97 12.037 21.97C16.8899 21.97 20.824 18.0359 20.824 13.183C20.824 12.6307 20.3763 12.183 19.824 12.183C19.2717 12.183 18.824 12.6307 18.824 13.183C18.824 16.9314 15.7854 19.97 12.037 19.97C8.28864 19.97 5.25 16.9314 5.25 13.183C5.25 9.44072 8.27879 6.40586 12.0188 6.39602L11.3299 7.08489C10.9394 7.47542 10.9394 8.10858 11.3299 8.49911C11.7204 8.88963 12.3536 8.88963 12.7441 8.49911L15.1401 6.10311C15.5306 5.71258 15.5306 5.07942 15.1401 4.68889L12.7441 2.29289Z",
      fill: "url(#paint0_linear_378174_8649)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16.9371 9.48088C17.3276 9.87141 17.3276 10.5046 16.9371 10.8951L12.1451 15.6871C11.7546 16.0776 11.1214 16.0776 10.7309 15.6871L8.93388 13.8901C8.54336 13.4996 8.54336 12.8664 8.93388 12.4759C9.32441 12.0854 9.95757 12.0854 10.3481 12.4759L11.438 13.5658L15.5229 9.48088C15.9134 9.09036 16.5466 9.09036 16.9371 9.48088Z",
      fill: "url(#paint1_linear_378174_8649)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_378174_8649",
          x1: "3.25",
          y1: "3.17471",
          x2: "9.24661",
          y2: "25.2709",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#0FAFFF",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#0067BF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_378174_8649",
          x1: "16.0241",
          y1: "10.4276",
          x2: "7.89185",
          y2: "14.4703",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#42B870",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#309C61",
        })
      )
    ),
  ]
);

export const FindPromptsIcon = createFluentIconExtended(
  "FindPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M21 19C21 21.5 16.9706 23 12 23C7.02944 23 3 21.5 3 19C3 16.5938 7.02944 15 12 15C16.9706 15 21 16.5 21 19Z",
      fill: "url(#paint0_radial_399929_2008)",
    }),
    React.createElement("path", {
      d: "M12 2C7.85786 2 4.5 5.35786 4.5 9.5C4.5 11.4319 5.56439 13.3007 6.76779 14.8164C7.98797 16.3533 9.44602 17.6449 10.4234 18.4378C11.3487 19.1885 12.6513 19.1885 13.5766 18.4378C14.554 17.6449 16.012 16.3533 17.2322 14.8164C18.4356 13.3007 19.5 11.4319 19.5 9.5C19.5 5.35786 16.1421 2 12 2Z",
      fill: "url(#paint1_linear_399929_2008)",
    }),
    React.createElement("path", {
      d: "M14.5 9.5C14.5 10.8807 13.3807 12 12 12C10.6193 12 9.5 10.8807 9.5 9.5C9.5 8.11929 10.6193 7 12 7C13.3807 7 14.5 8.11929 14.5 9.5Z",
      fill: "url(#paint2_linear_399929_2008)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "radialGradient",
        {
          id: "paint0_radial_399929_2008",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(11.3571 17.6667) rotate(-10.6779) scale(14.3921 6.38107)",
        },
        React.createElement("stop", {
          stopColor: "#7B7BFF",
        }),
        React.createElement("stop", {
          offset: "0.501667",
          stopColor: "#A3A3FF",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#CEB0FF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_399929_2008",
          x1: "1.21875",
          y1: "-2.85737",
          x2: "13.2024",
          y2: "16.5487",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#F97DBD",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#D7257D",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_399929_2008",
          x1: "9.79029",
          y1: "9.72097",
          x2: "12.3938",
          y2: "12.4282",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#FDFDFD",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#FECBE6",
        })
      )
    ),
  ]
);

export const ByRolePromptsIcon = createFluentIconExtended(
  "ByRolePromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M19.75 4C20.9926 4 22 5.00736 22 6.25V17.7546C22 18.9972 20.9926 20.0046 19.75 20.0046H4.25C3.00736 20.0046 2 18.9972 2 17.7546V6.25C2 5.00736 3.00736 4 4.25 4H19.75Z",
      fill: "url(#paint0_linear_6420_78)",
    }),
    React.createElement("path", {
      d: "M19.75 4C20.9926 4 22 5.00736 22 6.25V17.7546C22 18.9972 20.9926 20.0046 19.75 20.0046H4.25C3.00736 20.0046 2 18.9972 2 17.7546V6.25C2 5.00736 3.00736 4 4.25 4H19.75Z",
      fill: "url(#paint1_linear_6420_78)",
      fillOpacity: "0.7",
    }),
    React.createElement("path", {
      d: "M13.2523 12.9961H17.75C18.1642 12.9961 18.5 13.3319 18.5 13.7461C18.5 14.1258 18.2179 14.4396 17.8518 14.4893L17.75 14.4961H13.2523C12.8381 14.4961 12.5023 14.1604 12.5023 13.7461C12.5023 13.3664 12.7845 13.0526 13.1505 13.003L13.2523 12.9961H17.75H13.2523ZM13.2523 9.5H17.75C18.1642 9.5 18.5 9.83579 18.5 10.25C18.5 10.6297 18.2179 10.9435 17.8518 10.9932L17.75 11H13.2523C12.8381 11 12.5023 10.6642 12.5023 10.25C12.5023 9.8703 12.7845 9.55651 13.1505 9.50685L13.2523 9.5H17.75H13.2523Z",
      fill: "url(#paint2_linear_6420_78)",
    }),
    React.createElement("path", {
      d: "M9.75 12.5C10.1642 12.5 10.5 12.8358 10.5 13.25V13.7427L10.4921 13.8513C10.3293 14.9642 9.39767 15.5009 7.99995 15.5009C6.60213 15.5009 5.67048 14.9637 5.50787 13.8501L5.5 13.7418V13.25C5.5 12.8358 5.83579 12.5 6.25 12.5H9.75Z",
      fill: "url(#paint3_linear_6420_78)",
    }),
    React.createElement("path", {
      d: "M7.99997 8.5022C8.82839 8.5022 9.49995 9.17376 9.49995 10.0022C9.49995 10.8306 8.82839 11.5021 7.99997 11.5021C7.17156 11.5021 6.5 10.8306 6.5 10.0022C6.5 9.17376 7.17156 8.5022 7.99997 8.5022Z",
      fill: "url(#paint4_linear_6420_78)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_6420_78",
          x1: "9.14286",
          y1: "4",
          x2: "14.3116",
          y2: "19.9499",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#B3E0FF",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#8CD0FF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_6420_78",
          x1: "15.0952",
          y1: "5.96548",
          x2: "19.4859",
          y2: "28.7967",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.446512",
          stopColor: "#FF6CE8",
          stopOpacity: "0",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#FF6CE8",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_6420_78",
          x1: "14.9559",
          y1: "8.92352",
          x2: "17.0963",
          y2: "18.6856",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#0078D4",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#0067BF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_6420_78",
          x1: "5.5",
          y1: "10.6785",
          x2: "6.69258",
          y2: "15.92",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.125321",
          stopColor: "#9C6CFE",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#7A41DC",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_6420_78",
          x1: "6.5",
          y1: "7.50221",
          x2: "8.74228",
          y2: "11.3423",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.125321",
          stopColor: "#9C6CFE",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#7A41DC",
        })
      )
    ),
  ]
);

export const LearnPromptsIcon = createFluentIconExtended(
  "LearnPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M6.2998 3C7.1278 3 7.7998 3.672 7.7998 4.5V19.5C7.7998 20.328 7.1278 21 6.2998 21H4.2998C3.4718 21 2.7998 20.328 2.7998 19.5V4.5C2.7998 3.672 3.4718 3 4.2998 3H6.2998Z",
      fill: "url(#paint0_linear_3412_141798)",
    }),
    React.createElement("path", {
      d: "M12.2998 3C13.1278 3 13.7998 3.672 13.7998 4.5V19.5C13.7998 20.328 13.1278 21 12.2998 21H10.2998C9.4718 21 8.7998 20.328 8.7998 19.5V4.5C8.7998 3.672 9.4718 3 10.2998 3H12.2998Z",
      fill: "url(#paint1_linear_3412_141798)",
    }),
    React.createElement("path", {
      d: "M22.795 18.6429L19.581 6.12388C19.375 5.32288 18.558 4.83888 17.755 5.04388L15.879 5.52688C15.076 5.73288 14.593 6.54988 14.799 7.35288L18.013 19.8699C18.219 20.6729 19.036 21.1559 19.839 20.9499L21.715 20.4689C22.518 20.2629 23.001 19.4449 22.795 18.6429Z",
      fill: "url(#paint2_linear_3412_141798)",
    }),
    React.createElement("path", {
      d: "M2.7998 6H7.7998V8H2.7998V6Z",
      fill: "url(#paint3_linear_3412_141798)",
    }),
    React.createElement("path", {
      d: "M15.7816 11.1799L20.5664 9.96233L20.069 8.02515L15.2842 9.24272L15.7816 11.1799Z",
      fill: "url(#paint4_linear_3412_141798)",
    }),
    React.createElement("path", {
      d: "M13.7998 6H8.7998V8H13.7998V6Z",
      fill: "url(#paint5_linear_3412_141798)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_3412_141798",
          x1: "-0.950151",
          y1: "3",
          x2: "3.50017",
          y2: "26.492",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#43E5CA",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#2764E7",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_3412_141798",
          x1: "-0.950151",
          y1: "3",
          x2: "3.50017",
          y2: "26.492",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#43E5CA",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#2764E7",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_3412_141798",
          x1: "-0.950151",
          y1: "3",
          x2: "3.50017",
          y2: "26.492",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#43E5CA",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#2764E7",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_3412_141798",
          x1: "8.7998",
          y1: "2.17773",
          x2: "14.7693",
          y2: "4.42667",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#9FF0F9",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#6CE0FF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_3412_141798",
          x1: "8.7998",
          y1: "2.17773",
          x2: "14.7693",
          y2: "4.42667",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#9FF0F9",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#6CE0FF",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint5_linear_3412_141798",
          x1: "8.7998",
          y1: "2.17773",
          x2: "14.7693",
          y2: "4.42667",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          "stop-color": "#9FF0F9",
        }),
        React.createElement("stop", {
          offset: "1",
          "stop-color": "#6CE0FF",
        })
      )
    ),
  ]
);

export const ManagePromptsIcon = createFluentIconExtended(
  "ManagePromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M7.2002 7.5V6.25244C7.2002 5.0098 8.20755 4.00244 9.4502 4.00244H14.9502C16.1928 4.00244 17.2002 5.0098 17.2002 6.25244V7.5H15.7002V6.25244C15.7002 5.83823 15.3644 5.50244 14.9502 5.50244H9.4502C9.03598 5.50244 8.7002 5.83823 8.7002 6.25244V7.5H7.2002Z",
      fill: "url(#paint0_linear_3412_141808)",
    }),
    React.createElement("path", {
      d: "M4.4502 19.9966C3.20756 19.9966 2.2002 18.9892 2.2002 17.7466V11.5H22.2002V17.7466C22.2002 18.9892 21.1928 19.9966 19.9502 19.9966H4.4502Z",
      fill: "url(#paint1_linear_3412_141808)",
    }),
    React.createElement("path", {
      d: "M2.2002 9.24975C2.2002 8.00711 3.20756 6.99976 4.4502 6.99976H19.9502C21.1928 6.99976 22.2002 8.00711 22.2002 9.24976V11.9998H2.2002V9.24975Z",
      fill: "url(#paint2_linear_3412_141808)",
    }),
    React.createElement("path", {
      d: "M16.9502 9.99976C17.3644 9.99976 17.7002 10.3355 17.7002 10.7498V13.2498C17.7002 13.664 17.3644 13.9998 16.9502 13.9998C16.536 13.9998 16.2002 13.664 16.2002 13.2498V10.7498C16.2002 10.3355 16.536 9.99976 16.9502 9.99976Z",
      fill: "url(#paint3_linear_3412_141808)",
    }),
    React.createElement("path", {
      d: "M7.4502 9.99976C7.86441 9.99976 8.2002 10.3355 8.2002 10.7498V13.2498C8.2002 13.664 7.86441 13.9998 7.4502 13.9998C7.03598 13.9998 6.7002 13.664 6.7002 13.2498V10.7498C6.7002 10.3355 7.03598 9.99976 7.4502 9.99976Z",
      fill: "url(#paint4_linear_3412_141808)",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_3412_141808",
          x1: "3.62877",
          y1: "4.00244",
          x2: "4.0404",
          y2: "7.06872",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#B9C0C7",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#70777D",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_3412_141808",
          x1: "0.950194",
          y1: "8.31378",
          x2: "2.77697",
          y2: "28.3143",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          offset: "0.124704",
          stopColor: "#F97DBD",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#D7257D",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_3412_141808",
          x1: "0.950193",
          y1: "5.12476",
          x2: "1.32868",
          y2: "17.094",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#F97DBD",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#D7257D",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_3412_141808",
          x1: "2.77162",
          y1: "9.99976",
          x2: "3.52718",
          y2: "14.3304",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#EFEFEF",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#DADADA",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_3412_141808",
          x1: "2.77162",
          y1: "9.99976",
          x2: "3.52718",
          y2: "14.3304",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#EFEFEF",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#DADADA",
        })
      )
    ),
  ]
);

export const PreparePromptsIcon = createFluentIconExtended(
  "PreparePromptsIcon",
  "24px",
  [
    React.createElement("path", {
      d: "M4 6.25C4 5.00736 5.00736 4 6.25 4H17.75C18.9926 4 20 5.00736 20 6.25V19.75C20 20.9926 18.9926 22 17.75 22H6.25C5.00736 22 4 20.9926 4 19.75V6.25Z",
      fill: "url(#paint0_linear_390_21791)",
    }),
    React.createElement("path", {
      d: "M4 6.25C4 5.00736 5.00736 4 6.25 4H17.75C18.9926 4 20 5.00736 20 6.25V19.75C20 20.9926 18.9926 22 17.75 22H6.25C5.00736 22 4 20.9926 4 19.75V6.25Z",
      fill: "url(#paint1_radial_390_21791)",
    }),
    React.createElement("path", {
      d: "M4 6.25C4 5.00736 5.00736 4 6.25 4H17.75C18.9926 4 20 5.00736 20 6.25V19.75C20 20.9926 18.9926 22 17.75 22H6.25C5.00736 22 4 20.9926 4 19.75V6.25Z",
      fill: "url(#paint2_radial_390_21791)",
    }),
    React.createElement("path", {
      d: "M8 4.25C8 5.49264 9.00736 6.5 10.25 6.5H13.75C14.9926 6.5 16 5.49264 16 4.25C16 3.00736 14.9926 2 13.75 2H10.25C9.00736 2 8 3.00736 8 4.25Z",
      fill: "url(#paint3_linear_390_21791)",
    }),
    React.createElement("path", {
      d: "M17.0303 11.0303C17.3232 10.7374 17.3232 10.2626 17.0303 9.96967C16.7374 9.67678 16.2626 9.67678 15.9697 9.96967L11 14.9393L9.03033 12.9697C8.73744 12.6768 8.26256 12.6768 7.96967 12.9697C7.67678 13.2626 7.67678 13.7374 7.96967 14.0303L10.4697 16.5303C10.7626 16.8232 11.2374 16.8232 11.5303 16.5303L17.0303 11.0303Z",
      fill: "url(#paint4_linear_390_21791)",
      fillOpacity: "0.9",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_390_21791",
          x1: "4",
          y1: "5.8",
          x2: "18.1463",
          y2: "23.4829",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#36DFF1",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#0094F0",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint1_radial_390_21791",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform: "translate(12 3) rotate(90) scale(6.16892 6.75)",
        },
        React.createElement("stop", {
          stopColor: "#0A1852",
        }),
        React.createElement("stop", {
          offset: "0.9",
          stopColor: "#0A1852",
          stopOpacity: "0",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint2_radial_390_21791",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(12 4.61779) rotate(90) scale(2.79325 4.95298)",
        },
        React.createElement("stop", {
          stopColor: "#0A1852",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#0A1852",
          stopOpacity: "0",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_390_21791",
          x1: "12",
          y1: "2",
          x2: "12",
          y2: "6.5",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#FFE06B",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#FAB500",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_390_21791",
          x1: "18",
          y1: "18.5",
          x2: "10.2655",
          y2: "7.73189",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#9DEAFF",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "white",
        })
      )
    ),
  ]
);

export const UnderstandPromptsIcon = createFluentIconExtended(
  "UnderstandPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.2474 20.258C15.0242 21.2213 14.2013 21.9189 13.2286 21.9935L13.0555 22.0001H10.9437C9.9543 22.0001 9.08854 21.3552 8.79682 20.4236L8.75139 20.2563L8.34595 18.5H15.6541L15.2474 20.258Z",
      fill: "url(#paint0_radial_415063_2265)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.2474 20.258C15.0242 21.2213 14.2013 21.9189 13.2286 21.9935L13.0555 22.0001H10.9437C9.9543 22.0001 9.08854 21.3552 8.79682 20.4236L8.75139 20.2563L8.34595 18.5H15.6541L15.2474 20.258Z",
      fill: "url(#paint1_linear_415063_2265)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.23048 18H15.7698L16.4137 15.2167C16.4247 15.1692 16.4493 15.1259 16.4846 15.0922C18.3144 13.3448 19.25 11.388 19.25 9.25146C19.25 5.2474 16.0041 2.00146 12 2.00146C7.99593 2.00146 4.75 5.2474 4.75 9.25146C4.75 11.388 5.68616 13.3448 7.51701 15.0922C7.55234 15.126 7.57702 15.1693 7.58801 15.2169L8.23048 18Z",
      fill: "url(#paint2_radial_415063_2265)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.23048 18H15.7698L16.4137 15.2167C16.4247 15.1692 16.4493 15.1259 16.4846 15.0922C18.3144 13.3448 19.25 11.388 19.25 9.25146C19.25 5.2474 16.0041 2.00146 12 2.00146C7.99593 2.00146 4.75 5.2474 4.75 9.25146C4.75 11.388 5.68616 13.3448 7.51701 15.0922C7.55234 15.126 7.57702 15.1693 7.58801 15.2169L8.23048 18Z",
      fill: "url(#paint3_radial_415063_2265)",
      fillOpacity: "0.2",
    }),
    React.createElement("path", {
      d: "M8.46101 18.9995L8.11401 17.4995H15.886L15.538 18.9995H8.46101Z",
      fill: "url(#paint4_linear_415063_2265)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.5 12C20.5376 12 23 9.53757 23 6.5C23 3.46243 20.5376 1 17.5 1C14.4624 1 12 3.46243 12 6.5C12 9.53757 14.4624 12 17.5 12Z",
      fill: "url(#paint5_linear_415063_2265)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20.8536 4.85355C21.0488 4.65829 21.0488 4.34171 20.8536 4.14645C20.6583 3.95118 20.3417 3.95118 20.1464 4.14645L16.5 7.79289L14.8536 6.14645C14.6583 5.95118 14.3417 5.95118 14.1464 6.14645C13.9512 6.34171 13.9512 6.65829 14.1464 6.85355L16.1464 8.85355C16.3417 9.04882 16.6583 9.04882 16.8536 8.85355L20.8536 4.85355Z",
      fill: "url(#paint6_linear_415063_2265)",
      fillOpacity: "0.9",
    }),
    React.createElement(
      "defs",
      {},
      React.createElement(
        "radialGradient",
        {
          id: "paint0_radial_415063_2265",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(7.41916 7.57078) rotate(71.8063) scale(14.468 24.6328)",
        },
        React.createElement("stop", {
          stopColor: "#FFE06B",
        }),
        React.createElement("stop", {
          offset: "0.376456",
          stopColor: "#FFA43D",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#E67505",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_415063_2265",
          x1: "11.9995",
          y1: "18.5",
          x2: "11.9998",
          y2: "22.0001",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#D34719",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#D34719",
          stopOpacity: "0",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint2_radial_415063_2265",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform:
            "translate(7.16593 4.01193) rotate(74.8372) scale(18.226 26.4109)",
        },
        React.createElement("stop", {
          stopColor: "#FFE06B",
        }),
        React.createElement("stop", {
          offset: "0.376456",
          stopColor: "#FFA43D",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#E67505",
        })
      ),
      React.createElement(
        "radialGradient",
        {
          id: "paint3_radial_415063_2265",
          cx: "0",
          cy: "0",
          r: "1",
          gradientUnits: "userSpaceOnUse",
          gradientTransform: "translate(17.5 8) rotate(180) scale(7)",
        },
        React.createElement("stop", {
          offset: "0.165145",
          stopColor: "#741C06",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#741C06",
          stopOpacity: "0",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_415063_2265",
          x1: "11.3853",
          y1: "18.0953",
          x2: "12.7256",
          y2: "20.8744",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#FFC7A3",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#FF9C70",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint5_linear_415063_2265",
          x1: "12.3929",
          y1: "3.0625",
          x2: "19.9842",
          y2: "10.9496",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "#52D17C",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#22918B",
        })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint6_linear_415063_2265",
          x1: "15.3125",
          y1: "4.51003",
          x2: "16.4497",
          y2: "10.1303",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", {
          stopColor: "white",
        }),
        React.createElement("stop", {
          offset: "1",
          stopColor: "#E3FFD9",
        })
      )
    ),
  ]
);

export const TenantPromptsIcon = createFluentIconExtended(
  "TenantPromptsIcon",
  "24px",
  [
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14 4.25V22H3.75C3.33579 22 3 21.6642 3 21.25V4.25C3 3.00736 4.00736 2 5.25 2H11.75C12.9926 2 14 3.00736 14 4.25Z",
      fill: "url(#paint0_linear_4559_102748)",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14 4.25V22H3.75C3.33579 22 3 21.6642 3 21.25V4.25C3 3.00736 4.00736 2 5.25 2H11.75C12.9926 2 14 3.00736 14 4.25Z",
      fill: "url(#paint1_linear_4559_102748)",
      fillOpacity: "0.2",
    }),
    React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14 4.25V22H3.75C3.33579 22 3 21.6642 3 21.25V4.25C3 3.00736 4.00736 2 5.25 2H11.75C12.9926 2 14 3.00736 14 4.25Z",
      fill: "url(#paint2_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M8 6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6C6 5.44772 6.44772 5 7 5C7.55228 5 8 5.44772 8 6Z",
      fill: "url(#paint3_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M8 12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11C7.55228 11 8 11.4477 8 12Z",
      fill: "url(#paint4_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M8 9C8 9.55228 7.55228 10 7 10C6.44772 10 6 9.55228 6 9C6 8.44772 6.44772 8 7 8C7.55228 8 8 8.44772 8 9Z",
      fill: "url(#paint5_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M8 15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15C6 14.4477 6.44772 14 7 14C7.55228 14 8 14.4477 8 15Z",
      fill: "url(#paint6_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M8 18C8 18.5523 7.55228 19 7 19C6.44772 19 6 18.5523 6 18C6 17.4477 6.44772 17 7 17C7.55228 17 8 17.4477 8 18Z",
      fill: "url(#paint7_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M18.75 5C19.9926 5 21 6.00736 21 7.25V21.25C21 21.6642 20.6642 22 20.25 22C14.7988 22 15.0986 22 11.0027 22C10.4504 22 10 21.5523 10 21V7.25C10 6.00736 11.0074 5 12.25 5H18.75Z",
      fill: "url(#paint8_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M13 19C13 18.4477 13.4477 18 14 18H17C17.5523 18 18 18.4477 18 19V22H13V19Z",
      fill: "url(#paint9_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M15 9C15 9.55228 14.5523 10 14 10C13.4477 10 13 9.55228 13 9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9Z",
      fill: "url(#paint10_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M15 12C15 12.5523 14.5523 13 14 13C13.4477 13 13 12.5523 13 12C13 11.4477 13.4477 11 14 11C14.5523 11 15 11.4477 15 12Z",
      fill: "url(#paint11_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M15 15C15 15.5523 14.5523 16 14 16C13.4477 16 13 15.5523 13 15C13 14.4477 13.4477 14 14 14C14.5523 14 15 14.4477 15 15Z",
      fill: "url(#paint12_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M18 9C18 9.55228 17.5523 10 17 10C16.4477 10 16 9.55228 16 9C16 8.44772 16.4477 8 17 8C17.5523 8 18 8.44772 18 9Z",
      fill: "url(#paint13_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M18 12C18 12.5523 17.5523 13 17 13C16.4477 13 16 12.5523 16 12C16 11.4477 16.4477 11 17 11C17.5523 11 18 11.4477 18 12Z",
      fill: "url(#paint14_linear_4559_102748)",
    }),
    React.createElement("path", {
      d: "M18 15C18 15.5523 17.5523 16 17 16C16.4477 16 16 15.5523 16 15C16 14.4477 16.4477 14 17 14C17.5523 14 18 14.4477 18 15Z",
      fill: "url(#paint15_linear_4559_102748)",
    }),

    React.createElement(
      "defs",
      {},
      React.createElement(
        "linearGradient",
        {
          id: "paint0_linear_4559_102748",
          x1: "3.39286",
          y1: "5.75",
          x2: "15.2929",
          y2: "12.55",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#A3A3FF" }),
        React.createElement("stop", { offset: "1", stopColor: "#5750E2" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint1_linear_4559_102748",
          x1: "6.85",
          y1: "4.5",
          x2: "10.7",
          y2: "4.5",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#30116E", stopOpacity: "0" }),
        React.createElement("stop", { offset: "1", stopColor: "#30116E" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint2_linear_4559_102748",
          x1: "10.8375",
          y1: "8.09375",
          x2: "10.66",
          y2: "2.00117",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#A3A3FF", stopOpacity: "0" }),
        React.createElement("stop", { offset: "1", stopColor: "#A3A3FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint3_linear_4559_102748",
          x1: "5.33333",
          y1: "4.33333",
          x2: "8",
          y2: "19.6667",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#D1D1FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint4_linear_4559_102748",
          x1: "5.33333",
          y1: "4.33333",
          x2: "8",
          y2: "19.6667",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#D1D1FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint5_linear_4559_102748",
          x1: "5.33333",
          y1: "4.33333",
          x2: "8",
          y2: "19.6667",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#D1D1FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint6_linear_4559_102748",
          x1: "5.33333",
          y1: "4.33333",
          x2: "8",
          y2: "19.6667",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#D1D1FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint7_linear_4559_102748",
          x1: "5.33333",
          y1: "4.33333",
          x2: "8",
          y2: "19.6667",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#D1D1FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint8_linear_4559_102748",
          x1: "10",
          y1: "5.53125",
          x2: "25.9405",
          y2: "18.7363",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#3BD5FF" }),
        React.createElement("stop", { offset: "1", stopColor: "#2764E7" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint9_linear_4559_102748",
          x1: "13.8036",
          y1: "18.75",
          x2: "16.4743",
          y2: "22.2185",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#0067BF" }),
        React.createElement("stop", { offset: "1", stopColor: "#003580" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint10_linear_4559_102748",
          x1: "14.5",
          y1: "7.11111",
          x2: "18.7062",
          y2: "16.575",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#B3E0FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint11_linear_4559_102748",
          x1: "14.5",
          y1: "7.11111",
          x2: "18.7062",
          y2: "16.575",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#B3E0FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint12_linear_4559_102748",
          x1: "14.5",
          y1: "7.11111",
          x2: "18.7062",
          y2: "16.575",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#B3E0FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint13_linear_4559_102748",
          x1: "14.5",
          y1: "7.11111",
          x2: "18.7062",
          y2: "16.575",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#B3E0FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint14_linear_4559_102748",
          x1: "14.5",
          y1: "7.11111",
          x2: "18.7062",
          y2: "16.575",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#B3E0FF" })
      ),
      React.createElement(
        "linearGradient",
        {
          id: "paint15_linear_4559_102748",
          x1: "14.5",
          y1: "7.11111",
          x2: "18.7062",
          y2: "16.575",
          gradientUnits: "userSpaceOnUse",
        },
        React.createElement("stop", { stopColor: "#FDFDFD" }),
        React.createElement("stop", { offset: "1", stopColor: "#B3E0FF" })
      )
    ),
  ]
);
