import type { IPublicClientApplication } from "@azure/msal-browser";
import type { IFeedbackComplianceChecks } from "@ms/inapp-feedback-loader";
import {
  FeedbackAgeGroup,
  FeedbackAuthenticationType,
  FeedbackPolicyValue,
} from "@ms/inapp-feedback-loader";
import {
  OcpsSettingID,
  fetchUserOcpsSettings,
  getUserOcpsSetting,
  type OcpsResponse,
  type TenantType,
} from "./ocpsClient.web";

export function getTenantType(): TenantType {
  const tenantRegionScope = document
    .querySelector(`meta[name="awa-tenantRegionScope"]`)
    ?.getAttribute("content")
    ?.trim()
    ?.toLowerCase();

  return (tenantRegionScope as TenantType) ?? "public";
}

/**
 * Returns the user compliance settings according to OCPS (see https://www.owiki.ms/wiki/Working_with_feedback_policies)
 * @returns User compliance settings
 */
export async function getComplianceChecks(
  authenticationType: FeedbackAuthenticationType,
  msalInstance?: IPublicClientApplication
): Promise<IFeedbackComplianceChecks> {
  // https://office.visualstudio.com/OC/_git/M365AdminUX?path=/modules/ocv-inapp-feedback/utilities/SchemaValidator.ts&version=GBmain&line=209&lineEnd=210&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents
  if (
    authenticationType === FeedbackAuthenticationType.MSA ||
    authenticationType === FeedbackAuthenticationType.Unauthenticated
  ) {
    return {
      policyAllowFeedback: FeedbackPolicyValue.Enabled,
      policyAllowScreenshot: FeedbackPolicyValue.Enabled,
      policyAllowContact: FeedbackPolicyValue.Enabled,
      // Connected Experiences toggle does not apply to feedback taking entry points
      // It's used to control whether or not the links that lead out of product should be shown
      connectedExperiences: FeedbackPolicyValue.NotConfigured,
    };
  }

  // TODO: this can actually be undefined.
  // or fetch the compliance checks from OCPS for AAD users
  const ocpsResponse = (await fetchUserOcpsSettings(
    undefined,
    msalInstance
  )) as OcpsResponse;

  const tenantType = getTenantType();

  return {
    //Connected Experiences is always Enabled by default
    connectedExperiences: await getUserOcpsSetting(
      ocpsResponse,
      OcpsSettingID.CONNECTED_EXPERIENCES_SETTING_ID,
      tenantType
    ),
    policyAllowFeedback: await getUserOcpsSetting(
      ocpsResponse,
      OcpsSettingID.SEND_FEEDBACK_SETTING_ID,
      tenantType
    ),
    policyAllowSurvey: await getUserOcpsSetting(
      ocpsResponse,
      OcpsSettingID.SEND_SURVEY_SETTING_ID,
      tenantType
    ),
    policyAllowScreenshot: await getUserOcpsSetting(
      ocpsResponse,
      OcpsSettingID.SCREENSHOT_SETTING_ID,
      tenantType
    ),
    policyAllowContact: await getUserOcpsSetting(
      ocpsResponse,
      OcpsSettingID.EMAILCOLLECTION_SETTING_ID,
      tenantType
    ),
    policyAllowContent: await getUserOcpsSetting(
      ocpsResponse,
      OcpsSettingID.LOG_COLLECTION_SETTING_ID,
      tenantType
    ),
  };
}

export function getAgeGroup(ageGroup: number): FeedbackAgeGroup {
  /************************************************************************************
	0: Undefined --> Adult
	1: MinorWithoutParentalConsent --> child / kid (under the age of consent)
	2: MinorWithParentalConsent --> child / kid (under age of consent)
	3: Adult --> Adult
	4: NotAdult --> Minor (above age of consent)
	5: MinorNoParentalConsentRequired --> Adult
	************************************************************************************/
  if (ageGroup === 1) {
    return FeedbackAgeGroup.MinorWithoutParentalConsent;
  } else if (ageGroup === 2) {
    return FeedbackAgeGroup.MinorWithParentalConsent;
  } else if (ageGroup === 3) {
    return FeedbackAgeGroup.Adult;
  } else if (ageGroup === 4) {
    return FeedbackAgeGroup.NotAdult;
  } else if (ageGroup === 5) {
    return FeedbackAgeGroup.MinorNoParentalConsentRequired;
  }

  return FeedbackAgeGroup.Undefined;
}

export function getAuthenticationType(
  authType: string
): FeedbackAuthenticationType {
  authType = authType.toLowerCase();
  if (authType === "aad") {
    return FeedbackAuthenticationType.AAD;
  } else if (authType === "msa") {
    return FeedbackAuthenticationType.MSA;
  }

  return FeedbackAuthenticationType.Unauthenticated;
}
