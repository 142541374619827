import type { Theme } from "@fluentui/react-components";
import {
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsLightTheme,
} from "@fluentui/react-components";

export const CustomLightTheme: Theme = {
  ...teamsLightTheme,
  colorBrandForeground1: "#464FEB", // m365 brand color from figma
  colorBrandForegroundOnLight: "#5F71FA", // m365 brand color from Figma (Global.Color.Brand.M365.100)
  colorPalettePurpleForeground2: "#8330E9", //  // m365 brand color from Figma
  colorBrandBackground: "#464FEB",
  colorBrandBackgroundHover: "#3E45C9",
  colorBrandBackgroundPressed: "#353696",
  colorBrandForeground2: "#3E45C9",
  colorBrandForeground2Hover: "#3B3FB2",
  colorBrandForeground2Pressed: "#27265C",
};

export const CustomDarkTheme: Theme = {
  ...teamsDarkTheme,
  colorBrandForeground1: "#464FEB", // m365 brand color from figma
  colorBrandForegroundOnLight: "#5F71FA", // m365 brand color from Figma (Global.Color.Brand.M365.100)
  colorPalettePurpleForeground2: "#8330E9", //  // m365 brand color from Figma
  colorBrandBackground: "#464FEB",
  colorBrandBackgroundHover: "#3E45C9",
  colorBrandBackgroundPressed: "#353696",
  colorBrandForeground2: "#3E45C9",
  colorBrandForeground2Hover: "#3B3FB2",
  colorBrandForeground2Pressed: "#27265C",
};

export const CustomHighContrastTheme: Theme = {
  ...teamsHighContrastTheme,
  colorBrandForeground1: "#464FEB", // m365 brand color from figma
  colorBrandForegroundOnLight: "#5F71FA", // m365 brand color from Figma (Global.Color.Brand.M365.100)
  colorPalettePurpleForeground2: "#8330E9", //  // m365 brand color from Figma
  colorBrandBackground: "#464FEB",
  colorBrandBackgroundHover: "#3E45C9",
  colorBrandBackgroundPressed: "#353696",
  colorBrandForeground2: "#3E45C9",
  colorBrandForeground2Hover: "#3B3FB2",
  colorBrandForeground2Pressed: "#27265C",
};
