import type { Prompt } from "@1js/pl-types";
import * as React from "react";
import type { PageHeaderProps } from "../../components/PageHeader/PageHeader.web.data";
import type { SiteSetting } from "../../types";
import { Layout } from "../Layout.web";
import { NotFoundPage } from "./NotFoundPage.web";

export type NotFoundPageContainerProps = {
  setting: SiteSetting;
  header: PageHeaderProps;
  prompts: Prompt[];
};
export const NotFoundPageContainer: React.FC<NotFoundPageContainerProps> = ({
  header,
  setting,
  prompts,
}) => {
  return (
    <Layout header={header} setting={setting}>
      <NotFoundPage prompts={prompts} data-testid="not-found-page-container" />
    </Layout>
  );
};
