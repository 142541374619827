import {
  makeStyles,
  shorthands,
  type GriffelStyle,
} from "@fluentui/react-components";
import type { Appearance } from "../data";

type Styles = {
  microsoftAvatarContainer: GriffelStyle;
  avatar: GriffelStyle;
  iconOnly: GriffelStyle;
  srOnly: GriffelStyle;
};

const copilotLabStyles: Styles = {
  microsoftAvatarContainer: {
    height: "20px",
    width: "20px",
  },
  avatar: {
    marginLeft: "0px",
    height: "20px",
    width: "20px",
  },
  iconOnly: {
    "> span": {
      paddingLeft: "1px",
      paddingRight: "1px",
    },
    paddingRight: "0px",
    marginRight: "0px",
  },
  srOnly: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "auto",
    position: "absolute",
    width: "1px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    ...shorthands.border("0px"),
    ...shorthands.margin("0px"),
    ...shorthands.padding("0px"),
  },
};

const copilotLabGoBoldStyles: Styles = {
  microsoftAvatarContainer: {
    height: "16px",
    width: "16px",
  },
  avatar: {
    marginLeft: "0px",
    height: "16px",
    width: "16px",
  },
  iconOnly: {
    paddingRight: "0px",
    marginRight: "0px",
    background: "transparent",
    height: "20px",
    width: "20px",
  },
  srOnly: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "auto",
    position: "absolute",
    width: "1px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    ...shorthands.border("0px"),
    ...shorthands.margin("0px"),
    ...shorthands.padding("0px"),
  },
};

const rockSteadyStyles: Styles = {
  microsoftAvatarContainer: {
    height: "16px",
    width: "16px",
  },
  avatar: {
    marginLeft: "0px",
    height: "16px",
    width: "16px",
  },
  iconOnly: {
    height: "20px",
    width: "20px",
    background: "transparent",
    padding: "0px",
    margin: "0px",
    "> span": {
      paddingRight: "0px",
      marginRight: "0px",
    },
  },
  srOnly: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "auto",
    position: "absolute",
    width: "1px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    ...shorthands.border("0px"),
    ...shorthands.margin("0px"),
    ...shorthands.padding("0px"),
  },
};

const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const useRockSteadyStyles = makeStyles(rockSteadyStyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "web-v2":
    case "in-app-v2":
      return useCopilotLabGoBoldStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    default:
      return useCopilotLabStyles;
  }
};
