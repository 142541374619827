import { type OcidValues } from "@1js/pl-types";
import { appendQueryParam } from "./url";

const OCID_QUERY_PARAM_NAME = "ocid";

/**
 * Append OCID query param to a URL
 * @param url original URL
 * @param ocid OCID value
 * @returns URL with appended OCID query param
 */
export const appendOcidQueryParam = (url: string, ocid: OcidValues) => {
  return appendQueryParam(url, OCID_QUERY_PARAM_NAME, ocid);
};
