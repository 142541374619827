import { makeStyles, mergeClasses } from "@fluentui/react-components";
import * as React from "react";

export type GridProps = {
  children: React.ReactNode;
  fluid?: boolean;
  goBold?: boolean;
  isResponsive?: boolean;
  className?: string;
  id?: string;
  role?: React.AriaRole;
  testid?: string;
  isMetaOsV2?: boolean;
};

const useStyles = makeStyles({
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    paddingRight: "0.75rem",
    paddingLeft: "0.75rem",
    width: "100%",
    maxWidth: "1280px",
    "@media (max-width: 1422px)": {
      maxWidth: "954px",
    },

    "@media (max-width: 953px) and (min-width: 541px)": {
      maxWidth: "628px",
      paddingRight: "5%",
      paddingLeft: "5%",
      width: "90%",
      boxSizing: "content-box",
    },

    "@media (max-width: 540px)": {
      width: "95%",
    },
  },
  containerMetaOsHome: {
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    "@media (min-width: 1920px)": {
      maxWidth: "1451px",
    },
    "@media (max-width: 1919px) and (min-width: 1024px)": {
      maxWidth: "calc(100vw - 400px)",
    },
    "@media (max-width: 1023px) and (min-width: 642px)": {
      maxWidth: "calc(100vw - 344px)",
    },
    "@media (max-width: 641px) and (min-width: 361px)": {
      maxWidth: "calc(100vw - 64px)",
    },
    "@media (max-width: 360px)": {
      maxWidth: "calc(100vw - 48px)",
    },
  },

  fluidContainer: {
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
    paddingRight: "0.75rem",
    paddingLeft: "0.75rem",
    "@media (min-width: 1084px)": {
      paddingRight: "5%",
      paddingLeft: "5%",
      width: "90%",
      boxSizing: "content-box",
    },
  },

  goBoldContainer: {
    marginRight: "auto",
    marginLeft: "auto",
    paddingRight: "0.75rem",
    paddingLeft: "0.75rem",
    width: "100%",

    maxWidth: "1088px",

    "@media (min-width: 2560px)": {
      maxWidth: "2200px",
    },

    "@media (max-width: 2560px) and (min-width: 1920px)": {
      maxWidth: "1644px",
    },

    "@media (max-width: 1920px) and (min-width: 1600px)": {
      maxWidth: "1366px",
    },

    "@media (min-width: 948px) and (max-width: 1125px)": {
      maxWidth: "810px",
    },

    "@media (max-width: 948px) and (min-width: 540px)": {
      maxWidth: "532px",
      paddingRight: "5%",
      paddingLeft: "5%",
      width: "90%",
      boxSizing: "content-box",
    },

    "@media (max-width: 540px)": {
      width: "95%",
    },
  },
});

export const Grid: React.FC<GridProps> = ({
  fluid,
  goBold,
  children,
  className,
  id,
  testid,
  role,
  isMetaOsV2,
}) => {
  const getStyles = () => {
    if (isMetaOsV2) {
      return mergeClasses(styles.containerMetaOsHome);
    } else {
      return mergeClasses(
        fluid
          ? styles.fluidContainer
          : goBold
            ? styles.goBoldContainer
            : styles.container,
        className
      );
    }
  };

  const styles = useStyles();
  return (
    <div id={id} role={role} data-testid={testid ?? ""} className={getStyles()}>
      {children}
    </div>
  );
};
