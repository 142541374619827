import {
  endsWith,
  isString,
  keys,
  lowerFirst,
  upperFirst,
} from "@1js/functional";
import { filterOutNull } from "@1js/functional/lib/filterOutNull";
import { throwError } from "@1js/functional/lib/throwError";
import type {
  StringDeclarationWithPlaceholders,
  StringDeclarationWithoutPlaceholders,
} from "./declareString";

export interface StringDescription {
  readonly key: string;
  readonly stringToBeLocalized: string;
  readonly comment: string;
  readonly placeholderNames: ReadonlyArray<string>;
}

function makeSentence(s: string): string {
  s = upperFirst(s.trim());
  if (s && !endsWith(s, ".")) {
    s += ".";
  }

  return s;
}

export function getStringDescriptionWithOnlyKey(
  key: string
): StringDescription {
  return {
    key: key,
    stringToBeLocalized: "",
    comment: "",
    placeholderNames: [],
  };
}

export function getStringDescriptionWithoutPlaceholders(
  key: string,
  declaration: StringDeclarationWithoutPlaceholders
): StringDescription {
  return {
    key,
    stringToBeLocalized: declaration.text,
    comment: declaration.comment,
    placeholderNames: [],
  };
}

export function getStringDescriptionWithPlaceholders<K extends string>(
  key: string,
  declaration: StringDeclarationWithPlaceholders<K>
): StringDescription {
  /* Format the string with {0}, {1}, ... as placeholders. */
  const placeholderNames = keys(declaration.placeholders)
    .filter(isString)
    .sort();

  const placeholders: { [key in K]: string } = {} as any;
  for (const name of keys(declaration.placeholders)) {
    placeholders[name] = isString(name)
      ? `{${placeholderNames.indexOf(name)}}`
      : throwError(`Placeholder name ${name} is not a string`);
  }

  const stringToBeLocalized = declaration.text(placeholders);

  /* Generate a single description that incorporates the placeholder descriptions. */
  const parts = [
    declaration.comment,
    ...placeholderNames.map((p, i) => {
      const placeholderComment = declaration.placeholders[p].trim();

      return !placeholderComment
        ? null
        : `{${i}} is a placeholder for ${lowerFirst(placeholderComment)}`;
    }),
  ];
  let comment = filterOutNull(parts)
    .map(makeSentence)
    .filter(Boolean)
    .join(" ");

  /* Replace named placeholders in the description with their numeric equivalents. */
  placeholderNames.forEach((name, index) => {
    comment = comment.replace("{" + name + "}", "{" + index + "}");
  });

  return {
    key,
    stringToBeLocalized,
    comment,
    placeholderNames,
  };
}
