import {
  Link,
  MenuItem,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import type { HeaderLinkMobileProps } from "./PageHeader.web.data";

const useStyles = makeStyles({
  main: {
    display: "flex",
    height: "24px",
    alignItems: "center",
    alignSelf: "stretch",
    backgroundColor: "#FFF",
    ...shorthands.borderRadius("8px"),
  },
  link: {
    "& span": {
      color: tokens.colorNeutralForeground2,
      textAlign: "center",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      paddingLeft: "0px",
    },
    "&:hover": {
      ...shorthands.textDecoration("none"),
      "&:focus, &:active": {
        ...shorthands.textDecoration("none"),
      },
    },
  },
  menuItem: {
    paddingLeft: "16px",
  },
});

export const HeaderLinkMobile: React.FC<HeaderLinkMobileProps> = ({
  url,
  displayText,
  openInNewWindow,
}) => {
  const styles = useStyles();

  return (
    <div
      className={styles.main}
      data-telemetry-labels="true"
      data-telemetry-urls="true"
    >
      <Link
        className={styles.link}
        href={url}
        target={openInNewWindow ? "_blank" : undefined}
      >
        <MenuItem className={styles.menuItem}>{displayText}</MenuItem>
      </Link>
    </div>
  );
};
