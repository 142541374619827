import { copyHtmlAndTextToClipboard } from "@1js/clipboard-utils";
import {
  useStrings,
  useStringsWithPlaceholders,
} from "@1js/localization-components";
import {
  buildSharePromptUrl,
  useSharePromptViaEmail,
  type Prompt,
  type ScenarioType,
} from "@1js/pl-card-components";
import { isCopilotChatPrompt, isWXPPrompt } from "@1js/pl-types";
import type { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { MetaOSAppContext } from "../pages/metaos/MetaOSAppContext";
import { PropertyContext } from "../pages/PropertyContext.web";
import { authAwareFetch, retry } from "../services/fetch";
import { createPromptLink } from "../services/prompt-client.web";
import { buildCoreHeaders } from "../utils/header";
import useTelemetry from "../utils/Telemetry/useTelemetry";
import { useFlightContext } from "./useFlightContext";
import {
  SharePromptPrettifiedBizChatLinkWithTitle,
  SharePromptPrettifiedBizChatLinkWithoutTitle,
  SharePromptPrettifiedLinkWithTitleV2,
  SharePromptPrettifiedLinkWithoutTitle,
} from "./useSharePrompt.strings";

const host = window.location.host;

// The Endpoint for sharing a prompt via email
export const sharePromptViaEmailServiceUrl = `https://${host}/api/graph/me/messages`;

export const useSharePrompt = () => {
  const telemetry = useTelemetry();
  const { isFlightActive } = useFlightContext();
  const [promptSettings] = React.useContext(PropertyContext);
  const sharePromptViaEmail = useSharePromptViaEmail();
  const stringsWithPlaceholders = useStringsWithPlaceholders({
    SharePromptPrettifiedBizChatLinkWithTitle,
    SharePromptPrettifiedLinkWithTitleV2,
  });
  const strings = useStrings({
    SharePromptPrettifiedBizChatLinkWithoutTitle,
    SharePromptPrettifiedLinkWithoutTitle,
  });
  const metaOSAppContext = React.useContext(MetaOSAppContext);
  const msalInstance = useMsal();
  const instance =
    metaOSAppContext.isMosApp && metaOSAppContext.pca
      ? msalInstance.instance
      : undefined;

  const buildPromptDetailPageUrl = React.useCallback((prompt: Prompt) => {
    const { host } = window.location;
    return `https://${host}/prompts/${prompt.Slug}`;
  }, []);

  const buildPromptDetailPageUrlWithLocale = React.useCallback(
    (prompt: Prompt) => {
      const { origin, pathname } = window.location;
      // Remove trailing slash if present
      let newPathname = pathname.replace(/\/$/, "");
      newPathname = newPathname.substring(0, newPathname.lastIndexOf("/"));
      return `${origin}${newPathname}/${prompt.Slug}`;
    },
    []
  );
  /**
   * Build the share URL for the prompt
   * @param prompt The prompt to share
   * @returns prompt share link
   */
  const buildPromptShareUrl = React.useCallback(
    async (prompt: Prompt, scenario?: ScenarioType): Promise<string> => {
      if (isFlightActive("isPromptSharingEnabled")) {
        if (
          isCopilotChatPrompt(prompt) ||
          (prompt.Origin === "User" && isWXPPrompt(prompt))
        ) {
          const serviceGeneratedLinkId = await createPromptLink(
            host,
            prompt,
            telemetry.sendApiActionEvent,
            instance
          );

          return buildSharePromptUrl(
            prompt,
            scenario ?? "shareLink",
            "CopilotLab_Web",
            serviceGeneratedLinkId.PromptEncodedLink,
            undefined,
            isFlightActive("isBizChatTryInTeamsAppEnabled")
          );
        }

        return buildSharePromptUrl(
          prompt,
          scenario ?? "sharePromptCopyLink",
          "CopilotLab_Web"
        );
      }

      return buildPromptDetailPageUrl(prompt);
    },
    [
      buildPromptDetailPageUrl,
      instance,
      isFlightActive,
      telemetry.sendApiActionEvent,
    ]
  );

  /**
   * Build the share link text for the prompt
   * @param prompt The prompt to share
   * @returns prompt share link text
   */
  const buildPromptShareLinkText = React.useCallback(
    (prompt: Prompt) => {
      const isPromptTitleEmpty = prompt.Title?.trim().length === 0;
      if (
        isFlightActive("isPromptSharingEnabled") &&
        isCopilotChatPrompt(prompt)
      ) {
        if (!isPromptTitleEmpty) {
          return stringsWithPlaceholders.SharePromptPrettifiedBizChatLinkWithTitle(
            {
              promptTitle: prompt.Title,
            }
          );
        }

        return strings.SharePromptPrettifiedBizChatLinkWithoutTitle();
      }

      if (isPromptTitleEmpty) {
        return strings.SharePromptPrettifiedLinkWithoutTitle();
      } else {
        return stringsWithPlaceholders.SharePromptPrettifiedLinkWithTitleV2({
          promptTitle: prompt.Title,
        });
      }
    },
    [stringsWithPlaceholders, isFlightActive, strings]
  );

  /**
   * To share a prompt URL via the default email client
   */
  const shareUrlViaEmail = React.useCallback(
    async (prompt: Prompt, msalContext?: IPublicClientApplication) => {
      const startTimer = performance.now();
      const promptUrl = await buildPromptShareUrl(prompt, "shareLinkViaEmail");

      const headers = await buildCoreHeaders(msalContext, undefined);
      headers.append(
        "RequestVerificationToken",
        document
          ?.querySelector('input[name="__RequestVerificationToken"]')
          ?.getAttribute("value") ?? ""
      );

      const response = await retry(() =>
        authAwareFetch(sharePromptViaEmailServiceUrl, {
          method: "POST",
          headers,
          body: JSON.stringify({
            subject: sharePromptViaEmail.getEmailSubject(
              prompt,
              promptSettings?.user?.displayName ?? ""
            ),
            body: {
              contentType: "HTML",
              content: sharePromptViaEmail.getEmailBody(
                prompt,
                promptSettings?.user?.displayName ?? "",
                promptUrl,
                "CopilotLab_Web"
              ),
            },
            toRecipients: [],
          }),
        })
      );

      const endTimer = performance.now();

      telemetry.sendSharePromptViaEmailEvent(
        prompt,
        endTimer - startTimer,
        response.status
      );

      const json = (await response.json()) as {
        id: string;
        webLink: string;
      };

      const webLink = new URL(json.webLink);
      const link = `${webLink.origin}/mail/deeplink/compose/${encodeURIComponent(json.id)}?ItemID=${encodeURIComponent(json.id)}&exvsurl=1`;
      window.open(link, "_blank", "noopener,noreferrer");
    },
    [sharePromptViaEmail, promptSettings, buildPromptShareUrl, telemetry]
  );

  /**
   * To share a prompt URL by copying the link
   */
  const shareUrlViaCopyLink = React.useCallback(
    async (prompt: Prompt) => {
      const urlToShare = await buildPromptShareUrl(prompt);
      const linkText = buildPromptShareLinkText(prompt);
      const htmlUrlToShare = `<a href="${urlToShare}">${linkText}</a>`;

      // TODO: Add a telemetry event for when the copy link has failed
      await copyHtmlAndTextToClipboard(urlToShare, htmlUrlToShare);

      telemetry.sendSharePromptEvent(prompt);
    },
    [buildPromptShareUrl, buildPromptShareLinkText, telemetry]
  );

  const [isShareToWorkgroupDialogOpen, setIsShareToWorkgroupDialogOpen] =
    React.useState<boolean>(false);
  const [promptsToShareToWorkgroup, setPromptsToShareToWorkgroup] =
    React.useState<Prompt[]>();
  const shareToWorkgroup = React.useCallback(
    (prompts: Prompt[]) => {
      telemetry.sendPromptActionEvent(
        "WorkgroupPublish",
        "PromptAction",
        "PublishToWorkgroupButton",
        prompts[0]
      );
      setIsShareToWorkgroupDialogOpen(true);
      setPromptsToShareToWorkgroup(prompts);
    },
    [telemetry]
  );

  const onCloseShareToWorkgroupDialog = React.useCallback(
    (isClosedByCustomer: boolean, prompts: Prompt[]) => {
      if (isClosedByCustomer) {
        telemetry.sendPromptActionEvent(
          "WorkgroupPublish",
          "Dialog",
          "CancelButton",
          prompts[0]
        );
      }
      setIsShareToWorkgroupDialogOpen(false);
    },
    [telemetry]
  );

  const onGroupDropdownOpenChange = React.useCallback(
    (prompt: any, isOpen: boolean) => {
      if (prompt) {
        telemetry.sendPromptActionEvent(
          "WorkgroupPublish",
          "Dialog",
          isOpen ? "OpenDropdown" : "CloseDropdown",
          prompt
        );
      }
    },
    [telemetry]
  );

  return {
    shareUrlViaCopyLink,
    shareUrlViaEmail,
    buildPromptShareUrl,
    buildPromptDetailPageUrl,
    buildPromptDetailPageUrlWithLocale,
    shareToWorkgroup,
    isShareToWorkgroupDialogOpen,
    promptsToShareToWorkgroup,
    onCloseShareToWorkgroupDialog,
    onGroupDropdownOpenChange,
  };
};
