import { makeStyles, mergeClasses } from "@fluentui/react-components";
import * as React from "react";
import { AdditionalBadges } from "./PromptBadge/AdditionalBadges";
import { NonInteractivePromptBadge } from "./PromptBadge/PromptBadge";
import type { PromptBadgesProps } from "./PromptBadges.types";
export type DisplayMode = "iconOnly" | "textOnly" | "iconAndText";

const useStyles = makeStyles({
  badgeContainer: {
    marginRight: "8px",
    display: "inline-block",
  },
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  wrapOverflow: {
    flexWrap: "wrap",
    rowGap: "8px",
  },
});

export const PromptBadges: React.FC<PromptBadgesProps> = ({
  badges,
  maxNumber,
  displayMode,
  tagSize = "small",
  labelWrapper,
  wrapOverflow,
}) => {
  const badgesSlice = badges.slice(0, maxNumber);
  const extraBadges = badges.slice(maxNumber);
  const styles = useStyles();

  return (
    //Replacing PromptBadgeGroup with div because it is causing a bug with tabbing to the bookmark button
    <div
      role="list"
      className={mergeClasses(styles.list, wrapOverflow && styles.wrapOverflow)}
    >
      {badgesSlice.map((badge) => (
        <div className={styles.badgeContainer} key={badge.id}>
          <NonInteractivePromptBadge
            key={badge.id}
            badge={badge}
            tagSize={tagSize}
            displayMode={displayMode}
            labelWrapper={labelWrapper}
          />
        </div>
      ))}
      <div className={styles.badgeContainer}>
        <AdditionalBadges badges={extraBadges} tagSize={tagSize} />
      </div>
    </div>
  );
};
