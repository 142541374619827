import { useStringsWithPlaceholders } from "@1js/localization-components";
import type { Prompt } from "@1js/pl-types";
import { Tooltip, mergeClasses, useId } from "@fluentui/react-components";
import { Info16Regular } from "@fluentui/react-icons";
import * as React from "react";
import { useStyles } from "./PluginPrompts.styles";
import { PluginTooltip } from "./PluginPromptsToolTip.strings";

type PluginPromptsToolTipProps = {
  prompt: Prompt;
};

export const PluginPromptsToolTip: React.FC<PluginPromptsToolTipProps> = ({
  prompt,
}) => {
  const styles = useStyles();
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
  const toolTipId = useId("AlsoWorksInTooltipContent");
  const localizationsWithPlaceholders = useStringsWithPlaceholders({
    PluginTooltip,
  });

  return (
    <Tooltip
      content={{
        children: localizationsWithPlaceholders.PluginTooltip({
          extensionName: prompt.ProviderName as string,
        }),
        id: toolTipId,
      }}
      positioning="above-start"
      withArrow
      relationship="label"
      onVisibleChange={(_, data) => setIsTooltipVisible(data.visible)}
    >
      <Info16Regular
        data-testid="plugin-info"
        tabIndex={0}
        className={mergeClasses(
          styles.tooltipIcon,
          isTooltipVisible && styles.visibleTooltipIcon
        )}
      />
    </Tooltip>
  );
};
