import { usePromptFilterLocalizations } from "@1js/pl-card-components";
import {
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tab,
  Text,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import type { HeaderDropDownProps } from "./PageHeader.web.data";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "46px",
    ...shorthands.borderRadius("4px"),
  },
  tab: {
    height: "46px",
  },
  button: {
    color: tokens.colorNeutralForeground2,
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    "&:hover": {
      color: tokens.colorNeutralForeground2BrandHover,
    },
  },
  link: {
    "& span": {
      color: tokens.colorNeutralForeground2,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      "&:hover": {
        color: tokens.colorNeutralForeground2BrandHover,
      },
    },
    "&:hover": {
      ...shorthands.textDecoration("none"),
      "&:focus, &:active": {
        ...shorthands.textDecoration("none"),
      },
    },
  },
});

export const HeaderDropDown: React.FC<HeaderDropDownProps> = ({
  value,
  displayText,
  linkDictionary,
  telemetryParentId = null,
}) => {
  const styles = useStyles();
  const { facetValueToLabel } = usePromptFilterLocalizations();

  return (
    <div className={styles.main} data-testid="products-tab" role="tab">
      <Tab className={styles.tab} value={value} tabIndex={-1} id={value}>
        <Menu>
          <MenuTrigger>
            <MenuButton
              className={styles.button}
              as="a"
              appearance="transparent"
            >
              {displayText}
            </MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList tabIndex={0}>
              {linkDictionary.map((item) => (
                <Link
                  key={item.displayText}
                  className={styles.link}
                  href={item.url}
                  target={item.openInNewWindow ? "_blank" : "_self"}
                  data-testid="products-dropDown"
                  data-telemetry-labels="true"
                  data-telemetry-urls="true"
                  data-telemetry-parent-id={telemetryParentId}
                >
                  <MenuItem>
                    <Text>{facetValueToLabel(item.displayText)}</Text>
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </Tab>
    </div>
  );
};
