import type { Group } from "@1js/pl-types";
import { makeStyles, shorthands, Tag, Text } from "@fluentui/react-components";
import * as React from "react";
import type { Appearance, Prompt } from "../../data";
import { PromptAuthor } from "../PromptAuthor";
import { PromptCardActionButtons } from "../PromptCardActionButtons";

type GoBoldPromptCardHeaderProps = {
  prompt: Prompt;
  appearance?: Appearance;
  isFavoriteEnabled?: boolean;
  isBookmarking?: boolean;
  isCardFocused?: boolean;
  isFavorited?: boolean;
  onFavorited?: () => void;
  isDeleting?: boolean;
  isRemoving?: boolean;
  isAgentPromptFavoriteEnabled?: boolean;
  isPromptSavingEnabled?: boolean;
  isPromptEditingEnabled?: boolean;
  isPromptSharingEnabled?: boolean;
  isPromptSharingViaEmailEnabled?: boolean;
  isPromptSharingToWorkgroupEnabled?: boolean;
  isPromptRemovingFromWorkgroupEnabled?: boolean;
  isPromptSharingOneClickUxEnabled?: boolean;
  isPromptLikingEnabled?: boolean;
  onShareViaCopyLinkClicked?: (prompt: Prompt) => void;
  onShareViaEmailClicked?: (prompt: Prompt) => void | Promise<void>;
  onShareToWorkgroupClicked?: (prompts: Prompt[]) => void | Promise<void>;
  onDelete?: () => void;
  onEditToggled?: () => void;
  onEditSaved?: () => void;
  onRemoveFromWorkgroupClicked?: (
    prompts: Prompt[],
    groupIds: string[]
  ) => void;
  isEditing?: boolean;
  isPromptRingTagEnabled?: boolean;
  selectedGroupId?: string;
  groups?: Group[];
  setShareButtonTarget?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  isShowPromptAuthor?: boolean;
  extensionIconMap?: Record<string, string>;
};

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    marginBottom: "8px",
    height: "20px",
  },
  statusBar: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  promptRingText: {
    fontSize: "12px",
    fontWeight: 400,
  },
});

export const GoBoldPromptCardHeader: React.FC<GoBoldPromptCardHeaderProps> = ({
  appearance,
  prompt,
  isFavorited,
  isFavoriteEnabled,
  isBookmarking,
  isCardFocused,
  onFavorited,
  isDeleting,
  isRemoving,
  isPromptSavingEnabled,
  isPromptSharingOneClickUxEnabled,
  onDelete: onDelete,
  isPromptEditingEnabled,
  isPromptLikingEnabled,
  onEditToggled,
  onEditSaved,
  onRemoveFromWorkgroupClicked,
  isEditing,
  isAgentPromptFavoriteEnabled,
  isPromptSharingEnabled,
  isPromptSharingViaEmailEnabled,
  isPromptSharingToWorkgroupEnabled,
  isPromptRemovingFromWorkgroupEnabled,
  onShareViaCopyLinkClicked,
  onShareViaEmailClicked,
  onShareToWorkgroupClicked,
  isPromptRingTagEnabled,
  selectedGroupId,
  groups,
  setShareButtonTarget,
  isShowPromptAuthor,
  extensionIconMap,
}) => {
  const styles = useStyles();
  const tabIndex = isCardFocused ? 0 : -1;

  return (
    <div className={styles.header}>
      <div className={styles.statusBar}>
        {((isPromptSavingEnabled &&
          (selectedGroupId === "Default" || !selectedGroupId)) ||
          isShowPromptAuthor) && (
          <PromptAuthor
            prompt={prompt}
            tabIndex={tabIndex}
            extensionIconMap={extensionIconMap}
            appearance={appearance}
          />
        )}
        <div className={"hideOnCardHover"}>
          {isPromptRingTagEnabled && prompt.PromptLabel && (
            <Tag size="extra-small" appearance={"outline"}>
              <Text className={styles.promptRingText}>
                {prompt.PromptLabel}
              </Text>
            </Tag>
          )}
        </div>
      </div>
      <PromptCardActionButtons
        prompt={prompt}
        appearance={appearance}
        isFavoriteEnabled={isFavoriteEnabled}
        isBookmarking={isBookmarking}
        isFavorited={isFavorited}
        onFavorited={onFavorited}
        isDeleting={isDeleting}
        isRemoving={isRemoving}
        isAgentPromptFavoriteEnabled={isAgentPromptFavoriteEnabled}
        isPromptSavingEnabled={isPromptSavingEnabled}
        isPromptEditingEnabled={isPromptEditingEnabled}
        isPromptSharingEnabled={isPromptSharingEnabled}
        isPromptSharingViaEmailEnabled={isPromptSharingViaEmailEnabled}
        isPromptSharingToWorkgroupEnabled={isPromptSharingToWorkgroupEnabled}
        isPromptRemovingFromWorkgroupEnabled={
          isPromptRemovingFromWorkgroupEnabled
        }
        isPromptSharingOneClickUxEnabled={isPromptSharingOneClickUxEnabled}
        isPromptLikingEnabled={isPromptLikingEnabled}
        onShareViaCopyLinkClicked={onShareViaCopyLinkClicked}
        onShareViaEmailClicked={onShareViaEmailClicked}
        onShareToWorkgroupClicked={onShareToWorkgroupClicked}
        onDelete={onDelete}
        onEditToggled={onEditToggled}
        onEditSaved={onEditSaved}
        onRemoveFromWorkgroupClicked={onRemoveFromWorkgroupClicked}
        isEditing={isEditing}
        selectedGroupId={selectedGroupId}
        groups={groups}
        setShareButtonTarget={setShareButtonTarget}
        tabIndex={tabIndex}
      />
    </div>
  );
};
