export enum Event3sType {
  ResponseReceived = "ResponseReceived",
  ResultsRendered = "ResultsRendered",
  SearchEntityClicked = "SearchEntityClicked",
  SearchEntityActions = "SearchEntityActions",
  ClientLayout = "ClientLayout",
  EntityActionTaken = "EntityActionTaken",
  ChevronClicked = "ChevronClicked",
  SearchActions = "SearchActions",
  ClientDataSource = "ClientDataSource",
}

export type Event3sTypeV2 =
  | "ResponseReceived"
  | "ResultsRendered"
  | "SearchEntityClicked"
  | "SearchEntityActions"
  | "ClientLayout"
  | "EntityActionTaken"
  | "ChevronClicked"
  | "SearchActions"
  | "ClientDataSource";

export type ImpressionType = "Suggestions" | "Query" | "Answers";

export enum EntityActionTakenType {
  CopyLink = "CopyLink",
  SendCopy = "SendCopy",
  Share = "Share",
  Download = "Download",
  Unsubscribe = "Unsubscribe",
}

export type EntityActionTakenTypeV2 =
  | "CopyLink"
  | "SendCopy"
  | "Share"
  | "Download"
  | "Unsubscribe";

export enum ScrollbarMovementType {
  Up = "Up",
  Down = "Down",
  Left = "Left",
  Right = "Right",
}

export type ScrollbarMovementTypeV2 = "Up" | "Down" | "Left" | "Right";

/**
 * Enum representing various user action types.
 */
export enum SearchActionEventType {
  /** Optional. User puts focus on the search box. */
  SearchBoxFocus = "SearchBoxFocus",

  /** Represents the user action: hitting <enter> key, clicking search button, clicking search magnifier icon in search box, or clicking “search” link at the bottom of QF pane. */
  SearchDone = "searchdone",

  /** Select the “Exit Search” button on the top of the page */
  ExitSearch = "ExitSearch",

  /** Select the verticals on the search page. Check the “VerticalType” & “Supporting Ids” metadata below. */
  VerticalClicked = "VerticalClicked",

  /** Select the pagination control by the user. Check the “Supporting Ids” metadata below. */
  PaginationClicked = "PaginationClicked",

  /** Select the “More <entityGroup>” or “More from <contentSource>” button/link. Check “ExpansionType” & “Supporting Ids” metadata below. */
  ExpandLinkClicked = "ExpandLinkClicked",

  /** Select the “Back” button on L2 page to return to previous All SERP page. This should only be applied to the feature that has L2 page experience now, such as Teams and Channels feature on Teams Mobile. */
  BackButtonClicked = "BackButtonClicked",

  /** User selects the link in a scoped context SERP without any results which triggers the up-scope search. */
  UpScopeSearch = "UpScopeSearch",

  /** User selects the scope control to open the scope options dropdown box. */
  ScopePaneClicked = "ScopePaneClicked",

  /** User selects one of the scope options in the dropdown box. Check "ScopeOptionType", "PreviousScopeType" and "ClickDetails" metadata below. “PreviousScopeType” and "ScopeOptionType" are the scope option types before and after selected. "ClickDetails" field with a value of "Upscope" indicates that the action is an upscope action. */
  ScopeModified = "ScopeModified",

  /** User selects the non-entity breadcrumb which triggers an upscope search. */
  BreadcrumbClicked = "BreadcrumbClicked",

  /** A Contact is added by manually add means. */
  InsertContact = "InsertContact",

  /** User removes a contact which came from manually add means other than suggestion list clicks. */
  RemoveContact = "RemoveContact",

  /** User selects the dismiss button to dismiss a group, making this group disappear from rendering. Metadata GroupId needed for this event. The value for GroupId should be the same as the referenceId for this group in ClientLayout ResultsView. */
  DismissClicked = "DismissClicked",

  /** Select the filter pane/dropdown - on the search pane header. */
  FilterPaneClicked = "FilterPaneClicked",

  /** User modified the filter to narrow/expand the search results. Check “FilterTypes” & “Supporting Ids” metadata below. */
  FilterModified = "FilterModified",

  /** User selects "Clear filters" to clear any previously selected filters */
  ClearFilters = "ClearFilters",

  /** Movement of the scroll bar on the search page. Check metadata below. */
  ScrollBarMoved = "ScrollBarMoved",

  /** User selects the buttons/links like “x-more” to view hidden items. It could be collapsed verticals/tabs (due to space constraint) or entities hidden in a region which can be expanded by specific user interaction. Check ExpansionType metadata below. */
  ExpandHiddenItems = "ExpandHiddenItems",

  /** User selects the dismiss button of the PeoplePill */
  PeoplePillRemoved = "PeoplePillRemoved",

  /** User selects a link to see privacy information */
  PrivacyInformationClicked = "PrivacyInformationClicked",

  /** User selects a link to see privacy information */
  PrivacyInformationLinkClicked = "PrivacyInformationLinkClicked",

  /** User selects one of the verticals in People Centric Search */
  PeopleScopeVerticalClicked = "PeopleScopeVerticalClicked",

  /** User sent the email. */
  EmailSent = "EmailSent",

  /** User cancelled the email. */
  EmailCancelled = "EmailCancelled",

  /** User created a new meeting. */
  MeetingCreated = "MeetingCreated",

  /** User cancelled the meeting. */
  MeetingCancelled = "MeetingCancelled",

  /** User navigated from another entrypoint */
  CrossEntryPointNavigationDone = "CrossEntryPointNavigationDone",

  /** User selects an entity on the page which doesn't come from search results or doesn't have a direct relationship with the search results For example, selecting a non-retrieved utterance in transcript search. */
  NonSearchedEntityClicked = "NonSearchedEntityClicked",

  /** Search text entered by the user has been cleared via user interaction like selecting a "clear link" in mainline. */
  ClearSearchText = "ClearSearchText",

  /** User selects a group header which will trigger a new logical impression with scope. Check the “ScopeHeaderType” metadata below. */
  ScopeHeaderClicked = "ScopeHeaderClicked",

  /** User remove the items which don't have reference id now. Such as custom filters in filter bar in Office.com. Check "ItemTypes" metadata below. When applied in custom filters, please check "FilterTypes" metadata as well. */
  RemoveItem = "RemoveItem",

  /** Denotes that user switches to another account, including multi-account. It is only used by the client with multi-account scenario enabled, e.g., Outlook Mobile, Outlook Mac, etc. */
  AccountSwitched = "AccountSwitched",

  /** Denotes that user clickes on the chevron in the carousel, etc. */
  ChevronClicked = "ChevronClicked",
}

export type SearchActionEventTypeV2 =
  /** Optional. User puts focus on the search box. */
  | "SearchBoxFocus"
  /** Represents the user action: hitting <enter> key, clicking search button, clicking search magnifier icon in search box, or clicking “search” link at the bottom of QF pane. */
  | "searchdone"
  /** Select the “Exit Search” button on the top of the page */
  | "ExitSearch"
  /** Select the verticals on the search page. Check the “VerticalType” & “Supporting Ids” metadata below. */
  | "VerticalClicked"
  /** Select the pagination control by the user. Check the “Supporting Ids” metadata below. */
  | "PaginationClicked"
  /** Select the “More <entityGroup>” or “More from <contentSource>” button/link. Check “ExpansionType” & “Supporting Ids” metadata below. */
  | "ExpandLinkClicked"
  /** Select the “Back” button on L2 page to return to previous All SERP page. This should only be applied to the feature that has L2 page experience now, such as Teams and Channels feature on Teams Mobile. */
  | "BackButtonClicked"
  /** User selects the link in a scoped context SERP without any results which triggers the up-scope search. */
  | "UpScopeSearch"
  /** User selects the scope control to open the scope options dropdown box. */
  | "ScopePaneClicked"
  /** User selects one of the scope options in the dropdown box. Check "ScopeOptionType", "PreviousScopeType" and "ClickDetails" metadata below. “PreviousScopeType” and "ScopeOptionType" are the scope option types before and after selected. "ClickDetails" field with a value of "Upscope" indicates that the action is an upscope action. */
  | "ScopeModified"
  /** User selects the non-entity breadcrumb which triggers an upscope search. */
  | "BreadcrumbClicked"
  /** A Contact is added by manually add means. */
  | "InsertContact"
  /** User removes a contact which came from manually add means other than suggestion list clicks. */
  | "RemoveContact"
  /** User selects the dismiss button to dismiss a group, making this group disappear from rendering. Metadata GroupId needed for this event. The value for GroupId should be the same as the referenceId for this group in ClientLayout ResultsView. */
  | "DismissClicked"
  /** Select the filter pane/dropdown - on the search pane header. */
  | "FilterPaneClicked"
  /** User modified the filter to narrow/expand the search results. Check “FilterTypes” & “Supporting Ids” metadata below. */
  | "FilterModified"
  /** User selects "Clear filters" to clear any previously selected filters */
  | "ClearFilters"
  /** Movement of the scroll bar on the search page. Check metadata below. */
  | "ScrollBarMoved"
  /** User selects the buttons/links like “x-more” to view hidden items. It could be collapsed verticals/tabs (due to space constraint) or entities hidden in a region which can be expanded by specific user interaction. Check ExpansionType metadata below. */
  | "ExpandHiddenItems"
  /** User selects the dismiss button of the PeoplePill */
  | "PeoplePillRemoved"
  /** User selects a link to see privacy information */
  | "PrivacyInformationClicked"
  /** User selects a link to see privacy information */
  | "PrivacyInformationLinkClicked"
  /** User selects one of the verticals in People Centric Search */
  | "PeopleScopeVerticalClicked"
  /** User sent the email. */
  | "EmailSent"
  /** User cancelled the email. */
  | "EmailCancelled"
  /** User created a new meeting. */
  | "MeetingCreated"
  /** User cancelled the meeting. */
  | "MeetingCancelled"
  /** User navigated from another entrypoint */
  | "CrossEntryPointNavigationDone"
  /** User selects an entity on the page which doesn't come from search results or doesn't have a direct relationship with the search results For example, selecting a non-retrieved utterance in transcript search. */
  | "NonSearchedEntityClicked"
  /** Search text entered by the user has been cleared via user interaction like selecting a "clear link" in mainline. */
  | "ClearSearchText"
  /** User selects a group header which will trigger a new logical impression with scope. Check the “ScopeHeaderType” metadata below. */
  | "ScopeHeaderClicked"
  /** User remove the items which don't have reference id now. Such as custom filters in filter bar in Office.com. Check "ItemTypes" metadata below. When applied in custom filters, please check "FilterTypes" metadata as well. */
  | "RemoveItem"
  /** Denotes that user switches to another account, including multi-account. It is only used by the client with multi-account scenario enabled, e.g., Outlook Mobile, Outlook Mac, etc. */
  | "AccountSwitched"
  /** Denotes that user clickes on the chevron in the carousel, etc. */
  | "ChevronClicked";
