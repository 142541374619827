// Copyright (C) Microsoft Corporation. All rights reserved.

import type { IPublicClientApplication } from "@azure/msal-browser";
import type {
  DataField,
  FilledTelemetryEvent,
  TelemetryEvent,
  TelemetrySink,
} from "@microsoft/oteljs";
import { buildCoreHeaders } from "../../header";
import type { SinkParameters } from "../attachSink";
import { getDataFieldName, type Action, type Step } from "../config";
import { getEventName } from "../events";
import { getUserCountryCode, getUserTenantId } from "../utils";

/** 3S Events API Endpoint */
export const ThreeSEventsEndpoint = "/api/promptsclient/events";

export type ThreeSEvent = {
  Key: string;
  Value: {
    Name: string;
    Attributes: {
      Key: string;
      Value: string;
    }[];
  }[];
};

type EventHandler = (
  telemetryEvent: FilledTelemetryEvent,
  logicalId: string
) => ThreeSEvent | undefined;

/**
 * 3S Events API sink
 */
export class ThreeSSink implements TelemetrySink {
  version = "2";
  private buffer: FilledTelemetryEvent[];
  private drainTimeoutMs: number;
  private drainTimer: number;
  private draining: boolean;
  private endpointUrl: string;
  private eventHandlerMap: Record<string, EventHandler>;
  private msalInstance?: IPublicClientApplication;

  /**
   * Creates a new instance of 3S Events API
   * @param endpointUrl Events API endpoint URL
   * @param drainTimeoutMs Buffer drain timeout in milliseconds
   */
  constructor(
    endpointUrl: string,
    drainTimeoutMs = 2000,
    msalInstance?: IPublicClientApplication
  ) {
    this.buffer = [];
    this.drainTimeoutMs = drainTimeoutMs;
    this.endpointUrl = endpointUrl;
    this.drainTimer = -1;
    this.draining = false;
    this.msalInstance = msalInstance;
    this.resetDrainTimer();

    this.eventHandlerMap = {
      [getEventName("PromptClicked")]: this.getPromptClickedEvent,
      [getEventName("ResultsRendered")]: this.getResultsRenderedEvent,
      [getEventName("ClientLayout")]: this.getClientLayoutEvent,
      [getEventName("CopyPrompt")]: this.getCopyPromptEvent,
      [getEventName("SharePrompt")]: this.getSharePromptEvent,
      [getEventName("SharePromptViaEmail")]: this.getSharePromptViaEmailEvent,
      [getEventName("PromptBookmarked")]: this.getPromptBookmarkedEvent,
      [getEventName("UnsharePrompt")]: this.unsharePromptEvent,
      [getEventName("PromptAction")]: this.getPromptActionEvent,
      [getEventName("TryInAppLoading")]: this.getTryInAppEvent,
    };
  }

  private resetDrainTimer() {
    if (this.drainTimer) {
      clearInterval(this.drainTimer);
    }
    this.drainTimer = window.setInterval(
      () => this.drainBuffer(),
      this.drainTimeoutMs
    );
  }

  private async sendEvents(events: ThreeSEvent[]) {
    // don't call the endpoint if there are no events to be processed
    if (!events.length) return;

    if (!getUserTenantId()) return;

    const headers = await buildCoreHeaders(this.msalInstance);
    headers.append(
      "RequestVerificationToken",
      document
        ?.querySelector('input[name="__RequestVerificationToken"]')
        ?.getAttribute("value") ?? ""
    );

    await fetch(this.endpointUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(events),
    });
  }

  private getDataField(
    fieldName: string,
    telemetryEvent: TelemetryEvent
  ): DataField["value"] | undefined {
    return telemetryEvent.dataFields?.find((x) => x.name === fieldName)?.value;
  }

  private convertTo3SEvent(
    telemetryEvent: FilledTelemetryEvent
  ): ThreeSEvent | undefined {
    // We either use LogicalId or TraceId to identify the event
    const logicalId = this.getDataField(
      getDataFieldName("LogicalId"),
      telemetryEvent
    );
    const traceId = this.getDataField("TraceId", telemetryEvent);
    if (!logicalId && !traceId) return;

    if (typeof logicalId === "string") {
      const eventHandler = this.eventHandlerMap[telemetryEvent.eventName];
      if (eventHandler) {
        return eventHandler.call(this, telemetryEvent, logicalId);
      }
    }

    if (
      telemetryEvent.eventName === getEventName("ResponseReceived") &&
      typeof traceId === "string"
    ) {
      return this.getResponseReceivedEvent(telemetryEvent, traceId);
    }

    return undefined;
  }

  /** Get PromptClicked event, returns undefined if required metadata not available. */
  private getPromptClickedEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const referenceId = this.getReferenceId(telemetryEvent);
    if (referenceId == undefined) return undefined;

    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "id",
        Value: referenceId,
      },
      {
        Key: "eventtype",
        Value: "entityclicked",
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "searchentityactions",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes
          ),
        },
      ],
    };
  }

  /** Get CopyPrompt event, returns undefined if required metadata not available. */
  private getCopyPromptEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const referenceId = this.getReferenceId(telemetryEvent);
    if (referenceId == undefined) return undefined;

    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "id",
        Value: referenceId,
      },
      {
        Key: "eventtype",
        Value: "entityactiontaken",
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "searchentityactions",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes,
            { entityactiontakentype: "sendcopy" }
          ),
        },
      ],
    };
  }

  /** Get SharePrompt event, returns undefined if required metadata not available. */
  private getSharePromptEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const referenceId = this.getReferenceId(telemetryEvent);
    if (referenceId == undefined) return undefined;

    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "id",
        Value: referenceId,
      },
      {
        Key: "eventtype",
        Value: "entityactiontaken",
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "searchentityactions",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes,
            { entityactiontakentype: "copylink" }
          ),
        },
      ],
    };
  }

  /** Get SharePrompt event, returns undefined if required metadata not available. */
  private getSharePromptViaEmailEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const referenceId = this.getReferenceId(telemetryEvent);
    if (referenceId == undefined) return undefined;

    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "id",
        Value: referenceId,
      },
      {
        Key: "eventtype",
        Value: "entityactiontaken",
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "searchentityactions",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes,
            { entityactiontakentype: "share" }
          ),
        },
      ],
    };
  }

  /** Get PromptBookmarked event, returns undefined if required metadata not available. */
  private getPromptBookmarkedEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const referenceId = this.getReferenceId(telemetryEvent);
    if (referenceId == undefined) return undefined;

    const isFavorite = this.getDataField(
      getDataFieldName("IsFavorite"),
      telemetryEvent
    );

    const entityActionTakenType: string = isFavorite
      ? "download"
      : "unsubscribe";

    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "id",
        Value: referenceId,
      },
      {
        Key: "eventtype",
        Value: "entityactiontaken",
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "searchentityactions",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes,
            { entityactiontakentype: entityActionTakenType }
          ),
        },
      ],
    };
  }

  /** Get unshare prompt event, returns undefined if required metadata not available. */
  private unsharePromptEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const referenceId = this.getReferenceId(telemetryEvent);
    if (referenceId == undefined) return undefined;
    const entityActionTakenType = "remove";
    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "id",
        Value: referenceId,
      },
      {
        Key: "eventtype",
        Value: entityActionTakenType,
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "searchentityactions",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes,
            { entityactiontakentype: entityActionTakenType }
          ),
        },
      ],
    };
  }

  /** Get ResultsRendered event, returns undefined if required metadata not available. */
  private getResultsRenderedEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "E2ELatency",
        Value:
          this.getDataField("E2ELatency", telemetryEvent)?.toString() ?? "",
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "resultsrendered",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes
          ),
        },
      ],
    };
  }

  /** Get ResponseReceived event, returns undefined if required metadata not available. */
  private getResponseReceivedEvent(
    telemetryEvent: FilledTelemetryEvent,
    traceId: string
  ): ThreeSEvent | undefined {
    const attributes = [
      {
        Key: "TraceId",
        Value: traceId,
      },
      {
        Key: "Latency",
        Value: this.getDataField("Latency", telemetryEvent)?.toString() ?? "",
      },
      {
        Key: "Status",
        Value: this.getDataField("Status", telemetryEvent)?.toString() ?? "",
      },
    ];

    const event = {
      Key: traceId,
      Value: [
        {
          Name: "responsereceived",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes
          ),
        },
      ],
    };

    return event;
  }

  /** Get ClientLayout event, returns undefined if required metadata not available. */
  private getClientLayoutEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "resultsview",
        Value:
          this.getDataField("ResultsView", telemetryEvent)?.toString() ?? "",
      },
      {
        Key: "layouttype",
        Value:
          this.getDataField("LayoutType", telemetryEvent)?.toString() ?? "",
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "clientlayout",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes
          ),
        },
      ],
    };
  }

  private resolvePromptActionMapping(
    step: Step,
    action: Action
  ): string | undefined {
    if (step === "Dialog" && action === "PublishToWorkgroupButton") {
      return "ModernGroupsConversationSelected";
    }

    return undefined;
  }

  private createSearchEntityActionsResponse(
    logicalId: string,
    referenceId: string,
    telemetryEvent: FilledTelemetryEvent,
    entityActionTakenType: string
  ): ThreeSEvent {
    const attributes = [
      {
        Key: "LogicalId",
        Value: logicalId,
      },
      {
        Key: "id",
        Value: referenceId,
      },
      {
        Key: "eventtype",
        Value: "entityactiontaken",
      },
    ];

    return {
      Key: logicalId,
      Value: [
        {
          Name: "searchentityactions",
          Attributes: this.buildAttributes(
            telemetryEvent.timestamp,
            attributes,
            { entityactiontakentype: entityActionTakenType }
          ),
        },
      ],
    };
  }

  /** Get PromptAction event, returns undefined if required metadata not available, or metadata not selected for logging. */
  private getPromptActionEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const step: Step = this.getDataField("Step", telemetryEvent) as Step;
    const action: Action = this.getDataField(
      "Action",
      telemetryEvent
    ) as Action;

    const eventActionTakenType = this.resolvePromptActionMapping(step, action);

    if (!eventActionTakenType) return undefined;

    const referenceId = this.getReferenceId(telemetryEvent);
    if (referenceId == undefined) return undefined;

    return this.createSearchEntityActionsResponse(
      logicalId,
      referenceId,
      telemetryEvent,
      "ModernGroupsConversationSelected"
    );
  }

  /** Get TryInApp event, returns undefined if required metadata not available. */
  private getTryInAppEvent(
    telemetryEvent: FilledTelemetryEvent,
    logicalId: string
  ): ThreeSEvent | undefined {
    const referenceId = this.getReferenceId(telemetryEvent);
    if (referenceId == undefined) return undefined;

    return this.createSearchEntityActionsResponse(
      logicalId,
      referenceId,
      telemetryEvent,
      "ViewInChat"
    );
  }

  /** Get reference id from telemetry event, undefined if does not exist. */
  private getReferenceId(
    telemetryEvent: FilledTelemetryEvent
  ): string | undefined {
    const referenceId = this.getDataField(
      getDataFieldName("ReferenceId"),
      telemetryEvent
    );
    // don't parse this event if the 3S Reference ID is missing
    // See: https://docs.substrate.microsoft.net/docs/substrate-intelligence/MSAI-Data-Platform/Contracts/Events-description.html
    if (!referenceId || typeof referenceId !== "string") return undefined;
    return referenceId;
  }

  /** Get metadata */
  private getMetadata(additionalNodes: Record<string, any> = {}): string {
    const baseNode = {
      userCountryCode: getUserCountryCode(),
    };
    return JSON.stringify({ ...baseNode, ...additionalNodes });
  }

  /** Helper method to append attributes to event. */
  private buildAttributes(
    timestamp: number | undefined,
    attributes: { Key: string; Value: string }[],
    metadata: Record<string, any> = {}
  ): { Key: string; Value: string }[] {
    return [
      {
        Key: "version",
        Value: this.version,
      },
      {
        Key: "localtime",
        Value: new Date(timestamp || Date.now()).toISOString(),
      },
      ...attributes,
      {
        Key: "metadata",
        Value: this.getMetadata(metadata),
      },
    ];
  }

  private convertTo3SEvents(
    telemetryEvents: FilledTelemetryEvent[]
  ): ThreeSEvent[] {
    return telemetryEvents
      .map((x) => this.convertTo3SEvent(x))
      .filter((x): x is ThreeSEvent => x !== undefined);
  }

  sendTelemetryEvent(telemetryEvent: FilledTelemetryEvent): void {
    this.buffer.push(telemetryEvent);
  }

  async drainBuffer() {
    if (this.draining) return;

    this.draining = true;
    try {
      await this.sendEvents(this.convertTo3SEvents(this.buffer.slice()));
      this.buffer = [];
    } catch (e) {
      // try to send the 3S Events request but don't throw exceptions if it fails
      console.error(e);
    } finally {
      this.draining = false;
    }
  }
  shutdown(): void {
    clearInterval(this.drainTimer);
    this.drainBuffer();
  }
}

let threeSSSink: ThreeSSink | undefined;

export default function get3SEventsSink(config: SinkParameters) {
  if (threeSSSink && config.msalInstance !== undefined) {
    //rettach since we have MSAL Instance
    threeSSSink = new ThreeSSink(
      config.endpointUrl,
      undefined,
      config.msalInstance
    );

    return threeSSSink;
  }

  threeSSSink = new ThreeSSink(
    config.endpointUrl,
    undefined,
    config.msalInstance
  );

  // Call shutdown() before unload to send remaining events
  window.addEventListener("beforeunload", (_ev) => {
    if (threeSSSink) {
      threeSSSink.shutdown();
    }
  });

  return threeSSSink;
}
