import { AppId, pages } from "@microsoft/teams-js";
import { METAOS_APP_ID, METAOS_TEAMS_URL } from "../config";
import { sendDiagnosticEvent } from "./Telemetry";
import { LogCategory, LogType } from "./Telemetry/events";

/**
 * Append query params to a URL
 * @param url original URL
 * @param name query param name
 * @param value query param value
 * @returns URL with appended query params
 */
export const appendQueryParam = (url: string, name: string, value: string) => {
  const urlObject = new URL(url);
  urlObject.searchParams.append(name, value);
  return urlObject.toString();
};

export const isMetaOsHelper = (href = location.href) => {
  const convertedString = href.toLowerCase();

  if (convertedString.indexOf("metaos") === -1) {
    return false;
  }

  return true;
};

/*
Teams app (web or desktop)
https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/hostclienttype?view=msteams-client-js-latest
https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/hostname?view=msteams-client-js-latest
*/
export const isTeamsApp = (url = document.location.toString()) => {
  const params = new URL(url).searchParams;

  if (params !== undefined) {
    const hostName = params.get("hostName");
    if (
      hostName?.toLowerCase() === "teamsmodern" ||
      hostName?.toLowerCase() === "teams"
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// “Harmony.Web.CopilotLab"
// "Harmony.Win32.CopilotLab"
// "Teams.Web.CopilotLab"
// "Teams.Win32.CopilotLab"
// "Outlook.Web.CopilotLab"
// "Outlook.Win32.CopilotLab"
export const GetScenarioName = () => {
  if (!isMetaOsHelper()) {
    return "Copilotlab.Web.Prompts";
  }

  const params = new URL(document.location.toString()).searchParams;

  if (params !== undefined) {
    let hostClientType = params.get("hostClientType") ?? "web";
    let hostName = params.get("hostName") ?? "";

    hostName = hostName.toLowerCase();
    hostClientType = hostClientType.toLowerCase();

    if (hostName === "office") {
      if (hostClientType === "web") {
        return "Harmony.Web.CopilotLab";
      }
      return "Harmony.Win32.CopilotLab";
    }

    if (hostName === "outlook" || hostName === "outlookwin32") {
      if (hostClientType === "web") {
        return "Outlook.Web.CopilotLab";
      }
      return "Outlook.Win32.CopilotLab";
    }

    if (hostName === "teams" || hostName === "teamsmodern") {
      if (hostClientType === "web") {
        return "Teams.Web.CopilotLab";
      }
      return "Teams.Win32.CopilotLab";
    }
  }

  return "Copilotlab.Web.Prompts";
};

export const isMacUserAgent = () => {
  const deviceDetect = navigator.platform;
  const appleDevicesArr = [
    "macos",
    "mac",
    "macintel",
    "macppc",
    "mac68k",
    "macintosh",
  ];

  // If on Apple device
  if (
    deviceDetect !== undefined &&
    appleDevicesArr.includes(deviceDetect.toLowerCase())
  ) {
    return true;
  }

  return false;
};

// Updates the Meta tags which is used by Telemetry.
export const updateMetaTagFromIframe = (
  name: string,
  content: string
): void => {
  const element = document.querySelector(`meta[name="${name}"]`);
  if (element !== null) {
    element.setAttribute("content", content);
  } else {
    const link = document.createElement("meta");
    link.setAttribute("name", name);
    link.setAttribute("content", content);
    document.getElementsByTagName("head")[0].appendChild(link);
  }
};

export interface AppNavigationParameters {
  /**
   * ID of the app to navigate to
   */
  appId: AppId;

  /**
   * Developer-defined ID of the page to navigate to within the app (formerly called `entityId`)
   */
  pageId: string;

  /**
   * Fallback URL to open if the navigation cannot be completed within the host (e.g., if the target app is not installed)
   */
  webUrl?: URL;

  /**
   * Developer-defined ID describing the content to navigate to within the page. This ID is passed to the application
   * via the {@link app.PageInfo.subPageId} property on the {@link app.Context} object (retrieved by calling {@link app.getContext})
   */
  subPageId?: string;

  /**
   * For apps installed as a channel tab, this ID can be supplied to indicate in which Teams channel the app should be opened
   * This property has no effect in hosts where apps cannot be opened in channels
   */
  channelId?: string;

  /**
   * Optional ID of the chat or meeting where the app should be opened
   * This property has no effect in hosts where apps cannot be opened in chats or meetings
   */
  chatId?: string;
}

export const getPromptGalleryAppParameters = () => {
  const navigateParams: AppNavigationParameters = {
    appId: new AppId(METAOS_APP_ID),
    pageId: "index0",
    webUrl: new URL(METAOS_TEAMS_URL),
  };

  return navigateParams;
};
export const navigateToApp = (navigateParams: AppNavigationParameters) => {
  pages
    .navigateToApp(navigateParams)
    .then()
    .catch((e) => {
      sendDiagnosticEvent(
        LogType.Error,
        `error navigating to app ${navigateParams.appId} - ${e}`,
        LogCategory.NAVIGATETOAPP
      );
    });
};
