import {
  makeStyles,
  shorthands,
  tagClassNames,
  tokens,
  type GriffelStyle,
} from "@fluentui/react-components";
import type { Appearance } from "../../data";

type Styles = {
  body: GriffelStyle;
  tag: GriffelStyle;
  tagText: GriffelStyle;
  placeholder: GriffelStyle;
  entityText: GriffelStyle;
};

const gradientText = {
  background: "linear-gradient(102.49deg, #464FEB 1.5%, #B515FF 100%)",
  WebkitBackgroundClip: "text",
  WebkitBackground: "transparent",
  color: "transparent",
};

const copilotLabStyles: Styles = {
  body: {
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: "150%",
    wordBreak: "break-word",
    "@media (max-width: 540px)": {
      fontSize: "1rem",
    },
  },

  tag: {
    backgroundColor: tokens.colorBrandBackground2,
    paddingLeft: "6px",
    paddingRight: "6px",
    "@media (max-width: 540px)": {
      fontSize: "1rem",
    },
    [`& .${tagClassNames.primaryText}`]: {
      whiteSpace: "nowrap",
      ...shorthands.overflow("hidden"),
      textOverflow: "ellipsis",
    },
  },

  tagText: {
    fontSize: "1rem",
    lineHeight: "137.5%",
    whiteSpace: "nowrap",
  },

  placeholder: {
    color: tokens.colorNeutralForeground4,
    fontSize: "1.125rem",
    "@media (max-width: 540px)": {
      fontSize: "1rem",
    },
  },

  entityText: {},
};

const copilotLabGoBoldStyles: Styles = {
  body: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "26px",
    wordBreak: "break-word",
    "@media (max-width: 540px)": {
      fontSize: "1rem",
    },
  },

  tag: {},

  tagText: {},

  entityText: {
    ...gradientText,
  },

  placeholder: {
    ...gradientText,
    fontSize: "1.125rem",
    fontWeight: tokens.fontWeightSemibold,
    "@media (max-width: 540px)": {
      fontSize: "1rem",
    },
  },
};

const inAppStyles: Styles = {
  body: {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: "1.25rem",
    wordBreak: "break-word",
  },

  tag: {
    backgroundColor: tokens.colorBrandBackground2,
    paddingLeft: "6px",
    paddingRight: "6px",
    [`& .${tagClassNames.primaryText}`]: {
      whiteSpace: "nowrap",
      ...shorthands.overflow("hidden"),
      textOverflow: "ellipsis",
    },
  },

  tagText: {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
  },

  placeholder: {
    color: tokens.colorNeutralForeground4,
  },

  entityText: {},
};

const m365Styles: Styles = {
  body: {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: "1.625rem",
    wordBreak: "break-word",
  },

  tag: {
    backgroundColor: tokens.colorNeutralBackground3,
    paddingLeft: "6px",
    paddingRight: "6px",
    [`& .${tagClassNames.primaryText}`]: {
      whiteSpace: "nowrap",
      ...shorthands.overflow("hidden"),
      textOverflow: "ellipsis",
    },
  },

  tagText: {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: "1rem",
    whiteSpace: "nowrap",
  },

  placeholder: {
    color: tokens.colorNeutralForeground4,
  },

  entityText: {},
};

const singleColumnStyles: Styles = {
  body: {
    fontSize: tokens.fontSizeBase300,
    fontStyle: "normal",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: "20px",
    wordBreak: "break-word",
  },

  tag: {
    backgroundColor: tokens.colorNeutralBackground3,
    paddingLeft: "2px",
    paddingRight: "2px",
    [`& .${tagClassNames.primaryText}`]: {
      whiteSpace: "nowrap",
      ...shorthands.overflow("hidden"),
      textOverflow: "ellipsis",
    },
  },

  tagText: {
    fontSize: tokens.fontSizeBase300,
    fontStyle: "normal",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: "20px",
    whiteSpace: "nowrap",
  },

  placeholder: {
    color: tokens.colorNeutralForeground4,
  },

  entityText: {},
};

const rockSteadyStyles: Styles = {
  body: {
    fontSize: tokens.fontSizeBase400,
    fontStyle: "normal",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase400,
    wordBreak: "break-word",
  },

  tag: {},

  tagText: {},

  placeholder: {
    fontSize: tokens.fontSizeBase400,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: tokens.lineHeightBase400,
    wordBreak: "break-word",
    color: tokens.colorBrandForeground1,
  },

  entityText: {
    fontWeight: 600,
    color: tokens.colorBrandForeground1,
  },
};

const useInAppStyles = makeStyles(inAppStyles);
const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const useM365Styles = makeStyles(m365Styles);
const useSingleColumnStyle = makeStyles(singleColumnStyles);
const useRockSteadyStyles = makeStyles(rockSteadyStyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "in-app":
      return useInAppStyles;
    case "web":
      return useCopilotLabStyles;
    case "web-v2":
    case "in-app-v2":
      return useCopilotLabGoBoldStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    case "m365":
    case "bing-web":
    case "win-copilot":
      return useM365Styles;
    case "bing-mobile":
    case "underside":
    case "single-col":
      return useSingleColumnStyle;
    default:
      return useInAppStyles;
  }
};
