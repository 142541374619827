import { declareString } from "@1js/localization";

export const BadgesGroupLabel = declareString(
  "promptLibrary.badgesGroupLabel",
  {
    text: "List of Microsoft products this prompt works great in",
    comment: "Describe the list of badges that will appear on the prompt card",
  }
);

export const CategoriesGroupLabel = declareString(
  "promptLibrary.categoriesGroupLabel",
  {
    text: "Categories for this prompt",
    comment:
      "Describe the list of categories that will appear on the prompt card",
  }
);

export const TeamsLabel = declareString("promptLibrary.teams", {
  text: "Teams",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const CopilotForMicrosoft365Label = declareString(
  "promptLibrary.copilotForMicrosoftM365",
  {
    text: "Copilot for Microsoft 365",
    comment:
      "Product name that will appear in drop-down and in the prompt card. Copilot for Microsoft 365 is a product name as defined in TermStudio.",
  }
);

export const BusinessChatLabel = declareString(
  "promptLibrary.newCopilotForMicrosoftM365",
  {
    text: "Business Chat",
    comment:
      "Product name that will appear in drop-down and in the prompt card. Business Chat is a product name as defined in TermStudio.",
  }
);

export const CopilotChatLabel = declareString(
  "promptLibrary.copilotChatForMicrosoftM365",
  {
    text: "Copilot Chat",
    comment:
      "Product name that will appear in drop-down and in the prompt card. Copilot Chat is a product name as defined in TermStudio.",
  }
);

export const FilterBusinessChatLabel = declareString(
  "promptLibrary.newFilterCopilotForMicrosoftM365",
  {
    text: "Business Chat (work)",
    comment:
      "Product name that will appear in drop-down and in the prompt card. Business Chat (work) is a product name as defined in TermStudio.",
  }
);

export const FilterCopilotChatWorkLabel = declareString(
  "promptLibrary.copilotChatWorkForMicrosoftM365",
  {
    text: "Copilot Chat (work)",
    comment:
      "Product name that will appear in drop-down and in the prompt card. Copilot Chat (work) is a product name as defined in TermStudio.",
  }
);

export const FilterCopilotChatWebLabel = declareString(
  "promptLibrary.copilotChatWebForMicrosoftM365",
  {
    text: "Copilot Chat (web)",
    comment:
      "Product name that will appear in drop-down and in the prompt card. Copilot Chat (web) is a product name as defined in TermStudio.",
  }
);

export const ExcelLabel = declareString("promptLibrary.excel", {
  text: "Excel",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const AzureLabel = declareString("promptLibrary.azure", {
  text: "Azure",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const WordLabel = declareString("promptLibrary.word", {
  text: "Word",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const OneDriveLabel = declareString("promptLibrary.oneDrive", {
  text: "OneDrive",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const OneNoteLabel = declareString("promptLibrary.oneNote", {
  text: "OneNote",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const OutlookLabel = declareString("promptLibrary.outlook", {
  text: "Outlook",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const PowerPointLabel = declareString("promptLibrary.powerpoint", {
  text: "PowerPoint",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const LoopLabel = declareString("promptLibrary.loop", {
  text: "Loop",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const WhiteboardLabel = declareString("promptLibrary.whiteboard", {
  text: "Whiteboard",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const SharePointLabel = declareString("promptLibrary.sharepoint", {
  text: "SharePoint",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const VivaTopicsLabel = declareString("promptLibrary.vivatopics", {
  text: "Viva Topics",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const M365AdminCenterLabel = declareString(
  "promptLibrary.m365AdminCenter",
  {
    text: "Microsoft 365 admin center",
    comment: "Product name that will appear in drop-down {Locked}",
  }
);

export const WindowsLabel = declareString("promptLibrary.windows", {
  text: "Windows",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const FormsLabel = declareString("promptLibrary.forms", {
  text: "Forms",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const CatchUpCategoryLabel = declareString(
  "promptLibrary.catchUpCategory",
  {
    text: "Catch up",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const PlannerLabel = declareString("promptLibrary.planner", {
  text: "Planner",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const StreamLabel = declareString("promptLibrary.stream", {
  text: "Stream",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const VivaEngageLabel = declareString("promptLibrary.vivaEngage", {
  text: "Viva Engage",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const VivaGoalsLabel = declareString("promptLibrary.vivaGoals", {
  text: "Viva Goals",
  comment: "Product name that will appear in drop-down {Locked}",
});

export const CreateCategoryLabel = declareString(
  "promptLibrary.createCategory",
  {
    text: "Create",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const AskCategoryLabel = declareString("promptLibrary.askCategory", {
  text: "Ask",
  comment:
    "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
});

export const EditCategoryLabel = declareString("promptLibrary.editCategory", {
  text: "Edit",
  comment:
    "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
});

export const UnderstandCategoryLabel = declareString(
  "promptLibrary.understandCategory",
  {
    text: "Understand",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const SummarizeCategoryLabel = declareString(
  "promptLibrary.summarizeCategory",
  {
    text: "Summarize",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const CustomizeCategoryLabel = declareString(
  "promptLibrary.customizeCategory",
  {
    text: "Customize",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const DesignCategoryLabel = declareString(
  "promptLibrary.designCategory",
  {
    text: "Design",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const OptimizeCategoryLabel = declareString(
  "promptLibrary.optimizeCategory",
  {
    text: "Optimize",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const OperateCategoryLabel = declareString(
  "promptLibrary.operateCategory",
  {
    text: "Operate",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const TroubleshootCategoryLabel = declareString(
  "promptLibrary.troubleshootCategory",
  {
    text: "Troubleshoot",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const AnalyzeCategoryLabel = declareString(
  "promptLibrary.analyzeCategory",
  {
    text: "Analyze",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const AssistCategoryLabel = declareString(
  "promptLibrary.assistCategory",
  {
    text: "Assist",
    comment: "Prompts for the AI Hub Assist Module",
  }
);

export const AutomateCategoryLabel = declareString(
  "promptLibrary.automateCategory",
  {
    text: "Automate",
    comment: "Prompts for the AI Hub Automate Module",
  }
);

export const BrowseCategoryLabel = declareString(
  "promptLibrary.browseCategory",
  {
    text: "Browse",
    comment: "Prompts for the AI Hub Browse Module",
  }
);

export const CollectCategoryLabel = declareString(
  "promptLibrary.collectCategory",
  {
    text: "Collect",
    comment: "Prompts for the AI Hub Collect Module",
  }
);

export const ExecuteCategoryLabel = declareString(
  "promptLibrary.executeCategory",
  {
    text: "Execute",
    comment:
      "Prompts that ask Copilot to actually perform a task like scheduling a meeting.",
  }
);

export const FindCatagoryLabel = declareString("promptLibrary.findCategory", {
  text: "Find",
  comment:
    "Prompts that help the user find something. For instance, find all of the users that haven't enabled two-factor authentication.",
});

export const LearnCategoryLabel = declareString("promptLibrary.learnCategory", {
  text: "Learn",
  comment:
    "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
});

export const NewsCategoryLabel = declareString("promptLibrary.newsCategory", {
  text: "News",
  comment: "Prompts for the AI Hub News Module",
});

export const PeopleCategoryLabel = declareString(
  "promptLibrary.peopleCategory",
  {
    text: "People",
    comment: "Prompts for the AI Hub People Module",
  }
);

export const PrepareCategoryLabel = declareString(
  "promptLibrary.prepareCategory",
  {
    text: "Prepare",
    comment: "Prompts for the AI Hub Prepare Module",
  }
);

export const ScheduleCategoryLabel = declareString(
  "promptLibrary.scheduleCategory",
  {
    text: "Schedule",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const ManageCategoryLabel = declareString(
  "promptLibrary.manageCategory",
  {
    text: "Manage",
    comment:
      "Display label for a Copilot prompt's display category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
  }
);

export const HomeUserLabel = declareString("promptLibrary.homeUser", {
  text: "For home users",
  comment:
    "Display label for a link in a dropdown for users to learn more about Copilot for personal use.",
});

export const BusinessLeaderLabel = declareString(
  "promptLibrary.businessLeader",
  {
    text: "For business leaders",
    comment:
      "Display label for a link in a dropdown for users to learn more about Copilot for business use.",
  }
);
export const AdminLabel = declareString("promptLibrary.admin", {
  text: "For admins",
  comment:
    "Display label for a link in a dropdown for business admins to learn more about Copilot.",
});

export const VideoTutorialLabel = declareString("promptLibrary.videoTutorial", {
  text: "Video tutorials",
  comment:
    "Display label for a link in a dropdown for video tutorials to learn more about Copilot.",
});

export const DepartmentLabel = declareString("promptLibrary.department", {
  text: "Department",
  comment:
    "Department is used as one of the group label inside a dropdown filter. For example, there can be role based checkboxes such as 'Human Resources' and 'Marketing', and those checkboxes will be displayed under the same group label 'Department'. This dropdown filter is used for users to filter on Copilot prompts.",
});

export const IndustryLabel = declareString("promptLibrary.industry ", {
  text: "Industry",
  comment:
    "Industry is used as one of the group label inside a dropdown filter. For example, there can be role based checkboxes such as 'Retail', 'Manufacturing', and 'Financial Services', and those checkboxes will be displayed under the same group label 'Department'. This dropdown filter is used for users to filter on Copilot prompts.",
});

export const FinancialServicesLabel = declareString(
  "promptLibrary.financialServices",
  {
    text: "Financial Services",
    comment:
      "Financial Services is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
  }
);

export const HumanResourcesLabel = declareString(
  "promptLibrary.humanResources",
  {
    text: "Human Resources",
    comment:
      "Human Resources is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
  }
);

export const ManufacturingLabel = declareString("promptLibrary.manufacturing", {
  text: "Manufacturing",
  comment:
    "Manufacturing is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const MarketingLabel = declareString("promptLibrary.marketing", {
  text: "Marketing",
  comment:
    "Marketing is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const RetailLabel = declareString("promptLibrary.retail ", {
  text: "Retail",
  comment:
    "Retail is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const EducationLabel = declareString("promptLibrary.education", {
  text: "Education",
  comment:
    "Education is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const Student18Label = declareString("promptLibrary.student18", {
  text: "Student (18+)",
  comment:
    "Student (18+) is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const FacultyLabel = declareString("promptLibrary.faculty", {
  text: "Faculty",
  comment:
    "Faculty is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const FinanceLabel = declareString("promptLibrary.finance", {
  text: "Finance",
  comment:
    "Finance is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const SalesLabel = declareString("promptLibrary.sales", {
  text: "Sales",
  comment:
    "Sales is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const EnergyLabel = declareString("promptLibrary.energy", {
  text: "Energy",
  comment:
    "Energy is one of the available job type filter options users can use to narrow down the list of Copilot prompts.",
});

export const SustainabilityLabel = declareString(
  "promptLibrary.sustainability",
  {
    text: "Sustainability",
    comment:
      "Sustainability is one of the available job type filter options users can use to narrow down the list of Copilot prompts. The sustainability industry focuses on developing and implementing practices that meet present needs without compromising the ability of future generations to meet theirs.",
  }
);

export const NonprofitLabel = declareString("promptLibrary.nonprofit", {
  text: "Nonprofit",
  comment:
    "Nonprofit is one of the available job type filter options users can use to narrow down the list of Copilot prompts. The nonprofit industry is a sector dedicated to furthering social causes and providing public benefits through organizations that reinvest any profits into their missions rather than distributing them to owners or shareholders.",
});

export const CustomerServiceLabel = declareString(
  "promptLibrary.customerService",
  {
    text: "Customer Service",
    comment:
      "Customer Service is one of the available job type filter options users can use to narrow down the list of Copilot prompts. Customer service is the frontline support that assists customers with inquiries, resolves issues, and ensures a positive experience with a company’s products or services.",
  }
);

export const CommunicationsLabel = declareString(
  "promptLibrary.communications",
  {
    text: "Communications",
    comment:
      "Communications is one of the available job type filter options users can use to narrow down the list of Copilot prompts. Customer service is the frontline support that assists customers with inquiries, resolves issues, and ensures a positive experience with a company’s products or services.",
  }
);

export const LegalLabel = declareString("promptLibrary.legal", {
  text: "Legal",
  comment:
    "Legal is one of the available job type filter options users can use to narrow down the list of Copilot prompts. The Legal department within a company is responsible for overseeing and managing legal affairs, ensuring compliance with laws and regulations, and providing legal advice to protect the organization’s interests.",
});

export const MobilityLabel = declareString("promptLibrary.mobility", {
  text: "Mobility",
  comment:
    "Mobility is one of the available job type filter options users can use to narrow down the list of Copilot prompts. The Mobility Industry is a dynamic sector that encompasses the development and integration of innovative transportation solutions, aiming to enhance the movement of people and goods through sustainable, efficient, and technologically advanced means.",
});

export const ConsumerGoodsLabel = declareString("promptLibrary.consumerGoods", {
  text: "Consumer Goods",
  comment:
    "Consumer Goods is one of the available job type filter options users can use to narrow down the list of Copilot prompts. The Consumer Goods Industry is a diverse and competitive sector that focuses on the production, distribution, and marketing of products designed to meet the everyday needs and desires of consumers, ranging from food and beverages to clothing and electronics. ",
});

export const InformationTechnologyLabel = declareString(
  "promptLibrary.informationTechnology",
  {
    text: "Information Technology",
    comment:
      "Information Technology is one of the available job type filter options users can use to narrow down the list of Copilot prompts. Information Technology professional’s goals include accelerating digital transformation, securing the enterprise, optimizing collaboration, and driving adoption of emerging technologies like generative AI",
  }
);
export const AllLabel = declareString("promptLibrary.savedPromptsAll", {
  text: "All",
  comment:
    "Label used on the saved prompts filter component to show all Copilot prompts",
});

export const MeLabel = declareString("promptLibrary.savedPromptsMe", {
  text: "Me",
  comment:
    "Label used on the saved prompts filter component to show all user-authored Copilot prompts",
});

export const MicrosoftLabel = declareString(
  "promptLibrary.savedPromptsMicrosoft",
  {
    text: "Microsoft",
    comment:
      "Label used on the saved prompts filter component to show all Microsoft-authored Copilot prompts {Locked}",
  }
);

export const OthersLabel = declareString("promptLibrary.savedPromptsOthers", {
  text: "Others",
  comment:
    "Label used on the saved prompts filter component to show all Copilot prompts created by others",
});

export const CreatedByMeLabel = declareString(
  "promptLibrary.savedPromptsCreatedByMe",
  {
    text: "Created by Me",
    comment:
      "Label used on the saved prompts filter component to show all user-authored Copilot prompts",
  }
);

export const CreatedByMicrosoftLabel = declareString(
  "promptLibrary.savedPromptsCreatedByMicrosoft",
  {
    text: "Created by Microsoft",
    comment:
      "Label used on the saved prompts filter component to show all Microsoft-authored Copilot prompts",
  }
);

export const CreatedByOthersLabel = declareString(
  "promptLibrary.savedPromptsCreatedByOthers",
  {
    text: "Created by others",
    comment:
      "Label used on the saved prompts filter component to show all Copilot prompts created by others",
  }
);

export const CreatedByAllLabel = declareString(
  "promptLibrary.savedPromptsCreatedByAll",
  {
    text: "Created by all",
    comment:
      "Label used on the saved prompts filter component to show all Copilot prompts created by all",
  }
);

export const CodeCategoryLabel = declareString("promptLibrary.codeCategory", {
  text: "Code",
  comment:
    "Display label for a Copilot prompt's display category. Code category represents prompts that have to do with writing code. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the display category of a Copilot prompt",
});

export const WebCopilotLabel = declareString("promptLibrary.webCopilot", {
  text: "Copilot chat (web)",
  comment:
    "Display label for a app category. It is rendered inside a dropdown for users to filter Copilot prompts and a card to show user the app category of a Copilot prompt {Locked}",
});
