import type {
  Placeholders,
  StringFunction,
  StringFunctionWithPlaceholders,
} from "@1js/localization";
import { useMemo } from "react";
import { useStringProvider } from "../StringProviderContext/StringProviderContext";
import { getString, getStringWithPlaceholders } from "../getString/getString";

export function useStrings<
  T extends {
    [stringKey: string]: StringFunction;
  },
>(strings: T): Record<keyof T, () => string> {
  const stringProvider = useStringProvider();

  const stringKeys = Object.keys(strings);
  const stringFunctions = stringKeys.map((stringKey) => strings[stringKey]);

  return useMemo(
    () =>
      stringKeys.reduce<Partial<Record<keyof T, () => string>>>(
        (r, stringKey) => ({
          ...r,
          [stringKey]: () => getString(stringProvider, strings[stringKey]),
        }),
        {}
      ),
    [stringProvider, ...stringKeys, ...stringFunctions]
  ) as Record<keyof T, () => string>;
}

export function useStringsWithPlaceholders<
  U extends string,
  T extends Record<U, StringFunctionWithPlaceholders<U>>,
>(
  strings: T
): { [K in keyof T]: (placeholders: Parameters<T[K]>[0]) => string } {
  const stringProvider = useStringProvider();

  const stringKeys = Object.keys(strings) as U[];
  const stringFunctions = stringKeys.map((stringKey) => strings[stringKey]);

  return useMemo(
    () =>
      stringKeys.reduce<
        Partial<{ [K in keyof T]: (p: Parameters<T[K]>[0]) => string }>
      >(
        (r, stringKey) => ({
          ...r,
          [stringKey]: (placeholders: Placeholders<typeof stringKey>) =>
            getStringWithPlaceholders(
              stringProvider,
              strings[stringKey],
              placeholders
            ),
        }),
        {}
      ),
    [stringProvider, ...stringKeys, ...stringFunctions]
  ) as { [K in keyof T]: (p: Parameters<T[K]>[0]) => string };
}

export function useString(stringFunction: StringFunction): string {
  const stringProvider = useStringProvider();

  return getString(stringProvider, stringFunction);
}

export function useStringWithKey(stringFunction: StringFunction): {
  key: string;
  text: string;
} {
  return { key: stringFunction.key, text: useString(stringFunction) };
}

export function useStringWithPlaceholders<K extends string>(
  stringFunction: StringFunctionWithPlaceholders<K>,
  placeholders: Placeholders<K>
): string {
  const stringProvider = useStringProvider();

  return getStringWithPlaceholders(
    stringProvider,
    stringFunction,
    placeholders
  );
}

/*
+ * This is a lazy version of useStringWithPlaceholders which returns a function
+ * that takes the placeholders and returns a string.
+ *
+ * This is useful when passing strings from a hook context to for example a
+ * class based component, where the placeholders aren't known until the string
+ * is rendered.
+ *
+ */
export function useStringForPlaceholders<K extends string>(
  stringFunction: StringFunctionWithPlaceholders<K>
): (placeholders: Placeholders<K>) => string {
  const stringProvider = useStringProvider();

  return (placeholders: Placeholders<K>): string =>
    getStringWithPlaceholders(stringProvider, stringFunction, placeholders);
}
