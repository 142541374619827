import { declareString } from "@1js/localization";

export const BookmarkButtonTooltip = declareString(
  "promptLibrary.bookmarkButtonTooltip",
  {
    text: "Save prompt",
    comment:
      "Tooltip that's displayed to user when user hovers over the bookmark button icon on a Copilot prompt card to bookmark it",
  }
);

export const UnBookmarkButtonTooltip = declareString(
  "promptLibrary.unBookmarkButtonTooltip",
  {
    text: "Remove from saved",
    comment:
      "Tooltip that's displayed to user when user hovers over the bookmark button icon on a Copilot prompt card to unbookmark it",
  }
);

export const LikeButtonTooltip = declareString(
  "promptLibrary.likeButtonTooltip",
  {
    text: "Like",
    comment:
      "Tooltip that's displayed to user when user hovers over the heart icon on a Copilot prompt card to like it",
  }
);

export const UnLikeButtonTooltip = declareString(
  "promptLibrary.unLikeButtonTooltip",
  {
    text: "Unlike",
    comment:
      "Tooltip that's displayed to user when user hovers over the heart icon on a Copilot prompt card to unlike it",
  }
);

export const BookmarkSavingLabel = declareString(
  "promptLibrary.bookmarkSavingLabel",
  {
    text: "Saving ...",
    comment:
      "Label that's displayed next to spinner when user is bookmarking a Copilot prompt",
  }
);

export const BookmarkRemovingLabel = declareString(
  "promptLibrary.removingLabel",
  {
    text: "Removing ...",
    comment:
      "Label that's displayed next to spinner when user is removing a saved Copilot prompt",
  }
);
