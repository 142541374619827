import { EntityActionTakenType, Event3sType } from "@1js/scc-types-logger";
import { type LoggerContextProps } from "../context/LoggerContext";
import { getIsoTimeStringWithMs } from "../utils/getIsoTimeStringWithMs";
import { type LoggerProps } from "./type";

/**
 * Events mapping: https://microsoft.sharepoint-df.com/:w:/t/ProjectLibbie/EfRBFyTCLPpFmVnxQDOUNCsBrdhYlbhGOpKIfRfbM379VA?e=VMcroX
 */
export const promptCardLogger = ({
  logger,
  logicalId,
  scenarioName,
  metaData,
}: LoggerContextProps) => {
  const sendPromptCardClickEvent = ({ prompt }: LoggerProps) => {
    logger.log3sEvent?.({
      localTime: getIsoTimeStringWithMs(new Date()),
      eventType: Event3sType.SearchEntityClicked,
      logicalId,
      scenario: scenarioName,
      id: prompt.ReferenceId ?? "null",
      metaData: JSON.stringify({
        referenceId: `${prompt.ReferenceId}`,
        ...metaData?.(Event3sType.SearchEntityClicked),
      }),
    });
  };

  const sendPromptCardFavoriteEvent = (
    { prompt }: LoggerProps,
    favorite: boolean
  ) => {
    logger.log3sEvent?.({
      localTime: getIsoTimeStringWithMs(new Date()),
      eventType: Event3sType.EntityActionTaken,
      logicalId,
      id: prompt.ReferenceId ?? "null",
      scenario: scenarioName,
      metaData: JSON.stringify({
        referenceId: `${prompt.ReferenceId}`,
        EntityActionTakenType: favorite
          ? EntityActionTakenType.Download
          : EntityActionTakenType.Unsubscribe,
        ...metaData?.(Event3sType.EntityActionTaken),
      }),
    });
  };

  const sendPromptCopyEvent = ({ prompt }: LoggerProps) => {
    logger.log3sEvent?.({
      localTime: getIsoTimeStringWithMs(new Date()),
      eventType: Event3sType.EntityActionTaken,
      logicalId,
      id: prompt.ReferenceId ?? "null",
      scenario: scenarioName,
      metaData: JSON.stringify({
        referenceId: `${prompt.ReferenceId}`,
        EntityActionTakenType: EntityActionTakenType.SendCopy,
        ...metaData?.(Event3sType.EntityActionTaken),
      }),
    });
  };

  const sendPromptCopyLinkEvent = ({ prompt }: LoggerProps) => {
    logger.log3sEvent?.({
      localTime: getIsoTimeStringWithMs(new Date()),
      eventType: Event3sType.EntityActionTaken,
      logicalId,
      id: prompt.ReferenceId ?? "null",
      scenario: scenarioName,
      metaData: JSON.stringify({
        referenceId: `${prompt.ReferenceId}`,
        EntityActionTakenType: EntityActionTakenType.CopyLink,
        ...metaData?.(Event3sType.EntityActionTaken),
      }),
    });
  };

  const sendPromptShareViaEmailEvent = ({ prompt }: LoggerProps) => {
    logger.log3sEvent?.({
      localTime: getIsoTimeStringWithMs(new Date()),
      eventType: Event3sType.EntityActionTaken,
      logicalId,
      id: prompt.ReferenceId ?? "null",
      scenario: scenarioName,
      metaData: JSON.stringify({
        referenceId: `${prompt.ReferenceId}`,
        EntityActionTakenType: EntityActionTakenType.Share,
        ...metaData?.(Event3sType.EntityActionTaken),
      }),
    });
  };

  return {
    sendPromptCardClickEvent,
    sendPromptCardFavoriteEvent,
    sendPromptCopyEvent,
    sendPromptCopyLinkEvent,
    sendPromptShareViaEmailEvent,
  };
};
