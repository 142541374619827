/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLogger } from "@1js/pl-logger";
import type { Group } from "@1js/pl-types";
import { Card, mergeClasses } from "@fluentui/react-components";
import DOMPurify from "dompurify";
import * as React from "react";
import { forwardRef, useState } from "react";
import type { Appearance, ProductType, Prompt } from "../../data";
import { ProductTypes } from "../../data";
import type { Badge } from "../PromptBadges/PromptBadges.types";
import { PromptCardBody } from "../PromptBody/PromptCardBody";
import {
  useAutoHideButtonsStyles,
  useInteractiveStyles,
  useStyles,
} from "../PromptCard.styles";
import { PromptCardEditBody } from "../PromptCardEditBody";
import { GoBoldPromptCardFooter } from "./GoBoldPromptCardFooter/GoBoldPromptCardFooter";
import { GoBoldPromptCardHeader } from "./GoBoldPromptCardHeader";
export type GoBoldPromptCardProps = {
  appearance: Appearance;
  className?: string;
  isPromptFocused?: boolean;
  prompt: Prompt;
  isPromptRingTagEnabled?: boolean;
  displayPosition?: number;
  isBadgesEnabled?: boolean;
  isPromptLikingEnabled?: boolean;
  isFavoriteEnabled?: boolean;
  onClicked?: (prompt: Prompt, displayPosition?: number) => void;
  onFavoriteToggled?: (prompt: Prompt, value: boolean) => void;
  isAgentPromptFavoriteEnabled?: boolean;
  isPromptSavingEnabled?: boolean;
  isPromptEditingEnabled?: boolean;
  isPromptSharingEnabled?: boolean;
  isPromptSharingViaEmailEnabled?: boolean;
  isPromptSharingToWorkgroupEnabled?: boolean;
  isPromptRemovingFromWorkgroupEnabled?: boolean;
  isPromptSharingOneClickUxEnabled?: boolean;
  onShareViaCopyLinkClicked?: (prompt: Prompt) => void;
  onShareViaEmailClicked?: (prompt: Prompt) => void | Promise<void>;
  onShareToWorkgroupClicked?: (prompts: Prompt[]) => void | Promise<void>;
  onDelete?: (prompt: Prompt) => void;
  onEditToggled?: (prompt: Prompt) => void;
  onEditSaved?: (prompt: Prompt) => void;
  onFocus?: (prompt: Prompt, displayPosition?: number) => void;
  onRemoveFromWorkgroupClicked?: (
    prompts: Prompt[],
    groupIds: string[]
  ) => void;
  "aria-posinset"?: number;
  "aria-setsize"?: number;
  selectedGroupId?: string;
  groups?: Group[];
  setShareButtonTarget?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  isShowPromptAuthor?: boolean;
  extensionIconMap?: Record<string, string>;
  handoffProduct?: string;
};

export const GoBoldPromptCard = forwardRef<
  HTMLDivElement,
  GoBoldPromptCardProps
>((props, ref) => {
  const {
    appearance,
    prompt,
    isPromptFocused: promptFocused,
    displayPosition,
    className,
    isBadgesEnabled,
    isFavoriteEnabled,
    onClicked,
    onFavoriteToggled,
    isAgentPromptFavoriteEnabled,
    isPromptSavingEnabled,
    isPromptEditingEnabled,
    isPromptSharingEnabled,
    isPromptSharingViaEmailEnabled,
    isPromptSharingToWorkgroupEnabled,
    isPromptRingTagEnabled,
    isPromptRemovingFromWorkgroupEnabled,
    isPromptSharingOneClickUxEnabled,
    isPromptLikingEnabled,
    onDelete,
    onEditToggled,
    onEditSaved,
    onFocus,
    onShareViaCopyLinkClicked,
    onShareViaEmailClicked,
    onShareToWorkgroupClicked,
    onRemoveFromWorkgroupClicked,
    selectedGroupId,
    groups,
    setShareButtonTarget,
    isShowPromptAuthor,
    extensionIconMap,
    handoffProduct,
  } = props;

  const logger = useLogger();
  const styles = useStyles(appearance);
  const interactiveStyles = useInteractiveStyles(appearance);
  const autoHideButtonsStyles = useAutoHideButtonsStyles();

  const handleClick = () => {
    !prompt.IsEditing && onClicked && onClicked(prompt, displayPosition);
    onClicked && logger?.promptCard.sendPromptCardClickEvent({ prompt });
  };

  const [isCardFocused, setIsCardFocused] = useState(false);

  // ignore products which are not supported and we have no icons for
  const supportedProducts = prompt.Products.filter((product) =>
    ProductTypes.includes(product as ProductType)
  );
  const badges: Badge[] = supportedProducts.map((product) => {
    return {
      id: product === "CWC" ? handoffProduct || product : product,
      onAction: undefined,
    };
  });

  const sanitizedDisplayText = DOMPurify.sanitize(`${prompt.DisplayText}.`, {
    IN_PLACE: true,
    ALLOWED_TAGS: [],
  });

  React.useEffect(() => {
    if (!promptFocused) {
      setIsCardFocused(false);
    }
  }, [promptFocused]);

  const handleCardFocus = () => {
    setIsCardFocused(true);
    if (onFocus) {
      onFocus(prompt, displayPosition);
    }
  };

  return (
    <Card
      data-type="card"
      className={mergeClasses(
        styles.card,
        !!onClicked && interactiveStyles.card,
        className,
        (isPromptSavingEnabled || isPromptRemovingFromWorkgroupEnabled) &&
          autoHideButtonsStyles.card
      )}
      size="large"
      tabIndex={displayPosition === 0 ? 0 : -1}
      ref={ref}
      data-testid="prompt-card"
      data-display-category={prompt.DisplayCategory}
      data-products={prompt.Products?.join(",")}
      onFocus={handleCardFocus}
      role="listitem"
      aria-label={sanitizedDisplayText}
      aria-posinset={props["aria-posinset"]}
      aria-setsize={props["aria-setsize"]}
    >
      {/* we are attaching handleClick to this div instead of <Card> itself because as soon as we add handleClick to <Card>, tab navigation within the card breaks. User loses the ability to navigate to bookmark action button using tab. */}
      <div
        className={mergeClasses(
          styles.cardBody,
          !!onClicked && interactiveStyles.cardBody
        )}
        tabIndex={-1}
        onClick={handleClick}
      >
        {
          <GoBoldPromptCardHeader
            appearance={appearance}
            prompt={prompt}
            isBookmarking={prompt.IsBookmarking}
            isCardFocused={isCardFocused}
            isFavoriteEnabled={isFavoriteEnabled}
            isFavorited={prompt.UserActivity.Favorite.IsFavorite}
            onFavorited={() => {
              logger?.promptCard.sendPromptCardFavoriteEvent(
                { prompt },
                !prompt.UserActivity.Favorite.IsFavorite
              );
              onFavoriteToggled &&
                onFavoriteToggled(
                  prompt,
                  !prompt.UserActivity.Favorite.IsFavorite
                );
            }}
            isPromptLikingEnabled={isPromptLikingEnabled}
            isAgentPromptFavoriteEnabled={isAgentPromptFavoriteEnabled}
            isPromptSavingEnabled={isPromptSavingEnabled}
            isPromptEditingEnabled={isPromptEditingEnabled}
            isPromptSharingEnabled={isPromptSharingEnabled}
            isPromptSharingViaEmailEnabled={isPromptSharingViaEmailEnabled}
            isPromptSharingToWorkgroupEnabled={
              isPromptSharingToWorkgroupEnabled
            }
            isPromptSharingOneClickUxEnabled={isPromptSharingOneClickUxEnabled}
            onShareViaCopyLinkClicked={onShareViaCopyLinkClicked}
            onShareViaEmailClicked={onShareViaEmailClicked}
            onShareToWorkgroupClicked={onShareToWorkgroupClicked}
            isDeleting={prompt.IsDeleting}
            isRemoving={prompt.RemovingState === "long"}
            onDelete={() => {
              onDelete && onDelete(prompt);
            }}
            onEditToggled={() => {
              onEditToggled && onEditToggled(prompt);
            }}
            onEditSaved={() => {
              onEditSaved && onEditSaved(prompt);
            }}
            isEditing={prompt.IsEditing}
            isPromptRingTagEnabled={isPromptRingTagEnabled}
            isPromptRemovingFromWorkgroupEnabled={
              isPromptRemovingFromWorkgroupEnabled
            }
            onRemoveFromWorkgroupClicked={onRemoveFromWorkgroupClicked}
            selectedGroupId={selectedGroupId}
            groups={groups}
            setShareButtonTarget={setShareButtonTarget}
            isShowPromptAuthor={isShowPromptAuthor}
            extensionIconMap={extensionIconMap}
          />
        }
        {prompt.IsEditing ? (
          <PromptCardEditBody prompt={prompt} appearance={appearance} />
        ) : (
          <PromptCardBody prompt={prompt} appearance={appearance} />
        )}
        <GoBoldPromptCardFooter
          badges={badges}
          isBadgeEnabled={isBadgesEnabled}
          handoffProduct={handoffProduct}
        />
      </div>
    </Card>
  );
});
GoBoldPromptCard.displayName = "GoBoldPromptCard";
