import { useStrings } from "@1js/localization-components";
import {
  makeStyles,
  Menu,
  MenuButton,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Dismiss24Regular, Navigation24Regular } from "@fluentui/react-icons";
import * as React from "react";
import { AllPromptsHeading } from "../../common/common.web.string";
import { appendOcidQueryParam } from "../../utils/ocid";
import { HeaderDropDownMobile } from "./HeaderDropDownMobile.web";
import { HeaderLinkMobile } from "./HeaderLinkMobile.web";
import {
  DiscoverLink,
  ExploreProductsLink,
  GettingStarted,
  WhatsNew,
} from "./HeaderNav.web.string";
import type { HeaderNavBaseProps } from "./PageHeader.web.data";

const useStyles = makeStyles({
  main: {
    display: "inline-flex",
    height: "48px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    paddingLeft: "7px",
    ...shorthands.gap("10px"),
    ...shorthands.borderRadius("4px"),
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.padding("8px"),
    ...shorthands.borderRadius("4px"),
  },
  menupopover: {
    display: "flex",
    width: "100%",
    maxWidth: "540px",
    boxShadow:
      "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "#FFF",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    ...shorthands.padding("12px 4px"),
  },
  menulist: {
    marginTop: "12px",
    marginBottom: "12px",
    ...shorthands.gap("12px"),
  },
  icon: {
    color: tokens.colorNeutralForeground2,
  },
});

const mainPageUrl = `${window.location.origin}/prompts/all`;

export const HeaderNavMobile: React.FC<HeaderNavBaseProps> = ({
  copilotProductLinks,
  gettingStartedLinks,
  whatsNewLink,
}) => {
  const styles = useStyles();
  const [icon, setIcon] = React.useState(<Navigation24Regular />);
  const localizations = useStrings({
    DiscoverLink,
    ExploreProductsLink,
    AllPromptsHeading,
    WhatsNew,
    GettingStarted,
  });

  const handleMenuDismiss = (isOpen: boolean) => {
    setIcon(
      isOpen ? (
        <Dismiss24Regular className={styles.icon} />
      ) : (
        <Navigation24Regular className={styles.icon} />
      )
    );
  };

  return (
    <div className={styles.main} data-testid="header-navbar-mobile">
      <Menu
        persistOnItemClick={true}
        onOpenChange={(_, data) => handleMenuDismiss(data.open)}
      >
        <MenuTrigger>
          <MenuButton
            className={styles.button}
            appearance="transparent"
            icon={icon}
          ></MenuButton>
        </MenuTrigger>

        <MenuPopover className={styles.menupopover}>
          <MenuList className={styles.menulist}>
            <HeaderDropDownMobile
              displayText={localizations.ExploreProductsLink()}
              linkDictionary={copilotProductLinks}
            />
            <HeaderLinkMobile
              url={appendOcidQueryParam(
                mainPageUrl,
                "CopilotLab_HM_PromptsToTry"
              )}
              displayText={localizations.AllPromptsHeading()}
              openInNewWindow={false}
            />
            <HeaderDropDownMobile
              displayText={localizations.GettingStarted()}
              linkDictionary={gettingStartedLinks}
            />
            <HeaderLinkMobile
              url={whatsNewLink}
              displayText={localizations.WhatsNew()}
              openInNewWindow={true}
            />
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};
