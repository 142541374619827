import getRandomValues from "./getRandomValues";

const guidEmpty = "00000000-0000-0000-0000-000000000000";
export function isGuidNullOrEmpty(guid: string | undefined | null): boolean {
  return !guid || guid === guidEmpty;
}

// A check for RFC4122-compliant UUID version 4: https://tools.ietf.org/html/rfc4122 in a form of a string with dashes
export function isValidGuid(guid: string | undefined | null): boolean {
  return (
    !isGuidNullOrEmpty(guid) &&
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      guid || ""
    )
  );
}

export function generateGuid(): string {
  const randomNumbers = getRandomValues();

  let offset = 0;
  let guid = "";
  for (let i = 0; i < 36; i++) {
    switch (i) {
      case 8:
      case 13:
      case 18:
      case 23: {
        offset += 1;
        guid += "-";
        break;
      }
      case 14: {
        offset += 1;
        guid += "4";
        break;
      }
      case 19: {
        guid += ((randomNumbers[i - offset] % 4) + 8).toString(16);
        break;
      }
      default: {
        guid += (randomNumbers[i - offset] % 16).toString(16);
        break;
      }
    }
  }

  return guid;
}
