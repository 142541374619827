import type { ProductType, Prompt } from "@1js/pl-card-components";
import type { HandoffFile } from "../services/workingsetfiles-client.web";

export type HandoffCallback = {
  host: string;
  locale: string;
  prompt: Prompt;
  destination: ProductType | string;
  file?: HandoffFile;
  abortController: AbortController;
};

export type HandoffCallbackResult = { handoffUrl: string; queryId?: string };

export type HandoffResult = {
  handoff: (props: HandoffCallback) => Promise<HandoffCallbackResult>;
  isHandoffSupported: (
    product: ProductType | string,
    prompt?: Prompt
  ) => boolean;
  isProductNeedsFile: (product: ProductType | string) => boolean;
  chooseBestProductForHandoff: (
    prompt: Prompt
  ) => ProductType | string | undefined;
};

const ErrorMessages = [
  "NoResponse",
  "PopupBlocked",
  "PopupBlockedNewWindow",
  "UnknownDestination",
  "UnknownError",
  "UserCancelled",
  "HandoffPromptNotValid",
] as const;
export const HandoffErrors = new Set(ErrorMessages);
export type HandoffErrorsType = (typeof ErrorMessages)[number];

export type BizChatSubEntityContext = {
  subEntityId: string;
};
