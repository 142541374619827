import {
  makeStyles,
  mergeClasses,
  shorthands,
} from "@fluentui/react-components";
import * as React from "react";

export type ColumnSize =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | "1/5"
  | "auto";

export type ColumnProps = {
  size: ColumnSize;
  children: React.ReactNode;
  className?: string;
  noGutter?: boolean;
};

const useStyles = makeStyles({
  col: {
    position: "relative",
    width: "100%",
    paddingRight: "0.75rem",
    paddingLeft: "0.75rem",
    boxSizing: "border-box",
  },

  noGutter: {
    paddingRight: 0,
    paddingLeft: 0,
  },

  colAuto: {
    ...shorthands.flex(0, 0, "auto"),
    width: "auto",
    maxWidth: "100%",
  },

  col1: {
    ...shorthands.flex(0, 0, "8.33333%"),
    maxWidth: "8.33333%",
  },

  col2: {
    ...shorthands.flex(0, 0, "16.66667%"),
    maxWidth: "16.66667%",
  },

  col3: {
    ...shorthands.flex(0, 0, "25%;"),
    maxWidth: "25%;",
  },

  col4: {
    ...shorthands.flex(0, 0, "33.33333%"),
    maxWidth: "33.33333%",
  },

  col5: {
    ...shorthands.flex(0, 0, "41.66667%"),
    maxWidth: "41.66667%",
  },

  col6: {
    ...shorthands.flex(0, 0, "50%"),
    maxWidth: "50%",
  },

  col7: {
    ...shorthands.flex(0, 0, "58.33333%"),
    maxWidth: "58.33333%",
  },

  col8: {
    ...shorthands.flex(0, 0, "66.66667%"),
    maxWidth: "66.66667%",
  },

  col9: {
    ...shorthands.flex(0, 0, "75%"),
    maxWidth: "75%",
  },

  col10: {
    ...shorthands.flex(0, 0, "83.33333%"),
    maxWidth: "83.33333%",
  },

  col11: {
    ...shorthands.flex(0, 0, "91.66667%"),
    maxWidth: "91.66667%",
  },

  col12: {
    ...shorthands.flex(0, 0, "100%"),
    maxWidth: "100%",
  },

  "col1/5": {
    ...shorthands.flex(0, 0, "20%"),
    maxWidth: "20%",
  },
});

const getColClassBySize = (
  size: ColumnSize,
  styles: Record<
    | "col"
    | "colAuto"
    | "col1"
    | "col2"
    | "col3"
    | "col4"
    | "col5"
    | "col6"
    | "col7"
    | "col8"
    | "col9"
    | "col10"
    | "col11"
    | "col12"
    | "col1/5",
    string
  >
) => {
  switch (size) {
    case 1:
      return styles.col1;
    case 2:
      return styles.col2;
    case 3:
      return styles.col3;
    case 4:
      return styles.col4;
    case 5:
      return styles.col5;
    case 6:
      return styles.col6;
    case 7:
      return styles.col7;
    case 8:
      return styles.col8;
    case 9:
      return styles.col9;
    case 10:
      return styles.col10;
    case 11:
      return styles.col11;
    case 12:
      return styles.col12;
    case "1/5":
      return styles["col1/5"];
    case "auto":
      return styles.colAuto;
    default:
      return styles.colAuto;
  }
};

export const Column: React.FC<ColumnProps> = ({
  size,
  noGutter,
  children,
  className,
}) => {
  const styles = useStyles();
  return (
    <div
      className={mergeClasses(
        styles.col,
        getColClassBySize(size, styles),
        noGutter && styles.noGutter,
        className
      )}
    >
      {children}
    </div>
  );
};
