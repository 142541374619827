import type {
  Metadata,
  Prompt,
  TenantPrompt,
  UnifiedPrompt,
  UserExtensionPrompt,
  UserPrompt,
} from "./types";
import { AllowedExtensionList, AllowedJobTypeList, WXPProducts } from "./types";

/**
 * Check if the prompt is a BizChat prompt
 * @param prompt Prompt
 * @returns true if the prompt is a BizChat prompt
 */
export const isBizChatPrompt = (prompt: Prompt) => {
  return prompt.Products && prompt.Products?.indexOf("BizChat") > -1;
};

/**
 * Check if the prompt is a CWC prompt
 * @param prompt Prompt
 * @returns true if the prompt is a CWC prompt
 */
export const isCWCPrompt = (prompt: Prompt) => {
  return prompt.Products?.includes("CWC");
};

export const isWXPPrompt = (prompt: Prompt) => {
  return prompt.Products?.some((product) => WXPProducts.has(product));
};

/**
 * Check if the prompt is prompt that can be run in Copilot Chat
 * @param prompt Prompt
 * @returns true if the prompt is a Copilot Chat prompt
 */
export const isCopilotChatPrompt = (prompt: Prompt) => {
  return isBizChatPrompt(prompt) || isCWCPrompt(prompt);
};

/**
 * Check if the prompt is a User prompt
 * @param prompt Prompt
 * @returns true if the prompt is a User authored prompt
 */
export const isUserPrompt = (prompt: Prompt): prompt is UserPrompt => {
  return prompt.Origin === "User";
};

export const isTenantPrompt = (prompt: Prompt): prompt is TenantPrompt => {
  return prompt.Origin === "Tenant";
};

/**
 * Check if the prompt is an extension prompt
 * @param prompt Prompt
 * @returns true if the prompt is an extension prompt
 */
export const isExtensionPrompt = (prompt: Prompt) => {
  return (
    (!!prompt && !!prompt.Origin && AllowedExtensionList.has(prompt.Origin)) ||
    (!!prompt && isUserCreatedExtensionPrompt(prompt))
  );
};

/**
 * Check if the prompt is a user created extension prompt
 * @param prompt Prompt
 * @returns true if the prompt is a user created extension prompt
 */
export const isUserCreatedExtensionPrompt = (prompt: Prompt) => {
  return (
    !!prompt && isUserPrompt(prompt) && !!(prompt as UserExtensionPrompt).GptId
  );
};

export const isUnifiedPrompt = (prompt: Prompt | UnifiedPrompt) => {
  return "PromptId" in prompt;
};

/**
 * Check if the prompt is a workgroup prompt
 * @param prompt Prompt
 * @returns true if the prompt is a workgroup prompt
 */
export const isWorkgroupPrompt = (prompt: Prompt | undefined) => {
  return !!prompt?.Groups;
};

/**
 * Check if the prompt is a shared prompt (e.g. workgroup prompt or tenant shared prompt)
 * @param prompt Prompt
 */
export const isSharedPrompt = (prompt: Prompt) => {
  // Note: this method should be expanded in the future to include other types of shared prompts (e.g. tenant shared prompts)
  return isWorkgroupPrompt(prompt);
};

/**
 * Check if the prompt is a non-shared user prompt (i.e. user authored prompt that is not shared with a workgroup)
 * @param prompt Prompt
 */
export const isNonSharedUserPrompt = (prompt: Prompt): prompt is UserPrompt => {
  return isUserPrompt(prompt) && !isSharedPrompt(prompt);
};

/**
 * Check if the prompt is authored by Microsoft
 * @param prompt Prompt object
 * @returns true if the prompt is authored by Microsoft, false otherwise
 */
export const isMicrosoftPrompt = (prompt: Prompt) => {
  return !isUserPrompt(prompt) && !isTenantPrompt(prompt);
};

/**
 * Check if the prompt is liked by the user
 * Note: This is different from isUserPrompt, which checks if the prompt is authored by the user
 * @param prompt Prompt object
 * @returns true if the prompt is liked by the user, false otherwise
 */
export const isLikedPrompt = (prompt: Prompt) => {
  // A prompt is liked if it is favorited and not authored by the user
  // This is because all user authored prompts are automatically favorited in the backend
  return (
    prompt.UserActivity?.Favorite?.IsFavorite && !isNonSharedUserPrompt(prompt)
  );
};

/**
 * Extract job type from the prompt
 * @param prompt Prompt
 * @returns job type list
 */
export const extractJobTypes = (prompt: Prompt): string[] => {
  // example meta data looks like this { name: "Industry", value: "Human Resources" }
  // go through all meta data from a prompt, and extract values of each meta data to a list only if the name is in the allowed list.
  const jobTypes: string[] = [];
  if (prompt && Array.isArray(prompt.Metadata)) {
    prompt.Metadata.forEach((item: Metadata) => {
      if (AllowedJobTypeList.has(item.Name)) {
        jobTypes.push(item.Value);
      }
    });
  }

  return jobTypes;
};
