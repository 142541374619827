import type {
  BasePageProps,
  ClientSettings,
  CopilotCapabilitiesLinks,
  CopilotPortalSettings,
  PromptSettings,
} from "../types";

export const parseDataModel = <T>(element: HTMLElement | null): T => {
  const modelStr = element?.getAttribute("data-model");
  const dataModel = modelStr ? JSON.parse(modelStr) : {};
  return dataModel as T;
};

export function postProcessHeaderLinks(
  inputLinks: CopilotCapabilitiesLinks[]
): CopilotCapabilitiesLinks[] {
  const links: CopilotCapabilitiesLinks[] = [];

  for (const item of inputLinks) {
    if (!item.openInNewWindow) {
      item.url = "/" + new URL(location.href).pathname.split("/")[1] + item.url;
    }
    links.push(item);
  }
  return links;
}

export function getClientSettings(): ClientSettings {
  const settingsElement = document.getElementById("prompts-settings");
  if (settingsElement) {
    const settingsModel = JSON.parse(
      settingsElement.dataset.settings as string
    );
    return settingsModel;
  }
  return {} as ClientSettings;
}

export const getPromptsClientSettings = (): PromptSettings => {
  const clientSettings = getClientSettings();

  return clientSettings
    ? getClientSettings().promptsClientSettings
    : ({} as PromptSettings);
};

export const getCopilotPortalSettings = (): CopilotPortalSettings => {
  const clientSettings = getClientSettings();

  return clientSettings
    ? getClientSettings().copilotPortalSettings
    : ({} as CopilotPortalSettings);
};

/**
 * Gets the hostname of the current page for making API calls
 * @returns The hostname of the current page, including the port on localhost
 */
export function getPageHostName(): string {
  let { hostname } = window.location;
  const { port } = window.location;
  if (hostname.includes("localhost") && port !== "443" && port !== "80") {
    hostname = `${hostname}:${port}`;
  }
  return hostname;
}

export function enrichFlights(
  flightSet: Set<string>,
  dataModel: BasePageProps
) {
  if (!dataModel.uhfDisplayed) {
    flightSet.add("isHeaderEnabled");
  } else {
    flightSet.delete("isHeaderEnabled");
  }
  if (dataModel.isUserLicensed) {
    flightSet.add("isUserLicensed");
  } else {
    flightSet.delete("isUserLicensed");
  }
}

export const isEligibile = (
  licenses: string[] | undefined,
  isMetaOSAppActive: boolean
) => {
  if (!isMetaOSAppActive) {
    return false;
  }

  const checkForAny = ["CopilotBusinessChat", "CopilotAI"];
  if (licenses === undefined || (licenses && licenses.length === 0)) {
    return false;
  }

  const allLicensesHashed = new Set(licenses);
  for (const checkFor of checkForAny) {
    if (allLicensesHashed.has(checkFor)) {
      return true;
    }
  }
  return false;
};
