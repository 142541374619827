import React from "react";
import { PropertyContext } from "../pages/PropertyContext.web";
import type { Flights } from "../types";

export const useFlightContext = () => {
  const isMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [siteSetting, setSiteSettings] = React.useContext(PropertyContext);
  const jsonFlights = JSON.stringify(Array.from(siteSetting.activeFlights));
  const isFlightActive = React.useCallback(
    (flightName: Flights) => {
      return siteSetting.activeFlights.has(flightName);
    },
    // The activeFlights object keeps changing even when its content is the same, so using the JSON stringified version to compare
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jsonFlights]
  );

  const activeFlights = React.useMemo(() => {
    return new Set(Array.from(siteSetting.activeFlights.values()));
  }, [siteSetting.activeFlights]);

  /**
   * Sets flight state for current browser page rendered. Should not be used, flights should be immutable on client.
   * @param flightName Flight name to override
   * @param isActive whether flight should be active or not
   */
  const setFlightState = React.useCallback(
    (flightName: Flights, isActive: boolean) => {
      if (!isMounted.current) {
        return;
      }

      setSiteSettings((prev) => {
        const newActiveFlights = new Set(prev.activeFlights);
        if (isActive) {
          newActiveFlights.add(flightName);
        } else {
          newActiveFlights.delete(flightName);
        }
        return {
          ...prev,
          activeFlights: newActiveFlights,
        };
      });
    },
    [setSiteSettings]
  );
  return {
    isFlightActive,
    setFlightState,
    activeFlights,
  };
};
