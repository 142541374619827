import {
  isBizChatPrompt,
  isCWCPrompt,
  isWXPPrompt,
  type OcidValues,
} from "@1js/pl-types";
import type { Prompt } from "../components/data";

export type ScenarioType =
  | "tryInApp"
  | "sharePromptViaEmail"
  | "sharePromptCopyLink"
  | "shareLink"
  | "shareLinkViaEmail"
  | "shareResponseViaEmail"
  | "shareLinkViaRichCopy"
  | "shareLinkViaPage"
  | "shareLinkViaPopover"
  | "shareLinkViaPopoverDialog"
  | "shareLinkViaResponse"
  | "shareLinkViaResponseDialog"
  | "shareLinkViaPromptLibrary"
  | "shareLinkViaEmailPopoverDialog"
  | "shareLinkViaEmailResponseDialog"
  | "shareLinkViaEmailPromptLibrary"
  | "notificationFetch"
  | "notificationShare";

export type ChatType = "web" | "work";

export type SharePromptPayloadType =
  // When there are no entities in the prompt
  | "PlainText"
  // When there are entities in the prompt that the customer needs to fill out in the Copilot experience
  | "CIQEmpty"
  // When there are entities in the prompt that the customer ALL filled out in Copilot Lab
  | "CIQFilled";

const prodPromptGalleryHost = "copilot.cloud.microsoft";
const stagingPromptGalleryHost = "copilot.cloud-dev.microsoft";
/**
 * Removes HTML tags from the prompt text
 * @param promptText prompt text
 * @returns prompt text without HTML tags
 */
const removeHtmlTags = (promptText: string) =>
  promptText.replace(/<(?!((\/)*entity))[^>]*>?/gm, "");

const getChatType = (prompt: Prompt): ChatType | undefined => {
  let chatType: ChatType | undefined = undefined;
  if (prompt.Products && prompt.Products.length > 0) {
    if (isBizChatPrompt(prompt)) {
      chatType = "work";
    } else if (isCWCPrompt(prompt)) {
      chatType = "web";
    }
  }
  return chatType;
};

/**
 * Builds the payload for the handoff URL
 * @param prompt prompt to be shared
 * @param scenario scenario of the handoff
 * @returns prompt payload in JSON
 */
export const getSharePromptPayloadNewFormat = (
  prompt: Prompt,
  scenario: ScenarioType,
  clickTimestamp?: string,
  serviceGeneratedLinkId?: string,
  chatType?: ChatType
): {
  id: string;
  scenario: ScenarioType;
  properties: {
    promptSource: string;
    clickTimestamp: string;
  };
  chatType?: ChatType;
  version: number;
} => {
  let id = serviceGeneratedLinkId ?? prompt.Id;
  if (id === prompt.Id) {
    // removing locale based on underscore index
    const underscoreIndex = id.indexOf("_");

    if (underscoreIndex !== -1) {
      id = id.substring(0, underscoreIndex);
    }
  }

  if (!clickTimestamp) {
    clickTimestamp = new Date().toISOString();
  }

  if (chatType === undefined) {
    chatType = getChatType(prompt);
  }
  return {
    id,
    scenario,
    properties: {
      promptSource: prompt.Origin === "User" ? "user" : "microsoft",
      clickTimestamp,
    },
    chatType: chatType,
    version: serviceGeneratedLinkId ? 1.1 : 1.0,
  };
};

/**
 * Builds the payload for the handoff URL
 * @param prompt prompt to be shared
 * @param scenario scenario of the handoff
 * @returns prompt payload in JSON
 */
export const getTryInPromptPayload1_2Format = (
  prompt: Prompt,
  scenario: ScenarioType,
  clickTimestamp?: string,
  chatType?: ChatType
): {
  id: string;
  scenario: ScenarioType;
  properties: {
    promptSource: string;
    clickTimestamp: string;
  };
  chatType: ChatType;
  version: number;
} => {
  if (!clickTimestamp) {
    clickTimestamp = new Date().toISOString();
  }
  return {
    id: prompt.Id,
    scenario,
    properties: {
      promptSource: "microsoft",
      clickTimestamp,
    },
    // TODO: update it to web, when feature also available to other user segments
    chatType: chatType != undefined ? chatType : "work",
    version: 1.2,
  };
};

/**
 * Builds the payload for the share prompt URL
 * @param prompt prompt to be shared
 * @param payloadType type of payload
 * @param shouldSubmit whether the prompt should be executed automatically
 * @param source source of the prompt
 * @param isRemoveHtmlTags whether to remove HTML tags from the prompt command text
 * @returns prompt payload in JSON
 */
export const getSharePromptPayload = (
  prompt: Prompt,
  shouldSubmit: boolean,
  source: string,
  isRemoveHtmlTags = true
): {
  type: SharePromptPayloadType;
  text: string;
  source: string;
  shouldSubmit: boolean;
} => {
  let type: SharePromptPayloadType = "PlainText";
  if (prompt.CommandText.indexOf("<entity") !== -1) {
    type = "CIQEmpty";
  }

  return {
    type,
    text: isRemoveHtmlTags
      ? removeHtmlTags(prompt.CommandText)
      : prompt.CommandText,
    source,
    shouldSubmit,
  };
};

/**
 * Converts a string to base64 and replaces the characters to be URL safe
 * @param jsonToBeEncoded string to be encoded
 * @returns base64 encoded string
 */
export const stringToBase64 = (jsonToBeEncoded: string): string => {
  // Use TextEncoder to handle non-Latin1 characters when encoding in base64.
  const bytes = new TextEncoder().encode(jsonToBeEncoded);
  if (bytes === undefined) {
    throw new Error("Failed to encode string to bytes in TextEncoder");
  }
  const binString = String.fromCodePoint(...bytes);
  const encodedString = btoa(binString)
    .replace(/=+$/, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
  return encodedString;
};

/**
 * Builds the M365 Chat URL for the given Prompt object
 * @param prompt Prompt to build URL for
 * @returns M365 Chat URL
 */
export const buildM365ChatUrl = ({
  prompt,
  scenario,
  serviceGeneratedLinkId,
  removeHtmlTags = true,
  newFormat = false,
  clickTimestamp,
  chatType,
  isAppendM365ChatFeatures = false,
  source = "CopilotLab",
  isTryIn1_2Format = false,
  isBizChatTryInTeamsAppEnabled = false,
}: {
  prompt: Prompt;
  scenario: ScenarioType;
  serviceGeneratedLinkId?: string;
  removeHtmlTags?: boolean;
  newFormat?: boolean;
  clickTimestamp?: string;
  chatType?: ChatType;
  isAppendM365ChatFeatures?: boolean;
  source: string;
  isTryIn1_2Format?: boolean;
  isBizChatTryInTeamsAppEnabled?: boolean;
}): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let promptPayload: any = {};

  if (isTryIn1_2Format && newFormat && !serviceGeneratedLinkId) {
    promptPayload = getTryInPromptPayload1_2Format(
      prompt,
      scenario,
      clickTimestamp,
      chatType
    );
  } else if (newFormat || serviceGeneratedLinkId) {
    promptPayload = getSharePromptPayloadNewFormat(
      prompt,
      scenario,
      clickTimestamp,
      serviceGeneratedLinkId,
      chatType
    );
  } else {
    promptPayload = getSharePromptPayload(
      prompt,
      false,
      source,
      removeHtmlTags
    );
  }

  const base64PromptPayload = stringToBase64(JSON.stringify(promptPayload));

  // when modifying these URLs, ensure to update https://office.visualstudio.com/MAX/_git/supportservice?path=/src/SupportService.Prompts/Models/SharedPromptEmailNotificationRequestModel.cs
  if (source === "bingchatpromptshare") {
    return `https://www.bing.com/business/chat?payload=${base64PromptPayload}`;
  }

  if (source === "copilothubpromptshare") {
    return `https://copilot.microsoft.com/?payload=${base64PromptPayload}`;
  }

  let url = `https://www.microsoft365.com/chat/entity1-d870f6cd-4aa5-4d42-9626-ab690c041429/${base64PromptPayload}`;

  if (scenario == "tryInApp" && isAppendM365ChatFeatures) {
    url += "?M365ChatFeatures=TryInApp";
  }

  //For Teams Desktop Copilot Prompt Gallery MetaOs app this is the URL pattern needed to open in BizChat teams
  if (isTeamsApp() && isBizChatTryInTeamsAppEnabled) {
    url = `https://teams.microsoft.com/l/entity/b5abf2ae-c16b-4310-8f8a-d3bcdb52f162/entity1-d870f6cd-4aa5-4d42-9626-ab690c041429?context={"subEntityId":"${base64PromptPayload}"}`;
  }

  return url;
};

/**
 * Builds the encoded prompt link URL for the given Prompt object and service generated link ID (used primarily for WEPO prompts)
 * @param prompt prompt to be shared
 * @param scenario scenario of the sharing (e.g. tryInApp, sharePromptViaEmail, sharePromptCopyLink)
 * @returns encoded prompt URL
 */
export const buildEncodedPromptLinkUrl = ({
  prompt,
  serviceGeneratedLinkId,
  scenario,
  ocidPrefix,
}: {
  prompt: Prompt;
  serviceGeneratedLinkId: string;
  scenario: ScenarioType;
  ocidPrefix?: string;
}) => {
  const copilotLabHostname =
    window.location.host === stagingPromptGalleryHost
      ? stagingPromptGalleryHost
      : prodPromptGalleryHost;

  return `https://${copilotLabHostname}/prompts/link?product=${prompt.Products[0]}&linkText=${serviceGeneratedLinkId}&OCID=${getOcid(scenario, ocidPrefix ?? "")}`;
};

/**
 * Builds the Copilot Lab URL for the given Prompt object
 * @param prompt prompt to be shared
 * @param scenario scenario of the sharing (e.g. tryInApp, sharePromptViaEmail, sharePromptCopyLink)
 * @param ocidPrefix prefix for the OCID value (e.g. CopilotLab_L2_M365Chat)
 * @returns Copilot Lab URL
 */
export const buildCopilotLabUrl = ({
  prompt,
  scenario,
  ocidPrefix,
}: {
  prompt: Prompt;
  scenario: ScenarioType;
  ocidPrefix?: string;
}) => {
  const copilotLabHostname =
    window.location.host === stagingPromptGalleryHost
      ? stagingPromptGalleryHost
      : prodPromptGalleryHost;

  return `https://${copilotLabHostname}/prompts/${prompt.PromptId}?ocid=${getOcid(scenario, ocidPrefix ?? "")}`;
};

/**
 * Gets the OCID value for the given scenario and appearance
 * @param scenario scenario of the sharing
 * @param ocidPrefix source of the sharing
 * @returns OCID value
 */
export const getOcid = <P extends string>(
  scenario: ScenarioType,
  ocidPrefix: P
): OcidValues<P> => {
  let ocidPostfix: OcidValues<P> | undefined = undefined;

  if (
    scenario === "sharePromptCopyLink" ||
    scenario === "shareLink" ||
    scenario === "shareLinkViaRichCopy" ||
    scenario === "shareLinkViaPage" ||
    scenario === "shareLinkViaPopover" ||
    scenario === "shareLinkViaPopoverDialog" ||
    scenario === "shareLinkViaResponse" ||
    scenario === "shareLinkViaResponseDialog" ||
    scenario === "shareLinkViaPromptLibrary"
  ) {
    ocidPostfix = "SS_CopyLink";
  }

  if (
    scenario === "sharePromptViaEmail" ||
    scenario === "shareLinkViaEmail" ||
    scenario === "shareResponseViaEmail" ||
    scenario === "shareLinkViaEmailPopoverDialog" ||
    scenario === "shareLinkViaEmailResponseDialog" ||
    scenario === "shareLinkViaEmailPromptLibrary"
  ) {
    ocidPostfix = "SS_Email";
  }

  if (scenario === "notificationShare" || scenario === "notificationFetch") {
    ocidPostfix = "NN_Email";
  }

  if (ocidPrefix && ocidPostfix) {
    return `${ocidPrefix}_${ocidPostfix}`;
  }

  return ocidPostfix ?? scenario;
};

/**
 * Builds the URL to share the prompt
 * @param prompt prompt to be shared
 * @param scenario scenario of the sharing (e.g. tryInApp, sharePromptViaEmail, sharePromptCopyLink)
 * @param ocidPrefix prefix for the OCID value (e.g. CopilotLab_L2_M365Chat)
 * @returns URL to share the prompt
 */
export const buildSharePromptUrl = (
  prompt: Prompt,
  scenario: ScenarioType,
  ocidPrefix?: string,
  serviceGeneratedLinkId?: string,
  source = "CopilotLab",
  isBizChatTryInTeamsAppEnabled?: boolean
) => {
  if (isBizChatPrompt(prompt) || isCWCPrompt(prompt)) {
    return buildM365ChatUrl({
      prompt,
      scenario,
      serviceGeneratedLinkId,
      source,
      isBizChatTryInTeamsAppEnabled: isBizChatTryInTeamsAppEnabled,
    });
  } else if (serviceGeneratedLinkId && isWXPPrompt(prompt)) {
    return buildEncodedPromptLinkUrl({
      prompt,
      serviceGeneratedLinkId,
      scenario,
      ocidPrefix,
    });
  }

  return buildCopilotLabUrl({ prompt, scenario, ocidPrefix });
};

/**
 * Determines if this is Teams Desktop MetaOs hub
 */
export const isTeamsApp = () => {
  const params = new URL(document.location.toString()).searchParams;

  if (params !== undefined) {
    const hostName = params.get("hostName");
    if (
      hostName?.toLowerCase() === "teamsmodern" ||
      hostName?.toLowerCase() === "teams"
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
