// Copyright (C) Microsoft Corporation. All rights reserved.

import React from "react";
import * as telemetry from ".";

/**
 * React hook that returns an instance of the Telemetry Logger
 * @returns Telemetry Logger
 */
export default function useTelemetry() {
  const telemetryMemo = React.useMemo(() => {
    return telemetry;
  }, []);

  return telemetryMemo;
}
