import {
  declareString,
  declareStringWithPlaceholders,
} from "@1js/localization";
export const SharePromptPrettifiedBizChatLinkWithTitle =
  declareStringWithPlaceholders(
    "promptLibrary.sharePromptPrettifiedBizChatLinkWithTitle",
    {
      text: ({ promptTitle }) =>
        `Try this prompt in Copilot for Microsoft 365: ${promptTitle}`,
      comment:
        "Prettified link for sharing an M365 BizChat prompt in Copilot with title",
      placeholders: { promptTitle: "The Prompt Title" },
    }
  );

export const SharePromptPrettifiedBizChatLinkWithoutTitle = declareString(
  "promptLibrary.sharePromptPrettifiedBizChatLinkWithoutTitle",
  {
    text: "Check out this prompt I made in Copilot!",
    comment:
      "Prettified link for sharing an M365 BizChat prompt in Copilot without title",
  }
);

export const SharePromptPrettifiedLinkWithoutTitle = declareString(
  "promptLibrary.sharePromptPrettifiedLinkWithoutTitle",
  {
    text: "Check out this prompt in Copilot Prompt Gallery",
    comment: "Prettified link for sharing a prompt in Copilot without title",
  }
);

export const SharePromptPrettifiedLinkWithTitleV2 =
  declareStringWithPlaceholders(
    "promptLibrary.sharePromptPrettifiedLinkWithTitleV2",
    {
      text: ({ promptTitle }) =>
        `Check out this prompt in Copilot Prompt Gallery: ${promptTitle}`,
      comment: "Prettified link for sharing a prompt in Copilot with title",
      placeholders: { promptTitle: "The Prompt Title" },
    }
  );
