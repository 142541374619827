import { useStrings } from "@1js/localization-components";
import { makeStyles, shorthands } from "@fluentui/react-components";
import * as React from "react";
import { ErrorButton } from "../Error/ErrorButton";
import { ErrorDescription } from "../Error/ErrorDescription";
import { ErrorTitle } from "../Error/ErrorHeading";
import type { NotFoundSectionProps } from "./NotFound.web.data";
import {
  ExploreButton,
  NotFoundText,
  NotFoundTitle,
  PromptGalleryButton,
} from "./NotFound.web.string";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    ...shorthands.padding("48px", "0px", "40px", "0px"),
    ...shorthands.borderRadius("32px"),
    "@media (max-width: 540px)": {
      ...shorthands.padding("0px", "0px", "40px", "0px"),
      ...shorthands.borderRadius("0px"),
      ...shorthands.gap("16px"),
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.borderRadius("0px"),
    ...shorthands.gap("32px"),
  },
});

export const NotFoundSection: React.FC<NotFoundSectionProps> = ({
  onHandleButtonClick,
}) => {
  const styles = useStyles();
  const localizations = useStrings({
    NotFoundTitle,
    NotFoundText,
    ExploreButton,
    PromptGalleryButton,
  });

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.container}>
          <ErrorTitle titleText={localizations.NotFoundTitle()} />
          <ErrorDescription descriptionText={localizations.NotFoundText()} />
          <ErrorButton
            buttonText={`${localizations.ExploreButton()} ${localizations.PromptGalleryButton()}`}
            onHandleButtonClick={onHandleButtonClick}
          />
        </div>
      </div>
    </div>
  );
};
