// Copyright (C) Microsoft Corporation. All rights reserved.

import { PrivacyGuardPlugin } from "@microsoft/1ds-privacy-guard-js";
import {
  makeBooleanDataField,
  makeStringDataField,
  type TelemetryEvent,
} from "@microsoft/oteljs";
import { OneDSSink, type OneDSSinkProperties } from "@microsoft/oteljs-1ds";
import type { SinkParameters } from "../attachSink";
import { get1DSCoreFieldsFromMetaTags, readFromMetaTags } from "../config";
import { Events } from "../events";
import { getOrCreateUserSession } from "../userSession";
import { extractOCID, getPageReferrer, sanitizeUrl } from "../utils";

let oneDSSink: OneDSSink | undefined;

// Resets the 1DS sink, mainly used to reset after a user signs into MetaOs app so that the telemetry includes user info
export function reset1DSSink() {
  oneDSSink = undefined;
}

export default function get1DSSink(config: SinkParameters) {
  if (oneDSSink) {
    return oneDSSink;
  }

  const oneDSSinkProperties: OneDSSinkProperties = {
    endpointUrl: config.endpointUrl,
    plugins: [new PrivacyGuardPlugin() as any],
    extensionConfig: {
      PrivacyGuardPlugin: {
        enabled: true,
        scanForUrls: true,
      },
    },
    coreFields: get1DSCoreFieldsFromMetaTags(),
  };

  oneDSSink = new OneDSSink(
    [
      ...(config.partADataFields ?? []),
      makeStringDataField(
        "AppInfo.Language",
        readFromMetaTags("awa-market") || ""
      ),
      makeStringDataField("Page.Title", readFromMetaTags("awa-title") || ""),
      makeStringDataField(
        "Page.Url",
        sanitizeUrl(window.location.href, true) || ""
      ),
      makeStringDataField("Page.OCID", extractOCID(window.location.href) || ""),
      makeStringDataField(
        "Page.Referrer",
        sanitizeUrl(getPageReferrer(), true) || ""
      ),
      makeStringDataField("User.SessionId", getOrCreateUserSession().sessionId),
      makeStringDataField(
        "User.SubstrateRing",
        readFromMetaTags("awa-substrateRing") || ""
      ),
      makeBooleanDataField(
        "AppInfo.IsTeamsChannel",
        readFromMetaTags("awa-isTeamsChannel") !== "" &&
          readFromMetaTags("awa-isTeamsChannel") !== undefined
      ),
      makeStringDataField("Page.Asset", readFromMetaTags("awa-asst") || ""),
    ],
    oneDSSinkProperties
  );

  const originalSentTelemetryEvent = oneDSSink.sendTelemetryEvent;
  oneDSSink.sendTelemetryEvent = (telemetryEvent: TelemetryEvent) => {
    // These events should not be sent to the 1DS sink because we don't have a privacy approval for them.
    if (telemetryEvent.eventName.endsWith(Events[Events.ResponseReceived])) {
      return;
    }
    if (telemetryEvent.eventName.endsWith(Events[Events.ClientLayout])) {
      return;
    }
    originalSentTelemetryEvent(telemetryEvent);
  };

  // Required: Call begin() to ensure the sink is valid
  if (!oneDSSink.begin()) {
    console.error("Error initializing OneDSSink");
    return;
  }

  // Call shutdown() before unload to send remaining events
  window.addEventListener("beforeunload", (_ev) => {
    if (oneDSSink) {
      oneDSSink.shutdown();
    }
  });

  return oneDSSink;
}

// Function to reset oneDSSink variable, used to clear states between unit tests.
export function resetOneDSSink() {
  oneDSSink = undefined;
}
