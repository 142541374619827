import {
  Link,
  makeStyles,
  shorthands,
  Tab,
  Text,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import type { HeaderLinkProps } from "./PageHeader.web.data";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.borderRadius("4px"),
  },
  discoverTab: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "43px",
    ...shorthands.borderRadius("4px"),
  },
  link: {
    "& span": {
      color: tokens.colorNeutralForeground2,
      textAlign: "center",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      "&:hover": {
        color: tokens.colorNeutralForeground2BrandHover,
      },
    },
    "&:hover": {
      ...shorthands.textDecoration("none"),
      "&:focus, &:active": {
        ...shorthands.textDecoration("none"),
      },
    },
  },
  line: {
    backgroundColor: tokens.colorNeutralForeground2BrandHover,
    borderBottomLeftRadius: "2px",
    borderBottomRightRadius: "2px",
    borderTopLeftRadius: "2px",
    borderTopRightRadius: "2px",
    height: "3px",
    position: "absolute",
    bottom: "0",
    left: "10%",
    right: "10%",
  },
});

export const HeaderLink: React.FC<HeaderLinkProps> = ({
  value,
  displayText,
  url,
  openInNewWindow,
  activeTab,
  testid,
}) => {
  const styles = useStyles();

  return (
    <div
      className={value === "tab1" ? styles.discoverTab : styles.main}
      data-testid={testid}
      role="tab"
    >
      <Tab value={value} tabIndex={-1} role="tab" id={value}>
        <Link
          className={styles.link}
          href={url}
          target={openInNewWindow ? "_blank" : undefined}
          aria-label={displayText}
        >
          <Text>{displayText}</Text>
          {activeTab && <div className={styles.line} />}
        </Link>
      </Tab>
    </div>
  );
};
