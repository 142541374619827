import {
  isExtensionPrompt,
  isNonSharedUserPrompt,
  type Group,
} from "@1js/pl-types";
import { makeStyles, shorthands } from "@fluentui/react-components";
import * as React from "react";
import type { Appearance, Prompt } from "../data";
import { PluginPromptsToolTip } from "./PluginPromptsToolTip";
import { PromptDelete } from "./PromptDelete/PromptDelete";
import { PromptEdit } from "./PromptEdit";
import { PromptFavorite } from "./PromptFavorite/PromptFavorite";
import { PromptRemove } from "./PromptRemove/PromptRemove";
import { PromptShare } from "./PromptShare/PromptShare";

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
});

type PromptCardActionButtonsProps = {
  prompt: Prompt;
  appearance?: Appearance;
  isFavoriteEnabled?: boolean;
  isBookmarking?: boolean;
  isFavorited?: boolean;
  onFavorited?: () => void;
  isDeleting?: boolean;
  isRemoving?: boolean;
  isAgentPromptFavoriteEnabled?: boolean;
  isPromptSavingEnabled?: boolean;
  isPromptEditingEnabled?: boolean;
  isPromptSharingEnabled?: boolean;
  isPromptSharingViaEmailEnabled?: boolean;
  isPromptSharingToWorkgroupEnabled?: boolean;
  isPromptRemovingFromWorkgroupEnabled?: boolean;
  isPromptSharingOneClickUxEnabled?: boolean;
  isPromptLikingEnabled?: boolean;
  onShareViaCopyLinkClicked?: (prompt: Prompt) => void;
  onShareViaEmailClicked?: (prompt: Prompt) => void | Promise<void>;
  onShareToWorkgroupClicked?: (prompts: Prompt[]) => void | Promise<void>;
  onDelete?: () => void;
  onEditToggled?: () => void;
  onEditSaved?: () => void;
  onRemoveFromWorkgroupClicked?: (
    prompts: Prompt[],
    groupIds: string[]
  ) => void;
  isEditing?: boolean;
  selectedGroupId?: string;
  groups?: Group[];
  setShareButtonTarget?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  tabIndex?: number;
};

export const PromptCardActionButtons: React.FC<
  PromptCardActionButtonsProps
> = ({
  prompt,
  appearance,
  isFavoriteEnabled,
  isBookmarking,
  isFavorited,
  onFavorited,
  isDeleting,
  isRemoving,
  isAgentPromptFavoriteEnabled,
  isPromptSavingEnabled,
  isPromptEditingEnabled,
  isPromptSharingEnabled,
  isPromptSharingViaEmailEnabled,
  isPromptSharingToWorkgroupEnabled,
  isPromptRemovingFromWorkgroupEnabled,
  isPromptSharingOneClickUxEnabled,
  isPromptLikingEnabled,
  onShareViaCopyLinkClicked,
  onShareViaEmailClicked,
  onShareToWorkgroupClicked,
  onDelete,
  onEditToggled,
  onEditSaved,
  onRemoveFromWorkgroupClicked,
  isEditing,
  selectedGroupId,
  groups,
  setShareButtonTarget,
  tabIndex,
}) => {
  const styles = useStyles();
  const isDeleteEnabled =
    isNonSharedUserPrompt(prompt) && isPromptSavingEnabled;
  const isEditEnabled = isNonSharedUserPrompt(prompt) && isPromptEditingEnabled;
  const isExtension = isExtensionPrompt(prompt);
  const showPluginInfoIcon =
    prompt.Origin === "Plugin" && appearance === "m365";
  const isRockSteady = appearance === "in-app-v3" || appearance === "web-v3";

  const pluginTooltip = showPluginInfoIcon ? (
    <div className={styles.buttons}>
      <PluginPromptsToolTip prompt={prompt} />
    </div>
  ) : null;

  const promptShare =
    !isExtension && isPromptSharingEnabled ? (
      <PromptShare
        appearance={appearance}
        prompt={prompt}
        isLoading={isBookmarking || isDeleting || isEditing}
        isPromptSharingViaEmailEnabled={isPromptSharingViaEmailEnabled}
        isPromptSharingToWorkgroupEnabled={isPromptSharingToWorkgroupEnabled}
        isPromptSharingOneClickUxEnabled={isPromptSharingOneClickUxEnabled}
        tabIndex={tabIndex}
        onShareViaCopyLinkClicked={onShareViaCopyLinkClicked}
        onShareViaEmailClicked={onShareViaEmailClicked}
        onShareToWorkgroupClicked={onShareToWorkgroupClicked}
        groups={groups}
        setShareButtonTarget={setShareButtonTarget}
      />
    ) : null;

  const promptDelete = isDeleteEnabled ? (
    <PromptDelete
      appearance={appearance}
      onDelete={onDelete}
      isDeleting={isDeleting}
      tabIndex={tabIndex}
      isPromptLikingEnabled={isPromptLikingEnabled}
    />
  ) : null;

  const promptRemoveFromWorkgroup =
    !isExtension && isPromptRemovingFromWorkgroupEnabled ? (
      <PromptRemove
        appearance={appearance}
        onRemove={onRemoveFromWorkgroupClicked}
        prompt={prompt}
        groupId={selectedGroupId}
        isRemoving={isRemoving || false}
        tabIndex={tabIndex}
      />
    ) : null;

  const promptEdit = isEditEnabled ? (
    <PromptEdit
      appearance={appearance}
      onEditToggled={onEditToggled}
      onEditSaved={onEditSaved}
      isEditing={isEditing}
      tabIndex={tabIndex}
    />
  ) : null;

  const promptFavorite =
    isFavoriteEnabled &&
    !isDeleteEnabled &&
    !isEditing &&
    (!isExtension || (isExtension && isAgentPromptFavoriteEnabled)) ? (
      <PromptFavorite
        appearance={appearance}
        onFavorited={onFavorited}
        isFavorited={isFavorited}
        isLoading={isBookmarking}
        tabIndex={tabIndex}
        isPromptLikingEnabled={isPromptLikingEnabled}
      />
    ) : null;

  return (
    <>
      {pluginTooltip}
      {isRockSteady ? (
        <div className={styles.buttons}>
          {promptFavorite}
          {promptShare}
          {promptDelete}
          {promptRemoveFromWorkgroup}
          {promptEdit}
        </div>
      ) : (
        <div className={styles.buttons}>
          {promptShare}
          {promptDelete}
          {promptRemoveFromWorkgroup}
          {promptEdit}
          {promptFavorite}
        </div>
      )}
    </>
  );
};
