import type { Placeholders } from "@1js/localization";
import * as React from "react";
import type { StringLookupFunc } from "../StringProviderContext";
import {
  StringProviderContextReactContextProvider as ReactContextProviderForStringProvider,
  useStringProvider,
} from "../StringProviderContext/StringProviderContext";

export type LocalizationProps = React.PropsWithChildren<{
  lookupString: StringLookupFunc;
}>;

function makeStringLookUpFunctionWithParent(
  stringLookupByKeyAndLocale: StringLookupFunc,
  parentStringLookupByKeyAndLocale: StringLookupFunc
): (
  key: string
) => <K extends string>(placeholders?: Placeholders<K>) => string {
  return (key) => (placeholders) => {
    const foundString = stringLookupByKeyAndLocale(key)(placeholders);

    if (foundString !== key) {
      return foundString;
    }

    const stringFromParent =
      parentStringLookupByKeyAndLocale(key)(placeholders);

    if (stringFromParent !== key) {
      return stringFromParent;
    }

    return key;
  };
}

export function Localization({
  lookupString,
  children,
}: LocalizationProps): React.ReactElement<LocalizationProps> {
  const parentStringProviderContext = useStringProvider();

  // Take the parent provider and merge it with the current provider.
  // If the current string provider can not provide the string we'll go up the
  // tree to find the provider that can. Once we reach the last provider it'll just return the key.
  const stringProviderContext = React.useMemo(
    () => ({
      lookupString: makeStringLookUpFunctionWithParent(
        lookupString,
        parentStringProviderContext.lookupString
      ),
    }),
    [lookupString, parentStringProviderContext]
  );

  return (
    <ReactContextProviderForStringProvider value={stringProviderContext}>
      {children}
    </ReactContextProviderForStringProvider>
  );
}
