import {
  createFocusOutlineStyle,
  makeStyles,
  shorthands,
  tokens,
  type GriffelStyle,
} from "@fluentui/react-components";
import type { Appearance } from "../data";
import { highContrastHoverStyles } from "./sharedStyles";

type PromptCardStyles = {
  card: GriffelStyle;
  cardBody: GriffelStyle;
  shortCard: GriffelStyle;
};

type PromptCardInteractiveStyles = {
  card: GriffelStyle;
  cardBody: GriffelStyle;
};

const copilotLabInteractiveStyles: PromptCardInteractiveStyles = {
  card: {
    "&:hover": {
      boxShadow: tokens.shadow8,
      backgroundColor: tokens.colorNeutralForegroundInvertedHover,
      ...shorthands.border("1px", "solid", tokens.colorBrandForeground1),
    },

    "&:active": {
      backgroundColor: tokens.colorNeutralForegroundInvertedHover,
    },

    "@media screen and (-ms-high-contrast: active), (forced-colors: active)": {
      "&:active": {
        "-ms-high-contrast-adjust": "none",
        "forced-color-adjust": "none",
        backgroundColor: "highlight",
        color: "highlighttext",
      },

      "&:hover": {
        "-ms-high-contrast-adjust": "none",
        "forced-color-adjust": "none",
        backgroundColor: "highlight",
        color: "highlighttext",
      },
    },
  },
  cardBody: {
    cursor: "pointer",
  },
};

const copilotLabStyles: PromptCardStyles = {
  card: {
    height: "244px",
    width: "100%",
    "@media (max-width: 540px)": {
      height: "222px",
    },
    boxShadow: tokens.shadow4,
    ...shorthands.borderRadius("16px"),
    ...shorthands.padding("0px"),
    ...shorthands.border("1px", "solid", "transparent"),

    ...createFocusOutlineStyle({
      selector: "focus",
      style: {
        outlineRadius: "16px",
        outlineOffset: {
          top: "-2px",
          bottom: "-2px",
          left: "-2px",
          right: "-2px",
        },
        outlineWidth: "2px",
      },
    }),
  },

  cardBody: {
    flexGrow: "1",
    ...shorthands.padding("24px", "20px"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  shortCard: {
    height: "206px",
  },
};

const copilotLabGoBoldInteractiveStyles: PromptCardInteractiveStyles = {
  card: {
    "&:hover": {
      background: `linear-gradient(227deg, ${tokens.colorNeutralBackground1}, ${tokens.colorPaletteCornflowerBackground2} 312.13%)`,
      ...shorthands.border("1px", "solid", "#7385FF"),
    },

    "&:active": {
      background: `linear-gradient(227deg, ${tokens.colorNeutralBackground1}, ${tokens.colorPaletteCornflowerBackground2} 312.13%)`,
    },

    ...highContrastHoverStyles,
  },
  cardBody: {
    cursor: "pointer",
  },
};

const copilotLabGoBoldStyles: PromptCardStyles = {
  card: {
    height: "244px",
    width: "100%",
    "@media (max-width: 540px)": {
      height: "174px",
    },
    boxShadow: `${tokens.colorBackgroundOverlay} 0px 16px 26px -26px`,
    ...shorthands.borderRadius("12px"),
    ...shorthands.padding("0px"),
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    background: `linear-gradient(180deg, ${tokens.colorNeutralBackground1}, ${tokens.colorNeutralBackground1Hover})`,

    ...createFocusOutlineStyle({
      selector: "focus",
      style: {
        outlineRadius: "12px",
        outlineOffset: {
          top: "-2px",
          bottom: "-2px",
          left: "-2px",
          right: "-2px",
        },
        outlineWidth: "2px",
      },
    }),
  },

  cardBody: {
    flexGrow: "1",
    ...shorthands.padding("18px", "18px"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  shortCard: {
    height: "206px",
  },
};

const inAppInteractiveStyles: PromptCardInteractiveStyles = {
  card: {
    "&:hover": {
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },

    "&:active": {
      backgroundColor: tokens.colorNeutralBackground1Pressed,
    },

    ...highContrastHoverStyles,
  },
  cardBody: {
    cursor: "pointer",
  },
};

const inAppStyles: PromptCardStyles = {
  card: {
    height: "160px",
    width: "100%",
    ...shorthands.padding("0px"),
    ...createFocusOutlineStyle({
      selector: "focus",
      style: {
        outlineRadius: "6px",
        outlineOffset: {
          top: "-2px",
          bottom: "-2px",
          left: "-2px",
          right: "-2px",
        },
        outlineWidth: "2px",
      },
    }),
  },

  cardBody: {
    flexGrow: "1",
    ...shorthands.padding("16px"),
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  shortCard: {
    height: "122px",
  },
};

const m365InteractiveStyles: PromptCardInteractiveStyles = {
  card: {
    "&:hover": {
      boxShadow: tokens.shadow8,
      backgroundColor: tokens.colorNeutralForegroundInvertedHover,
      ...shorthands.border("1px", "solid", tokens.colorBrandForeground1),
    },

    "&:active": {
      backgroundColor: tokens.colorNeutralForegroundInvertedHover,
    },

    ...highContrastHoverStyles,
  },
  cardBody: {
    cursor: "pointer",
  },
};

const m365Styles: PromptCardStyles = {
  card: {
    height: "216px",
    width: "100%",
    boxShadow: tokens.shadow4,
    ...shorthands.padding("0px"),
    ...shorthands.borderRadius("16px"),
    ...shorthands.border("1px", "solid", "transparent"),

    ...createFocusOutlineStyle({
      selector: "focus",
      style: {
        outlineRadius: "16px",
        outlineOffset: {
          top: "-2px",
          bottom: "-2px",
          left: "-2px",
          right: "-2px",
        },
        outlineWidth: "2px",
      },
    }),
  },

  cardBody: {
    flexGrow: "1",
    ...shorthands.padding("16px", "20px"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  shortCard: {
    height: "170px",
  },
};

const singleColumnStyles: PromptCardStyles = {
  card: {
    height: "160px",
    width: "100%",
    backgroundColor: tokens.colorNeutralBackground1,
    oxShadow: tokens.shadow2,
    ...shorthands.padding("16px", "20px"),
    ...shorthands.borderRadius("16px"),

    ...createFocusOutlineStyle({
      selector: "focus",
      style: {
        outlineRadius: "16px",
        outlineOffset: {
          top: "-2px",
          bottom: "-2px",
          left: "-2px",
          right: "-2px",
        },
        outlineWidth: "2px",
      },
    }),

    "@media (max-width: 480px)": {
      height: "auto",
    },
  },

  cardBody: {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    ...shorthands.gap("8px"),
  },

  shortCard: {
    height: "140px",
  },
};

const singleColumnInteractiveStyles: PromptCardInteractiveStyles = {
  card: {
    "&:hover": {
      backgroundColor: tokens.colorNeutralBackground1Hover,
      ...shorthands.border("1px", "solid", tokens.colorBrandForeground1),
    },

    "&:active": {
      backgroundColor: tokens.colorNeutralBackground1Pressed,
    },
    ...highContrastHoverStyles,
  },
  cardBody: {
    cursor: "pointer",
  },
};

const rockSteadyInteractiveStyles: PromptCardInteractiveStyles = {
  card: {
    "&:hover, &:active": {
      ...shorthands.border("1px", "solid", tokens.colorBrandForeground1),
      boxShadow: tokens.shadow16,
    },

    ...highContrastHoverStyles,
  },
  cardBody: {
    cursor: "pointer",
  },
};

const rockSteadyStyles: PromptCardStyles = {
  card: {
    height: "220px",
    width: "100%",
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
    padding: "0",
    borderRadius: "24px",
    boxShadow: "none",
    ...createFocusOutlineStyle({
      selector: "focus",
      style: {
        outlineRadius: "24px",
        outlineOffset: {
          top: "-2px",
          bottom: "-2px",
          left: "-2px",
          right: "-2px",
        },
        outlineWidth: "2px",
      },
    }),
  },

  cardBody: {
    flexGrow: "1",
    padding: "24px",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  shortCard: {},
};

const createInAppStyles = makeStyles(inAppStyles);
const createCopilotLabStyles = makeStyles(copilotLabStyles);
const createCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const createM365Styles = makeStyles(m365Styles);
const createSingleColumnStyle = makeStyles(singleColumnStyles);

const createInAppInteractiveStyles = makeStyles(inAppInteractiveStyles);
const createCopilotLabInteractiveStyles = makeStyles(
  copilotLabInteractiveStyles
);
const createCopilotLabGoBoldInteractiveStyles = makeStyles(
  copilotLabGoBoldInteractiveStyles
);
const createM365InteractiveStyles = makeStyles(m365InteractiveStyles);
const createSingleColumnInteractiveStyle = makeStyles(
  singleColumnInteractiveStyles
);
const createRockSteadyStyles = makeStyles(rockSteadyStyles);
const createRockSteadyInteractiveStyles = makeStyles(
  rockSteadyInteractiveStyles
);

export const useInteractiveStyles = (
  appearance?: Appearance
): Record<keyof PromptCardInteractiveStyles, string> => {
  switch (appearance) {
    case "in-app":
      return createInAppInteractiveStyles();
    case "web":
      return createCopilotLabInteractiveStyles();
    case "web-v2":
    case "in-app-v2":
      return createCopilotLabGoBoldInteractiveStyles();
    case "in-app-v3":
    case "web-v3":
      return createRockSteadyInteractiveStyles();
    case "m365":
    case "bing-web":
    case "win-copilot":
      return createM365InteractiveStyles();
    case "bing-mobile":
    case "underside":
    case "single-col":
      return createSingleColumnInteractiveStyle();
    default:
      return createInAppInteractiveStyles();
  }
};

export const useStyles = (
  appearance?: Appearance
): Record<keyof PromptCardStyles, string> => {
  switch (appearance) {
    case "in-app":
      return createInAppStyles();
    case "web":
      return createCopilotLabStyles();
    case "web-v2":
    case "in-app-v2":
      return createCopilotLabGoBoldStyles();
    case "in-app-v3":
    case "web-v3":
      return createRockSteadyStyles();
    case "m365":
    case "bing-web":
    case "win-copilot":
      return createM365Styles();
    case "bing-mobile":
    case "underside":
    case "single-col":
      return createSingleColumnStyle();
    default:
      return createInAppStyles();
  }
};

/**
 * Automatically hides the footer button and shows them when the card is hovered or focused.
 */
export const useAutoHideButtonsStyles = makeStyles({
  card: {
    // hide all buttons by default
    "& button": {
      opacity: "0",
    },
    // show buttons that are already focused by keyboard navigation, pressed or busy (aria-busy)
    "& button:focus-visible, & button[aria-pressed='true'], & button[aria-busy='true'], & button[aria-expanded='true']":
      {
        opacity: "100%",
      },
    ":has(button:focus-visible) button": {
      opacity: "100%",
    },
    // show buttons when the card is hovered or focused by keyboard navigation
    // and hide any element with the hideOnCardHover class
    "&:hover, &:focus-visible, &:active": {
      "& button": {
        opacity: "100%",
      },
      "& div.hideOnCardHover": {
        opacity: "0",
      },
    },

    "& button.popover-open": {
      opacity: "100%",
    },
  },
});
