import {
  makeStyles,
  shorthands,
  tokens,
  type GriffelStyle,
} from "@fluentui/react-components";
import type { Appearance } from "../data";

type Styles = {
  edit: GriffelStyle;
  actionButton: GriffelStyle;
  icon: GriffelStyle;
};

const defaultStyles: Styles = {
  edit: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "right",
    height: "24px",
    ...shorthands.gap("4px"),
  },

  actionButton: {
    ...shorthands.border("0px"),
  },

  icon: {
    color: tokens.colorBrandForeground1,
  },
};

const copilotLabStyles: Styles = {
  ...defaultStyles,
};

const copilotLabGoBoldStyles: Styles = {
  ...defaultStyles,

  edit: {
    ...defaultStyles.edit,
    height: "20px",
  },

  actionButton: {
    ...defaultStyles.actionButton,
  },

  icon: {
    ...defaultStyles.icon,
    height: "16px",
    width: "16px",
  },
};

const rockSteadyStyles: Styles = {
  ...defaultStyles,
  icon: {
    ...defaultStyles.icon,
    fontSize: "20px",
  },

  edit: {
    ...defaultStyles.edit,
    height: "32px",
  },
};

const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const useRockSteadyStyles = makeStyles(rockSteadyStyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "web-v2":
    case "in-app-v2":
      return useCopilotLabGoBoldStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    default:
      return useCopilotLabStyles;
  }
};
