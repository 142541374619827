import { useStringsWithPlaceholders } from "@1js/localization-components";
import { CardFooter, makeStyles } from "@fluentui/react-components";
import * as React from "react";
import { type Badge } from "../../PromptBadges/PromptBadges.types";
import { TryInLabel } from "./GoBoldPromptCardFooter.strings";
import { GoBoldPromptCardFooterTryInApp } from "./GoBoldPromptCardFooterTryInApp";

export type GoBoldPromptCardFooterProps = {
  badges: Badge[];
  isBadgeEnabled?: boolean;
  handoffProduct?: string;
};

const useStyles = makeStyles({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2px",
    alignItems: "flex-end",
    alignSelf: "stretch",
  },
});

export const GoBoldPromptCardFooter: React.FC<GoBoldPromptCardFooterProps> = ({
  badges,
  isBadgeEnabled,
  handoffProduct,
}) => {
  const styles = useStyles();
  const stringsWithPlaceholders = useStringsWithPlaceholders({
    TryInLabel,
  });
  const tryInAppBadge =
    badges.length > 0
      ? badges.find((badge) => badge.id === handoffProduct) || badges[0]
      : undefined;

  return (
    <CardFooter className={styles.footer}>
      {isBadgeEnabled && tryInAppBadge && (
        <GoBoldPromptCardFooterTryInApp
          badge={tryInAppBadge}
          labelWrapper={stringsWithPlaceholders.TryInLabel}
          showTryInAppLabel={tryInAppBadge.id === handoffProduct}
        />
      )}
    </CardFooter>
  );
};
