import { declareStringWithPlaceholders } from "@1js/localization";

export const TryInLabel = declareStringWithPlaceholders(
  "promptLibrary.TryInLabel",
  {
    text: ({ productName }) => `Try in ${productName}`,
    placeholders: {
      productName:
        "the name of the product or service, like Business Chat, Word, or Excel",
    },
    comment:
      "Label for the prompt stating what product it is for. Full text could be for example 'Try in Business Chat'",
  }
);
