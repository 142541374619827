import {
  initializeInAppFeedback,
  type FeedbackInitData,
} from "@ms/inapp-feedback-loader";

/*
 * Initializes OCV feedback widget
 */
export async function initializeFeedback(
  centroHostname: string,
  language: string
) {
  let feedbackInfo: FeedbackInitData;
  const feedbackRef = document.getElementById("feedbackRef");

  if (feedbackRef && centroHostname && language) {
    feedbackInfo = {
      hostName: centroHostname,
      locale: language,
      inAppFeedbackRef: feedbackRef,
    };
    await initializeInAppFeedback(feedbackInfo);
  }
}
