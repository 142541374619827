import type { HydratedEntity } from "@1js/pl-types";
import { type SendApiActionEventParameter } from "../utils/Telemetry";
import type { ApiName, HttpStatusCode } from "../utils/Telemetry/config";

/**
 * Logs an API error by converting it to boolean
 * @param apiName Api to log error for
 * @param error original error that gets parsed and converted
 * @param logicalId Logical Id of the request
 * @param onSendApiAction Callback method from telemetry hook to send telemetry
 */
export function logApiError(
  apiName: ApiName,
  error: Error,
  logicalId: string,
  supportServiceLatency: number,
  onSendApiAction: (params: SendApiActionEventParameter) => void
) {
  onSendApiAction({
    apiName,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    status: (error as any)?.response?.status as HttpStatusCode,
    isInit: false,
    isFromCache: false,
    supportServiceLatency,
    traceId:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      (error as any)?.response?.headers?.get("x-datasource-traceid") ||
      logicalId,
  });
}

/**
 * Extracts HydratedEntities from the input string
 * @param input - The input string
 * @returns A list of HydratedEntities
 */
export const extractHydratedEntities = (
  input: string | null | undefined
): HydratedEntity[] | undefined => {
  if (!input) return undefined;

  const regex =
    /<entity type="(File|People|Event)" ReferenceId="([^"]+)">([^<]+)<\/entity>/g;
  const entities = [] as HydratedEntity[];
  let match;

  while ((match = regex.exec(input)) !== null) {
    const type = match[1];
    const referenceId = match[2];
    const displayName = match[3];

    if (type === "File") {
      entities.push({
        Type: type,
        Entity: {
          Id: referenceId,
          ReferenceId: referenceId,
          FileName: displayName,
          SpoId: referenceId,
        },
      });
    } else if (type === "People") {
      entities.push({
        Type: type,
        Entity: {
          Id: referenceId,
          ReferenceId: referenceId,
          EmailAddresses: [referenceId],
          DisplayName: displayName,
        },
      });
    } else if (type === "Event") {
      entities.push({
        Type: type,
        Entity: {
          Id: "",
          ReferenceId: referenceId,
          Subject: displayName,
        },
      });
    }
  }

  return entities.length > 0 ? entities : undefined;
};
