// Copyright (C) Microsoft Corporation. All rights reserved.

import type { Prompt } from "@1js/pl-card-components";
import type { App } from "@microsoft/oteljs";
import {
  AudienceGroup,
  Platform,
  type Release,
  type Session,
  type User,
} from "@microsoft/oteljs";
import type { CoreFields } from "@microsoft/oteljs-1ds";
import { isMacUserAgent, isMetaOsHelper } from "../url";

/** Telemetry App Name */
export const APP_NAME = "Copilot Lab";

/** Aria ingestion key */
export const ARIA_INGESTION_KEY =
  "08ef9c3b37c94441a974354b2f79821c-46f6063b-b699-4fab-b864-e332eb39da22-7402";

/** Returns true if the app is running in production, false otherwise */
export const isProduction = () =>
  readFromMetaTags("awa-env")?.toLowerCase() === "production";

/** Returns true if the app is running in staging, false otherwise */
export const isStaging = () =>
  readFromMetaTags("awa-env")?.toLowerCase() === "staging";

export const isGcc = () =>
  readFromMetaTags("awa-env")?.toLowerCase() === "gcc" ||
  readFromMetaTags("awa-env")?.toLowerCase() === "gccdev";

/**
 * Defines the users data boundary settings
 */
export enum DataBoundary {
  WW = "WW",
  EU = "EU",
}

/**
 * Defines the action input for a given telemetry event
 */
export enum ActionInputMethods {
  None = "None", // No Input Method.
  Mouse = "Mouse",
  Keyboard = "Keyboard",
  All = "All", // Available via all known electronic input methods.
}

/**
 * Defines the action target for a give telemetry event
 */
export enum ActionTarget {
  None = "None", // No Intended Target.
  ScrollArea = "ScrollArea",
  Button = "Button",
  Toggle = "Toggle",
  TextField = "TextField",
  DropDown = "DropDown",
}

/**
 * Defines the ype of action represent for the feature
 * https://eng.ms/docs/experiences-devices/data-platform-growth/ideas/ideas-wiki/data-platform/ideas-onboarding/feature-onboarding
 */
export enum FeatureActionType {
  IsOffered = "IsOffered", // A user is targeted for a specific feature
  IsTried = "IsTried", // The user has tried the feature
}

/**
 * Defines the type of feature for the telemetry event
 */
export const Contexts = [
  "WorkgroupPublish",
  "WorkgroupDelete",
  "WorkgroupView",
  "SavedPrompts",
  "AllPrompts",
  "LikedPrompts",
  "AgentPrompts",
  "BrowsePrompts",
  "SearchPrompts",
  "WorkplacePrompts",
] as const;
export type Context = (typeof Contexts)[number];

/**
 * Defines the step of the feature for the telemetry event.
 * E.g., a button gets clicked (Action) inside the PromptAction-part of the modal dialog (Step) of the WorkgroupPublish-feature (Context).
 */
export const Steps = ["PromptAction", "Dialog", "Card"] as const;
export type Step = (typeof Steps)[number];

export type FilterStep = "GroupPromptsFilter" | "Tab" | "Filter";
export type FilterAction =
  | "Open"
  | "Close"
  | "ValueApply"
  | "Clear"
  | "OpenTab"
  | "OpenTabPageInit";

export type UINotificationAction = "Open" | "LinkClicked"; // "Open" represents the dialog is opened. "LinkClicked" represents a link in the dialog is clicked.
export type UINotificationName = "View Settings"; // "View Settings" represents the managed access setting dialog.

export type SettingAction = "StopSharing" | "Save" | "Open" | "Cancel";
export type SettingName = "SharedLinksSetting";

/**
 * Defines the action of the feature (Context) at the Step for the telemetry event.
 */
export const Actions = [
  "RemoveButton",
  "CancelButton",
  "PublishToWorkgroupButton",
  "SharePromptsErrorButton",
  "ExploreOtherPromptsErrorButton",
  "ViewGroupLink",
  "OpenCard",
  "OpenDropdown",
  "CloseDropdown",
  "SelectGroup",
  "StopSharingButton",
] as const;
export type Action = (typeof Actions)[number];

/**
 * Defines the API name for the telemetry event.
 */
export type ApiName =
  | "GetWorkgroupsMember"
  | "GetWorkgroups"
  | "GetPublish"
  | "PostPublish"
  | "DeletePublish"
  | "GetAllPrompts"
  | "GetGptPrompts"
  | "GetInstalledExtensions"
  | "GetSaved"
  | "DeleteSaved"
  | "GetBookmarkedPrompts"
  | "GetFavoritesAdd"
  | "GetFavoritesRemove"
  | "GetLinkedPrompts"
  | "DeleteLinkedPrompts"
  | "UnsharePost"
  | "CreatePromptLink"
  | "SendSharePromptEmailNotification"
  | "GetUserEligibilityForCWC"
  | "GetGroupMemberCount";

/**
 * Defines the Http Status Code for the telemetry event.
 */
export type HttpStatusCode =
  | 100
  | 101
  | 102
  | 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 226
  | 300
  | 301
  | 302
  | 303
  | 304
  | 305
  | 307
  | 308
  | 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 407
  | 408
  | 409
  | 410
  | 411
  | 412
  | 413
  | 414
  | 415
  | 416
  | 417
  | 418
  | 421
  | 422
  | 423
  | 424
  | 425
  | 426
  | 427
  | 428
  | 429
  | 431
  | 451
  | 500
  | 501
  | 502
  | 503
  | 504
  | 505
  | 506
  | 507
  | 508
  | 510
  | 511;

/**
 * Defines the API error reason for the telemetry event.
 */
export type ApiErrorReason =
  | "Timeout"
  | "GroupsNotSpecified"
  | "NoGroupWithSpecifiedID"
  | undefined;

/**
 * Session ID used as Part A fields
 * https://eng.ms/docs/experiences-devices/opg/office-fuel/dig/unified-telemetry-documentation/unified-telemetry/sdks/js/sinks/onedssink
 */
let sessionId: string | undefined = undefined;

/**
 * With MetaOs we have other values that need to go into the Platform and Versions
 * Example querystring from metaos -> ?hostName=TeamsModern&hostClientType=desktop&pageId=index0&subPageId=
 * https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/hostclienttype?view=msteams-client-js-latest
 */
const getAppProperties = (): App.StrictContract => {
  let app: { platform: string; name: string; version: string };

  if (isMetaOsHelper()) {
    const params = new URLSearchParams(window.location.search);

    let hostClientType = params.get("hostClientType") ?? "Web";

    // This is an override due to a bugs in Teams SDK
    if (isMacUserAgent()) {
      hostClientType = "macos";
    }

    switch (hostClientType.toLowerCase()) {
      case Platform.Desktop.toLowerCase():
        hostClientType = Platform.Desktop;
        break;
      case Platform.Android.toLowerCase():
        hostClientType = Platform.Android;
        break;
      case Platform.Win32.toLowerCase():
        hostClientType = Platform.Win32;
        break;
      case Platform.iOS.toLowerCase():
        hostClientType = Platform.iOS;
        break;
      case Platform.Universal.toLowerCase():
        hostClientType = Platform.Universal;
        break;
      case "macos":
        hostClientType = Platform.Mac;
        break;
      default:
        hostClientType = Platform.Web;
        break;
    }
    app = {
      platform: hostClientType, //TODO change this for MetaOs
      name: APP_NAME,
      version: params.get("hostName") ?? "Unknown",
    };
  } else {
    app = {
      platform: "Web",
      name: APP_NAME,
      version: readFromMetaTags("awa-stv") || "",
    };
  }

  return app;
};

/**
 * Returns the Core Fields required to initialize a 1DS sink
 * The fields are read from the current page's meta tags in <head>
 * https://eng.ms/docs/experiences-devices/opg/office-fuel/dig/unified-telemetry-documentation/unified-telemetry/eventschema/unifiedeventschemacontracts
 * @returns 1DS Core Fields
 */
export function get1DSCoreFieldsFromMetaTags(): CoreFields {
  let user: User.StrictContract = {
    isSignedIn: false,
  };

  const authType = readFromMetaTags("awa-authtype")?.toLowerCase() || "";
  if (authType) {
    if (authType === "orgid") {
      user = {
        primaryIdentityHash: readFromMetaTags("awa-objectid"),
        tenantId: readFromMetaTags("awa-tenantid"),
        primaryIdentitySpace: "UserObjectId",
        isSignedIn: true,
      } as User.StrictContractKnownUser;
    } else if (authType === "msa") {
      user = {
        primaryIdentityHash: readFromMetaTags("awa-cid")
          ?.replace("m:", "")
          ?.toUpperCase(),
        primaryIdentitySpace: "MSACID",
        isSignedIn: true,
      } as User.StrictContractKnownUser;
    }
  }

  const app = getAppProperties();

  const session: Session.Contract = {
    id: getSessionId(),
  };
  const release: Release.Contract = {
    audienceGroup:
      isProduction() || isGcc()
        ? AudienceGroup.Production
        : AudienceGroup.Microsoft,
  };

  return {
    user,
    app,
    session,
    release,
  };
}

/**
 * Reads the current page' meta tags
 * @param metaTagName Meta tag name
 * @returns Meta tag content
 */
export const readFromMetaTags = (metaTagName: string): string | undefined => {
  return document
    .querySelector(`meta[name="${metaTagName}"]`)
    ?.getAttribute("content")
    ?.trim();
};

/**
 * Gets or creates a random session ID
 * @returns Random session ID
 */
export function getSessionId(): string {
  if (!sessionId) {
    sessionId = window.crypto.randomUUID();
  }
  return sessionId;
}

/**
 * Returns the user's data boundary settings based on the page settings
 * @returns DataBoundary enum
 */
export function getDataBoundary(): DataBoundary {
  if (
    readFromMetaTags("awa-dataBoundary")?.toLowerCase() ===
    DataBoundary.EU.toLowerCase()
  ) {
    return DataBoundary.EU;
  }

  return DataBoundary.WW;
}

/**
 * Returns the telemetry data field
 * @param key Object key to convert to data field
 * @returns data field name
 */
export function getDataFieldName<T extends Prompt, K extends keyof T & string>(
  key:
    | K
    | "Featured"
    | "IsFavorite"
    | "ProductTried"
    | "Result"
    | "DeliveryOrigin"
    | "JobType"
    | "Link"
    | "Extension"
    | "Message"
    | "LogType"
    | "LogCategory"
    | "QueryId"
    | "UserLicenses"
): string {
  return `Prompt.${key}`;
}
