import { Text, makeStyles, tokens } from "@fluentui/react-components";
import * as React from "react";
import type { ErrorDescriptionProps } from "./Error.web.data";

const useStyles = makeStyles({
  main: {
    textAlign: "center",
  },
  button: {
    color: tokens.colorNeutralForeground1,
    textAlign: "center",
    fontSize: "28px",
    maxWidth: "619px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    "@media (max-width: 540px)": {
      fontSize: "16px",
      maxWidth: "296px",
    },
  },
});

export const ErrorDescription: React.FC<ErrorDescriptionProps> = ({
  descriptionText,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <Text className={styles.button}>{descriptionText}</Text>
    </div>
  );
};
