import type { GriffelStyle } from "@fluentui/react-components";

export const highContrastHighlightStyles: GriffelStyle = {
  "@media screen and (-ms-high-contrast: active), (forced-colors: active)": {
    "-ms-high-contrast-adjust": "none",
    "forced-color-adjust": "none",
    backgroundColor: "highlight",
    color: "highlighttext",
  },
};

export const highContrastWindowStyles: GriffelStyle = {
  "@media screen and (-ms-high-contrast: active), (forced-colors: active)": {
    "-ms-high-contrast-adjust": "none",
    "forced-color-adjust": "none",
    backgroundColor: "window",
    color: "windowtext",
  },
};

export const highContrastHoverStyles: GriffelStyle = {
  "@media screen and (-ms-high-contrast: active), (forced-colors: active)": {
    "&:active": {
      "-ms-high-contrast-adjust": "none",
      "forced-color-adjust": "none",
      backgroundColor: "highlight",
      color: "highlighttext",
    },
    "&:hover": {
      "-ms-high-contrast-adjust": "none",
      "forced-color-adjust": "none",
      backgroundColor: "highlight",
      color: "black",
    },
  },
};
