import React, {
  createContext,
  createRef,
  type Ref,
  type RefObject,
} from "react";

export type DefaultButtonContext = {
  focusButton: CallableFunction;
  registerButtonReference: CallableFunction;
  getRef: CallableFunction;
};

export const ButtonRefContext = createContext<DefaultButtonContext>({
  focusButton: (): void => {
    return;
  },
  registerButtonReference: () => undefined,
  getRef: () => undefined,
});

type ButtonRefProviderProps = {
  children: React.ReactNode;
};
type RegisteredButtonRefContainerType = {
  [key: string]: Ref<HTMLButtonElement | null>;
};

const registeredButtonRefs = {} as RegisteredButtonRefContainerType;

export const ButtonRefProvider = ({ children }: ButtonRefProviderProps) => {
  const isRefObject = React.useCallback(
    (
      ref: Ref<HTMLButtonElement | null>
    ): ref is RefObject<HTMLButtonElement | null> => {
      return ref !== null && typeof ref === "object" && "current" in ref;
    },
    []
  );

  const registerButtonReference = React.useCallback((key: string) => {
    const ref = createRef<HTMLButtonElement>();
    registeredButtonRefs[key] = ref;
    return ref;
  }, []);

  const getRef = React.useCallback(
    (key: string): Ref<HTMLButtonElement | null> | null => {
      return registeredButtonRefs[key];
    },
    []
  );

  const focusButton = React.useCallback(
    (key: string) => {
      const buttonRef = registeredButtonRefs[key];
      if (buttonRef && isRefObject(buttonRef) && buttonRef.current) {
        buttonRef.current.focus();
      }
    },
    [isRefObject]
  );

  return (
    <ButtonRefContext.Provider
      value={{ focusButton, registerButtonReference, getRef }}
    >
      {children}
    </ButtonRefContext.Provider>
  );
};
