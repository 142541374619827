import {
  makeStyles,
  tokens,
  type GriffelStyle,
} from "@fluentui/react-components";
import type { Appearance } from "../../data";

type Styles = {
  icon: GriffelStyle;
};

const defaultStyles: Styles = {
  icon: {
    display: "block",
    fontSize: "20px",
    color: tokens.colorBrandForeground1,
  },
};

const copilotLabStyles: Styles = {
  ...defaultStyles,
  icon: {
    ...defaultStyles.icon,
    paddingTop: "2px", // 2 pixel because icon size is 20px, but title line height is 22px. odd.
  },
};

const inAppStyles: Styles = {
  ...defaultStyles,
  icon: {
    ...defaultStyles.icon,
    paddingTop: "2px", // 2 pixel because icon size is 20px, but title line height is 22px. odd.
    color: tokens.colorBrandForeground1,
  },
};

const m365Styles: Styles = {
  ...defaultStyles,
};

const singleColumnStyles: Styles = {
  ...defaultStyles,
};

const rockSteadyStyles: Styles = {
  ...defaultStyles,
  icon: {
    ...defaultStyles.icon,
    fontSize: "24px",
  },
};

const useInAppStyles = makeStyles(inAppStyles);
const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useM365Styles = makeStyles(m365Styles);
const useSingleColumnStyle = makeStyles(singleColumnStyles);
const useRockSteadyStyles = makeStyles(rockSteadyStyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "in-app":
      return useInAppStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    case "web":
      return useCopilotLabStyles;
    case "m365":
    case "bing-web":
    case "win-copilot":
      return useM365Styles;
    case "bing-mobile":
    case "underside":
    case "single-col":
      return useSingleColumnStyle;
    default:
      return useInAppStyles;
  }
};
