import {
  useStringForPlaceholders,
  useStrings,
} from "@1js/localization-components";
import type { Prompt } from "@1js/pl-types";
import { isBizChatPrompt, isUserPrompt } from "@1js/pl-types";
import React from "react";
import { useProducts } from "../../hooks/useProducts";
import { buildCopilotLabUrl } from "../../services/urlBuilder";
import { sanitizePromptCommand } from "../PromptCard/PromptCommand/PromptCommand";
import { BusinessChatLabel } from "../sharedStrings";
import {
  PromptShareEmailBodyCheckItOut,
  PromptShareEmailBodyCopilotFor,
  PromptShareEmailBodyGiveItATry,
  PromptShareEmailBodyIntro,
  PromptShareEmailMicrosoftCopilot,
  PromptShareEmailSharedBySubtitle,
  PromptShareEmailTryWithCopilotLabel,
  PromptShareEmailViewInCopilotPromptGalleryLabel,
} from "./PromptCardEmailTemplate.strings";

const styles = `
    a{text-decoration:none;color:#463feb}
    table{border: none}
    #email-container{width:99%;padding:40px 0;background-color:#f7f8ff;border-radius:16px}
    .message{font-family:"Segoe UI",system-ui,-apple-system;font-size:13px;font-style:normal;font-weight:400;line-height:20px}
    .intro{font-family:"Segoe UI",system-ui,-apple-system;font-size:13px;font-style:normal;font-weight:400;line-height:20px}
    a#link{text-decoration:none;}
    #link{font-family:"Segoe UI",system-ui,-apple-system;font-size:15px;line-height:20px;font-weight:600;display:block;}
    .prompt-cards-with{font-family:"Segoe UI",system-ui,-apple-system;font-size:13px;font-style:normal;font-weight:400;line-height:20px;width:302px;border-radius:16px;background-color:#fff;box-sizing:border-box}
    .prompt-cards-with .regular-icon{width:19px;height:20px}
    .prompt-cards-with .title{flex:0;font-family:"Segoe UI",system-ui,-apple-system;color:#424242;font-size:16px;font-style:normal;font-weight:600;line-height:22px}
    .prompt-cards-with .body{align-self:stretch;font-family:"Segoe UI",system-ui,-apple-system;font-size:15px;font-style:normal;font-weight:400;line-height:22px;color:#242424;margin:0}
    .prompt-cards-with .footer{background-color:transparent;margin-top:15px}
    .prompt-cards-with .avatar{width:19px;height:20px;background-color:#e6e6e6}
    .prompt-cards-with .avatar img{width:19px;height:20px}
    .prompt-cards-with .img{width:15px;height:16px}
    .prompt-cards-with .placeholder{color:#707069}
    .prompt-cards-with .tag{vertical-align:middle;text-align:center;background:#f4f5f5;border-radius:4px;padding-left:6px;padding-right:6px;padding-top:2px;padding-bottom:2px}
    #microsoft-copilot-footer{vertical-align:middle;color:#242423;font-family:"Segoe UI",system-ui,-apple-system;font-size:14px;font-style:normal;font-weight:600;line-height:22px;text-align:center}
    #microsoft-copilot-logo-footer{vertical-align:middle}
`;

export type PromptCardEmailTemplateProps = {
  prompt: Prompt;
  sharedByDisplayName: string;
  promptUrl: string;
  ocidPrefix?: string;
};

const Table: React.FC<
  React.TableHTMLAttributes<HTMLTableElement> & {
    align?: "left" | "center" | "right";
  }
> = ({ children, style, className, align, cellPadding, cellSpacing }) => {
  return (
    <table
      align={align}
      role="presentation"
      contentEditable="false"
      cellPadding={cellPadding ?? "0"}
      cellSpacing={cellSpacing ?? "0"}
      border={0}
      style={style}
      className={className}
    >
      {children}
    </table>
  );
};

const Header: React.FC<PromptCardEmailTemplateProps> = ({
  sharedByDisplayName,
}) => {
  const strings = useStrings({
    PromptShareEmailSharedBySubtitle,
  });
  return (
    <Table
      style={{
        maxWidth: "302px",
        marginRight: "auto",
        border: "0",
      }}
      cellPadding={"4px"}
    >
      <tr>
        <td
          style={{
            borderRadius: "4px",
            width: "40px",
            height: "40px",
            background: "#e6e6e6",
            textAlign: "center",
            fontFamily: "Segoe UI,system-ui,-apple-system",
            wordBreak: "break-word",
            overflow: "hidden",
          }}
        >
          {displayNameToInitials(sharedByDisplayName)}
        </td>

        <td>
          <Table
            style={{
              wordBreak: "break-word",
              border: "0",
            }}
          >
            <tr>
              <td style={{ paddingLeft: 0 }}>
                <div
                  style={{
                    fontFamily: "Segoe UI,system-ui,-apple-system",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "28px",
                  }}
                >
                  {sharedByDisplayName}
                </div>

                <tr>
                  <td style={{ paddingLeft: 0 }}>
                    <div
                      style={{
                        fontFamily: "Segoe UI,system-ui,-apple-system",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {strings.PromptShareEmailSharedBySubtitle()}
                    </div>
                  </td>
                </tr>
              </td>
            </tr>
          </Table>
        </td>
      </tr>
    </Table>
  );
};

const Footer: React.FC = () => {
  const strings = useStrings({
    PromptShareEmailMicrosoftCopilot,
  });

  return (
    <Table
      style={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <tr>
        <td id="microsoft-copilot-logo-footer">
          <img
            alt={strings.PromptShareEmailMicrosoftCopilot()}
            height="20px"
            src="https://res.df.onecdn.static.microsoft/owamail/hashed-v1/resources/images/M365Chat-hash-89d6c819.m.svg"
            width="20px"
          />
        </td>
        <td id="microsoft-copilot-footer">
          {strings.PromptShareEmailMicrosoftCopilot()}
        </td>
      </tr>
    </Table>
  );
};

type LinkProps = {
  appearance: "primary" | "secondary";
  href: string;
  children: React.ReactNode;
};
const Link: React.FC<LinkProps> = ({
  appearance = "primary",
  href,
  children,
}) => {
  return (
    <a
      href={href}
      id={`link`}
      style={{
        color: appearance === "primary" ? "#fffffe" : "#242424",
      }}
    >
      <Table align="center" style={{ width: "100%" }}>
        <tr style={{ width: "100%" }}>
          <table
            width={"100%"}
            style={{
              objectFit: "contain",
              width: "100%",
              wordBreak: "break-word",
              borderCollapse: "separate",
              backgroundColor: appearance === "primary" ? "#463feb" : "#ffffff",
              borderRadius: "8px",
              border: "1px solid #ccc",
            }}
          >
            <tr>
              <td
                style={{
                  color: appearance === "primary" ? "#fffffe" : "#242424",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <div style={{ padding: "8px" }}>{children}</div>
              </td>
            </tr>
          </table>
        </tr>
      </Table>
    </a>
  );
};

const TryPromptLink: React.FC<PromptCardEmailTemplateProps> = ({
  promptUrl,
}) => {
  const strings = useStrings({
    PromptShareEmailTryWithCopilotLabel,
  });
  return (
    <Link appearance={"primary"} href={promptUrl}>
      {strings.PromptShareEmailTryWithCopilotLabel()}
    </Link>
  );
};

const ViewInCopilotLabLink: React.FC<
  PromptCardEmailTemplateProps & Pick<LinkProps, "appearance">
> = ({ prompt, ocidPrefix, appearance }) => {
  const strings = useStrings({
    PromptShareEmailViewInCopilotPromptGalleryLabel,
  });

  const promptUrl = buildCopilotLabUrl({
    prompt,
    scenario: "shareLinkViaEmail",
    ocidPrefix,
  });

  return (
    <Link appearance={appearance} href={promptUrl}>
      {strings.PromptShareEmailViewInCopilotPromptGalleryLabel()}
    </Link>
  );
};

export const displayNameToInitials = (displayName: string): string => {
  if (!displayName) {
    return "";
  }

  const displayNameParts = displayName.split(" ");

  if (displayNameParts.length > 1) {
    return displayNameParts[0][0] + displayNameParts[1][0];
  }

  return displayNameParts[0][0];
};

const PromptAuthorProfilePhoto: React.FC<PromptCardEmailTemplateProps> = ({
  prompt,
  sharedByDisplayName,
}) => {
  // TODO: we assume that the prompt author is the user who shared the prompt.
  // This is not always the case when prompt sharing by other users is supported.
  return prompt.Origin === "User" ? (
    <>{displayNameToInitials(sharedByDisplayName)}</>
  ) : (
    // TODO: This icon will be updated to load the resources from the 1JS CDN
    <img
      alt={""}
      style={{ width: "20px", height: "20px", display: "block" }}
      src="https://copilot.cloud.microsoft/favicon-32x32.png"
    />
  );
};

const PromptBody: React.FC<PromptCardEmailTemplateProps> = ({ prompt }) => {
  return (
    <>
      {sanitizePromptCommand(
        prompt.DisplayText,
        (children, entity) => {
          if (entity && entity.Entity.AccessUrl) {
            return (
              <span className="tag">
                <a data-testid="tag-link" href={entity.Entity.AccessUrl}>
                  {children}
                </a>
              </span>
            );
          }

          return <span className="tag">{children}</span>;
        },
        (children) => {
          return (
            <span data-testid="placeholder" className="placeholder">
              {children}
            </span>
          );
        },
        prompt.HydratedEntities
      )}
    </>
  );
};

const IntroString: React.FC<PromptCardEmailTemplateProps> = ({ prompt }) => {
  const PromptShareEmailBodyIntroString = useStringForPlaceholders(
    PromptShareEmailBodyIntro
  );
  const PromptShareEmailBodyCopilotForString = useStringForPlaceholders(
    PromptShareEmailBodyCopilotFor
  );
  const strings = useStrings({
    BusinessChatLabel,
    PromptShareEmailBodyGiveItATry,
    PromptShareEmailBodyCheckItOut,
  });
  const { badgeToLabel } = useProducts();

  return (
    <p className="intro">
      {PromptShareEmailBodyIntroString({
        productName: isBizChatPrompt(prompt)
          ? strings.BusinessChatLabel()
          : PromptShareEmailBodyCopilotForString({
              productName: badgeToLabel(prompt.Products[0]),
            }),
      })}{" "}
      {isUserPrompt(prompt)
        ? strings.PromptShareEmailBodyCheckItOut()
        : strings.PromptShareEmailBodyGiveItATry()}
    </p>
  );
};

export const PromptProducts: React.FC<PromptCardEmailTemplateProps> = ({
  prompt,
}) => {
  const { badgeToLabel } = useProducts();

  if (prompt.Products.length === 0) {
    return <></>;
  }

  return (
    <>
      <td className="tag">
        <div className="tag" data-testid="products">
          {badgeToLabel(prompt.Products[0])}
        </div>
      </td>
      {prompt.Products.length > 1 && (
        <td className="tag">
          <div className="tag" data-testid="products-more">
            +{prompt.Products.length - 1}
          </div>
        </td>
      )}
    </>
  );
};

export const PromptCardEmailTemplate: React.FC<PromptCardEmailTemplateProps> = (
  props
) => {
  return (
    <html contentEditable="false" lang="en">
      <meta content="text/html; charset=us-ascii" httpEquiv="Content-Type" />
      <style>{styles}</style>

      <body contentEditable="false">
        <div
          style={{
            paddingBottom: "12px",
            fontFamily: "Calibri",
            fontSize: "14px",
          }}
        >
          <IntroString {...props} />
        </div>

        <div id="email-container">
          <Table align="center">
            <tbody contentEditable="false">
              <tr>
                <td>
                  <Table style={{ maxWidth: "302px" }} align="center">
                    <tr>
                      <td contentEditable="false">
                        <Table style={{ width: "100%" }}>
                          <tr>
                            <td style={{ verticalAlign: "top" }}>
                              <Header {...props} />
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                paddingTop: "12px",
                                verticalAlign: "bottom",
                              }}
                            >
                              <table
                                style={{
                                  objectFit: "contain",
                                  width: "100%",
                                  wordBreak: "break-word",
                                  borderCollapse: "separate",
                                }}
                              >
                                <tr>
                                  <td
                                    style={{
                                      background: "#fff",
                                      borderRadius: "16px",
                                      boxShadow:
                                        "0 4px 8px 0 rgba(0,0,0,.14),0 0 2px 0 rgba(0,0,0,.12)",
                                      border: "1px solid #d1d1d1",
                                      padding: "24px 20px",
                                    }}
                                  >
                                    <Table
                                      style={{
                                        width: "100%",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      <tr>
                                        <td style={{ verticalAlign: "top" }}>
                                          <Table
                                            style={{
                                              objectFit: "contain",
                                              wordBreak: "break-word",
                                            }}
                                            className="prompt-cards-with"
                                          >
                                            <tbody contentEditable="false">
                                              <tr>
                                                <td className="title">
                                                  {props.prompt.Title}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="body">
                                                  <PromptBody {...props} />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    paddingTop: "20px",
                                                  }}
                                                >
                                                  <Table cellSpacing={"4px"}>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          verticalAlign:
                                                            "middle",
                                                          marginLeft: "0",
                                                        }}
                                                        className="tag"
                                                      >
                                                        <PromptAuthorProfilePhoto
                                                          {...props}
                                                        />
                                                      </td>

                                                      <PromptProducts
                                                        {...props}
                                                      />
                                                    </tr>
                                                  </Table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </td>
                                      </tr>
                                    </Table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ paddingTop: "20px" }}>
                              {isBizChatPrompt(props.prompt) ? (
                                <TryPromptLink {...props} />
                              ) : (
                                <ViewInCopilotLabLink
                                  {...props}
                                  appearance={"primary"}
                                />
                              )}
                            </td>
                          </tr>

                          {!isUserPrompt(props.prompt) &&
                            isBizChatPrompt(props.prompt) && (
                              <tr>
                                <td style={{ paddingTop: "8px" }}>
                                  <ViewInCopilotLabLink
                                    {...props}
                                    appearance={"secondary"}
                                  />
                                </td>
                              </tr>
                            )}

                          <tr>
                            <td
                              style={{
                                paddingTop: "40px",
                                verticalAlign: "middle",
                              }}
                            >
                              <Footer />
                            </td>
                          </tr>
                        </Table>
                      </td>
                    </tr>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </body>
    </html>
  );
};
