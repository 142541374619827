import { createSharedReactContext } from "@1js/create-shared-react-context";
import type {
  StringLookupFunc,
  StringProviderContext,
} from "@1js/midgard-types";
import * as React from "react";

export type { StringLookupFunc, StringProviderContext };

const defaultStringProviderContext: StringProviderContext = {
  lookupString: (key: string) => () => key,
};

export const stringProviderContextName = "LocalizationComponentsStringProvider";
export const StringProviderContextReactContext =
  createSharedReactContext<StringProviderContext>(
    defaultStringProviderContext,
    stringProviderContextName
  );

export const StringProviderContextReactContextProvider =
  StringProviderContextReactContext.Provider;

export function useStringProvider(): StringProviderContext {
  return React.useContext(StringProviderContextReactContext);
}
