// Copyright (C) Microsoft Corporation. All rights reserved.

import { sendClickEvent, sendPageScrollEvent } from ".";
import {
  debounce,
  getClickableElementDetails,
  getPageHeight,
  getScrollOffset,
  getViewportDimensions,
} from "./utils";

export function captureClickEvents() {
  const clickableElements = ["input", "a", "area", "button"];

  window.document.addEventListener("click", (e) => {
    const path = e.composedPath() as HTMLElement[];
    if (path && path.length > 0) {
      // loop all composedPath element, this is necessary because node of shadow DOM is not the correct clicked tag
      for (const element of path) {
        if (!element.tagName) continue;

        if (clickableElements.includes(element.tagName.toLocaleLowerCase())) {
          sendClickEvent({
            clickCoordinateX: e.pageX,
            clickCoordinateY: e.pageY,
            pageHeight: getPageHeight(),
            ...getScrollOffset(),
            ...getViewportDimensions(),
            ...getClickableElementDetails(element),
          });
          break;
        }
      }
    } else {
      // use target element if composedPath is not supported by browser, notice this does not work on shadow DOM
      let element = e.target as HTMLElement;
      while (element && element.tagName) {
        if (clickableElements.includes(element.tagName.toLowerCase())) {
          sendClickEvent({
            clickCoordinateX: e.pageX,
            clickCoordinateY: e.pageY,
            pageHeight: getPageHeight(),
            ...getScrollOffset(),
            ...getViewportDimensions(),
            ...getClickableElementDetails(element),
          });
          break;
        }

        element = element.parentElement || (element.parentNode as HTMLElement);
      }
    }
  });
}

export function captureScrollEvents(debounceTimeout = 500) {
  window.document.addEventListener(
    "scroll",
    debounce(
      () =>
        sendPageScrollEvent({
          pageHeight: getPageHeight(),
          ...getScrollOffset(),
          ...getViewportDimensions(),
        }),
      debounceTimeout
    )
  );
}
