import { keys, memoizeFunction } from "@1js/functional";
import { getDefaultStringProvider } from "./defaultStringProvider";
import { doPseudoLocalization, isDevLocalization } from "./environmentUtils";
import { getStringDescriptionWithPlaceholders } from "./getStringDescription";
import { pseudoLocalize } from "./pseudoLocalize";
import type { StringProvider } from "./stringMap";
import {
  handleLocalizationError,
  lookUpString,
  stringExists,
} from "./stringMap";

export interface StringDeclarationWithoutPlaceholders {
  readonly text: string;
  readonly comment: string;
}

export type Placeholders<K extends string> = { readonly [name in K]: string };

export interface StringDeclarationWithPlaceholders<K extends string> {
  readonly text: (placeholders: Placeholders<K>) => string;
  readonly comment: string;
  readonly placeholders: Placeholders<K>;
}

export type StringFunction = ((stringProvider?: StringProvider) => string) & {
  readonly key: string;
};

export type StringFunctionWithPlaceholders<K extends string> = ((
  placeholders: Placeholders<K>,
  stringProvider?: StringProvider
) => string) & { readonly key: string };

const anyPlaceholderPattern = /\\{\\d+\\}/g;

const placeholderPattern = memoizeFunction(
  (index: number) => new RegExp(`\\{${index}\\}`, "g")
);

export function resolveString(
  key: string,
  declaration: StringDeclarationWithoutPlaceholders | undefined,
  stringProvider: StringProvider
): string {
  try {
    if (isDevLocalization() && declaration) {
      /* In dev mode, use the string from the bundle and possibly pseudo-localize it. */
      return doPseudoLocalization()
        ? escapedPseudoLocalize(declaration.text)
        : declaration.text;
    } else {
      /* Prefer to use the string from the string map, but if it's missing, and we
       have a string in the bundle, we can fall back to that instead. */
      if (!stringExists(key, stringProvider) && declaration) {
        return declaration.text;
      } else {
        return lookUpString(key, stringProvider);
      }
    }
  } catch (error: any) {
    handleLocalizationError(error, stringProvider);
    return "";
  }
}

const guardStringFunctionWithPlaceholders =
  <K extends string>(
    f: (placeholders: Placeholders<K>) => string,
    stringProvider: StringProvider
  ) =>
  (placeholders: Placeholders<K>) => {
    try {
      return f(placeholders);
    } catch (error: any) {
      handleLocalizationError(error, stringProvider);
      return "";
    }
  };

export function resolveStringWithPlaceholders<K extends string>(
  key: string,
  declaration: StringDeclarationWithPlaceholders<K> | undefined,
  stringProvider: StringProvider,
  options?: { alreadyPseudoLocalized?: boolean }
): (placeholders: Placeholders<K>) => string {
  try {
    if (
      isDevLocalization() &&
      declaration &&
      declaration.text &&
      !options?.alreadyPseudoLocalized
    ) {
      return guardStringFunctionWithPlaceholders(
        (placeholders) =>
          doPseudoLocalization()
            ? pseudoLocalizeWithPlaceholders(declaration, placeholders)
            : declaration.text(placeholders),
        stringProvider
      );
    } else {
      if (
        !stringExists(key, stringProvider) &&
        declaration &&
        declaration.text
      ) {
        return guardStringFunctionWithPlaceholders(
          declaration.text,
          stringProvider
        );
      } else {
        return guardStringFunctionWithPlaceholders(
          (placeholders) =>
            keys(placeholders)
              .sort()
              .reduce(
                (formattedString: string, name: K, index: number) => {
                  /* Remove placeholder-like substrings from the values we are inserting. */
                  const placeholder = placeholders[name];
                  const placeholderValue = (
                    (typeof placeholder === "string" && placeholder) ||
                    (placeholder &&
                      placeholder.toString &&
                      placeholder.toString()) ||
                    ""
                  ).replace(anyPlaceholderPattern, "");
                  return (formattedString || "").replace(
                    placeholderPattern(index),
                    placeholderValue
                  );
                },
                lookUpString(key, stringProvider)
              ),
          stringProvider
        );
      }
    }
  } catch (error: any) {
    handleLocalizationError(error, stringProvider);
    return () => "";
  }
}

export type StringLookupFunc = <K extends string>(
  key: string
) => (placeholders?: Placeholders<K>) => string;

export function lookupStringInStringProvider(
  stringProvider: StringProvider
): StringLookupFunc {
  return (key) => (placeholders) =>
    placeholders
      ? resolveStringWithPlaceholders(
          key,
          undefined,
          stringProvider
        )(placeholders)
      : resolveString(key, undefined, stringProvider);
}

/**
 * Declares a string that should be localized.
 *
 * @param key The resource key used to identify the string.
 * @param declaration An object of this format:
 *                    { text: "Hello, world!", comment: "A greeting" }
 * @returns A function that returns the translated string.
 */
export function declareString(
  key: string,
  declaration: StringDeclarationWithoutPlaceholders
): StringFunction;
// During bundle localization, we strip out declarations. So make sure our
// internal behavior is compatible with this behavior, even though our public
// interface requires consumers to pass declarations.
export function declareString(
  key: string,
  declaration: StringDeclarationWithoutPlaceholders | undefined
): StringFunction {
  /* Delay the resolution of the string until it's needed, so there's time to
     add a string map in the meantime. Also add a toString() method on the
     function. */
  const stringResolver = (
    stringProvider: StringProvider = getDefaultStringProvider()
  ) => resolveString(key, declaration, stringProvider);

  stringResolver.toString = stringResolver;
  stringResolver.key = key;
  return stringResolver;
}

/**
 * Declares a string that should be localized, with placeholders in the string.
 *
 * @param key The resource key used to identify the string.
 * @param declaration An object on this format:
 *     {
 *       text: (placeholders) => "Hello, " + placeholders.person,
 *       comment: "A greeting",
 *       placeholders: { person: "The name of the person to greet" }
 *     }
 * @returns A function that returns the translated string, given values for the
 * placeholders.
 */
export function declareStringWithPlaceholders<K extends string>(
  key: string,
  declaration: StringDeclarationWithPlaceholders<K>
): StringFunctionWithPlaceholders<K>;
// During bundle localization, we strip out declarations. So make sure our
// internal behavior is compatible with this behavior, even though our public
// interface requires consumers to pass declarations.
export function declareStringWithPlaceholders<K extends string>(
  key: string,
  declaration: StringDeclarationWithPlaceholders<K> | undefined
): StringFunctionWithPlaceholders<K> {
  /* Delay the resolution of the string until it's needed, so there's time to
     add string maps in the meantime. Add a toString() method that gives the
     string with {0}, {1} as values for the placeholders. */
  const stringResolver = (
    placeholders: Placeholders<K>,
    stringProvider: StringProvider = getDefaultStringProvider()
  ) => {
    return resolveStringWithPlaceholders(
      key,
      declaration,
      stringProvider
    )(placeholders);
  };

  stringResolver.toString = (
    stringProvider: StringProvider = getDefaultStringProvider()
  ) => {
    if (isDevLocalization() && declaration) {
      return getStringDescriptionWithPlaceholders(key, declaration)
        .stringToBeLocalized;
    } else if (!stringExists(key, stringProvider) && declaration) {
      return getStringDescriptionWithPlaceholders(key, declaration)
        .stringToBeLocalized;
    } else {
      return lookUpString(key, stringProvider);
    }
  };

  stringResolver.key = key;
  return stringResolver;
}

// Placeholder that won't get pseudo-localized
const PSEUDO_LOC_PLACEHOLDER = "0.7059175321136157";

/**
 * Handle "extra" template formats not supported natively by localization. E.g. there are some alternative template variants in use that
 * present as normal strings, but actually have markup that shouldn't be pseudo-localized.
 */
function escapedPseudoLocalize(s: string): string {
  // Map of placeholder keys to the new safe placeholder strings
  const remapPlaceholders: { [key: string]: string } = {};
  // Map of the new placeholder strings to the original placeholder values
  const newPlaceholders: { [key in string]: string } = {};

  (s.match(/({.+?})/g) || []).forEach((placeholder, i) => {
    const newKey = `${PSEUDO_LOC_PLACEHOLDER}_${i}`;
    remapPlaceholders[placeholder] = newKey;
    newPlaceholders[newKey] = placeholder;
  });

  const escaped = Object.keys(remapPlaceholders).reduce(
    (template, placeholder) =>
      template.replace(placeholder, remapPlaceholders[placeholder]),
    escapeInf(s)
  );
  const localized = pseudoLocalize(escaped);
  return Object.keys(newPlaceholders).reduce(
    (template, placeholder) =>
      template.replace(placeholder, newPlaceholders[placeholder]),
    unescapeInf(localized)
  );
}

// INF tag used in the TDBuild localization scheme in open ended ranges. Since
// this includes characters that would normally be pseudo-localized, we need to
// escape it before pseudo-localizing.
const infTag = "->INF::";
const infTagPlaceholder = `${PSEUDO_LOC_PLACEHOLDER}_-1`;

/**
 * Replace INF pluralization tag usage in a string with a unique placeholder
 */
function escapeInf(s: string): string {
  return s.replace(new RegExp(infTag, "g"), infTagPlaceholder);
}

/**
 * Replace escaped INF pluralization tag placeholder in a pseudo-localized
 * string with the original.
 */
function unescapeInf(s: string): string {
  return s.replace(new RegExp(infTagPlaceholder, "g"), infTag);
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

/**
 * Extra magic for pseudo localizing strings with placeholders, without pseudo
 * localizing the template arguments.
 *
 * Instead of generating "ħḗḗŀŀǿǿ, Şŧḗḗƒƒḗḗƞ" when processing {name: "Steffen"}
 * => "hello, ${name}", we first format the template string with a set of
 * placeholders that won't be localized and then replace those with the input
 * values.
 *
 * E.g. {name: "Steffen"} => "hello, ${name}" -> "hello, 0.123_1" -> "ħḗḗŀŀǿǿ,
 * 0.123_1" -> "ħḗḗŀŀǿǿ, Steffen"
 */
function pseudoLocalizeWithPlaceholders<K extends string>(
  declaration: StringDeclarationWithPlaceholders<K>,
  placeholders: Placeholders<K>
): string {
  // Map of placeholder keys to the new safe placeholder strings
  const remapPlaceholders: { [key in K]: string } = {} as any;

  // Map of the new placeholder strings to the original placeholder values
  const newPlaceholders: { [key in string]: string } = {};
  keys(placeholders).forEach((key, i) => {
    const newKey = `${PSEUDO_LOC_PLACEHOLDER}_${i}`;
    remapPlaceholders[key] = newKey;
    newPlaceholders[newKey] = placeholders[key];
  });

  const pseudoLocalized = pseudoLocalize(
    escapeInf(declaration.text(remapPlaceholders))
  );
  return keys(newPlaceholders).reduce(
    (template, placeholder) =>
      template.replace(
        new RegExp(escapeRegExp(placeholder), "g"),
        newPlaceholders[placeholder]
      ),
    unescapeInf(pseudoLocalized)
  );
}

/**
 * Extra magic for pseudo localizing pluralized strings with placeholders,
 * without pseudo localizing the template arguments.
 *
 * Instead of generating "ħḗḗŀŀǿǿ, Şŧḗḗƒƒḗḗƞ" when processing {name: "Steffen"}
 * => {one: "hello, ${name}"}, we first format the template string with a set of
 * placeholders that won't be localized and then replace those with the input
 * values.
 *
 * E.g. {name: "Steffen"} => {one: "hello, ${name}}" -> {one: "hello, 0.123_1"}
 * -> {one: "ħḗḗŀŀǿǿ, 0.123_1"} -> {one: "ħḗḗŀŀǿǿ, Steffen"}
 */
export function pseudoLocalizePluralWithPlaceholders<K extends string>(
  textFunc: (placeholders: Placeholders<K>) => { [key in string]: string },
  placeholders: Placeholders<K>
): { [key in string]: string } {
  // Map of placeholder keys to the new safe placeholder strings
  const remapPlaceholders: { [key in K]: string } = {} as any;

  // Map of the new placeholder strings to the original placeholder values
  const newPlaceholders: { [key in string]: string } = {};
  keys(placeholders).forEach((key, i) => {
    const newKey = `${PSEUDO_LOC_PLACEHOLDER}_${i}`;
    remapPlaceholders[key] = newKey;
    newPlaceholders[newKey] = placeholders[key];
  });

  const strings = textFunc(remapPlaceholders);

  keys(strings).forEach((key) => {
    const pseudoLocalized = pseudoLocalize(escapeInf(strings[key]));
    strings[key] = keys(newPlaceholders).reduce(
      (template, placeholder) =>
        template.replace(
          new RegExp(escapeRegExp(placeholder), "g"),
          newPlaceholders[placeholder]
        ),
      unescapeInf(pseudoLocalized)
    );
  });

  return strings;
}
