import {
  lookupStringInStringProvider,
  type StringProvider,
} from "@1js/localization";
import { Localization } from "@1js/localization-components";
import { FluentProvider, makeStyles } from "@fluentui/react-components";
import * as React from "react";
import {
  CustomDarkTheme,
  CustomHighContrastTheme,
  CustomLightTheme,
} from "../components/Styles";

import { initializeFeedback } from "../feedback/initializer.web";
import { openFeedback } from "../feedback/openFeedback.web";

// Unfortunately we need this global variable because Suite Shell is not rendered
// in the React app, therefore we need this global variable to open the feedback pane
// from the Suite Shell header (in _PromptsLayout.cshtml).
window.openFeedback = openFeedback;
window.initializeFeedback = initializeFeedback;

const rtlLocales = ["ar", "he", "fa", "ur"]; // Add more RTL locales as needed type dir = "ltr" | "rtl" | undefined;

const getTextDirection = (locale: string) => {
  const lang = locale.split("-")[0]; // Get the language part of the locale
  return rtlLocales.includes(lang) ? "rtl" : "ltr";
};

const defaultErrorHandler = (error: string | Error) => console.error(error);

// Empty provider with all the required fields.
export const emptyProvider: StringProvider = {
  currentLocale: undefined,
  strings: {},
  errorHandler: defaultErrorHandler,
  loaders: [],
  stringMapInitialized: false,
};

export type PageWrapperProps = {
  locale: string;
  children: React.ReactNode;
  provider: StringProvider;
  theme?: string;
};

const useStyles = makeStyles({
  provider: {
    backgroundColor: "transparent",
  },
});

export function PageWrapper(props: PageWrapperProps): JSX.Element {
  const { locale, children } = props;
  const styles = useStyles();
  const stringLookup = lookupStringInStringProvider(props.provider);

  let theme = CustomLightTheme;
  if (props.theme) {
    if (props.theme?.toLowerCase() === "dark") {
      theme = CustomDarkTheme;
    } else if (props.theme?.toLowerCase() === "contrast") {
      theme = CustomHighContrastTheme;
    }
  }

  return (
    <FluentProvider
      className={styles.provider}
      theme={theme}
      dir={getTextDirection(locale)}
    >
      <Localization lookupString={stringLookup}>{children}</Localization>
    </FluentProvider>
  );
}
