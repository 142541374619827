import { Event3sType } from "@1js/scc-types-logger";
import { type LoggerContextProps } from "../context/LoggerContext";
import { getIsoTimeStringWithMs } from "../utils/getIsoTimeStringWithMs";
import { type LoggerProps } from "./type";

export const promptLibraryLogger = ({
  logger,
  logicalId,
  scenarioName,
  metaData: hostMetaData,
}: LoggerContextProps) => {
  const sendResultsRenderedEvent = (props?: Pick<LoggerProps, "metaData">) => {
    const metaData = {
      ...props?.metaData,
      ...hostMetaData?.(Event3sType.ResultsRendered),
    };
    logger.log3sEvent?.({
      localTime: getIsoTimeStringWithMs(new Date()),
      eventType: Event3sType.ResultsRendered,
      logicalId: logicalId,
      scenario: scenarioName,
      latency: "0",
      metaData:
        metaData && Object.keys(metaData).length > 0
          ? JSON.stringify(metaData)
          : undefined,
    });
  };

  return {
    sendResultsRenderedEvent,
  };
};
