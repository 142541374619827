import { useStrings } from "@1js/localization-components";
import {
  InteractionRequiredAuthError,
  type AuthenticationResult,
  type IPublicClientApplication,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Input,
  Label,
  Title2,
  makeStyles,
  shorthands,
  type InputOnChangeData,
} from "@fluentui/react-components";
import React from "react";
import { PropertyContext } from "../../pages/PropertyContext.web";
import {
  DebugOverlayAuthenticationHeader,
  DebugOverlayHeader,
  DebugOverlayRetrieveTokenButtonLabel,
  DebugOverlayScopesLabel,
  DebugOverlaySignInRedirectUrlLabel,
  DebugOverlaySignInStatusLabel,
  DebugOverlaySignedInUsernameClientLabel,
  DebugOverlaySignedInUsernameServerLabel,
  DebugOverlayToggleButtonLabel,
} from "./DebugPanel.web.string";

const useStyles = makeStyles({
  element: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
});

export const AuthenticationPanel = () => {
  const [siteSetting] = React.useContext(PropertyContext);
  const styles = useStyles();

  const localization = useStrings({
    DebugOverlayToggleButtonLabel,
    DebugOverlayHeader,
    DebugOverlayAuthenticationHeader,
    DebugOverlaySignedInUsernameServerLabel,
    DebugOverlaySignedInUsernameClientLabel,
    DebugOverlaySignInStatusLabel,
    DebugOverlaySignInRedirectUrlLabel,
    DebugOverlayRetrieveTokenButtonLabel,
    DebugOverlayScopesLabel,
  });
  const { instance: msalInstance, accounts, inProgress } = useMsal();

  const authenticationServerUsername = siteSetting.user?.email;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const authenticationClientUsername = accounts?.[0]?.username;
  const [scopes, setScopes] = React.useState("user.read");
  const onScopesChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
      setScopes(data.value),
    []
  );
  const retrieveToken = () => {
    const code = siteSetting.authentication?.authCode;
    const loginHint = siteSetting.user?.email;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return (
      (msalInstance as IPublicClientApplication)
        .acquireTokenByCode({
          code,
          scopes: scopes.split(","),
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
        .then((_: AuthenticationResult) => {})
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          if (error instanceof InteractionRequiredAuthError) {
            // Use loginHint/sid from server to ensure same user
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return msalInstance.loginRedirect({
              loginHint,
              scopes: scopes.split(","),
            });
          }
        })
    );
  };

  return (
    <>
      <Title2>{localization.DebugOverlayAuthenticationHeader()}</Title2>
      <br />
      <div className={styles.element}>
        <Label htmlFor="DebugOverlayServerSignIn">
          {localization.DebugOverlaySignedInUsernameServerLabel()}
        </Label>
        <Input
          disabled
          id="DebugOverlayServerSignedIn"
          defaultValue={authenticationServerUsername}
        />
      </div>
      <div className={styles.element}>
        <Label htmlFor="DebugOverlayClientSignIn">
          {localization.DebugOverlaySignedInUsernameClientLabel()}
        </Label>
        <Input
          disabled
          id="DebugOverlayClientSignedIn"
          defaultValue={authenticationClientUsername}
        />
      </div>
      <div className={styles.element}>
        <Label htmlFor="DebugOverlaySignInStauts">
          {localization.DebugOverlaySignInStatusLabel()}
        </Label>
        <Input
          disabled
          id="DebugOverlaySignInStauts"
          defaultValue={inProgress}
        />
      </div>
      <div className={styles.element}>
        <Label htmlFor="DebugOverlaySignInRedirectUrl">
          {localization.DebugOverlaySignInRedirectUrlLabel()}
        </Label>
        <Input
          disabled
          id="DebugOverlaySignInRedirectUrl"
          defaultValue={siteSetting.authentication?.redirectUrl}
        />
      </div>
      <div className={styles.element}>
        <Label htmlFor="DebugOverlayScopes">
          {localization.DebugOverlayScopesLabel()}
        </Label>
        <Input
          id="DebugOverlayScopes"
          value={scopes}
          onChange={onScopesChange}
        />
      </div>
      <div className={styles.element}>
        <Button onClick={retrieveToken}>
          {localization.DebugOverlayRetrieveTokenButtonLabel()}
        </Button>
      </div>
    </>
  );
};
AuthenticationPanel.defaultName = "AuthenticationPanel";
