import { addStringMap, type StringProvider } from "@1js/localization";
import * as React from "react";
import * as ReactDOM from "react-dom";
import type { BasePageProps } from "../../types";
import {
  enrichFlights,
  getClientSettings,
  getCopilotPortalSettings,
  getPromptsClientSettings,
  parseDataModel,
  postProcessHeaderLinks,
} from "../../utils/utils.web";
import { emptyProvider, PageWrapper } from "../PageWrapper.web";
import {
  NotFoundPageContainer,
  type NotFoundPageContainerProps,
} from "./NotFoundContainer.web";

const root = document.getElementById("root");
const dataModel = parseDataModel<NotFoundPageContainerProps & BasePageProps>(
  root
);

const settings = getPromptsClientSettings();
const copilotProductLinks = settings.copilotProductUrls;
const capabilitiesLink = getCopilotPortalSettings().capabilitiesSiteUrl;
const clientSettings = getClientSettings();
const whatsNewLink = getCopilotPortalSettings().whatsNewUrl;
const gettingStartedLinks = settings.gettingStartedUrls;
const flightSet = new Set(clientSettings.flights);
enrichFlights(flightSet, dataModel);

const renderApp = (provider: StringProvider) => {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <PageWrapper locale={dataModel.locale} provider={provider}>
      <NotFoundPageContainer
        header={{
          capabilitiesLink: capabilitiesLink,
          copilotProductLinks: postProcessHeaderLinks(copilotProductLinks),
          whatsNewLink: whatsNewLink,
          gettingStartedLinks: postProcessHeaderLinks(gettingStartedLinks),
        }}
        setting={{
          user: clientSettings.user,
          licenses: settings.licenses,
          activeFlights: flightSet,
        }}
        prompts={dataModel.prompts}
      ></NotFoundPageContainer>
    </PageWrapper>,
    root
  );
};

window.loadStrings
  ? window.loadStrings(dataModel.locale).then((strings) => {
      const provider = addStringMap(dataModel.locale, strings, emptyProvider);
      renderApp(provider);
    })
  : renderApp(emptyProvider);

export {};
