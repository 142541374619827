import {
  makeStyles,
  tokens,
  type GriffelStyle,
} from "@fluentui/react-components";
import { iconFilledClassName } from "@fluentui/react-icons";
import type { Appearance } from "../../data";
import {
  highContrastHighlightStyles,
  highContrastHoverStyles,
} from "../sharedStyles";

type Styles = {
  icon: GriffelStyle;
  shareButton: GriffelStyle;
  displayAboveOthers: GriffelStyle;
};

const defaultStyles: Styles = {
  icon: {
    [`&.${iconFilledClassName}`]: {
      color: tokens.colorBrandForeground1,
      ...highContrastHighlightStyles,
    },
    ...highContrastHighlightStyles,
    ...highContrastHoverStyles,
  },
  shareButton: {
    ...highContrastHighlightStyles,
  },
  displayAboveOthers: {
    zIndex: 1,
  },
};

const copilotLabStyles: Styles = {
  ...defaultStyles,
};

const copilotLabGoBoldStyles: Styles = {
  ...defaultStyles,

  icon: {
    ...defaultStyles.icon,
    height: "16px",
    width: "16px",
  },

  shareButton: {
    ...defaultStyles.shareButton,
    height: "20px",
    width: "20px",
  },
};

const inAppStyles: Styles = {
  ...defaultStyles,
};

const m365Styles: Styles = {
  ...defaultStyles,
};

const singleColumnStyles: Styles = {
  ...defaultStyles,
};

const rockSteadyStyles: Styles = {
  ...defaultStyles,
  icon: {
    ...defaultStyles.icon,
    fontSize: "20px",
  },
  shareButton: {
    ...defaultStyles.shareButton,
    height: "32px",
    width: "32px",
    "--colorSubtleBackgroundSelected": tokens.colorBrandBackground2,
    "--colorSubtleBackgroundHover": tokens.colorBrandBackground2,
    "--colorSubtleBackgroundPressed": tokens.colorBrandBackground2,
    ":focus-visible": {
      backgroundColor: tokens.colorBrandBackground2,
    },
  },
};

const useInAppStyles = makeStyles(inAppStyles);
const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const useM365Styles = makeStyles(m365Styles);
const useSingleColumnStyle = makeStyles(singleColumnStyles);
const useRockSteadyStyles = makeStyles(rockSteadyStyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "in-app":
      return useInAppStyles;
    case "web":
      return useCopilotLabStyles;
    case "web-v2":
    case "in-app-v2":
      return useCopilotLabGoBoldStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    case "m365":
    case "bing-web":
    case "win-copilot":
      return useM365Styles;
    case "bing-mobile":
    case "underside":
    case "single-col":
      return useSingleColumnStyle;
    default:
      return useInAppStyles;
  }
};
