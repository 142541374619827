import {
  makeStyles,
  shorthands,
  type GriffelStyle,
} from "@fluentui/react-components";
import type { Appearance } from "../../data";
import {
  highContrastHighlightStyles,
  highContrastHoverStyles,
} from "../sharedStyles";

type Styles = {
  deletes: GriffelStyle;
  spinner: GriffelStyle;
  delete: GriffelStyle;
  span: GriffelStyle;
  icon: GriffelStyle;
};

const defaultStyles: Styles = {
  deletes: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "right",
    height: "24px",
    ...shorthands.gap("4px"),
    ...highContrastHoverStyles,
  },

  spinner: {
    ...shorthands.border("0px"),
    ...shorthands.padding("0px"),
  },

  delete: {
    ...shorthands.border("0px"),
    ...shorthands.padding("0px"),
    minWidth: "24px",
    maxWidth: "24px",
    ...highContrastHoverStyles,
  },

  span: {
    width: "20px",
    height: "20px",
    fontSize: "20px",
  },

  icon: {
    fontSize: "20px",
    ...highContrastHighlightStyles,
  },
};

const copilotLabStyles: Styles = {
  ...defaultStyles,
};

const copilotLabGoBoldStyles: Styles = {
  ...defaultStyles,

  deletes: {
    ...defaultStyles.deletes,
    height: "20px",
  },

  delete: {
    ...defaultStyles.delete,
    minWidth: "20px",
    maxWidth: "20px",
  },

  span: {
    ...defaultStyles.span,
    height: "16px",
    width: "16px",
    fontSize: "16px",
  },

  icon: {
    ...defaultStyles.icon,
    fontSize: "16px",
  },
};

const rockSteadyStyles: Styles = {
  ...defaultStyles,

  deletes: {
    ...defaultStyles.deletes,
    height: "32px",
  },

  delete: {
    ...defaultStyles.delete,
    minWidth: "32px",
    maxWidth: "32px",
  },
};

const useCopilotLabStyles = makeStyles(copilotLabStyles);
const useCopilotLabGoBoldStyles = makeStyles(copilotLabGoBoldStyles);
const useRockSteadyStyles = makeStyles(rockSteadyStyles);

export const useStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case "web-v2":
    case "in-app-v2":
      return useCopilotLabGoBoldStyles;
    case "in-app-v3":
    case "web-v3":
      return useRockSteadyStyles;
    default:
      return useCopilotLabStyles;
  }
};
