import * as React from "react";
import { useContext } from "react";
import { LoggerContext } from "../context/LoggerContext";
import { promptCardLogger } from "../loggers/promptCardLogger";
import { promptLibraryLogger } from "../loggers/promptLibraryLogger";
import { getLogger } from "../utils/getLogger";

export const useLogger = () => {
  const loggerContext = useContext(LoggerContext);
  const logger = getLogger(loggerContext);

  return React.useMemo(() => {
    if (!logger || !loggerContext?.scenarioName || !loggerContext?.logicalId) {
      return undefined;
    }

    return {
      promptCard: promptCardLogger({
        logger,
        logicalId: loggerContext.logicalId,
        scenarioName: loggerContext.scenarioName,
        metaData: loggerContext.metaData,
      }),
      promptLibrary: promptLibraryLogger({
        logger,
        logicalId: loggerContext.logicalId,
        scenarioName: loggerContext.scenarioName,
        metaData: loggerContext.metaData,
      }),
    };
  }, [
    logger,
    loggerContext?.scenarioName,
    loggerContext?.logicalId,
    loggerContext?.metaData,
  ]);
};
