/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { HydratedEntityType } from "@1js/pl-types";
import { Tag, Text, Tooltip, mergeClasses } from "@fluentui/react-components";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import * as React from "react";
import { EntityIcon } from "../../EntityIcons";
import type { Appearance, HydratedEntity } from "../../data";
import { useStyles } from "./PromptCommand.styles";

export type EntityType =
  | "Channel"
  | "Chat"
  | "Connector"
  | "Email"
  | "Event"
  | "External"
  | "File"
  | "LoopWS"
  | "Meeting"
  | "Message"
  | "People"
  | "Person"
  | "Site"
  | "SPFolder"
  | "SPList"
  | "SPSite";

export type Entity = {
  type: EntityType;
  referenceId: string;
  accessUrl?: string;
  fileName?: string;
};

export type PromptCommandProps = {
  command: string;
  isTooltipEnabled?: boolean;
  appearance?: Appearance;
  showEntityIcon?: boolean;
  entities?: HydratedEntity[];
  className?: string;
};

// ref:https://microsoft.sharepoint.com/:w:/r/teams/msai/_layouts/15/Doc.aspx?sourcedoc=%7B7FBCBFCF-6F71-43D5-B682-BDE49E25DDF7%7D&file=M365%20Chat%20-%20Engineering%20Spec%20-%20Prompt%20Placeholder%20Schema.docx&action=default&mobileredirect=true&share=IQHPv7x_cW_VQ7aCveSeJd33AfCZbGs-46NAZ3mu9X3Zt4c&isSPOFile=1&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA3MjgwNjkwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D
const entityTypes = [
  "channel",
  "chat",
  "connector",
  "email",
  "event",
  "external",
  "file",
  "loopws",
  "meeting",
  "message",
  "people",
  "person",
  "site",
  "spfolder",
  "splist",
  "spsite",
];

const entityTagName = "entity";
const placeholderTagName = "placeholder";
const allowedHtmlTags = [
  "b",
  "i",
  "u",
  "ul",
  "ol",
  "li",
  "br",
  "a",
  entityTagName,
  placeholderTagName,
];
const entityTypeName = "type";
const entityReferenceIdName = "referenceid";

/**
 * Get the display name of the entity based on its type.
 * @param entity HydratedEntity
 * @returns The display name of the entity or undefined if not found.
 */
export const getEntityDisplayName = (entity?: HydratedEntity) => {
  if (!entity) {
    return undefined;
  }

  const entityType = entity.Type;
  switch (entityType) {
    case "People":
    case "Person" as HydratedEntityType:
      return entity.Entity.DisplayName;
    case "File":
      return entity.Entity.FileName;
    case "Event":
    case "Meeting" as HydratedEntityType:
      return entity.Entity.Subject;
  }

  return undefined;
};

export const sanitizePromptCommand = (
  command: string,
  entityTag: (
    children: JSX.Element,
    entity?: HydratedEntity,
    isHydrated?: boolean
  ) => JSX.Element,
  placeholderTag: (children: any) => JSX.Element,
  entities?: HydratedEntity[]
) => {
  const sanitizedHTML = DOMPurify.sanitize(command, {
    IN_PLACE: true,
    ALLOWED_TAGS: allowedHtmlTags,
    ALLOWED_ATTR: ["target", "href", entityTypeName, entityReferenceIdName],
  });

  const sanitizedText = parse(sanitizedHTML, {
    replace: (domNode: any) => {
      if (domNode.tagName === entityTagName) {
        const entityType: string = domNode.attribs[entityTypeName]
          .toLowerCase()
          .trim();
        if (entityTypes.includes(entityType) && domNode.children.length > 0) {
          const referenceId = domNode.attribs[entityReferenceIdName]
            ?.toLowerCase()
            .trim();
          const entity = entities?.find(
            (entity) =>
              entity.Entity.ReferenceId.trim().toLowerCase() === referenceId
          );
          return entityTag(
            getEntityDisplayName(entity) || domNode.children[0].data,
            entity,
            !!referenceId
          );
        }
      }

      if (
        domNode.tagName === placeholderTagName &&
        domNode.children.length > 0
      ) {
        return placeholderTag(domNode.children[0].data);
      }
    },
  });

  return sanitizedText;
};

export const PromptCommand: React.FC<PromptCommandProps> = ({
  isTooltipEnabled,
  command,
  appearance,
  showEntityIcon,
  entities,
  className,
}) => {
  const styles = useStyles(appearance)();
  const isGoBold = appearance === "web-v2" || appearance === "in-app-v2";
  const isRockSteady = appearance === "in-app-v3" || appearance === "web-v3";
  const sanitizedText = sanitizePromptCommand(
    command,
    (children: any, entity?: HydratedEntity, isHydrated?: boolean) => {
      return isGoBold || isRockSteady ? (
        <Text className={mergeClasses(styles.body, styles.entityText)}>
          {isHydrated ? "" : "/"}
          {children}
        </Text>
      ) : (
        <Tag
          appearance="filled"
          size="small"
          shape="rounded"
          className={styles.tag}
          media={
            showEntityIcon && entity ? (
              <EntityIcon entity={entity} iconSize={16} />
            ) : undefined
          }
        >
          <Text className={mergeClasses(styles.body, styles.tagText)}>
            {children}
          </Text>
        </Tag>
      );
    },
    (children: JSX.Element) => {
      return <Text className={styles.placeholder}>[{children}]</Text>;
    },
    entities
  );

  return isTooltipEnabled ? (
    <Tooltip content={sanitizedText} relationship="label">
      <div className={mergeClasses(styles.body, className)}>
        {sanitizedText}
      </div>
    </Tooltip>
  ) : (
    <div className={mergeClasses(styles.body, className)}>{sanitizedText}</div>
  );
};
